import { Directive, forwardRef, Inject, OnDestroy } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { positionElements } from './positioning';

// See https://github.com/ng-bootstrap/ng-bootstrap/issues/1012#issuecomment-426652688
// NOTE THIS DOES NOT SEEM TO WORK... AUTHOR NOTED IT IS FOR BS3 NOT BS4

@Directive({
  selector: '[ngbDropdown][appDropdownAppendToBody]'
})
export class DropdownAppendToBodyDirective implements OnDestroy {

  private onChangeSubscription: Subscription;

  constructor(@Inject(forwardRef(() => NgbDropdown)) private dropdown: NgbDropdown) {

    this.onChangeSubscription = this.dropdown.openChange.subscribe((open: boolean) => {
      this.dropdown['_menu'].position = (triggerEl: HTMLElement, placement: string) => {
        if (!this.isInBody()) {
          this.appendMenuToBody();
        }
        positionElements(triggerEl, this.dropdown['_menu']['_elementRef'].nativeElement, placement, true);
      };

      if (open) {
        if (!this.isInBody()) {
          this.appendMenuToBody();
        }
      } else {
        setTimeout(() => this.removeMenuFromBody());
      }
    });
  }

  ngOnDestroy() {
    this.removeMenuFromBody();
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
  }

  private isInBody() {
    return this.dropdown['_menu']['_elementRef'].nativeElement.parentNode === document.body;
  }

  private removeMenuFromBody() {
    if (this.isInBody()) {
      window.document.body.removeChild(this.dropdown['_menu']['_elementRef'].nativeElement);
    }
  }

  private appendMenuToBody() {
    window.document.body.appendChild(this.dropdown['_menu']['_elementRef'].nativeElement);
  }
}
