<div>

  <h3>Notes</h3>

  <br />
  <h4>Documentation</h4>
  <p>
    The documentation for most resource methods includes the following information:
  </p>
  <ul>
    <li>The full API endpoint with any possible parameters.</li>
    <li>An overview of the resource including what properties are part of the resource.</li>
    <li>Sample request URL, request headers, request body, response headers, and response body.</li>
    <li>Possible result codes.</li>
    <li>
      A test form that allows you to enter request headers, parameters, and body information and
      run a live test to observe the data submitted in the request and the response received.
    </li>
  </ul>

</div>
