<table class="table table-striped table-bordered table-sm table-hover">
  <thead>
    <tr>
      <th width="10%">Header</th>
      <th width="40%">Value</th>
      <th width="50%">Comments</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Content-Type</td>
      <td>application/vnd.ib.api-v?+json</td>
      <td>Where ? is the version number.  See <a [routerLink]="['/', 'overview', 'versions']">versions</a> for specific versions supported.</td>
    </tr>
    <tr>
      <td>Authorization</td>
      <td>An authorization token for the user.</td>
      <td>
        Depending on the age of the authorization token used in the request this may represent a refreshed token.
        If this value is present and differs from the token used in the request then this refreshed token should
        be used for future requests.
      </td>
    </tr>
    <tr>
      <td>X-Language</td>
      <td>The language used for the response.</td>
      <td>This is the language submitted with the request or the language configured for the current user.</td>
    </tr>
    <tr>
      <td>X-Api-Server</td>
      <td>The name of the api server responding to the API request.</td>
      <td>This is only included when the request includes a X-Trace=True header.</td>
    </tr>
    <tr>
      <td>X-Data-Server</td>
      <td>The name of the data server responding to the API request.</td>
      <td>This is only included when the request includes a X-Trace=True header.</td>
    </tr>
    <tr>
      <td>X-Data-Store</td>
      <td>The name of the data store utilized for this API request.</td>
      <td>This is only included when the request includes a X-Trace=True header.</td>
    </tr>
    <tr>
      <td>X-Core-Version</td>
      <td>The core version number of the back end data schema and services.</td>
      <td>This is only included when the request includes a X-Trace=True header.</td>
    </tr>
    <tr>
      <td>X-Auth-User</td>
      <td>The name of the authorized user based on the authorization token or api key provided.</td>
      <td>This is only included when the request includes a X-Trace=True header.</td>
    </tr>
  </tbody>
</table>
