import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/api/ApiModels";
declare var AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

@Component({
  selector: 'app-endpoint-sample',
  templateUrl: './endpoint-sample.component.html',
  styleUrls: ['./endpoint-sample.component.scss']
})
export class EndpointSampleComponent implements OnInit, OnChanges {

  @Input() version: number = AppConfig.apiVersion;
  @Input() httpMethod: string = "";
  @Input() url: string = "";
  @Input() requestHeaders: any = {
    "Authorization": "Bearer eyJ0eXAiOiJKV.sample.5QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzZWxmIiwiYXVkIjoiaHR0cDovL",
    "Accept": `application/vnd.ib.api-V${AppConfig.apiVersion}+json`,
    "X-Api-Version": AppConfig.apiVersion,
    "X-Meta-Data": false,
    "X-Trace": false,
    "X-HTTP-Response-Override": false
  };
  @Input() requestBody: any = {};
  @Input() requestModelDocumentation: m.ApiDocDataModel = null;
  @Input() responseHeaders: any = {
    "pragma": "no-cache",
    "x-api-server": "TEST-SERVER-015",
    "content-type": "application/vnd.ib.api-V5+json; charset=utf-8",
    "cache-control": "no-cache",
    "expires": "-1"
  };
  @Input() responseBody: any = {};
  @Input() responseModelDocumentation: m.ApiDocDataModel = null;


  public requestModelDocumentationVisible: boolean = false;
  public responseModelDocumentationVisible: boolean = false;



  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(
    protected appService: AppService,
    public apiService: ApiService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  protected init(): void {
    // See if we have the objects that will tell us we're ready
    if (!this.responseBody) {
      return;
    }
    if (this.version) {
      this.requestHeaders["X-Api-Version"] = this.version;
      this.requestHeaders["Accept"] = `application/vnd.ib.api-V${this.version}+json`;
      this.responseHeaders["content-type"] = `application/vnd.ib.api-V${this.version}+json`;
    }
  }

}
