import { TableColumnOptions, PrimeColumn } from "projects/common-lib/src/lib/table/table-column-options";
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
//import { Column } from 'primeng/components/common/shared';
import { Table } from "primeng/table";
import { TableOptions } from "projects/common-lib/src/lib/table/table-options";
import { SortMeta } from "primeng/api";
import { ApiService } from "projects/core-lib/src/lib/api/api.service";
import { Api } from "projects/core-lib/src/lib/api/Api";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";
import { ApiOperationType, CacheLevel, Query, IApiResponseWrapperTyped, ApiProperties } from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Enumerable from 'linq';
import * as moment from "moment";
import { ApiModuleCore } from "projects/core-lib/src/lib/api/Api.Module.Core";
import { Action, ButtonItem, EventElementModel, EventModel } from "../ux-models";
import { EventEmitter, QueryList, TemplateRef } from "@angular/core";
import { StaticPickList } from "projects/core-lib/src/lib/models/model-helpers";
import { isLabeledStatement } from "typescript";

export class TableHelper {


  public static buildDefaultTableOptions(apiProperties: ApiProperties, propertyNames: string = ""): TableOptions {

    // TODO accept api name and generic model type to see what properties we can turn into columns.
    const options = new TableOptions();
    options.theme = "striped";
    options.tableId = `${apiProperties.id}DataTable`;
    options.rowsPerPage = 10;
    options.apiName = apiProperties.id;
    options.apiProperties = apiProperties;
    if (Helper.isArray(apiProperties.documentation.objectPrimaryKey)) {
      options.primaryKey = apiProperties.documentation.objectPrimaryKey[0] as string;
    } else {
      options.primaryKey = apiProperties.documentation.objectPrimaryKey as string;
    }

    // Put our suggested properties into an array so we can use that to determine default column visibility
    let suggestedProperties: string[] = [];
    if (propertyNames) {
      suggestedProperties = Helper.parseCsvString(propertyNames);
    }

    // Get list of all property names from the api meta information
    let allProperties: string[] = [];
    if (!allProperties || allProperties.length === 0) {
      apiProperties.endpoints.forEach((endpoint) => {
        if (endpoint.type === ApiOperationType.List && endpoint.documentation) {
          allProperties = Helper.objectGetPropertyNameList(endpoint.documentation.responseDataModelObject || apiProperties.documentation.responseDataModelObject);
        }
      });
    }
    if (!allProperties || allProperties.length === 0) {
      allProperties = Helper.objectGetPropertyNameList(apiProperties.documentation.responseDataModelObject);
    }

    // Now step through properties and create column objects
    let count: number = 0;
    allProperties.forEach(property => {
      const col = new TableColumnOptions(property);
      // If we have suggested property names and this one isn't in that list then mark as not visible
      if (suggestedProperties && suggestedProperties.length > 0 && suggestedProperties.indexOf(property) === -1) {
        col.visible = false;
      } else if (count > 5 || Helper.equals(property, options.primaryKey, true)) {
        // We don't want to include the PK in the table and we don't want more than 5 columns to be visible
        col.visible = false;
      }
      options.columns.push(col);
      if (col.visible) {
        count++;
      }
    });

    return options;

  }


  public static changeRowActionLabelAndIcon(options: TableOptions, actionId: string, newLabel: string, newIcon: string): void {

    if (!options?.rowActionButton?.options || options.rowActionButton.options.length === 0) {
      return;
    }

    options.rowActionButton.options.forEach(option => {
      if (Helper.equals(option.actionId, actionId, true)) {
        option.label = newLabel || option.label;
        option.icon = newIcon || option.icon;
      }
    });

    return;

  }


  public static getDateRangeActionButton(defaultDateRange: string, onSelect: (event?: EventModel) => any, includeNone: boolean, includeCustom: boolean): ButtonItem {

    const button = new ButtonItem(`Pick Date Range`, "calendar-alt", "primary", null);
    button.size = "sm";
    // button.getLabel = (data?: any, cargo?: any) => {
    //   console.error("getLabel", data, cargo);
    //   let label: string = "Pick Date";
    //   return label;
    // };

    if (!defaultDateRange) {
      defaultDateRange = "Last 3 Days";
    }

    const dateRangeOptions = StaticPickList.DateRangeOptions();
    const matches = dateRangeOptions.filter(x => Helper.equals(x.Value, defaultDateRange, true));
    if (matches && matches.length > 0) {
      button.cargo = { Value: matches[0].Value, DisplayText: matches[0].DisplayText };
      button.label = matches[0].DisplayText;
      // Fire onSelect callback with our inital value
      onSelect(new EventModel("init", null, null, new EventElementModel("action", matches[0].Value, "", matches[0].DisplayText)));
    } else {
      Log.errorMessage(`Unknown default date range '${defaultDateRange}'.`);
    }

    if (includeNone) {
      const action: Action = new Action("NONE", "None");
      action.action = (event: EventModel) => {
        // First update our cargo so we can update our label via getLabel
        button.cargo = { Value: event.element.id, DisplayText: event.element.label };
        button.label = "Pick Date Range";
        // Pass event to our callback so things like filters can be set
        onSelect(event);
      };
      button.options.push(action);
    }

    dateRangeOptions.forEach(option => {
      if (!includeCustom && Helper.equals(option.Value, "Custom", true)) {
        return; //return = continue
      }
      const action: Action = new Action(option.Value, option.DisplayText);
      action.action = (event: EventModel) => {
        // First update our cargo so we can update our label via getLabel
        button.cargo = { Value: event.element.id, DisplayText: event.element.label };
        button.label = event.element.label || "Pick Date Range";
        // Pass event to our callback so things like filters can be set
        onSelect(event);
      };
      button.options.push(action);
    });
    button.scrollHeight = "auto";

    return button;

  }




  public static buildColumnOptionsFromData(data: any[]): TableColumnOptions[] {

    // Crap... how do we get info from our data when we don't have any data?
    if (!data || data.length === 0) {
      return [];
    }

    let options: TableColumnOptions[] = [];

    let properties = Helper.objectGetPropertyNameList(data[0]);
    properties.forEach((property) => {
      let col = new TableColumnOptions(property, Helper.formatIdentifierWithSpaces(property));
      col.dataType = "string";
      if (typeof (data[0][property]) === typeof (true)) {
        col.dataType = "boolean";
      }
      options.push(col);
    });

    return options;

  }


  public static getColumn(options: TableOptions, propertyName: string, returnNullWhenNotFound: boolean = true): TableColumnOptions {

    if (!options || !propertyName) {
      if (returnNullWhenNotFound) {
        return null;
      } else {
        return new TableColumnOptions();
      }
    }

    let match = Helper.firstOrDefault(options.columns, x => Helper.equals(x.propertyName, propertyName, true));
    if (match) {
      return match;
    }

    if (returnNullWhenNotFound) {
      return null;
    } else {
      return new TableColumnOptions();
    }

  }


  public static setFooter(options: TableOptions, propertyName: string, footerHtml: string): void {

    if (!options || !propertyName) {
      return;
    }

    let match = Helper.firstOrDefault(options.columns, x => Helper.equals(x.propertyName, propertyName, true));
    if (match) {
      match.footerHtml = footerHtml;
      // If we're setting a column footer then we want footers turned on
      options.footer = true;
    } else {
      Log.errorMessage(`Unable to find property name ${propertyName} in columns in order to set footer value ${footerHtml}`);
    }

    return;

  }


  public static assignColumnDataTypesFromData(cols: TableColumnOptions[], data: any[]): TableColumnOptions[] {

    // Crap... how do we get info from our data when we don't have any data?
    if (!data || data.length === 0) {
      return cols;
    }

    cols.forEach(col => {
      if (!col.dataType || Helper.equals(col.dataType, "unknown", true)) {
        col.dataType = "string";
        try {
          if (typeof (data[0][col.propertyName]) === typeof (true)) {
            col.dataType = "boolean";
          }
        } catch (err) {
          Log.errorMessage(err);
        }
      }
    });

    return cols;

  }


  public static toPrimeColumn(col: TableColumnOptions): PrimeColumn {
    let primeCol: PrimeColumn = new PrimeColumn();
    if (!col) {
      return primeCol;
    }
    primeCol.field = col.propertyName;
    primeCol.sortable = col.sortable;
    primeCol.header = col.header;
    primeCol.resizable = col.resizable;
    primeCol.editable = col.editable;
    primeCol.hidden = !col.visible;
    primeCol.filterType = col.filterType;
    primeCol.filterMatchMode = col.filterMatchMode;
    primeCol.excludeGlobalFilter = !col.includeInGlobalFilter;
    (<any>primeCol).options = col;
    //(<any>primeCol).options = {};
    //(<any>primeCol).options.dataType = col.dataType;
    //(<any>primeCol).options.wrap = col.wrap;
    //(<any>primeCol).options.allowHtml = col.allowHtml;
    //(<any>primeCol).options.filterValue = col.filterValue;
    //(<any>primeCol).options.filterSelections = col.filterSelections;
    return primeCol;
  }

  public static fromPrimeColumn(primeCol: PrimeColumn): TableColumnOptions {
    let col: TableColumnOptions = new TableColumnOptions();
    if (!primeCol) {
      return col;
    }
    col.propertyName = primeCol.field;
    col.header = primeCol.header;
    col.sortable = primeCol.sortable;
    col.resizable = primeCol.resizable;
    col.editable = primeCol.editable;
    col.visible = !primeCol.hidden;
    col.filterType = (<any>primeCol).filterType;
    col.filterMatchMode = primeCol.filterMatchMode;
    col.includeInGlobalFilter = !primeCol.excludeGlobalFilter;
    if ((<any>primeCol).options) {
      col.dataType = (<any>primeCol).options.dataType;
      col.wrap = (<any>primeCol).options.wrap;
      col.allowHtml = (<any>primeCol).options.allowHtml;
      col.filterValue = (<any>primeCol).options.filterValue;
      col.filterSelections = (<any>primeCol).options.filterSelections;
    }
    return col;
  }

  public static toPrimeColumns(cols: TableColumnOptions[]): PrimeColumn[] {
    const primeColumns: PrimeColumn[] = [];
    if (!cols || cols.length === 0) {
      return primeColumns;
    }
    cols.forEach((col) => {
      if (col.visible) {
        primeColumns.push(TableHelper.toPrimeColumn(col));
      }
    });
    return primeColumns;
  }


  public static toPrimeSort(sort: string, options: TableOptions, table: Table): void {

    if (Helper.equals(options.sortMode, "none", true)) {
      return;
    }

    // Our native sort mode is CSV of properties with "-" property name prefix when descending
    const cols: string[] = Helper.parseCsvString(sort);
    if (!cols || cols.length === 0) {
      return;
    }

    if (Helper.equals(options.sortMode, "single", true)) {
      if (Helper.startsWith(cols[0], "-", true)) {
        table.sortOrder = -1;
        table.sortField = cols[0].substring(1);
      } else {
        table.sortOrder = 1;
        table.sortField = cols[0];
      }
      return;
    }

    // Multiple sort mode
    const multiple: SortMeta[] = [];
    cols.forEach((col: string) => {
      if (Helper.startsWith(col, "-", true)) {
        multiple.push({ order: -1, field: col.substring(1) });
      } else {
        multiple.push({ order: 1, field: col });
      }
    });
    table.multiSortMeta = multiple;

    return;

  }


  public static fromPrimeSort(event: any): string {

    let sort: string = "";

    // For single column sort config sort is stored here
    if (event?.sortField) {
      if (event.sortOrder === -1) {
        sort = `-${event.sortField}`;
      } else {
        sort = event.sortField;
      }
    }

    // For multi-column sort config sort is stored here even if only one column is defined
    // Oddly sometimes we get different casing on this event property so we need to check twice!!!
    if (event?.multiSortMeta && event.multiSortMeta.length > 0) {
      event.multiSortMeta.forEach((one) => {
        if (sort) {
          sort += ",";
        }
        if (one.order === -1) {
          sort += `-${one.field}`;
        } else {
          sort += one.field;
        }
      });
    } else if (event?.multisortmeta && event.multisortmeta.length > 0) {
      event.multisortmeta.forEach((one) => {
        if (sort) {
          sort += ",";
        }
        if (one.order === -1) {
          sort += `-${one.field}`;
        } else {
          sort += one.field;
        }
      });
    }

    // Our native sort mode is CSV of properties with "-" property name prefix when descending
    return sort;

  }


  public static toPrimePage(page: number, options: TableOptions, table: Table): void {

    let first: number = 0;

    if (page && page > 1) {
      // Page 2 => first: 5 , rows: 5 (5/5) = 1 + 1 = 2
      // Page 3 => first: 10 , rows 5 (10/5) = 2 + 1 = 3
      first = ((page - 1) * options.rowsPerPage);
    }

    table.first = first;

    return;

  }

  public static fromPrimePage(event): number {
    let page: number = 1;
    if (event?.first && event?.rows) {
      // Page 2 => first: 5 , rows: 5 (5/5) = 1 + 1 = 2
      // Page 3 => first: 10 , rows 5 (10/5) = 2 + 1 = 3
      page = (event.first / event.rows) + 1;
    }
    return page;
  }


  public static rowStyleWhenTruthy(propertyName: string, style: string): (row: any) => string {
    const func = (row: any) => {
      try {
        if (row[propertyName]) {
          return style;
        } else {
          return "";
        }
      } catch (err) {
        Log.errorMessage(err);
      }
      return "";
    };
    return func;
  }

  public static rowStyleWhenFalsy(propertyName: string, style: string): (row: any) => string {
    const func = (row: any) => {
      try {
        if (!row[propertyName]) {
          return style;
        } else {
          return "";
        }
      } catch (err) {
        Log.errorMessage(err);
      }
      return "";
    };
    return func;
  }

  public static rowStyleWhenDateTimeBeforeNow(propertyName: string, style: string): (row: any) => string {
    const func = (row: any) => {
      try {
        if (row[propertyName]) {
          if (moment().isSameOrAfter(moment(row[propertyName]))) {
            return style;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
      return "";
    };
    return func;
  }

  public static rowStyleWhenDateTimeAfterNow(propertyName: string, style: string): (row: any) => string {
    const func = (row: any) => {
      try {
        if (row[propertyName]) {
          if (moment().isSameOrBefore(moment(row[propertyName]))) {
            return style;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
      return "";
    };
    return func;
  }

  public static columnStyleWhenTruthy(style: string): (data: any, row: any) => string {
    const func = (data: any, row: any) => {
      if (data) {
        return style;
      } else {
        return "";
      }
    };
    return func;
  }

  public static columnStyleWhenFalsy(style: string): (data: any, row: any) => string {
    const func = (data: any, row: any) => {
      if (!data) {
        return style;
      } else {
        return "";
      }
    };
    return func;
  }

  public static columnStyleWhenDateTimeBeforeNow(style: string): (data: any, row: any) => string {
    const func = (data: any, row: any) => {
      try {
        if (data) {
          if (moment().isSameOrAfter(moment(data))) {
            return style;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
      return "";
    };
    return func;
  }

  public static columnStyleWhenDateTimeAfterNow(style: string): (data: any, row: any) => string {
    const func = (data: any, row: any) => {
      try {
        if (data) {
          if (moment().isSameOrBefore(moment(data))) {
            return style;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
      return "";
    };
    return func;
  }

  /**
   * Helper to set filter type on all columns.  Frequently used when not using lazy loading and multiselect is preferred.
   * @param options
   * @param filterType
   */
  public static setAllColumnFilterType(columns: TableColumnOptions[], filterType: "none" | "text" | "select" | "multiselect", onlyColumnsWithFilter: boolean = true): void {
    if (!columns) {
      return;
    }
    columns.forEach((col: TableColumnOptions) => {
      if (!onlyColumnsWithFilter || !Helper.equals(col.filterType, "none", true)) {
        col.filterType = filterType;
      }
    });
  }

  public static setAllColumnsSortable(columns: TableColumnOptions[], sortable: boolean): void {
    if (!columns) {
      return;
    }
    columns.forEach((col: TableColumnOptions) => {
      col.sortable = sortable;
    });
  }

  public static setColumnHeader(
    col: TableColumnOptions,
    header: string,
    headerIcon: string = "",
    filterType: "none" | "text" | "select" | "multiselect" = "none",
    sortable: boolean = false): void {

    col.header = header;
    col.headerIcon = headerIcon;
    col.filterType = filterType;
    col.sortable = sortable;

  }

  public static setColumnPickListId(apiService: ApiService, col: TableColumnOptions, pickListId: string) {

    if (!apiService || !col || !pickListId) {
      return;
    }

    // Support late render for delayed column refresh
    col.supportLateRender = true;

    let apiProp = ApiModuleCore.InputPickList();
    let apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    apiCall.silent = true;
    // Tweak caching
    if (Helper.startsWith(pickListId, "___")) {
      // Don't cache type-ahead pick lists like we do others
      apiCall.cacheIgnoreOnRead = true;
    } else if (Helper.startsWith(pickListId, "__")) {
      // Static data model options don't change
      apiCall.cacheLevel = CacheLevel.Static;
    }
    let query = new Query();
    query.Page = 1;
    query.Size = Constants.RowsToReturn.All;
    (<any>query).PickListId = pickListId;
    apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>) => {
      if (!result.Data.Success) {
        Log.errorMessage(result.Data);
      } else {
        col.optionsPickListId = pickListId;
        col.optionsPickList = result.Data.Data;
        // Now set mapping to show pick list description in our table output
        col.dataType = "function";
        col.render = (row: any) => {
          let value = row[col.propertyName];
          let item = Enumerable.from(col.optionsPickList).firstOrDefault(x => Helper.equals(x.Value, value, true), null);
          if (item) {
            return item.DisplayText || value;
          } else {
            return value;
          }
        }
      }
    });

  }



}
