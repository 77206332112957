<!-- We are not suppressing owner keys, not using an anon object based on path model properties, and
    don't have test form properties defined so provide a control for any owner key properties in our data model. -->
<ng-container *ngIf="!testFormSuppressPromptForOwnerKey && !testFormUseAnonymousObjectForPathModelProperties && !testFormProperties">
  <ng-container *ngFor="let col of modelDocumentationOwnerKeys;trackBy: trackByIndex">
    <ib-input-text name="{{col.Name}}" label="{{col.Description}}" [(ngModel)]="requestData[col.Name]"></ib-input-text>
  </ng-container>
</ng-container>

<!-- We are using an anon object based on path model properties and have not defined test form properties
    so provide a control for each path model property in our array of properties that is mapped in the url. -->
<ng-container *ngIf="(testFormUseAnonymousObjectForPathModelProperties || !modelDocumentationIncludesOwnerKeys) && !testFormProperties">
  <ng-container *ngFor="let col of pathModelPropertiesMappedInUrl;trackBy: trackByIndex">
    <ib-input-text name="{{col}}" label="{{col | formatText : 'SpaceDelimited'}}" [(ngModel)]="requestData[col]"></ib-input-text>
  </ng-container>
</ng-container>

<!-- We have test form properties defined so use that to build our test form. -->
<div *ngIf="testFormProperties && testFormProperties.length > 0">
  <app-endpoint-test-form-custom-controls [requestData]="requestData"
                                          [requestPathProperties]="requestPathProperties">
  </app-endpoint-test-form-custom-controls>
</div>

<!-- include common list properties if found in url -->
<ib-input-number *ngIf="apiCall.url.indexOf('{page}') > -1"
                 type="integer" width="medium" name="Page Number" label="Page Number"
                 [(ngModel)]="requestData.Page"></ib-input-number>

<ib-input-number *ngIf="apiCall.url.indexOf('{size}') > -1"
                 type="integer" width="medium" name="Page Size" label="Page Size"
                 [(ngModel)]="requestData.Size"></ib-input-number>

<ib-input-text *ngIf="apiCall.url.indexOf('{sort}') > -1"
               name="Sort" label="Sort" [(ngModel)]="requestData.Sort"></ib-input-text>

<ib-input-number *ngIf="apiCall.url.indexOf('{filterId}') > -1"
                 type="integer" width="medium" name="Filter Id" label="Filter Id"
                 [(ngModel)]="requestData.FilterId"></ib-input-number>

<ib-input-text *ngIf="apiCall.url.indexOf('{filter}') > -1"
               name="Filter" label="Filter" [(ngModel)]="requestData.Filter"></ib-input-text>

<ib-input-text *ngIf="apiCall.url.indexOf('{q}') > -1"
               name="Free Query" label="Free Query (q)" [(ngModel)]="requestData.Q"></ib-input-text>

<ib-input-text *ngIf="apiCall.url.indexOf('{expand}') > -1"
               name="Expand" label="Expand" [(ngModel)]="requestData.Expand"></ib-input-text>

<!-- we asked for testFormAllowFreeFormQueryString -->
<ib-input-text *ngIf="testFormAllowFreeFormQueryString"
               name="Other Query String" label="Other Query String"
               placeholder="Other Query String Parameters"
               tooltip="{{testFormAllowFreeFormQueryStringTooltip}}"
               [(ngModel)]="requestData.TestFreeFormQueryStringParameters"></ib-input-text>

<div class="row mt-1 mb-2">
  <div class="{{Constants.Layout.percent33}}"></div>
  <div class="{{Constants.Layout.percent66}}">
    <ib-button *ngIf="testButtonText" name="Test Submit Button"
               icon="{{testButtonIcon}}" label="{{testButtonText}}" color="{{testButtonContextColor}}" [working]="working"
               (click)="onSubmit($event)">
    </ib-button>
    &nbsp;&nbsp;
    <ib-button *ngIf="testButton2Text && testButton2Action" name="Test Submit2 Button"
               icon="{{testButton2Icon}}" label="{{testButton2Text}}" color="{{testButton2ContextColor}}" [working]="working"
               (click)="onSubmit2($event)">
    </ib-button>
  </div>
</div>
