<div>

  <h3>Credentials</h3>

  <br />
  <p>
    Each API call is stateless (i.e. independent of other API calls) and, therefore, requires authentication information to be included
    in the form of either an Authorization header or X-Auth-Key header.  The only exception to this rule is the login API request
    which is used to authenticate a user and to retrieve the authorization token to be used in subsequent API calls.
  </p>

  <h5>Example 1 - API Key</h5>
  <p>
    Example 1: When using an API Key for authentication the X-Auth-Key HTTP header is included with each request:
  </p>
  <ib-code-block>"X-Auth-Key": "A0C-K9ZQ0H-587RUX-1RXSRW-SLGJAKF"</ib-code-block>

  <div class="alert alert-danger" role="alert">
    <span>
      <strong>Warning:</strong> Except for limited use and locked down scenarios,
      <strong>API Keys should <u><em>only</em></u> be used in server based applications</strong>
      and are not suitable for client-side web based applications where the API Key is present in the web browser
      cookies, session storage, local storage, javascript, json data, etc. where a user could possibly find the API Key being used.
    </span>
  </div>

  <h5>Example 2 - Token</h5>
  <p>
    Example 2: When using a token for authentication the Authorization HTTP header is included with each request using a "Bearer " prefix before the token:
  </p>
  <ib-code-block>"Authorization": "Bearer eyJ0eXAiOiJKNiJ9.eyJpIxMzg2MCIsInBpZCI6IjAifQ.-vARX8tmxVo7Omn7K_EkCMR8ZqV3eVVmh15u_67-h8M"</ib-code-block>

  <br />
  <p>
    The <a [routerLink]="['/', 'security', 'login', 'call']">Login API</a> does not require authentication (since it's function is to perform authentication) and
    as part of a successful login response provides a token to be used with the Authorization HTTP header in subsequent API calls.
  </p>

  <br />
  <p>
    The <a [routerLink]="['/', 'security', 'authenticate', 'get']">Authenticate API</a> is not needed since each API call is authenticated independently but can be used
    in testing to validate credentials or to get further insight into why credentials are not working as expected.
  </p>

</div>
