import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

import { ApiCall, ApiHost, ApiOperationType, ApiResponse, IApiResponseWrapper, IApiResponse, ApiResponseWrapper, IApiRequestInformation, CacheLevel, Query, IApiResponseWrapperTyped, ApiProperties } from 'projects/core-lib/src/lib/api/ApiModels';

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as Enumerable from 'linq';
import { Observable, of, from, Subject, AsyncSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { CacheService } from '@ngx-cache/core';
import { AppCacheService } from 'projects/core-lib/src/lib/services/app-cache.service';
import { Router } from '@angular/router';
import { ApiModuleCore } from './Api.Module.Core';
import { Dictionary } from '../models/dictionary';
import * as moment from "moment";
import { Api } from './Api';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /**
  Used by api docs to keep track if our advanced headers on test page are hidden or shown
  */
  public isHttpHeadersAdvancedCollapsed: boolean = false;

  /**
  List of possible ApiHost objects.
  */
  public apiHosts: ApiHost[] = [];

  /**
  The currently selected ApiHost object.
  */
  public selectedHost: ApiHost = null;

  /**
  The current language to use for api requests.  Note that ngx-translate's translate service
  is unhappy if there is any circular references.  We reference it in the TranslationConfigService
  but also the ApiMissingTranslationHandler via MissingTranslationHandlerParams passed into
  handle() and if we inject it here then this ApiService won't get injected into ApiMissingTranslationHandler
  which we need there.  So we expose this property here and TranslationConfigService will
  assign it when things change.  Note that there are no errors about circular references the
  ApiMissingTranslationHandler reference to ApiService is simply undefined but that's the reason.
  */
  public language: string = "en";

  /**
   * The app service will set this when a user logs in.  That service consumes this one so we can't inject it
   * here but having this value set will enable our cache keys to have the partition id to help keep cache
   * separate per partition for scenarios where users may login to different partitions.
   */
  public currentUserPartitionId: number = null;

  /**
  This count goes up and down based on the number of active requests which is then used turn eye candy on or off.
  */
  protected activeRequestCount: number = 0;

  /**
  Progress bar reference we use to start/stop the progress bar.
  */
  protected progress: NgProgressRef;


  constructor(
    protected httpClient: HttpClient,
    public cache: AppCacheService,
    protected progressService: NgProgress,
    protected router: Router) {

    this.progress = this.progressService.ref();
    this.populateApiHosts();

  }


  public populateApiHosts(): void {

    this.apiHosts = AppConfig.apiHosts;

    // Set our initial selected host based on our default api url
    this.selectApiHost(AppConfig.apiUrl);

  }


  /**
  Select a host url as the current api host.
  @param {string} url The url of the selected api host.
  @param {ApiCall} call Optional ApiCall object that should have the new api host applied to it.
  @returns {ApiHost} The api host represented by the supplied host url parameter or null if there is no match.
  */
  public selectApiHost(url: string, call?: ApiCall): ApiHost {

    if (!url) {
      Log.errorMessage(`Unable to find api host when no url is specified.`);
      return null;
    }

    let host = Enumerable.from(this.apiHosts).firstOrDefault(x => Helper.equals(x.url, url, true), null);
    if (!host) {
      Log.errorMessage(`Unable to find api host for url ${url}.`);
      return null;
    }

    // Update our configured api host and our selected host
    AppConfig.apiUrl = url;
    this.selectedHost = host;

    // If we have an api call that we would like to have updated do that now.  This is used in
    // api docs where someone can pick which hot to execute an api call against.
    if (call) {
      ApiHelper.updateApiCall(call, url);
    }

    return host;

  }



  /**
   * A common handler for API success.  This is a function that captures input values we need for
   * our desired operations and then returns a function that is the function used to handle the
   * mapping from HttpResponse<IApiResponse> to IApiResponseWrapper that we want done on success.
   * @param {any} requestData The request data used for the API submission which combined with the
   *                          http error object generated when a non-2xx response is received helps
   *                          us build the expected response object.
   */
  protected handleSuccess(api: ApiCall, requestData: IApiRequestInformation) {
    return (apiResponse: HttpResponse<IApiResponse>): IApiResponseWrapper => {

      // Flag the operation is complete
      this.requestComplete(api);

      // Format our response
      const response = ApiHelper.formatResponseFromHttpResponse(apiResponse, requestData);

      // If we have a cache key then push our response body into the cache for future possible use
      let cacheKey: string = "";
      if (api.type === ApiOperationType.List) {
        cacheKey = requestData.Url;
        if (this.currentUserPartitionId) {
          cacheKey = `P${this.currentUserPartitionId}-${requestData.Url}`;
        }
      } else if (api.type === ApiOperationType.Get) {
        cacheKey = ApiHelper.buildCacheKey(api, requestData.Data, this.currentUserPartitionId);
      } else if (api.type === ApiOperationType.Add ||
        api.type === ApiOperationType.Edit ||
        api.type === ApiOperationType.Patch ||
        api.type === ApiOperationType.Merge ||
        api.type === ApiOperationType.Copy ||
        api.type === ApiOperationType.Delete) {
        if (api.impactedPickListIds && api.impactedPickListIds.length > 0) {
          api.impactedPickListIds.forEach(pickListId => {
            this.dumpPickListCache(pickListId);
          });
        }
        cacheKey = ApiHelper.buildCacheKey(api, response.Data.Data || requestData.Data, this.currentUserPartitionId);
        // Dump all lists for this cache name by removing all that start with "http" since the url is the cache key for lists
        if (this.currentUserPartitionId) {
          this.cache.cacheRemoveValue(api.cacheName, `P${this.currentUserPartitionId}-http`, true);
          this.cache.storedCacheRemoveValue(api.cacheName, `P${this.currentUserPartitionId}-http`, true);
        } else {
          this.cache.cacheRemoveValue(api.cacheName, "http", true);
          this.cache.storedCacheRemoveValue(api.cacheName, "http", true);
        }
        // Some debug logging
        if (AppConfig.debug && cacheKey) {
          if (api.type === ApiOperationType.Delete) {
            Log.debug("data", "Data", `Deleted ${cacheKey} on server.`);
          } else {
            Log.debug("data", "Data", `Saved ${cacheKey} to server.`);
          }
        }
      }

      // Now if our settings dictate we should cache then do that (or in the case of delete dump from cache)
      if (ApiHelper.shouldCache(api, cacheKey, "ignore")) {
        if (api.type === ApiOperationType.Delete) {
          if (api.cacheUseStorage) {
            this.cache.storedCacheRemoveValue(api.cacheName, cacheKey);
          } else {
            this.cache.cacheRemoveValue(api.cacheName, cacheKey);
          }
        } else {
          if (api.cacheUseStorage) {
            this.cache.storedCachePutValue(api.cacheName, cacheKey, response, api.cacheLevel);
          } else {
            this.cache.cachePutValue(api.cacheName, cacheKey, response, api.cacheLevel);
          }
        }
        if (api.type === ApiOperationType.List || api.type === ApiOperationType.Report) {
          Log.debug("cache", "Cache", `Cached ${api.objectShortDescription} list using cache name ${api.cacheName} and cache key ${cacheKey} (storage=${api.cacheUseStorage}).`);
        } else if (api.type === ApiOperationType.Delete) {
          Log.debug("cache", "Cache", `Deleted ${api.objectShortDescription} cache using cache name ${api.cacheName} and cache key ${cacheKey} (storage=${api.cacheUseStorage}).`);
        } else {
          Log.debug("cache", "Cache", `Cached ${api.objectShortDescription} using cache name ${api.cacheName} and cache key ${cacheKey} (storage=${api.cacheUseStorage}).`);
        }
      } else if (AppConfig.debug && cacheKey) {
        Log.debug("data", "Data", `Determine should not cache data for cache key "${cacheKey}".`);
      }

      return response;

    };
  }



  /**
   * A common handler for API errors.  This is a function that captures input values we need for
   * our desired operations and then returns a function that is the expected error handler.
   * @param {any} requestData The request data used for the API submission which combined with the
   *                          http error object generated when a non-2xx response is received helps
   *                          us build the expected response object.
   */
  protected handleError(api: ApiCall, requestData: IApiRequestInformation) {
    return (error: HttpErrorResponse): Observable<IApiResponseWrapper> => {

      // Flag the operation is complete
      this.requestComplete(api);

      // Check if HttpErrorResponse.error is our expected IApiResponse format or if perhaps we got
      // an error response from a web application firewall, web server, etc. before our API could
      // get called to provide our standard response format.
      let expectedFormat: boolean = false;
      if (error.error) {
        if (Helper.isObject(error.error)) {
          // Check the contents of the error object by testing the timestamp string to make sure it doesn't match this "not-expected-format" default
          if (!Helper.equals(Helper.tryGetValue(error.error, x => x.TimeStamp, "not-expected-format"), "not-expected-format", true)) {
            expectedFormat = true;
          }
        }
      }

      let response: IApiResponseWrapper = null;

      if (expectedFormat) {
        // Build our typical response object
        response = ApiHelper.formatResponseFromHttpError(error, requestData);
      } else {
        // This was an error not in expected format!
        console.error("An error intercepted that was not in our expected api response format!");
        console.error(error);
        const stub: IApiResponse = new ApiResponse();
        stub.Success = false;
        stub.ResultCode = error.status;
        stub.ResultText = `HTTP Status ${error.status}`;
        stub.Message = error.statusText;
        stub.Data = error;
        response = ApiHelper.formatResponseFromParts(stub, error.status, error.statusText, ApiHelper.convertHttpHeadersToDictionary(error.headers), requestData);
      }

      // See if we have an auth error and, if so, if we should redirect to login
      try {
        if (api && api.redirectToLoginOnAuthenticationErrors && response && response.Data &&
          (response.Data.ResultCode === m.StandardResultCode.InvalidUserNamePasswordCombination ||
            response.Data.ResultCode === m.StandardResultCode.InvalidAuthenticationToken ||
            response.Data.ResultCode === m.StandardResultCode.InvalidAuthenticationKey)) {

          // remove auth tokens from storage
          ApiHelper.removeAuthToken();

          if (Helper.getQueryStringParameter("returnUrl")) {
            // We already have returnUrl query string parameter so pass it through don't append it again
            this.router.navigate(["/login"], { queryParams: { returnUrl: Helper.getQueryStringParameter("returnUrl") } });
          } else if (this.router.routerState.snapshot.url) {
            // Oddly sometimes this is not provided so we check window.location.pathname first
            this.router.navigate(["/login"], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
          } else if (window.location.pathname) {
            // If we're not on the login page then append our path to returnUrl query string
            if (Helper.startsWith(window.location.pathname, "/login", true)) {
              this.router.navigate(["/login"]);
            } else {
              this.router.navigate(["/login"], { queryParams: { returnUrl: window.location.pathname } });
            }
          } else {
            this.router.navigate(["/login"]);
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }

      // Send back our expected response format
      return of(response);

    };
  }


  /**
   * If the api call is not set to silent we increment the number of active requests and start the progress bar.
   * @param api
   */
  public requestStart(api: ApiCall) {

    // Only showing progress if not tagged as a silent request
    if (!api || api.silent) {
      return;
    }

    // Increment active request counter
    this.activeRequestCount++;

    // Always start even if already started
    this.progress.start();

    // Debug
    //Log.debugMessage(this.activeRequestCount.toString());

  }


  /**
   * If the api call is not set to silent we decrement the number of active requests and if that hits zero we stop the progress bar.
   * @param api
   */
  public requestComplete(api: ApiCall) {

    // Only showing progress if not tagged as a silent request
    if (!api || api.silent) {
      return;
    }

    // Decrement active request counter
    this.activeRequestCount--;
    if (this.activeRequestCount < 0) {
      // We should not encounter a scenario where our active request count is negative
      Log.errorMessage(`API Service Active Request Count: ${this.activeRequestCount}`);
      this.activeRequestCount = 0;
    }

    // If our active request count is now zero then we're done
    if (this.activeRequestCount <= 0) {
      this.progress.complete();
    }

    // Debug
    //Log.debugMessage(this.activeRequestCount.toString());

  }



  /**
  Generic api service call will route to appropriate method based on operation type.  Note that without a subscriber to the
  observable returned the http client may not ever submit the request so even results that are not needed should have a
  subscriber in place.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object used for as input data for this call.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @param {boolean} allowStorageCache Defaults to true and checks local storage cache.  When not in cache makes recursive call to this method with this parameter set to false.
  @returns {Observable<IApiResponseWrapper>} An observable object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public execute(api: ApiCall, inputData?: any, httpHeaderOptions?: any, allowStorageCache: boolean = true): Observable<IApiResponseWrapper> {

    if (!api) {
      Log.errorMessage("No api object provided so no api service call will be executed.")
      // Deferred object that will give us the promise we want
      const response = new ApiResponseWrapper();
      response.Data = new ApiResponse();
      response.Data.Success = false;
      response.Data.Message = "No api object was provided and, therefore, no api call was executed.";
      return of(response);
    }

    // Update the token in case it was refreshed since the api call object was created
    api = ApiHelper.refreshApiCall(api);

    // For api calls that use storage for cache we check the cache here and if not cached make a recursive call with parameter that skips this step.
    if (allowStorageCache && api.cacheUseStorage && !api.cacheIgnoreOnRead && (api.type === ApiOperationType.List || api.type === ApiOperationType.Get)) {
      let cacheKey: string = "";
      if (api.type === ApiOperationType.Get) {
        cacheKey = ApiHelper.buildCacheKey(api, inputData, this.currentUserPartitionId);
      } else if (api.cacheKey) {
        Log.debug("cache", "Cache", `Cache Key: ${api.cacheKey} defined in the ApiCall object.`);
        cacheKey = api.cacheKey;
      } else {
        cacheKey = ApiHelper.buildApiAbsoluteUrl(api, inputData);
        if (this.currentUserPartitionId) {
          cacheKey = `P${this.currentUserPartitionId}-${cacheKey}`;
        }
      }
      // AsyncSubject: A Subject that only emits its last value upon completion
      const subject = new AsyncSubject<IApiResponseWrapper>();
      this.cache.storedCacheGetValue<IApiResponseWrapper>(api.cacheName, cacheKey).then(value => {
        //console.error(apiCall.cacheName, cacheKey, value);
        //this.cache.storedCacheGetLength().then(len => console.error("stored cache length", len));
        //this.cache.storedCacheGetKeys().then(keys => console.error("stored cache keys", keys));
        if (value) {
          Log.debug("cache", "Cache", `Pulled ${api.objectShortDescription} from stored cache with cache key ${cacheKey}`);
          subject.next(value);
          subject.complete();
        } else {
          // Recursive call but with allowStorageCache = false to skip this block and execute http request
          this.execute(api, inputData, httpHeaderOptions, false).subscribe(result => {
            subject.next(result);
            subject.complete();
          });
        }
      });
      return subject.asObservable();
    }

    if (api.type === ApiOperationType.List) {
      return this.list(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Get) {
      return this.get(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Report) {
      // Report is a type of list
      return this.list(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Add) {
      return this.add(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Edit) {
      return this.edit(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Patch) {
      //api.httpMethodOverride = true;
      return this.patch(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Merge) {
      //api.httpMethodOverride = true;
      return this.merge(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Delete) {
      return this.delete(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Copy) {
      return this.copy(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Export) {
      return this.export(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Import) {
      return this.import(api, inputData, httpHeaderOptions);
    } else if (api.type === ApiOperationType.Call) {
      return this.call(api, inputData, httpHeaderOptions);
    } else {
      Log.errorMessage(`Unknown api operation type ${api.type} has no api service method to execute.`);
    }

  }



  /**
  Calls a list API to get back a promise pointing to a collection of objects.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The input data to combine with the API call to help get the desired collection.  This can be
  any type but typically implements IQuery so the typical collection parameters of Page, Size, Sort, Filter, Q, and Expand
  are available.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IResponse.
  */
  public list(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: null, Method: "GET", Headers: requestOptions.headers };
    let cacheKey: string = url;
    if (this.currentUserPartitionId) {
      cacheKey = `P${this.currentUserPartitionId}-${cacheKey}`;
    }
    if (ApiHelper.shouldCache(api, cacheKey, "read")) {
      if (api.cacheUseStorage) {
        // TODO
      } else if (this.cache.cacheKeyExists(api.cacheName, cacheKey)) {
        const cachedValue = this.cache.cacheGetValue<IApiResponseWrapper>(api.cacheName, cacheKey);
        if (cachedValue) {
          Log.debug("cache", "Cache", `Pulled ${api.objectShortDescription} list from cache with cache key ${cacheKey}`);
          return of(cachedValue);
        }
      }
    }

    this.requestStart(api);
    const http = this.httpClient.get<IApiResponse>(url, {
      headers: requestOptions.headers,
      observe: 'response',
      // See https://angular.io/guide/http#listening-to-progress-events where it indicates that:
      // Every progress event triggers change detection, so only turn them on if you truly intend to report progress in the UI.
      // reportProgress: true
    });

    // TODO maybe we tap into ApiCall object for an optional bit flag if we want to report progress???
    // This could be used to show a spinner like shown here: https://stackoverflow.com/questions/45512285/angular-httpclient-show-spinner-progress-indicator-while-waiting-for-service-t
    //http.subscribe((event: HttpEvent<any>) => {
    //  switch (event.type) {
    //    case HttpEventType.Sent:
    //      console.log('HTTP => Request sent!');
    //      break;
    //    case HttpEventType.ResponseHeader:
    //      console.log('HTTP => Response header received!');
    //      break;
    //    case HttpEventType.UploadProgress:
    //      const percentDone = Math.round(100 * event.loaded / event.total);
    //      console.log(`HTTP => File is ${percentDone}% uploaded.`);
    //    case HttpEventType.DownloadProgress:
    //      const kbLoaded = Math.round(event.loaded / 1024);
    //      console.log(`HTTP => Download in progress! ${kbLoaded}Kb loaded`);
    //      break;
    //    case HttpEventType.Response:
    //      console.log('HTTP => 😺 Done!', event.body);
    //  }
    //});

    //return http.pipe(map(apiResponse => {
    //  this.requestComplete(api);
    //  return ApiHelper.formatResponseFromHttpResponse(apiResponse, requestData);
    //}),
    //  catchError(this.handleError(api, requestData)));

    return http.pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }


  /**
  Calls a get API to get back a promise pointing to the desired objects.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The input data to combine with the API call to help get the desired object.  This can be
  any type but should include any properties specified in the API call as being needed.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public get(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "GET", Headers: requestOptions.headers };
    const cacheKey: string = ApiHelper.buildCacheKey(api, inputData, this.currentUserPartitionId);
    if (ApiHelper.shouldCache(api, cacheKey, "read")) {
      if (api.cacheUseStorage) {
        // TODO
      } else if (this.cache.cacheKeyExists(api.cacheName, cacheKey)) {
        const cachedValue = this.cache.cacheGetValue<IApiResponseWrapper>(api.cacheName, cacheKey);
        if (cachedValue) {
          Log.debug("cache", "Cache", `Pulled ${api.objectShortDescription} from cache with cache key ${cacheKey}`);
          return of(cachedValue);
        }
      }
    }

    this.requestStart(api);
    return this.httpClient.get<IApiResponse>(url, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }



  /**
  Calls an add API to add an object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object to add.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public add(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "POST", Headers: requestOptions.headers };

    this.requestStart(api);
    return this.httpClient.post<IApiResponse>(url, inputData, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }


  /**
  Calls an edit API to edit an object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object to edit.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public edit(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "PUT", Headers: requestOptions.headers };

    if (api.httpMethodOverride) {
      requestData.Method = "POST";
      this.requestStart(api);
      return this.httpClient.post<IApiResponse>(url, inputData, {
        headers: requestOptions.headers,
        observe: 'response'
      }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));
    }

    this.requestStart(api);
    return this.httpClient.put<IApiResponse>(url, inputData, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }



  /**
  Calls a patch API to patch an object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object to patch with.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public patch(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "PATCH", Headers: requestOptions.headers };

    if (api.httpMethodOverride) {
      requestData.Method = "POST";
      this.requestStart(api);
      return this.httpClient.post<IApiResponse>(url, inputData, {
        headers: requestOptions.headers,
        observe: 'response'
      }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));
    }

    this.requestStart(api);
    return this.httpClient.patch<IApiResponse>(url, inputData, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }



  /**
  Calls a merge API to merge an object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object to patch with.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public merge(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "MERGE", Headers: requestOptions.headers };

    if (api.httpMethodOverride) {
      requestData.Method = "POST";
      this.requestStart(api);
      return this.httpClient.post<IApiResponse>(url, inputData, {
        headers: requestOptions.headers,
        observe: 'response'
      }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));
    }

    this.requestStart(api);
    return this.httpClient.request<IApiResponse>("MERGE", url, {
      body: inputData,
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }



  /**
  Calls a copy API to copy an object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object to copy.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public copy(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "POST", Headers: requestOptions.headers };

    this.requestStart(api);
    return this.httpClient.post<IApiResponse>(url, inputData, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }


  /**
  Calls a delete API to delete an object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The input data to combine with the API call to help delete the desired object.  This can be
  any type but should include any properties specified in the API call as being needed.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public delete(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "DELETE", Headers: requestOptions.headers };

    if (api.httpMethodOverride) {
      requestData.Method = "POST";
      this.requestStart(api);
      return this.httpClient.post<IApiResponse>(url, inputData, {
        headers: requestOptions.headers,
        observe: 'response'
      }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));
    }

    this.requestStart(api);
    return this.httpClient.delete<IApiResponse>(url, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }


  /**
  Calls an export API to get back a promise pointing to a collection of objects.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The input data to combine with the API call to help get the desired collection.  This can be
  any type but typically implements IQuery so the typical collection parameters of Sort, Filter, Q, and Expand are available.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IResponse.
  */
  public export(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: null, Method: "GET", Headers: requestOptions.headers };

    this.requestStart(api);
    const http = this.httpClient.get<IApiResponse>(url, {
      headers: requestOptions.headers,
      observe: 'response',
      // See https://angular.io/guide/http#listening-to-progress-events where it indicates that:
      // Every progress event triggers change detection, so only turn them on if you truly intend to report progress in the UI.
      // reportProgress: true
    });

    return http.pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }


  /**
  Calls an import API to import data.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData Any input data.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public import(api: ApiCall, inputData: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: "POST", Headers: requestOptions.headers };

    this.requestStart(api);
    return this.httpClient.post<IApiResponse>(url, inputData, {
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }


  /**
  Calls an API to perform the action outlined by the API call object.
  @param {ApiCall} api The API call object containing information about the API being called.
  @param {any} inputData The object used for as input data for this call.
  @param {any} httpHeaderOptions An optional object of HTTP headers options to use.
  @returns {Observable<IApiResponseWrapper>} A promise object which when resolved will provide an object which implements IApiResponseWrapper.
  */
  public call(api: ApiCall, inputData?: any, httpHeaderOptions?: any): Observable<IApiResponseWrapper> {

    this.apiPrep(api);
    const url: string = ApiHelper.buildApiAbsoluteUrl(api, inputData);
    const requestOptions: any = ApiHelper.getApiHttpOptions(api, httpHeaderOptions);
    const requestData: IApiRequestInformation = { Url: url, Data: inputData, Method: api.methodName, Headers: requestOptions.headers };

    if (api.httpMethodOverride) {
      requestData.Method = "POST";
      this.requestStart(api);
      return this.httpClient.post<IApiResponse>(url, inputData, {
        headers: requestOptions.headers,
        observe: 'response'
      }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));
    }

    this.requestStart(api);
    return this.httpClient.request<IApiResponse>(api.methodName, url, {
      body: inputData,
      headers: requestOptions.headers,
      observe: 'response'
    }).pipe(map(this.handleSuccess(api, requestData)), catchError(this.handleError(api, requestData)));

  }



  /**
  Does some common api prep code that happens at the top of each api service call.
  @param {ApiCall} api The API call object containing information about the API being called.
  */
  public apiPrep(api: ApiCall): void {
    // Set the language which will get submitted as http header in request and if supported will provide some multi-lingual support
    api.language = this.language;
    // Save our current api url so it can be the default in scenarios that need it like api docs
    window.localStorage[Constants.LocalStorage.ApiUrl] = AppConfig.apiUrl;
    // If we're using an api key post that for this api url
    if (api.apiKey) {
      //console.error("ready to save api key", new Date(), api.apiKey);
      window.localStorage[Constants.LocalStorage.ApiKey + "-" + AppConfig.apiUrl] = api.apiKey;
      //console.error("current api key is", new Date(), ApiHelper.getAuthKey());
    }
  }


  public loadPickList(pickListId: string, pickListFilter: string = "", ignoreCache: boolean = false): Observable<IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>> {

    if (!pickListId) {
      const mock = new ApiResponseWrapper();
      mock.Data = new ApiResponse();
      mock.Data.Success = false;
      mock.Data.ResultCode = 404;
      mock.Data.Message = "Pick list requested but no pick list id was provided.";
      mock.Data.Data = [];
      Log.errorMessage(mock.Data.Message);
      return of(mock);
    }

    // If we see an odd pick list id showing up in network traffic uncomment the
    // below and look at the stack trace to figure out where it's coming from.
    //if (pickListId === "s") {
    //  Log.errorMessage(`Odd Pick List Id Request: ${pickListId}`);
    //}

    const apiProp = ApiModuleCore.InputPickList();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);

    // For pick lists we use storage for the cache
    apiCall.cacheUseStorage = true;

    // Don't blab to the ui any time we are loading a pick list
    apiCall.silent = true;

    // Tweak caching
    if (Helper.startsWith(pickListId, "___")) {
      // Don't cache type-ahead pick lists like we do others
      apiCall.cacheLevel = CacheLevel.Volatile;
      // is none too little caching?
      // apiCall.cacheIgnore = true;
    } else if (Helper.startsWith(pickListId, "__")) {
      // Static data model options don't change
      apiCall.cacheLevel = CacheLevel.Static;
    }

    // See if we were explicitly told to ignore cache
    if (!apiCall.cacheIgnoreOnRead && ignoreCache) {
      apiCall.cacheIgnoreOnRead = true;
    }

    // Build query for the pick list request
    const query = new Query();
    query.Page = 1;
    query.Size = Constants.RowsToReturn.All;
    query.Filter = pickListFilter;
    (query as any).PickListId = pickListId;

    // For pick lists we may be saving values to storage cache
    if (apiCall.cacheUseStorage) {
      let cacheKey: string = ApiHelper.buildApiAbsoluteUrl(apiCall, query);
      if (this.currentUserPartitionId) {
        cacheKey = `P${this.currentUserPartitionId}-${cacheKey}`;
      }
      // AsyncSubject: A Subject that only emits its last value upon completion
      const subject = new AsyncSubject<IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>>();
      this.cache.storedCacheGetValue<IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>>(apiCall.cacheName, cacheKey).then(value => {
        //console.error(apiCall.cacheName, cacheKey, value);
        //this.cache.storedCacheGetLength().then(len => console.error("stored cache length", len));
        //this.cache.storedCacheGetKeys().then(keys => console.error("stored cache keys", keys));
        if (value) {
          Log.debug("cache", "Cache", `Pulled ${apiCall.objectShortDescription} list from stored cache with cache name ${apiCall.cacheName} and cache key ${cacheKey}`);
          subject.next(value);
          subject.complete();
        } else {
          //return ...
          this.execute(apiCall, query).subscribe(result => {
            subject.next(result);
            subject.complete();
          });
        }
      });
      return subject.asObservable();
    } else {
      // Return our observable and let the caller decide how to handle reporting errors, etc.
      return this.execute(apiCall, query);
    }

  }

  public dumpPickListCache(pickListId: string, pickListFilter: string = ""): void {

    if (!pickListId) {
      return;
    }

    const apiProp = ApiModuleCore.InputPickList();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);

    // For pick lists we use storage for the cache
    apiCall.cacheUseStorage = true;

    // Don't blab to the ui any time we are loading a pick list
    apiCall.silent = true;

    // Tweak caching
    if (Helper.startsWith(pickListId, "___")) {
      // Don't cache type-ahead pick lists like we do others
      apiCall.cacheLevel = CacheLevel.Volatile;
      // is none too little caching?
      // apiCall.cacheIgnore = true;
    } else if (Helper.startsWith(pickListId, "__")) {
      // Static data model options don't change
      apiCall.cacheLevel = CacheLevel.Static;
    }

    // Build query for the pick list request
    const query = new Query();
    query.Page = 1;
    query.Size = Constants.RowsToReturn.All;
    query.Filter = pickListFilter;
    (query as any).PickListId = pickListId;

    let cacheKey: string = ApiHelper.buildApiAbsoluteUrl(apiCall, query);
    if (this.currentUserPartitionId) {
      cacheKey = `P${this.currentUserPartitionId}-${cacheKey}`;
    }
    if (apiCall.cacheUseStorage) {
      this.cache.storedCacheRemoveValue(apiCall.cacheName, cacheKey);
    } else {
      this.cache.cacheRemoveValue(apiCall.cacheName, cacheKey);
    }
    Log.debug("cache", "Cache", `Removed ${apiCall.objectShortDescription} list from cache with cache name ${apiCall.cacheName} and cache key ${cacheKey}`);

  }



  public loadSystemSettingOne(category: string, attribute: string, ignoreCache: boolean = false): Observable<IApiResponseWrapperTyped<m5.SettingEditViewModel>> {

    if (!category || !attribute) {
      const mock = new ApiResponseWrapper();
      mock.Data = new ApiResponse();
      mock.Data.Success = false;
      mock.Data.ResultCode = 404;
      if (!category) {
        mock.Data.Message = "Setting requested but no category was provided.";
      } else if (!attribute) {
        mock.Data.Message = "Setting requested but no attribute was provided.";
      }
      mock.Data.Data = null;
      Log.errorMessage(mock.Data.Message);
      return of(mock);
    }

    const apiProp = Api.SystemSettingOne();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);

    // Don't blab to the ui any time we are loading a setting
    apiCall.silent = true;

    // See if we were explicitly told to ignore cache
    if (!apiCall.cacheIgnoreOnRead && ignoreCache) {
      apiCall.cacheIgnoreOnRead = true;
    }

    // Return our observable and let the caller decide how to handle reporting errors, etc.
    return this.execute(apiCall, { Category: category, Attribute: attribute });

  }



}
