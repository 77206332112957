import * as m from "./ngCoreModels";
import * as m5core from "./ngModelsCore5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export enum ScriptLanguage {
		CSharp = 0,
		VisualBasic = 10,
		CPlusPlus = 20,
		JavaScriptOnServer = 30,
		JavaScript = 1100,
		TypeScript = 1101,
		Html = 1200,
		Css = 1300,
		Inherited = 2147483647
	}
	export enum ScriptLanguageServerCommon {
		CSharp = 0,
		Inherited = 2147483647
	}
	export enum SubscriptionOptionPriceTimePeriod {
		Month = 0,
		Year = 1
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class PartitionRouteEditViewModel {
		PartitionRouteId: number = null;
		Description: string = "";
		RouteUrl: string = "";
		RedirectToUrl: string = "";
		AssetId: number = null;
		AssetTitle: string = "";
		FormId: number = null;
		FormDescription: string = "";
		FormContext: string = "";
		AllowAnonymousAccess: boolean = false;
		HideStandardSiteElements: boolean = false;
		Properties: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationModel {
		PartitionId: number = null;
		PartitionDescription: string = "";
		Partition: m5core.PartitionEditViewModel = null;
		Domain: m5core.PartitionDomainEditViewModel = null;
		Branding: m.PartitionBrandModel = null;
		Tags: {[key: string]:  string[]} = null;
		AllowAnonymousAccess: boolean = false;
		AnonymousApiKey: string = "";
		Theme: string = "";
		LogoUrl: string = "";
		DomainName: string = "";
		SslEnabled: boolean = false;
		SslRequired: boolean = false;
		Services: m5core.ApplicationInformationServiceModel[] = [];
		Modules: string[] = [];
		Routes: m5core.ApplicationInformationRouteModel[] = [];
		Logos: m5core.ApplicationInformationLogoModel[] = [];
		Settings: m5core.AppSettingsEditViewModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionEditViewModel {
		PartitionId: number = null;
		Description: string = "";
		DisplayName: string = "";
		Brand: string = "";
		Branding: m.PartitionBrandModel = null;
		PrivateBranded: boolean = false;
		CustomerName: string = "";
		ContactName: string = "";
		ContactPhone: string = "";
		ContactEmail: string = "";
		ContactAlternateEmail: string = "";
		ContactId: number = null;
		ContactInformationName: string = "";
		ProductionPartitionId: number = null;
		SandboxPartitionId: number = null;
		PrimaryShardId: number = null;
		ReportShardId: number = null;
		CacheShardId: number = null;
		LogShardId: number = null;
		ActivityShardId: number = null;
		ArchiveActivityShardId: number = null;
		ArchiveShardId: number = null;
		Status: string = "";
		StatusMessage: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		ValidDateRangeBeforeMessage: string = "";
		ValidDateRangeAfterMessage: string = "";
		Features: m5core.PartitionFeaturesEditViewModel = null;
		Billing: m5core.PartitionBillingEditViewModel = null;
		AllowAnonymousAccess: boolean = false;
		Comments: string = "";
		Services: m5core.PartitionServiceEditViewModel[] = [];
		Domains: m5core.PartitionDomainEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionFeaturesEditViewModel {
		InAppUpgradeAdvertising: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionBillingEditViewModel {
		ContactId: number = null;
		BillingAccountId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionServiceEditViewModel {
		PartitionServiceId: number = null;
		PartitionId: number = null;
		Description: string = "";
		ServiceName: string = "";
		ServiceClass: string = "";
		ServiceType: string = "";
		ServiceModules: string[] = [];
		Status: string = "";
		StatusMessage: string = "";
		StatusInformation: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		ValidDateRangeBeforeMessage: string = "";
		ValidDateRangeAfterMessage: string = "";
		Features: m5core.PartitionServiceFeaturesEditViewModel = null;
		Billing: m5core.PartitionServiceBillingEditViewModel = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionServiceFeaturesEditViewModel {
		FreeTrial: boolean = false;
		FreeTrialDays: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionServiceBillingEditViewModel {
		PackageCode: string = "";
		PackageType: string = "";
		PackageId: number = null;
		PackageOccurrenceId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionDomainEditViewModel {
		PartitionDomainId: number = null;
		PartitionId: number = null;
		PartitionServiceId: number = null;
		Description: string = "";
		DisplayName: string = "";
		DomainName: string = "";
		IpAddress: string = "";
		CustomDomainName: boolean = false;
		SslEnabled: boolean = false;
		SslRequired: boolean = false;
		SslExpirationDateTime: Date = null;
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		SiteTheme: string = "";
		AllowAnonymousAccess: boolean = false;
		LogoAssets: number[] = [];
		BrandId: m.BrandId = null;
		Brand: string = "";
		Branding: m.PartitionBrandModel = null;
		PrivateBranded: boolean = false;
		ShowCopyrightMessage: boolean = false;
		ShowPoweredByMessage: boolean = false;
		AssetFilterTags: string[] = [];
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationServiceModel {
		Name: string = "";
		Class: string = "";
		Type: string = "";
		Status: string = "";
		Modules: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationRouteModel {
		PartitionRouteId: number = null;
		Description: string = "";
		RouteUrl: string = "";
		RedirectToUrl: string = "";
		AssetId: number = null;
		FormId: number = null;
		FormContext: string = "";
		AllowAnonymousAccess: boolean = false;
		HideStandardSiteElements: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationLogoModel {
		Title: string = "";
		FriendlyName: string = "";
		AssetId: number = null;
		Url: string = "";
		FileType: string = "";
		ContentType: string = "";
		Height: number = 0;
		Width: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class AppSettingsEditViewModel {
		CompanyLongName: string = "";
		CompanyShortName: string = "";
		SystemAdminGroupId: number = null;
		AssetAdminGroupId: number = null;
		LanguageNative: string = "";
		LanguageDefault: string = "";
		LanguagesSupported: string[] = [];
		DefaultCaseTemplateId: number = null;
		UseInternalCaseNumber: boolean = false;
		UseExternalCaseNumber: boolean = false;
		Labels: {[key: string]:  string} = null;
		Menu: m.MenuItem[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class PickListExcelMetaData {
		PickListId: string = null;
		WorksheetName: string = "";
		WorksheetExists: boolean = false;
		ColumnOffsetPickListValueId: number = null;
		ColumnOffsetDisplayText: number = 0;
		ColumnOffsetValue: number = 0;
		ColumnOffsetDisplayOrder: number = 0;
		ColumnOffsetGroupText: number = 0;
		ColumnCount: number = 0;
		PickListHierarchy: string[] = [];
		PickListHeaders: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class PickListImportResult {
		OriginalFileName: string = "";
		MimeFileName: string = "";
		FinalFileName: string = "";
		FinalFileFullPath: string = "";
		Extension: string = "";
		ContentType: string = "";
		DryRun: boolean = false;
		PickLists: m5core.PickListImportSingleResult[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class PickListImportSingleResult {
		PickListType: string = "";
		PickListId: string = null;
		ExcelMetaData: m5core.PickListExcelMetaData = null;
		PickListCount: number = 0;
		PickListHierarchy: string[] = [];
		PickListEntryCountParsed: number = 0;
		PickListEntryCountAdded: number = 0;
		PickListEntryCountAlreadyAdded: number = 0;
		PickListEntryCountUpdated: number = 0;
		PickListEntryCountUpdatedReferences: number = 0;
		PickListEntryCountDeleted: number = 0;
		PickListEntryCountAlreadyDeleted: number = 0;
		PickListEntryCountNotChanged: number = 0;
		PickListEntryCountWarning: number = 0;
		PickListEntryCountError: number = 0;
		PickListEntriesAdded: number[] = [];
		PickListEntriesAlreadyAdded: number[] = [];
		PickListEntriesUpdated: number[] = [];
		PickListEntriesUpdatedReferences: number[] = [];
		PickListEntriesDeleted: number[] = [];
		PickListEntriesAlreadyDeleted: number[] = [];
		PickListEntriesNotChanged: number[] = [];
		PickListEntriesWarnings: number[] = [];
		PickListEntriesErrors: number[] = [];
		Notices: m5core.PickListImportNotice[] = [];
		Warnings: m5core.PickListImportNotice[] = [];
		Errors: m5core.PickListImportNotice[] = [];
		Trace: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class PickListImportNotice {
		PickListId: string = null;
		PickListValueId: number = null;
		OperationType: string = "";
		ResultCode: m.StandardResultCode = null;
		Message: string = "";
		CurrentValue: string = "";
		RequestedValue: string = "";
		Details: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PickListEditViewModel {
		PickListSurrogateId: number = null;
		PickListId: string = null;
		PickListCategoryId: string = null;
		ParentPickListId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		DisplayOrder: number = 0;
		PickListSort: string = "";
		HasDisplayText: boolean = false;
		HasGrouping: boolean = false;
		Scope: string = "";
		Icon: string = "";
		SystemPickListId: string = null;
		RequiredModules: string[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PickListCategoryEditViewModel {
		PickListCategorySurrogateId: number = null;
		PickListCategoryId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		DisplayOrder: number = 0;
		Icon: string = "";
		RequiredModules: string[] = [];
		ParentPickListCategoryId: string = null;
		PickLists: m5core.PickListEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PickListSelectionViewModel {
		PickListId: string = null;
		DisplayOrder: number = 0;
		GroupText: string = "";
		DisplayText: string = "";
		Value: string = "";
		Icon: string = "";
		IconColor: string = "";
		TextColor: string = "";
		Description: string = "";
		Properties: any = null;
		IsDefaultValue: boolean = false;
		IsMutuallyExclusive: boolean = false;
		Children: m5core.PickListSelectionViewModel[] = [];
		PickListValueId: number = null;
		ParentPickListValueId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PickListValueEditViewModel {
		PickListValueId: number = null;
		PickListId: string = null;
		ParentPickListId: string = null;
		ParentPickListValueId: number = null;
		ParentPickListValue: string = "";
		DisplayOrder: number = 0;
		GroupText: string = "";
		DisplayText: string = "";
		Value: string = "";
		Icon: string = "";
		IconColor: string = "";
		TextColor: string = "";
		Properties: string = "";
		MutuallyExclusive: boolean = false;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		Scope: string = "";
		ContactId: number = null;
		ContactName: string = "";
		Enabled: boolean = false;
		Children: m5core.PickListValueEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ScriptViewModel {
		Name: string = "";
		Version: number = 0;
		Enabled: boolean = false;
		Code: m5core.ScriptSourceViewModel[] = [];
		FullyQualifiedTypeName: string = "";
		Interface: string = "";
		InstanceTypedAs: string = "";
		EntryPoint: string = "";
		EntryPointParameters: any = null;
		PartialSourceCodeWrappers: m5core.ScriptSourceViewModel[] = [];
		Snippets: m5core.ScriptSourceViewModel[] = [];
		Language: string = "";
		SupportedLanguages: string[] = [];
		AllowMultipleLanguages: boolean = false;
		ReferencedAssemblyDefaultFolder: string = "";
		ReferencedAssemblies: string[] = [];
		CompiledCode: m5core.ScriptSourceViewModel[] = [];
		UseCache: boolean = false;
		CompilerOptions: any = null;
		Properties: any = null;
		Comments: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ScriptSourceViewModel {
		Group: string = "";
		Order: number = 0;
		Name: string = "";
		SourceCode: string = "";
		Enabled: boolean = false;
		Partial: boolean = false;
		Language: string = "";
		Usings: string[] = [];
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TranslationAddMissingViewModel {
		NativeText: string = "";
		DesiredLanguage: string = "";
		Scope: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class TranslationResponseViewModel {
		TranslatedText: string = "";
		Language: string = "";
		Information: {[key: string]:  any} = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TranslationRequestViewModel {
		NativeText: string = "";
		DesiredLanguage: string = "";
		Scope: string = "";
		Parameters: any = null;
		AddIfMissing: boolean = false;
		IgnoreCache: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class TranslationEditViewModel {
		TranslationId: number = null;
		NativeLanguage: string = "";
		TranslatedLanguage: string = "";
		NativeText: string = "";
		FullNativeText: string = "";
		TranslatedText: string = "";
		TranslatedDateTime: Date = null;
		TranslatedByContactId: number = null;
		TranslatedByContactName: string = "";
		ReviewedDateTime: Date = null;
		ReviewedByContactId: number = null;
		ReviewedByContactName: string = "";
		ReviewComments: string = "";
		Status: string = "";
		Scope: string[] = [];
		IncludeInExport: boolean = false;
		LastUsedDateTime: Date = null;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionListViewModel {
		PartitionId: number = null;
		Tag: string = "";
		Description: string = "";
		Brand: string = "";
		CustomerName: string = "";
		ContactName: string = "";
		ContactPhone: string = "";
		ContactEmail: string = "";
		ContactAlternateEmail: string = "";
		ContactId: number = null;
		ContactInformationName: string = "";
		ProductionPartitionId: number = null;
		SandboxPartitionId: number = null;
		PrimaryShardId: number = null;
		ReportShardId: number = null;
		CacheShardId: number = null;
		LogShardId: number = null;
		ActivityShardId: number = null;
		ArchiveActivityShardId: number = null;
		ArchiveShardId: number = null;
		Status: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class SubscriptionConfigViewModel {
		SubscriptionConfigId: number = null;
		Description: string = "";
		ExistingOrganizationHideAllElements: boolean = false;
		SignUpAlwaysTrialMode: boolean = false;
		DataSourcePartitionId: number = null;
		AdminRoleId: number = null;
		AdminGroupId: number = null;
		NotificationGroupId: number = null;
		ProcessTemplateId: number = null;
		ProcessPeerId: string = null;
		CaseTemplateId: number = null;
		WebhookId: number = null;
		TriggerAssetId: number = null;
		Properties: any = null;
		Comments: string = "";
		StepNew: m5core.SubscriptionConfigStepViewModel = null;
		StepSubscription: m5core.SubscriptionConfigStepViewModel = null;
		StepOrganization: m5core.SubscriptionConfigStepViewModel = null;
		StepSecurity: m5core.SubscriptionConfigStepViewModel = null;
		StepFinalize: m5core.SubscriptionConfigStepViewModel = null;
		StepDone: m5core.SubscriptionConfigStepViewModel = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SubscriptionConfigStepViewModel {
		Title: string = "";
		Icon: string = "";
		InstructionsTop: string = "";
		InstructionsTopStyle: string = "";
		InstructionsBottom: string = "";
		InstructionsBottomStyle: string = "";
		PreviousButtonText: string = "";
		PreviousButtonIcon: string = "";
		PreviousButtonColor: string = "";
		NextButtonText: string = "";
		NextButtonIcon: string = "";
		NextButtonColor: string = "";
		CancelButtonText: string = "";
		CancelButtonIcon: string = "";
		CancelButtonColor: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class SubscriptionSignUpRequestViewModel {
		SubscriptionOptionId: number = null;
		SubscriptionOptionName: string = "";
		SubscriptionOptionPriceId: string = null;
		SubscriptionOptionPriceDescription: string = "";
		Trial: boolean = false;
		CustomerName: string = "";
		AdminFirstName: string = "";
		AdminLastName: string = "";
		AdminPhone: string = "";
		AdminEmail: string = "";
		AdminLogin: string = "";
		AdminPassword: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class SubscriptionOptionViewModel {
		SubscriptionOptionId: number = null;
		Enabled: boolean = false;
		Group: string = "";
		TargetAudienceDescription: string = "";
		Name: string = "";
		Description: string = "";
		DescriptionTitle: string = "";
		IsFeatured: boolean = false;
		FeaturedDescription: string = "";
		Brand: m.BrandId = null;
		ServiceName: string = "";
		ServiceClass: string = "";
		ServiceType: string = "";
		Modules: string[] = [];
		PackageType: string = "";
		PackageCode: string = "";
		PackageId: number = null;
		ButtonSignUpLabel: string = "";
		ButtonSignUpIcon: string = "";
		ButtonTrialLabel: string = "";
		ButtonTrialIcon: string = "";
		TrialDays: number = 0;
		DataSourcePartitionId: number = null;
		AdminRoleId: number = null;
		AdminGroupId: number = null;
		NotificationGroupId: number = null;
		ProcessTemplateId: number = null;
		ProcessPeerId: string = null;
		CaseTemplateId: number = null;
		WebhookId: number = null;
		TriggerAssetId: number = null;
		Properties: any = null;
		Comments: string = "";
		Features: m5core.SubscriptionOptionFeatureViewModel[] = [];
		Prices: m5core.SubscriptionOptionPriceViewModel[] = [];
		DisplayOrder: number = 0;
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SubscriptionOptionFeatureViewModel {
		Name: string = "";
		Description: string = "";
		DescriptionTitle: string = "";
		Properties: any = null;
		Comments: string = "";
		DisplayOrder: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class SubscriptionOptionPriceViewModel {
		Id: string = null;
		Description: string = "";
		PriceDescription: string = "";
		PriceUnitDescription: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		IsPerUser: boolean = false;
		TimePeriod: m5core.SubscriptionOptionPriceTimePeriod = null;
		Modules: string[] = [];
		PackageType: string = "";
		PackageCode: string = "";
		PackageId: number = null;
		Properties: any = null;
		Comments: string = "";
		DisplayOrder: number = 0;
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

