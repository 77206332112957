<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <form #f="ngForm">
    <fieldset [disabled]="( disabled || mode === 'view' ) ? 'disabled' : null">
      <ul ngbNav #nav='ngbNav' class="{{isMobile ? 'nav-pills' : 'nav-tabs'}}" [destroyOnHide]="false"> <!--(navChange)="onNavChange($event)"-->

        <li ngbNavItem title="Info">
          <a ngbNavLink>Info</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ng-container *ngIf="contactType === Constants.ContactType.Contact">
                  <ib-input-select name="Address Type" label="Type" optionsPickListId="{{Constants.PickList.ContactAddressType}}" [(ngModel)]="data.AddressType"></ib-input-select>
                  <ib-input-text name="Id" label="Id" [(ngModel)]="data.ExternalContactId"></ib-input-text>
                  <ib-input-text name="Contact Name" label="Contact Name" [(ngModel)]="data.ContactName"></ib-input-text>
                </ng-container>
                <ng-container *ngIf="contactType === Constants.ContactType.Customer">
                  <ib-input-text name="Id" label="Id" [(ngModel)]="data.ExternalContactId"></ib-input-text>
                  <ib-input-text name="Customer Name" label="Customer Name" [(ngModel)]="data.ContactName"></ib-input-text>
                </ng-container>
                <ng-container *ngIf="contactType === Constants.ContactType.Directory">
                  <ib-input-text name="Id" label="Id" [(ngModel)]="data.ExternalContactId"></ib-input-text>
                  <ib-input-text name="Directory Name" label="Directory Name" [(ngModel)]="data.ContactName"></ib-input-text>
                </ng-container>
                <ng-container *ngIf="contactType === Constants.ContactType.Location">
                  <ib-input-text name="Id" label="Id" [(ngModel)]="data.ExternalContactId"></ib-input-text>
                  <ib-input-text name="Location Name" label="Location Name" [(ngModel)]="data.ContactName"></ib-input-text>
                </ng-container>
                <ng-container *ngIf="contactType === Constants.ContactType.Vendor">
                  <ib-input-text name="Id" label="Id" [(ngModel)]="data.ExternalContactId"></ib-input-text>
                  <ib-input-text name="Vendor Name" label="Vendor Name" [(ngModel)]="data.ContactName"></ib-input-text>
                </ng-container>
                <ng-container *ngIf="contactType === Constants.ContactType.Warehouse">
                  <ib-input-text name="Id" label="Id" [(ngModel)]="data.ExternalContactId"></ib-input-text>
                  <ib-input-text name="Warehouse Name" label="Warehouse Name" [(ngModel)]="data.ContactName"></ib-input-text>
                </ng-container>
                <ib-input-text name="First Name" label="First Name" [(ngModel)]="data.FirstName"></ib-input-text>
                <ib-input-text name="Last Name" label="Last Name" [(ngModel)]="data.LastName"></ib-input-text>
                <ib-input-text name="Title" label="Title" [(ngModel)]="data.Title"></ib-input-text>
                <ib-input-text name="Email" label="Email" suffixIcon="envelope" (suffixClick)="onClickEmail($event, data.Email)" [(ngModel)]="data.Email"></ib-input-text>
                <ib-input-text name="Alternate Email" label="Alternate Email" suffixIcon="envelope" (suffixClick)="onClickEmail($event, data.AlternateEmail)" [(ngModel)]="data.AlternateEmail"></ib-input-text>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Phone" label="Phone" suffixIcon="phone" (suffixClick)="onClickCall($event, data.Phone)" [(ngModel)]="data.Phone"></ib-input-text>
                <ib-input-text name="Fax" label="Fax" [(ngModel)]="data.Fax"></ib-input-text>
                <ib-input-text name="Cellular" label="Cellular" suffixIcon="phone" (suffixClick)="onClickCall($event, data.Cellular)" [(ngModel)]="data.Cellular"></ib-input-text>
                <ib-input-text name="Alternate Phone" label="Alternate Phone" suffixIcon="phone" (suffixClick)="onClickCall($event, data.AlternatePhone)" [(ngModel)]="data.AlternatePhone"></ib-input-text>
                <ib-input-text name="Chat" label="Chat"
                               [labelButton]="instantMessengerButton1" [labelButtonData]="data" [labelButtonCargo]="{ alternate: false }"
                               suffixIcon="comment" (suffixClick)="onMessengerClickChat($event, data.ImNetwork, data.ImAddress)"
                               suffixIcon2="phone" (suffixClick2)="onMessengerClickCall($event, data.ImNetwork, data.ImAddress)"
                               [(ngModel)]="data.ImAddress"></ib-input-text>
                <ib-input-text name="Alternate Chat" label="Alternate Chat"
                               [labelButton]="instantMessengerButton2" [labelButtonData]="data" [labelButtonCargo]="{ alternate: true }"
                               suffixIcon="comment" (suffixClick)="onMessengerClickChat($event, data.AlternateImNetwork, data.AlternateImAddress)"
                               suffixIcon2="phone" (suffixClick2)="onMessengerClickCall($event, data.AlternateImNetwork, data.AlternateImAddress)"
                               [(ngModel)]="data.AlternateImAddress"></ib-input-text>
                <ib-input-text name="Web Site" label="Web Site" suffixIcon="globe" (suffixClick)="onClickBrowse($event, data.WebSite)" [(ngModel)]="data.WebSite"></ib-input-text>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem title="Address">
          <a ngbNavLink>Address</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Address 1" label="Address 1" [(ngModel)]="data.Address1"></ib-input-text>
                <ib-input-text name="Address 2" label="Address 2" [(ngModel)]="data.Address2"></ib-input-text>
                <ib-input-text name="Address 3" label="Address 3" [(ngModel)]="data.Address3"></ib-input-text>
                <ib-input-text name="City" label="City" [(ngModel)]="data.City"></ib-input-text>
                <ib-input-text name="State" label="State" width="medium" [(ngModel)]="data.State"></ib-input-text>
                <ib-input-text name="Postal Code" label="Postal Code" width="medium" [(ngModel)]="data.PostalCode"></ib-input-text>
                <ib-input-text name="Country" label="Country" [(ngModel)]="data.Country"></ib-input-text>
              </div>
              <div class="{{Constants.Layout.split2column}}">
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem title="Business">
          <a ngbNavLink>Business</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-select name="Time Zone" label="Time Zone" optionsPickListId="_TimeZone" [(ngModel)]="data.TimeZoneId"></ib-input-select>
                <ng-container *ngIf="contactType !== Constants.ContactType.Location && contactType !== Constants.ContactType.Warehouse">
                  <ib-input-select name="Language" label="Language" optionsPickListId="WebLanguage" [(ngModel)]="data.WebLanguage"></ib-input-select>
                </ng-container>
                <ng-container *ngIf="contactType !== Constants.ContactType.Directory && contactType !== Constants.ContactType.Location && contactType !== Constants.ContactType.Warehouse">
                  <ib-input-select name="Currency" label="Currency" optionsPickListId="_Currency" [(ngModel)]="data.IsoCurrencyCode"></ib-input-select>
                  <ib-input-text name="Tax Id" label="Tax Id" [(ngModel)]="data.TaxId"></ib-input-text>
                  <ib-input-text name="Ticker Symbol" label="Ticker Symbol" [(ngModel)]="data.TickerSymbol"></ib-input-text>
                </ng-container>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ng-container *ngIf="contactType !== Constants.ContactType.Directory && contactType !== Constants.ContactType.Location && contactType !== Constants.ContactType.Warehouse">
                  <ib-input-text name="SIC Code" label="SIC Code" [(ngModel)]="data.SicCode"></ib-input-text>
                  <ib-input-text name="Industry" label="Industry" [(ngModel)]="data.Industry"></ib-input-text>
                </ng-container>
                <ib-input-text name="Department Name" label="Department Name" [(ngModel)]="data.DepartmentName"></ib-input-text>
                <ib-input-text name="Office" label="Office" [(ngModel)]="data.Office"></ib-input-text>
                <ib-input-text *ngIf="contactType === Constants.ContactType.Customer || contactType === Constants.ContactType.Agent"
                               name="Referred By" label="Referred By" [(ngModel)]="data.ReferredBy"></ib-input-text>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem title="Personal">
          <a ngbNavLink>Personal</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Courtesy Title" label="Courtesy Title" placeholder="Courtesy Title (e.g. Mr., Ms.)" [(ngModel)]="data.CourtesyTitle"></ib-input-text>
                <ib-input-text name="Post-Nominal Titles" label="Post-Nominal Titles" placeholder="Post-Nominal Titles (e.g. PhD, CPA, PE)" [(ngModel)]="data.PostNominalTitles"></ib-input-text>
                <ib-input-text name="Salutation" label="Salutation" [(ngModel)]="data.Salutation"></ib-input-text>
                <ib-input-text name="Profession" label="Profession" [(ngModel)]="data.Profession"></ib-input-text>
                <ib-input-text name="Assistant's Name" label="Assistant's Name" [(ngModel)]="data.AssistantName"></ib-input-text>
                <ib-input-text name="Manager's Name" label="Manager's Name" [(ngModel)]="data.ManagerName"></ib-input-text>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Spouse's Name" label="Spouse's Name" [(ngModel)]="data.SpouseName"></ib-input-text>
                <ib-input-text name="Children's Names" label="Children's Names" [(ngModel)]="data.ChildrenNames"></ib-input-text>
                <ib-input-text name="Hobbies" label="Hobbies" [(ngModel)]="data.Hobbies"></ib-input-text>
                <ib-input-radio name="Gender" label="Gender" [(ngModel)]="data.Gender" [inline]="true" [options]="[{value:'M',label:'Male'}, {value:'F',label:'Female'}, {value:'O',label:'Other'}, {value:'U', label:'Unknown'}]"></ib-input-radio>
                <ib-input-date name="Birth Date" label="Birth Date" width="medium" [(ngModel)]="data.BirthDate"></ib-input-date>
                <ib-input-date name="Anniversary Date" label="Anniversary Date" width="medium" [(ngModel)]="data.AnniversaryDate"></ib-input-date>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem title="Comments">
          <a ngbNavLink>Comments</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-input-textarea name="Comments" label="Comments" placeholder="Comments" width="full" [rows]="15" [fullWidth]="true" [(ngModel)]="data.Comments"></ib-input-textarea>
              </div>
            </div>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </fieldset>
  </form>
  <ib-form-status-error-output [formStatus]="formStatus" classes="float-right mb-1"></ib-form-status-error-output>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
