import { Injectable } from '@angular/core';
import { FormArray, FormGroup, NgForm, AbstractControl } from '@angular/forms';
import { BaseService } from './base.service';
import { takeUntil } from 'rxjs/operators';
import { Dictionary } from '../models/dictionary';
import { Log, Helper } from '../helpers/helper';
import { FormStatusModel } from '../models/form-status-model';
import { BehaviorSubject } from 'rxjs';
import { PackageAssociationEditViewModel } from '../models/ngModels5';

// No { providedIn: 'root' } since we want unique instances per parent component
// On the parent component specify "providers: [FormStatusService]" so that component
// and any children get the same instance of this service so we can know information
// about all forms on the component.  
@Injectable()
export class FormStatusService extends BaseService {

  private formArray: FormArray = new FormArray([]);

  // Public to facilitate translations ... see ib-form-status-error-output
  propertiesByErrorType: Dictionary<string[]> = new Dictionary<string[]>();
  customErrorMessages: string[] = [];
  errors: string[] = [];

  protected _lastStatus: FormStatusModel = null;
  get status(): FormStatusModel {
    this.formStatusUpdate();
    return this._lastStatus;
  }

  private statusSubject = new BehaviorSubject<FormStatusModel>(new FormStatusModel());
  statusChanges() { return this.statusSubject.asObservable(); }


  constructor() {

    super();

    // Subscribe to form changes so we can update the status of the form
    this.formArray.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(form => {
      this.formStatusCheck();
    });
    // We want at least one initial status check since the form may have required values, etc.
    // but we wouldn't otherwise know about them until something changed in the form itself
    // based on our subscription above.
    //setTimeout(() => this.formStatusCheck(), 100);
    // Not needed now that we update on addForm()

  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.statusSubject.complete();
  }


  addForm(formGroup: FormGroup) {
    this.formArray.push(formGroup);
    this.formStatusCheck();
  }

  get isPristine(): boolean {
    return this.formArray.pristine;
  }

  get isFormValid(): boolean {
    // Note that this only checks form validity missing any custom errors inserted here
    // so we usually use the isValid property to determine actual validity.
    return this.formArray.valid;
  }

  isValid: boolean = true;


  markAsPristine() {
    this.formArray.markAsPristine();
  }
  markAsDirty() {
    this.formArray.markAsDirty();
  }


  // Note this is public so if a form is manually marked pristine or dirty it can force a status check
  public formStatusCheck() {

    // Clear all but custom errors
    this.propertiesByErrorType = new Dictionary<string[]>();

    // Step through each control and check what errors need to be reported
    for (let propertyName in this.formArray.controls) {
      let control: AbstractControl = this.formArray.controls[propertyName];
      if ((<any>control).controls) {
        // This abstract control is a FormGroup and we should iterate over it's children
        for (let childPropertyName in (<any>control).controls) {
          let childControl: AbstractControl = (<any>control).controls[childPropertyName];
          //console.error("checking control", childPropertyName, childControl);
          this.addControlErrors(childPropertyName, childControl.errors);
        }
      } else {
        //console.error("checking control", propertyName, control);
        this.addControlErrors(propertyName, control.errors);
      }
    }

    // Update error message output
    this.prepareErrorReport();

    // Update and emit status if needed
    this.formStatusUpdate();

    //console.error("finished form status check", this);

  }

  protected formStatusUpdate() {
    const current = new FormStatusModel(this.isPristine, this.isValid, this.propertiesByErrorType, this.customErrorMessages, this.errors);
    // Only update our form status object and pulse out to our observables if the status actually changed
    if (!FormStatusModel.equals(this._lastStatus, current)) {
      this._lastStatus = current;
      this.statusSubject.next(current);
    }
  }

  // Note this is public because it gets called by custom form render component.
  addControlErrors(propertyName: string, controlErrors: { [key: string]: any }) {
    if (controlErrors) {
      if (controlErrors.required) {
        this.addError("required", propertyName);
      } else if (controlErrors.minlength) {
        this.addError("min", propertyName);
      } else if (controlErrors.maxlength) {
        this.addError("max", propertyName);
      } else if (controlErrors.pattern) {
        this.addError("format", propertyName);
      } else if (controlErrors.ngbDate) {
        this.addError("format", propertyName);
      } else {
        this.addError(JSON.stringify(controlErrors), propertyName);
        Log.errorMessage(`Form error could use some clean up.  The error type was reported as "${JSON.stringify(controlErrors)}".`);
      }
    }
  }


  protected addError(type: string, propertyName: string) {

    if (!this.propertiesByErrorType) {
      this.propertiesByErrorType = new Dictionary<string[]>();
    }

    // Convert from pascal case to separate words for friendlier error reporting
    let formattedPropertyName = Helper.formatIdentifierWithSpaces(propertyName);
    if (this.propertiesByErrorType.containsKey(type)) {
      let values: string[] = this.propertiesByErrorType.item(type);
      if (!values.includes(formattedPropertyName)) {
        this.propertiesByErrorType.item(type).push(formattedPropertyName);
      }
    } else {
      this.propertiesByErrorType.add(type, [formattedPropertyName]);
    }

    // Update error message output
    this.prepareErrorReport();

    // We want our valid flag to be based on existence of reported errors
    // as we cannot say we're not valid without a reason we're not valid.
    this.isValid = (this.errors.length === 0);

    // Update and emit status if needed
    this.formStatusUpdate();

  }

  clearCustomErrorMessages() {
    this.customErrorMessages = [];
    this.prepareErrorReport();
    // Update and emit status if needed
    this.formStatusUpdate();
  }

  addCustomErrorMessage(errorMessage: string) {

    if (!this.customErrorMessages) {
      this.customErrorMessages = [];
    }

    if (!this.customErrorMessages.includes(errorMessage)) {
      this.customErrorMessages.push(errorMessage);
      // Update error message output
      this.prepareErrorReport();
    }

    // We want our valid flag to be based on existence of reported errors
    // as we cannot say we're not valid without a reason we're not valid.
    this.isValid = (this.errors.length === 0);

    // Update and emit status if needed
    this.formStatusUpdate();

  }

  //public merge(status: FormStatusModel) {

  //  if (!status.isPristine) {
  //    this.isPristine = false;
  //  }

  //  if (status.propertiesByErrorType) {
  //    let keys: string[] = status.propertiesByErrorType.keys();
  //    keys.forEach(key => {
  //      let properties: string[] = status.propertiesByErrorType.item(key);
  //      properties.forEach(property => {
  //        this.addError(key, property);
  //      });
  //    });
  //  }

  //  if (status.customErrorMessages) {
  //    status.customErrorMessages.forEach(message => {
  //      this.addCustomErrorMessage(message);
  //    });
  //  }

  //  // Format error information
  //  this.prepareErrorReport();

  //  // We want our valid flag to be based on existence of reported errors
  //  // as we cannot say we're not valid without a reason we're not valid.
  //  this.isValid = (this.errors.length === 0);

  //}

  protected prepareErrorReport(): string[] {

    let errors: string[] = [];

    if (this.propertiesByErrorType) {
      let keys: string[] = this.propertiesByErrorType.keys();
      keys.forEach(key => {
        // Convert from key to description.  Known keys are: "required", "min", "max", "format"
        let description = key;
        if (Helper.equals(description, "min")) {
          description = "too short";
        } else if (Helper.equals(description, "max")) {
          description = "too long";
        } else if (Helper.equals(description, "format")) {
          description = "invalid format";
        }
        // Get array of properties that have this error type
        let properties: string[] = this.propertiesByErrorType.item(key);
        if (properties.length === 1) {
          errors.push(`${properties[0]} is ${description}.`);
        } else if (properties.length > 1) {
          errors.push(`These items are ${description}: ${properties.join(", ")}.`);
        }
      });
    }

    if (this.customErrorMessages && this.customErrorMessages.length > 0) {
      errors.push(...this.customErrorMessages);
    }

    this.errors = errors;

    // We want our valid flag to be based on existence of reported errors
    // as we cannot say we're not valid without a reason we're not valid.
    this.isValid = (this.errors.length === 0);

    return this.errors;

  }

}
