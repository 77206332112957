import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-response-headers',
  templateUrl: './response-headers.component.html',
  styleUrls: ['./response-headers.component.scss']
})
export class ResponseHeadersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
