<div class="d-inline-block" ngbDropdown #picker="ngbDropdown">
  <button class="{{classes}} {{buttonClasses}}" [style]="buttonStyles | safe : 'style'" id="{{inputControlId}}"
    ngbTooltip="{{tooltip | translate}}" ngbDropdownAnchor (click)="picker.toggle()">
    {{(displayText || value) | limitTo : buttonLabelMaxLength : true}}
  </button>
  <div class="ib-dropdown-scroll" ngbDropdownMenu>
    <div class="input-group input-group-sm" *ngIf="allowSearch">
      <input type="text" class="form-control ml-2 mr-2" style="max-width: 95%;" name="Search" placeholder="Search"
        [ngModelOptions]="{standalone: standalone}" [(ngModel)]="searchText">
    </div>
    <button *ngFor="let option of pickList | filterPropertyValue : 'DisplayText' : searchText : '$'"
      class="dropdown-item" (click)="selectValue(option,$event); picker.toggle();">
      <span class="" style="display:block; margin:0; border-radius:0; font-size:100%;"
        ngbTooltip="{{option.Description | translate}}" container="body">
        {{option.DisplayText || option.Value}}
      </span>
    </button>
  </div>
</div>
