import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-form-save-close-buttons',
  templateUrl: './form-save-close-buttons.component.html',
  styleUrls: ['./form-save-close-buttons.component.css']
})
export class FormSaveCloseButtonsComponent implements OnInit {

  @Input() public formStatus: FormStatusModel;
  @Input() public showSave: boolean = true;
  @Input() public showClose: boolean = true;
  @Input() public working: boolean = false;

  /**
  If used within an editor or other component that knows it's host we can pass
  that here so we can make informed decisions about rendering.  For example,
  we normally have a back button with certain icon/color/position, etc. but if
  host is set to component we may want that to appear differently since it may
  get confused with another back button from the outer editor which has host
  set to page.
  */
  @Input() public host: "page" | "modal" | "component" = "page";

  @Output() public save: EventEmitter<EventModel> = new EventEmitter();
  @Output() public close: EventEmitter<EventModel> = new EventEmitter();

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor() { }

  ngOnInit() {
  }

  protected fireSave($event) {
    // Do not emit any click events when the button is disabled
    if (this.formStatus && this.formStatus.isValid && !this.formStatus.isPristine) {
      let payload: EventModel = new EventModel("click", $event, true, new EventElementModel("button", null, "Save"));
      this.save.emit(payload);
    }
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more dev friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      Log.errorMessage(err);
    }
  }

  protected fireClose($event) {
    let payload: EventModel = new EventModel("click", $event, false, new EventElementModel("button", null, "Close"));
    this.close.emit(payload);
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more dev friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      Log.errorMessage(err);
    }
  }

}
