import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'app-data-formats',
  templateUrl: './data-formats.component.html',
  styleUrls: ['./data-formats.component.scss']
})
export class DataFormatsComponent implements OnInit {

  constructor(protected appService: AppService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "Data Formats";
  }

}
