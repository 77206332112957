<div style="font-size:14px; margin-top:4px; display:inline;">{{license.Description}}</div>
<div *ngIf="license.LongDescription" class="ml-4" style="font-style:italic; font-size:smaller; word-wrap:break-word; white-space: pre-wrap">{{license.LongDescription}}</div>
<div class="ml-4 mt-1" *ngIf="license.ExternalAssetLicenseId || ( license.Icons && license.Icons.length > 0 )">
  <ib-icon [icon]="icon" size="lg" class="mr-1" *ngFor="let icon of license.Icons"></ib-icon>
  <strong>{{license.ExternalAssetLicenseId}}</strong>
</div>
<div class="ml-4" *ngIf="license.OverviewUrl">
  <span class="underline-on-hover" (click)="onOpenUrl($event, license.OverviewUrl)">Overview <i class="far fa-external-link"></i></span>
</div>
<div class="ml-4" *ngIf="license.LicenseUrl">
  <span class="underline-on-hover" (click)="onOpenUrl($event, license.LicenseUrl)">License <i class="far fa-external-link"></i></span>
</div>
<div class="ml-4" *ngFor="let link of license.Urls">
  <span class="underline-on-hover" (click)="onOpenUrl($event, link.Url)">{{link.Description}} <i class="far fa-external-link"></i></span>
</div>
