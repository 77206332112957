import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";


export class ApiModuleSecurity {


  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleSecurity).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleSecurity[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleSecurity[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleSecurity.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleSecurity.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions


  //#region Security APIs

  public static SecurityAuthenticate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Authenticate";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityAuthenticate;
    api.documentation.requestAndResponseDataModelObject = new m5sec.AuthenticatedUserViewModel();
    api.documentation.documentationUrlBase = "/security/authenticate/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.Authenticate}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Authenticate";
    api.endpoints.slice(-1)[0].documentation.menuText = "Authenticate";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
Authentication is a required part of every API request by inclusion of an Authorization header or X-Auth-Key header.
Since each API request is authenticated, this authenticate API endpoint is not required.  It can be used to validate
authentication credentials or to get additional information as to why given authentication credentials
are not valid.
<br /><br />
Use the <a href='#/security/login'>login</a> API to retrieve the token to use in the Authorization header.
<br /><br />
There are no query string parameters on this get request.  The authorization information is carried in
the Authorization and/or X-Auth-Key headers.  For Example:
<br /><br />
Authorization = Bearer eyJ0eXAiOiJKV5QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzZWxmIiwiYXVkIjoiaHR0cDovL2liLmF
<br/>
X-Auth-Key = 4E7-DKQ7PJ8-3VHOZ9U
`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "lock";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Authenticate";
    return api;
  };

  public static SecurityLogin(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Login";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityLogin;
    api.documentation.requestDataModelObject = new m5sec.LoginRequest();
    api.documentation.responseDataModelObject = new m5sec.AuthenticatedUserViewModel();
    api.documentation.documentationUrlBase = "/security/login/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.Login}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Login";
    api.endpoints.slice(-1)[0].documentation.menuText = "Login";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
The login API is used to verify a user name and password are valid and to retrieve a token that can be used
in each subsequent API request in the Authorization header.
<br /><br />
If the RememberMe property is false the token is valid for one day.  If the RememberMe property is true the token
is valid for one year.  Tokens can be invalidated by changing the password on the account used for token creation.
`;
    //api.endpoints.slice(-1)[0].documentation.test
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "lock";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Login";
    return api;
  };

  public static SecurityMultiFactorAuthenticationOptions(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Multi-Factor Authentication Options";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityMultiFactorAuthenticationOptions;
    api.documentation.requestDataModelObject = new m5sec.MultiFactorAuthenticationOptionsViewModel();
    api.documentation.responseDataModelObject = new m5sec.MultiFactorAuthenticationOptionsViewModel();
    api.documentation.documentationUrlBase = "/security/multi-factor-authentication-options/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}/${m.RouteSegment.Options}/{contactId}`, ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}/${m.RouteSegment.Options}/{contactId}`, ApiOperationType.Add));
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}/${m.RouteSegment.Options}/{contactId}`, ApiOperationType.Edit));
    return api;
  };

  public static SecurityMultiFactorAuthentication(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Multi-Factor Authentication";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityMultiFactorAuthentication;
    api.documentation.requestDataModelObject = new m5sec.MultiFactorAuthenticationRequestViewModel();
    api.documentation.responseDataModelObject = new m5sec.AuthenticatedUserViewModel();
    api.documentation.documentationUrlBase = "/security/multi-factor-authentication/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Multi-Factor Authentication";
    api.endpoints.slice(-1)[0].documentation.menuText = "Multi-Factor Authentication";
    api.endpoints.slice(-1)[0].documentation.overviewText = `
The multi-factor authentication API is used to request that a multi-factor authentication code be sent to a specific target or to validate
a code that has been sent.`;
    //api.endpoints.slice(-1)[0].documentation.test
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "lock";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "MFA";
    return api;
  };

  public static SecurityMultiFactorAuthenticationTotp(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Multi-Factor Authentication TOTP";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactExternalAuthentication;
    api.documentation.requestDataModelObject = null;
    api.documentation.responseDataModelObject = new m5.ContactExternalAuthenticationEditViewModel();
    api.documentation.documentationUrlBase = "/security/multi-factor-authentication/totp/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}/${m.RouteSegment.TOTP}/{contactId}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Multi-Factor Authentication TOTP";
    api.endpoints.slice(-1)[0].documentation.menuText = "Multi-Factor Authentication TOTP";
    api.endpoints.slice(-1)[0].documentation.overviewText = `The multi-factor authentication TOTP API is used to request an external contact authentication object with TOTP data for the specified contact.`;
    //api.endpoints.slice(-1)[0].documentation.test
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "lock";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "TOTP";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}/${m.RouteSegment.TOTP}/{contactId}`, ApiOperationType.Delete));
    return api;
  };

  public static SecurityMultiFactorAuthenticationTotpQrCode(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Multi-Factor Authentication TOTP QR Code";
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestDataModelObject = null;
    api.documentation.responseDataModelObject = null;
    api.documentation.documentationUrlBase = "/security/multi-factor-authentication/totp/qr-code/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.MultiFactorAuthentication}/${m.RouteSegment.TOTP}/${m.RouteSegment.QrCode}/{contactId}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Multi-Factor Authentication TOTP QR Code";
    api.endpoints.slice(-1)[0].documentation.menuText = "Multi-Factor Authentication TOTP QR Code";
    api.endpoints.slice(-1)[0].documentation.overviewText = `The multi-factor authentication TOTP QR Code API is used to request a PNG image of the QR Code for the TOTP configuration for the specified contact.`;
    //api.endpoints.slice(-1)[0].documentation.test
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "qrcode";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "QR Code";
    return api;
  };

  public static SecurityLoginNameAvailable(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Login Name Available";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityLoginNameAvailable;
    api.documentation.requestAndResponseDataModelObject = new m5sec.LoginNameAvailableRequestViewModel();
    api.documentation.documentationUrlBase = "/security/login-name-available/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.LoginAvailable}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Login Name Available Check";
    api.endpoints.slice(-1)[0].documentation.menuText = "Login Name Available Check";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This checks to see if a long name is available and, if not available, gets some suggested alternate login names.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "check";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Check";
    return api;
  };

  public static SecurityLoginRecover(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Recover Login";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityLoginRecover;
    api.documentation.requestAndResponseDataModelObject = new m5sec.LoginRecoveryRequestViewModel();
    api.documentation.documentationUrlBase = "/security/login-recover/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.LoginRecover}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Recover Login";
    api.endpoints.slice(-1)[0].documentation.menuText = "Recover Login";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This provides a mechanism to recover the login for a contact.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "envelope";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Recover Login";
    return api;
  };

  public static SecurityLoginChange(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Change Login";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityLoginChange;
    api.documentation.requestAndResponseDataModelObject = new m5sec.LoginChangeRequest();
    api.documentation.documentationUrlBase = "/security/login-change/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.LoginChange}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Change Login";
    api.endpoints.slice(-1)[0].documentation.menuText = "Change Login";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This changes login for a contact.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "edit";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Change Login";
    return api;
  };

  public static SecurityPasswordReset(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Reset Password";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityPasswordReset;
    api.documentation.requestAndResponseDataModelObject = new m5sec.PasswordResetLinkRequestViewModel();
    api.documentation.documentationUrlBase = "/security/password-reset/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.PasswordReset}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Reset Password";
    api.endpoints.slice(-1)[0].documentation.menuText = "Reset Password";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This sends password reset link for a contact.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "link";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Reset Password";
    return api;
  };

  public static SecurityPasswordChange(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Change Password";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityPasswordChange;
    api.documentation.requestAndResponseDataModelObject = new m5sec.PasswordChangeRequest();
    api.documentation.documentationUrlBase = "/security/password-change/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.PasswordChange}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Change Password";
    api.endpoints.slice(-1)[0].documentation.menuText = "Change Password";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This changes password for a contact.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "edit";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Change Password";
    return api;
  };

  public static SecurityPasswordView(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "View Password";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityPasswordView;
    api.documentation.requestAndResponseDataModelObject = new m5sec.PasswordViewRequest();
    api.documentation.documentationUrlBase = "/security/password-view/";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.PasswordView}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Password";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Password";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This views the password for a contact.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "eye";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View Password";
    return api;
  };

  public static ComputeUserPermissionsView(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Compute User Permissions";
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.SecurityAuthenticate;
    api.documentation.responseDataModelObject = new m5sec.AuthenticatedUserViewModel();
    api.documentation.documentationUrlBase = "/security/compute-user-permissions/";
    api.pathVariables = "{contactId}";
    api.pathModelProperties = "ContactId";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Security}/${m.RouteSegment.ComputeUserPermissions}/{contactId}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Compute User Permissions";
    api.endpoints.slice(-1)[0].documentation.menuText = "Compute User Permissions";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This computes user permissions for the specified contact.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "user-unlock";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Compute";
    return api;
  };

  public static SecurityPolicy(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Security Policy";
    api.documentation.objectPrimaryKey = "SecurityPolicyId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ContactTypeDescription"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SecurityPolicy;
    api.documentation.requestAndResponseDataModelObject = new m5sec.SecurityPolicyViewModel();
    api.documentation.documentationUrlBase = "/security-policy/";
    api.documentation.securityAccessArea = Constants.AccessArea.SecurityPolicy;
    api.pathVariables = "{securityPolicyId}";
    api.pathModelProperties = "SecurityPolicyId";
    api.cacheName = "securityPolicyCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.Security}/${m.RouteSegment.Policy}`, null, "", true);
    return api;
  };

  public static Role(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Role";
    api.documentation.objectPrimaryKey = "RoleId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalRoleId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Role;
    api.documentation.requestAndResponseDataModelObject = new m5sec.RoleEditViewModel();
    api.documentation.documentationUrlBase = "/role/";
    api.documentation.securityAccessArea = Constants.AccessArea.Role;
    api.documentation.readOnly = true;
    api.pathVariables = "{roleId}";
    api.pathModelProperties = "RoleId";
    api.cacheName = "staticObjectCache";
    api.impactedPickListIds = [Constants.PickList._Role];
    api.useStandardEndpoints(`/${m.RouteSegment.Roles}`, new m5sec.RoleListViewModel(), Constants.DataModelName.RoleList, true);
    return api;
  };

  public static RoleDetail(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Role Detail";
    api.documentation.objectPrimaryKey = "RoleDetailId";
    api.documentation.objectDescriptionPropertyNames = ["SecurityArea"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RoleDetail;
    api.documentation.requestAndResponseDataModelObject = new m5sec.RoleDetailEditViewModel();
    api.documentation.documentationUrlBase = "/role/detail/";
    api.documentation.securityAccessArea = Constants.AccessArea.RoleDetail;
    api.documentation.readOnly = true;
    api.pathVariables = ["{roleId}", "{roleDetailId}"];
    api.pathModelProperties = ["RoleId", "RoleDetailId"];
    api.cacheName = "staticObjectCache";
    api.parentApi = "Role";
    api.useStandardEndpoints(`/${m.RouteSegment.Roles}/{roleId}/${m.RouteSegment.Details}`, null, null, true);
    return api;
  };

  public static RoleContact(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Role Contact";
    api.documentation.objectPrimaryKey = "ContactRoleId";
    api.documentation.objectDescriptionPropertyNames = ["RoleDescription"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ContactRole;
    api.documentation.requestAndResponseDataModelObject = new m5.ContactRoleEditViewModel();
    api.documentation.documentationUrlBase = "/role/contact/";
    api.documentation.securityAccessArea = Constants.AccessArea.ContactRole;
    api.pathVariables = ["{roleId}", "{contactRoleId}"];
    api.pathModelProperties = ["RoleId", "ContactRoleId"];
    api.cacheName = "contactCache";
    api.parentApi = "Directory";
    api.useStandardEndpoints(`/${m.RouteSegment.Roles}/{roleId}/${m.RouteSegment.Contacts}`, null, "", false);
    return api;
  };

}
