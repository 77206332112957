import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'replaceText'
})
export class ReplaceTextPipe implements PipeTransform {

  public transform(value: any, find: string = "", replace: string = ""): string {

    if (!value) {
      return "";
    }

    if (typeof value !== "string") {
      value = value.toString();
    }

    return Helper.replaceAll(value, find, replace);

  }


}
