import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ib-peek',
  templateUrl: './peek.component.html',
  styleUrls: ['./peek.component.css']
})
export class PeekComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() clearFloats: boolean = true;
  @Input() buttonWrapperClass: string = "";
  @Input() buttonWrapperStyle: string = "";
  @Input() buttonClass: string = "";
  @Input() buttonStyle: string = "";

  constructor() { }

  ngOnInit() {
  }

}
