import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';

@Component({
  selector: 'ib-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.css'],
})
export class TimelineItemComponent implements OnInit, OnChanges {

  @Input() side: "left" | "right" = "left";
  @Input() type: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | "light" | "dark" | "default" = "default";
  @Input() icon: string = "";
  @Input() singleSided: boolean = false;

  public timelineItemClass: string = "";
  public timelineBadgeClass: string = "";

  constructor() { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  protected configure() {
    if (this.singleSided) {
      this.timelineItemClass = "timeline-single-sided";
    } else if (Helper.equals(this.side, "right", true)) {
      this.timelineItemClass = "timeline-inverted";
    } else {
      this.timelineItemClass = "";
    }
    if (this.singleSided) {
      this.timelineBadgeClass = "timeline-badge-single-sided";
    } else {
      this.timelineBadgeClass = "";
    }
  }

}
