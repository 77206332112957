import { Chart } from 'chart.js'
import { Log } from './helper';

export class ChartHelpers {

  public static configDone: boolean = false;

  /**
   * We do some tiny inline charts so configure some default options for that scenario.
   **/
  public static getTinyChartConfig(): any {

    ChartHelpers.setChartDefaults();

    let config = {
      // No room for legends on tiny charts
      legend: { display: false },
      // Tooltips don't fit inside the space available for tiny charts
      tooltips: { enabled: false },
      // Tiny charts cannot afford space it takes for border width
      elements: { arc: { borderWidth: 0 } },
      // Tiny charts shouldn't change highlight on hover
      hover: { mode: "" },
      // Tiny pie charts have visible center cut out even on percent = 0 so set to -1.
      cutoutPercentage: -1
    };

    return config;

  }

  public static getChartConfig( stacked: boolean = false ): any {

    ChartHelpers.setChartDefaults();

    let config: any = {
      legend: { position: 'bottom' },
      colors: ChartHelpers.getChartColors()
    };

    if (stacked) {
      config.scales = { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] };
    }

    ////title: {
    ////  display: true,
    ////  text: 'My Title',
    ////  fontSize: 16
    ////},
    //legend: {
    //  position: 'bottom'
    //}

    return config;

  }

  public static getBarChartDataFromArrayOfObjects(data: any[], labelPropertyName: string,
    valuePropertyName1: string, valueLabel1: string,
    valuePropertyName2: string = "", valueLabel2: string = "",
    valuePropertyName3: string = "", valueLabel3: string = "",
    valuePropertyName4: string = "", valueLabel4: string = "",
    valuePropertyName5: string = "", valueLabel5: string = ""): any {

    let colors = ChartHelpers.getChartColors();
    let chartData = { labels: [], datasets: [{ data: [], backgroundColor: [], hoverBackgroundColor: [], label: valueLabel1 }] };
    if (valuePropertyName2) {
      chartData.datasets.push({ data: [], backgroundColor: [], hoverBackgroundColor: [], label: valueLabel2 });
    }
    if (valuePropertyName3) {
      chartData.datasets.push({ data: [], backgroundColor: [], hoverBackgroundColor: [], label: valueLabel3 });
    }
    if (valuePropertyName4) {
      chartData.datasets.push({ data: [], backgroundColor: [], hoverBackgroundColor: [], label: valueLabel4 });
    }
    if (valuePropertyName5) {
      chartData.datasets.push({ data: [], backgroundColor: [], hoverBackgroundColor: [], label: valueLabel5 });
    }
    data.forEach((item: any, index: number, array: any[]) => {
      chartData.labels.push(item[labelPropertyName]);
      chartData.datasets[0].data.push(item[valuePropertyName1]);
      chartData.datasets[0].backgroundColor.push(colors[0]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[0]);
      if (valuePropertyName2) {
        chartData.datasets[1].data.push(item[valuePropertyName2]);
        chartData.datasets[1].backgroundColor.push(colors[1]);
        chartData.datasets[1].hoverBackgroundColor.push(colors[1]);
      }
      if (valuePropertyName3) {
        chartData.datasets[2].data.push(item[valuePropertyName3]);
        chartData.datasets[2].backgroundColor.push(colors[2]);
        chartData.datasets[2].hoverBackgroundColor.push(colors[2]);
      }
      if (valuePropertyName4) {
        chartData.datasets[3].data.push(item[valuePropertyName4]);
        chartData.datasets[3].backgroundColor.push(colors[3]);
        chartData.datasets[3].hoverBackgroundColor.push(colors[3]);
      }
      if (valuePropertyName5) {
        chartData.datasets[4].data.push(item[valuePropertyName5]);
        chartData.datasets[4].backgroundColor.push(colors[4]);
        chartData.datasets[4].hoverBackgroundColor.push(colors[4]);
      }
    });

    return chartData;

  }


  public static getBarChartDataFromArrayOfObjectsWithVariableDataPoints(data: any[], labelPropertyName: string, valuePropertyNames: string[], valueLabels: string[]): any {
    if (!valuePropertyNames || valuePropertyNames.length === 0 || !valueLabels || valueLabels.length === 0) {
      return {};
    }
    if (valuePropertyNames.length !== valueLabels.length) {
      Log.errorMessage(`Chart value properties ${valuePropertyNames} has a different number of items than labels ${valueLabels}`);
      valueLabels = valuePropertyNames;
    }
    let colors = ChartHelpers.getChartColors();
    let chartData = { labels: [], datasets: [] };
    let index: number = 0;
    valuePropertyNames.forEach(property => {
      chartData.datasets.push({ data: [], backgroundColor: [], hoverBackgroundColor: [], label: valueLabels[index] });
      index++;
    });
    data.forEach((item: any, index: number, array: any[]) => {
      chartData.labels.push(item[labelPropertyName]);
      index = 0;
      valuePropertyNames.forEach(property => {
        chartData.datasets[index].data.push(item[property]);
        chartData.datasets[index].backgroundColor.push(colors[index]);
        chartData.datasets[index].hoverBackgroundColor.push(colors[index]);
        index++;
      });
    });

    return chartData;

  }


  public static getPieChartDataFromArrayOfObjects(data: any[], labelPropertyName: string, valuePropertyName: string): any {

    let colors = ChartHelpers.getChartColors();
    let chartData = { labels: [], datasets: [{ data: [], backgroundColor: [], hoverBackgroundColor: [] }] };
    data.forEach((item: any, index: number, array: any[]) => {
      chartData.labels.push(item[labelPropertyName]);
      chartData.datasets[0].data.push(item[valuePropertyName]);
      chartData.datasets[0].backgroundColor.push(colors[index]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[index]);
    });

    return chartData;

  }

  public static getPieChartData(label1: string, value1: number, label2: string, value2: number,
    label3: string = "", value3: number = null, label4: string = "", value4: number = null,
    label5: string = "", value5: number = null, label6: string = "", value6: number = null,
    label7: string = "", value7: number = null, label8: string = "", value8: number = null): any {

    let colors = ChartHelpers.getChartColors();
    let chartData = { labels: [], datasets: [{ data: [], backgroundColor: [], hoverBackgroundColor: [] }] };

    // 1
    chartData.labels.push(label1);
    chartData.datasets[0].data.push(value1);
    chartData.datasets[0].backgroundColor.push(colors[0]);
    chartData.datasets[0].hoverBackgroundColor.push(colors[0]);

    // 2
    chartData.labels.push(label2);
    chartData.datasets[0].data.push(value2);
    chartData.datasets[0].backgroundColor.push(colors[1]);
    chartData.datasets[0].hoverBackgroundColor.push(colors[1]);

    // 3
    if (label3 || value3 != null) {
      chartData.labels.push(label3);
      chartData.datasets[0].data.push(value3);
      chartData.datasets[0].backgroundColor.push(colors[2]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[2]);
    }

    // 4
    if (label4 || value4 != null) {
      chartData.labels.push(label4);
      chartData.datasets[0].data.push(value4);
      chartData.datasets[0].backgroundColor.push(colors[3]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[3]);
    }

    // 5
    if (label5 || value5 != null) {
      chartData.labels.push(label5);
      chartData.datasets[0].data.push(value5);
      chartData.datasets[0].backgroundColor.push(colors[4]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[4]);
    }

    // 6
    if (label6 || value6 != null) {
      chartData.labels.push(label6);
      chartData.datasets[0].data.push(value6);
      chartData.datasets[0].backgroundColor.push(colors[5]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[5]);
    }

    // 7
    if (label7 || value7 != null) {
      chartData.labels.push(label7);
      chartData.datasets[0].data.push(value7);
      chartData.datasets[0].backgroundColor.push(colors[6]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[6]);
    }

    // 8
    if (label8 || value8 != null) {
      chartData.labels.push(label8);
      chartData.datasets[0].data.push(value8);
      chartData.datasets[0].backgroundColor.push(colors[7]);
      chartData.datasets[0].hoverBackgroundColor.push(colors[7]);
    }

    return chartData;

  }

  public static getChartColors(): string[] {

    let colors: string[] = [
      '#97BBCD', // blue
      '#DCDCDC', // light grey
      '#F7464A', // red
      '#46BFBD', // green
      '#FDB45C', // yellow
      '#949FB1', // grey
      '#4D5360', // dark grey
      // custom extensions preferred over random colors otherwise used
      "#D500FF", // dark pink
      "#DBA901", // mustard
      "#0101DF", // blue
      "#8A0886", // purple
      "#088A29", // dark green
      "#00FFFF", // cyan
      "#D0F5A9", // light lime
      "#F5BCA9", // coral
      "#FFFF00", // bright yellow
      "#000000", // black
      "#8258FA", // violet
    ];

    //I'm not sure why this issue was closed as I ran into this problem today. I found the top 20 default google charts colors pretty helpful.
    //var default_colors = ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395',
    // '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC']

    // Other options here: https://github.com/Jam3/nice-color-palettes 

    return colors;

  }

  public static setChartDefaults(): void {

    if (ChartHelpers.configDone) {
      return;
    }

    if (!Chart.defaults.global) {
      Chart.defaults.global = {};
    }
    Chart.defaults.global.legend.display = true;
    Chart.defaults.global.legend.position = "bottom";
    //Chart.defaults.global.legend.fullWidth = false;
    Chart.defaults.global.colors = ChartHelpers.getChartColors();

    // Flag that we did our config so we don't keep doing it over and over
    ChartHelpers.configDone = true;

    //Chart.defaults.global = {
    //    // Boolean - Whether to animate the chart
    //    animation: true,

    //    // Number - Number of animation steps
    //    animationSteps: 60,

    //    // String - Animation easing effect
    //    // Possible effects are:
    //    // [easeInOutQuart, linear, easeOutBounce, easeInBack, easeInOutQuad,
    //    //  easeOutQuart, easeOutQuad, easeInOutBounce, easeOutSine, easeInOutCubic,
    //    //  easeInExpo, easeInOutBack, easeInCirc, easeInOutElastic, easeOutBack,
    //    //  easeInQuad, easeInOutExpo, easeInQuart, easeOutQuint, easeInOutCirc,
    //    //  easeInSine, easeOutExpo, easeOutCirc, easeOutCubic, easeInQuint,
    //    //  easeInElastic, easeInOutSine, easeInOutQuint, easeInBounce,
    //    //  easeOutElastic, easeInCubic]
    //    animationEasing: "easeOutQuart",

    //    // Boolean - If we should show the scale at all
    //    showScale: true,

    //    // Boolean - If we want to override with a hard coded scale
    //    scaleOverride: false,

    //    // ** Required if scaleOverride is true **
    //    // Number - The number of steps in a hard coded scale
    //    scaleSteps: null,
    //    // Number - The value jump in the hard coded scale
    //    scaleStepWidth: null,
    //    // Number - The scale starting value
    //    scaleStartValue: null,

    //    // String - Color of the scale line
    //    scaleLineColor: "rgba(0,0,0,.1)",

    //    // Number - Pixel width of the scale line
    //    scaleLineWidth: 1,

    //    // Boolean - Whether to show labels on the scale
    //    scaleShowLabels: true,

    //    // Interpolated JS string - can access value
    //    scaleLabel: "<%=value%>",

    //    // Boolean - Whether the scale should stick to integers, not floats even if drawing space is there
    //    scaleIntegersOnly: true,

    //    // Boolean - Whether the scale should start at zero, or an order of magnitude down from the lowest value
    //    scaleBeginAtZero: false,

    //    // String - Scale label font declaration for the scale label
    //    scaleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

    //    // Number - Scale label font size in pixels
    //    scaleFontSize: 12,

    //    // String - Scale label font weight style
    //    scaleFontStyle: "normal",

    //    // String - Scale label font color
    //    scaleFontColor: "#666",

    //    // Boolean - whether or not the chart should be responsive and resize when the browser does.
    //    responsive: true, //false,

    //    // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
    //    maintainAspectRatio: true,

    //    // Boolean - Determines whether to draw tooltips on the canvas or not
    //    showTooltips: true,

    //    // Function - Determines whether to execute the customTooltips function instead of drawing the built in tooltips (See [Advanced - External Tooltips](#advanced-usage-custom-tooltips))
    //    //customTooltips: false,

    //    // Array - Array of string names to attach tooltip events
    //    tooltipEvents: ["mousemove", "touchstart", "touchmove"],

    //    // String - Tooltip background color
    //    tooltipFillColor: "rgba(0,0,0,0.8)",

    //    // String - Tooltip label font declaration for the scale label
    //    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

    //    // Number - Tooltip label font size in pixels
    //    tooltipFontSize: 14,

    //    // String - Tooltip font weight style
    //    tooltipFontStyle: "normal",

    //    // String - Tooltip label font color
    //    tooltipFontColor: "#fff",

    //    // String - Tooltip title font declaration for the scale label
    //    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

    //    // Number - Tooltip title font size in pixels
    //    tooltipTitleFontSize: 14,

    //    // String - Tooltip title font weight style
    //    tooltipTitleFontStyle: "bold",

    //    // String - Tooltip title font color
    //    tooltipTitleFontColor: "#fff",

    //    // Number - pixel width of padding around tooltip text
    //    tooltipYPadding: 6,

    //    // Number - pixel width of padding around tooltip text
    //    tooltipXPadding: 6,

    //    // Number - Size of the caret on the tooltip
    //    tooltipCaretSize: 8,

    //    // Number - Pixel radius of the tooltip border
    //    tooltipCornerRadius: 6,

    //    // Number - Pixel offset from point x to tooltip edge
    //    tooltipXOffset: 10,

    //    // String - Template string for single tooltips
    //    tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value %>",

    //    // String - Template string for multiple tooltips
    //    multiTooltipTemplate: "<%= value %>",

    //    // Function - Will fire on animation progression.
    //    onAnimationProgress: function () { },

    //    // Function - Will fire on animation completion.
    //    onAnimationComplete: function () { }
    //}

    //Chart.defaults.Doughnut = {

    //    // Sets the chart to be responsive
    //    responsive: true,

    //    //Boolean - Whether we should show a stroke on each segment
    //    segmentShowStroke: true,

    //    //String - The color of each segment stroke
    //    segmentStrokeColor: '#fff',

    //    //Number - The width of each segment stroke
    //    segmentStrokeWidth: 2,

    //    //Number - The percentage of the chart that we cut out of the middle
    //    percentageInnerCutout: 50, // This is 0 for Pie charts

    //    //Number - Amount of animation steps
    //    animationSteps: 100,

    //    //String - Animation easing effect
    //    animationEasing: 'easeOutBounce',

    //    //Boolean - Whether we animate the rotation of the Doughnut
    //    animateRotate: true,

    //    //Boolean - Whether we animate scaling the Doughnut from the center
    //    animateScale: false,

    //    //String - A legend template
    //    legendTemplate: '<ul class="tc-chart-js-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>'
    //    //"<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>"
    //    //'<ul class="tc-chart-js-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="background-color:<%=datasets[i].strokeColor%>"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>'
    //    //'<ul class="tc-chart-js-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>'

    //};

    //if (!Chart.defaults.pie) {
    //    Chart.defaults.pie = {};
    //}
    //// Sets the chart to be responsive
    //Chart.defaults.pie.responsive = true;
    ////Boolean - Whether we should show a stroke on each segment
    //Chart.defaults.pie.segmentShowStroke = true;
    ////String - The color of each segment stroke
    //Chart.defaults.pie.segmentStrokeColor = '#fff';
    ////Number - The width of each segment stroke
    //Chart.defaults.pie.segmentStrokeWidth = 2;
    ////Number - The percentage of the chart that we cut out of the middle
    //Chart.defaults.pie.percentageInnerCutout = 0; // This is 0 for Pie charts
    ////Number - Amount of animation steps
    //Chart.defaults.pie.animationSteps = 100;
    ////String - Animation easing effect
    //Chart.defaults.pie.animationEasing = 'easeOutBounce';
    ////Boolean - Whether we animate the rotation of the Doughnut
    //Chart.defaults.pie.animateRotate = true;
    ////Boolean - Whether we animate scaling the Doughnut from the center
    //Chart.defaults.pie.animateScale = false;
    ////String - A legend template
    ////Chart.defaults.pie.legendTemplate = '<ul class="chart-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>';
    ////"<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>"
    ////'<ul class="tc-chart-js-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="background-color:<%=datasets[i].strokeColor%>"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>'
    ////'<ul class="tc-chart-js-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>'

  }


}
