<div #m [ngClass]="{'ib-menu-wrapper': depth===0}">
  <div *ngFor="let item of menu;trackBy: trackByFn"
       class="ib-menu-item clickable m-2"
       [style]="style | safe: 'style'"
       (mousedown)="onMouseDown($event, item)"
       (click)="onMenuItemClick($event, item)">
    <ib-icon icon="chevron-right" [fixedWidth]="true" *ngIf="item.Children && item.Children.length > 0 && !isExpanded(item)"></ib-icon>
    <ib-icon icon="chevron-down" [fixedWidth]="true" *ngIf="item.Children && item.Children.length > 0 && isExpanded(item)"></ib-icon>
    <ib-icon [icon]="item.Icon" [label]="item.Label" [tooltip]="item.Description" [fixedWidth]="true"></ib-icon>
    <ib-menu-standard *ngIf="isExpanded(item)"
                      [menu]="item.Children"
                      [parent]="item"
                      [depth]="depth+1"
                      [contextMenuActions]="contextMenuActions"
                      [developerMode]="developerMode"
                      (menuItemClick)="onChildMenuItemClick($event)"
                      (contextMenuSelection)="onChildContextMenuSelection($event)">
    </ib-menu-standard>
  </div>
</div>
<p-contextMenu *ngIf="primeContextMenu" [target]="m" appendTo="body" [model]="primeContextMenu"></p-contextMenu>
