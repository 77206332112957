import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';

@Component({
  selector: 'ib-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent implements OnInit {

  @Input() small: boolean = true;

  constructor(protected renderer: Renderer2) { }

  ngOnInit() {
  }

}
