import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { AssetService } from '../../../../../core-lib/src/lib/services/asset.service';
import { BaseComponent } from '../../../../../core-lib/src/lib/helpers/base-component';
import { takeUntil } from 'rxjs/operators';
import { EventModel, EventElementModel } from '../../ux-models';
import { Helper } from '../../../../../core-lib/src/lib/helpers/helper';

@Component({
  selector: 'ib-input-asset-license-select',
  templateUrl: './input-asset-license-select.component.html',
  styleUrls: ['./input-asset-license-select.component.css']
})
export class InputAssetLicenseSelectComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() name: string = "License";
  @Input() label: string = "License";
  @Input() value: string = "";
  @Input() filter: string[] = [];

  @Input() optionsIncludeNone: boolean = true;
  @Input() optionsNoneLabel: string = "<Other>";
  @Input() optionsNoneDescription: string = "Other License or Not Known";

  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  allLicenses: m5.AssetLicenseViewModel[] = [];
  licenses: m5.AssetLicenseViewModel[] = [];
  selectedLicense: m5.AssetLicenseViewModel = new m5.AssetLicenseViewModel();

  constructor(protected assetService: AssetService) {
    super();
  }

  ngOnInit() {
    this.assetService.getAssetLicenses().pipe(takeUntil(this.ngUnsubscribe)).subscribe((assetLicenses) => {
      this.allLicenses = assetLicenses;
      this.updateAllowedLicenses();
      this.updateSelectedLicense();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter) {
      this.updateAllowedLicenses();
    }
    if (changes.value && this.value) {
      this.updateSelectedLicense();
    }
  }

  updateAllowedLicenses() {
    if (!this.allLicenses || this.allLicenses.length === 0) {
      this.licenses = [];
      if (this.optionsIncludeNone) {
        this.licenses.push(this.getLicenseModelForNone());
      }
      return;
    }
    if (!this.filter || this.filter.length === 0) {
      this.licenses = [];
      if (this.optionsIncludeNone) {
        this.licenses.push(this.getLicenseModelForNone());
      }
      this.licenses.push(...this.allLicenses);
      //console.error(this.licenses);
      return;
    }
    this.licenses = [];
    if (this.optionsIncludeNone) {
      this.licenses.push(this.getLicenseModelForNone());
    }
    this.licenses = this.allLicenses.filter(x => this.filter.includes(x.ExternalAssetLicenseId));
    //console.error(this.licenses);
  }

  getLicenseModelForNone() {
    let model = new m5.AssetLicenseViewModel();
    model.ExternalAssetLicenseId = Helper.getFirstDefinedString(this.optionsNoneLabel, "<Other>");
    model.Description = Helper.getFirstDefinedString(this.optionsNoneDescription, "Other License or Not Known");
    return model;
  }

  updateSelectedLicense() {
    if (!this.licenses || this.licenses.length === 0) {
      this.selectedLicense = new m5.AssetLicenseViewModel();
      return;
    }
    if (!this.value) {
      if (this.licenses.length === 1) {
        this.selectedLicense = this.licenses[0];
        this.onChange({ value: this.selectedLicense });
      } else {
        this.selectedLicense = new m5.AssetLicenseViewModel();
      }
      return;
    }
    this.selectedLicense = Helper.firstOrDefault(this.licenses, x => x.ExternalAssetLicenseId === this.value);
  }

  onChange($event) {
    //console.error("select", $event);
    this.selectedLicense = $event.value;
    this.value = (<m5.AssetLicenseViewModel>$event.value).ExternalAssetLicenseId;
    let cargo: any = { selectedLicense: this.selectedLicense };
    let payload: EventModel = new EventModel("change", $event, this.value, new EventElementModel("input-asset-license-select", null, this.label, this.label), cargo);
    this.change.emit(payload);
  }

}
