<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <ib-form-render [formModel]="form"
                  [data]="data"
                  (status)="onFormStatusChange($event)">
  </ib-form-render>
  <div style="width:100%; float:left;">
    <ib-form-status-error-output [formStatus]="formStatus" classes="float-right mb-1"></ib-form-status-error-output>
  </div>
  <div class="clearfix"></div>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         [errors]="formStatus.errors"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
