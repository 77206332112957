import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit, Directive } from '@angular/core';
import { TableModule, Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
//import { Column } from 'primeng/components/common/shared';
import { EventModel, EventElementModel, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions, PrimeColumn } from 'projects/common-lib/src/lib/table/table-column-options';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiProperties, Query, ApiOperationType, ApiCall, IApiResponseWrapper, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { SafeStyle, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { FilterSelectionData } from 'projects/common-lib/src/lib/filter/filter-selection-data';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';

@Directive()
export abstract class TableBaseClass extends BaseComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  @Input() options: TableOptions = null;
  @Input() data: any[] = [];

  /**
   * Mostly this is used internally when loading data but it's available to be set externally for
   * scenarios where we are lazy loading data external to this component.
   * @see TableOptions.expandedRowEventHandler
   * @see TableOptions.expandedRowChildDataLoading
   */
  @Input() loading: boolean = false;


  /**
  Incrementing this input will alert the table that data was reloaded so it can handle any refresh needed internally.
  */
  @Input() reloadCount: number = 0;

  /**
   * An incrementor to trigger the collapse of all rows.
   */
  @Input() collapseRowsCount: number = 0;

  /**
   * For performance reasons our cell rendering uses ChangeDetectionStrategy.OnPush but if something external to
   * the table triggers a change that is not otherwise handled via an Input() parameter (e.g. something that
   * influences the output of a render function) then our cell rendering would not know change detection is needed.
   * This input provides us the ability to trigger change detection from the outside by incrementing the value
   * in those circumstances.
   */
  @Input() otherChangeCount: number = 0;

  @Output() rowReorder: EventEmitter<any> = new EventEmitter();


  viewPortHeight: number = Helper.getMaxComponentHeight(null, null, 200);

  /**
  Default options as submitted.  We have support for saving options to local storage
  so keeping copy of defaults allows us to revert to default if desired, etc.
  */
  public defaultOptions: TableOptions = null;

  public rowsPerPageOptions: { rows: string, label: string }[] = [
    { rows: "5", label: "5" },
    { rows: "10", label: "10" },
    { rows: "25", label: "25" },
    { rows: "50", label: "50" },
    { rows: "100", label: "100" },
    { rows: Constants.RowsToReturn.All.toString(), label: "All" }
  ];

  /**
  When row selection is turned on this is an array of selected rows.
  */
  public selectedData: any = null;
  public selectionMode: "" | "single" | "multiple" = "single"; //| "multipleWithCheckboxes" = "single";

  public contextMenu: MenuItem[] = [];
  public contextMenuRowIndex: number = -1;
  public contextMenuButtonHeaderRowIndex: number = -1;
  public contextMenuButtonRowIndex: number = -1;
  public contextMenuIsDynamic: boolean = false;


  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected queryService: QueryService,
    protected sanitizer: DomSanitizer,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
    // Any change should trigger otherChangeCount so our render component triggers ChangeDetectionStrategy.OnPush
    this.otherChangeCount++;
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }


  public configure() {

    if (!this.options) {
      this.options = new TableOptions();
    }

    if (!this.options.columns || this.options.columns.length === 0) {
      this.options.columns = TableHelper.buildColumnOptionsFromData(this.data);
    } else {
      this.options.columns = TableHelper.assignColumnDataTypesFromData(this.options.columns, this.data);
    }

    if (this.options.rowsPerPageOptions && this.options.rowsPerPageOptions.length > 0) {
      this.rowsPerPageOptions = [];
      this.options.rowsPerPageOptions.forEach((rows: number) => {
        let label: string = rows.toString();
        if (rows === 0 || rows === Constants.RowsToReturn.All) {
          label = "All";
        }
        this.rowsPerPageOptions.push({ rows: rows.toString(), label: label });
      });
    }

    if (this.options.selectRows && this.selectionMode === "single") {
      this.selectionMode = "multiple"; // "multipleWithCheckboxes"; // "multiple";
      this.selectedData = [];
    } else if (!this.options.selectRows && this.selectionMode === "multiple") {
      this.selectionMode = "single";
      this.selectedData = null;
    }

    // Convert from our row action menu to prime context menu
    this.prepareContextMenu(null);

  }


  public prepareContextMenu(data: any) {

    // If no row action button then no context menu
    if (!this.options || !this.options.rowActionButton) {
      return;
    }

    // If we already have a context menu and it's not dynamic based on data supplied or if we have no data supplied then we're done
    if (this.contextMenu && this.contextMenu.length > 0 && (!this.contextMenuIsDynamic || !data)) {
      return;
    }

    this.contextMenu = [];
    this.options.rowActionButton.options.forEach((action) => {
      if (!action.icon && !action.label) {
        // Divider but not if it's first in our list and not if it's right after another separator
        if (this.contextMenu.length === 0) {
          // We don't add a separator as first menu item
        } else if (this.contextMenu.slice(-1)[0].separator) {
          // We don't add a separator right after another separator
        } else {
          this.contextMenu.push({ separator: true });
        }
      } else {
        // If we have a visible function then note that the context menu is dynamic so it can get reevaluated for each row
        if (action.visible) {
          this.contextMenuIsDynamic = true;
        }
        let include: boolean = true;
        if (action.visible && data) {
          // If we have a visible function and data let's see if it's visible here
          include = action.visible(data);
        }
        if (include) {
          const icon = IconHelper.parseIcon(action.icon);
          const item: MenuItem = {
            label: action.label, icon: icon.calculatedClasses, command: (event) => {
              //console.error("context menu native event");
              //console.error(event);
              let index = this.contextMenuRowIndex;
              if (index === -1) {
                index = this.contextMenuButtonRowIndex;
              }
              if (index === -1) {
                index = this.data.findIndex(x => x === this.selectedData);
              }
              const cargo: any = {
                index: index,
                selectedData: this.selectedData
              };
              let row: any = null;
              if (index > -1) {
                row = this.data[index];
              } else {
                row = this.selectedData; // TODO could be array!
              }
              const model: EventModel = new EventModel("context-menu", event, row, null, cargo);
              this.contextMenuRowIndex = -1;
              action.action(model);
            }
          };
          this.contextMenu.push(item);
        }
      }
    });

  }



  public getRowStyle(row: any, extraStyles: string = ""): SafeStyle {
    let style: string = this.options.rowStyle || "";
    if (extraStyles) {
      if (style) {
        style = `${style};${extraStyles}`;
      } else {
        style = extraStyles;
      }
    }
    if (this.options.getRowStyle) {
      try {
        const more = this.options.getRowStyle(row);
        if (more) {
          if (style) {
            style = `${style};${more}`;
          } else {
            style = more;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }



  public getCellStyle(col: PrimeColumn, data: any, row: any, extraStyles: string = ""): SafeStyle {
    const options: TableColumnOptions = (<any>col).options;
    let style: string = options.style || "";
    if (extraStyles) {
      if (style) {
        style = `${style};${extraStyles}`;
      } else {
        style = extraStyles;
      }
    }
    if (options.width) {
      if (style) {
        style = `${style};width:${options.width};`;
      } else {
        style = `width:${options.width};`;
      }
    }
    if (options.getStyle) {
      try {
        const more = options.getStyle(data, row);
        if (more) {
          if (style) {
            style = `${style};${more}`;
          } else {
            style = more;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getModel(row: any, propertyName: string): any {
    let value: any = null;
    if (!row) {
      return "";
    }
    try {
      if (propertyName.includes(".")) {
        const parts: string[] = propertyName.split(".");
        if (parts.length === 4) {
          value = row[parts[0]][parts[1]][parts[2]][parts[3]];
        } else if (parts.length === 3) {
          value = row[parts[0]][parts[1]][parts[2]];
        } else if (parts.length === 2) {
          value = row[parts[0]][parts[1]];
        } else {
          value = row[propertyName];
        }
      } else {
        value = row[propertyName];
      }
    } catch (err) {
      const message = `Error accessing ${propertyName}:`; // ${JSON.stringify(err)}`;
      Log.errorMessage(message);
      Log.errorMessage(err);
      value = "";
    }
    //console.error(propertyName, value, row);
    return value;
  }

  onModelChange($event: any, row: any, propertyName: string) {
    try {
      if (propertyName.includes(".")) {
        const parts: string[] = propertyName.split(".");
        if (parts.length === 3) {
          if ($event.data) {
            row[parts[0]][parts[1]][parts[2]] = $event.data;
          } else {
            row[parts[0]][parts[1]][parts[2]] = $event;
          }
        } else if (parts.length === 2) {
          if ($event.data) {
            row[parts[0]][parts[1]] = $event.data;
          } else {
            row[parts[0]][parts[1]] = $event;
          }
        } else {
          if ($event.data) {
            row[propertyName] = $event.data;
          } else {
            row[propertyName] = $event;
          }
        }
      } else {
        if ($event.data) {
          row[propertyName] = $event.data;
        } else {
          row[propertyName] = $event;
        }
      }
    } catch (err) {
      const message = `Error accessing ${propertyName}:`; // ${JSON.stringify(err)}`;
      Log.errorMessage(message);
      Log.errorMessage(err);
    }
  }

  trackByFn(index, item) {
    if (!this.options || !this.options.primaryKey || !item) {
      // We don't have options or a primary key or an object to get the primary key from then index based
      // value is all we can do.  We don't want index to collide with a primary key so use index*-1000.
      return (-1000 * index);
    }
    if (!item[this.options.primaryKey]) {
      return (-1000 * index);
    }
    return item[this.options.primaryKey];
  }
  trackByIndex(index, item) {
    return index; // or item.id
  }


  public showMenu(menu: any, $event: any, rowData: any, rowIndex: number = -1, headerIndex: number = -1) {
    this.prepareContextMenu(rowData);
    this.contextMenuButtonRowIndex = rowIndex;
    this.contextMenuButtonHeaderRowIndex = headerIndex;
    menu.toggle($event);
  }


  public getRowExpandedHtml(row: any, index: number): SafeHtml {

    if (!this.options.expandedRowHtmlBuilder) {
      return this.sanitizer.bypassSecurityTrustHtml("");
    }

    const cargo: any = { index: index };
    if (Helper.isArray(this.selectedData)) {
      cargo.selectedRows = this.selectedData;
    } else {
      cargo.selectedRows = [this.selectedData];
    }

    const html = this.options.expandedRowHtmlBuilder(row, cargo.selectedRows, this.data, cargo);
    return this.sanitizer.bypassSecurityTrustHtml(html);

  }




  protected isRowSelected(row: any): boolean {
    if (!row || !this.selectedData) {
      return false;
    }
    if (Helper.isArray(this.selectedData)) {
      if (this.options.primaryKey) {
        const matches = this.selectedData.filter(x => x[this.options.primaryKey] === row[this.options.primaryKey]);
        return (matches.length > 0);
      } else {
        const matches = this.selectedData.filter(x => JSON.stringify(x) === JSON.stringify(row));
        return (matches.length > 0);
      }
    } else {
      if (this.options.primaryKey) {
        return (this.selectedData[this.options.primaryKey] === row[this.options.primaryKey]);
      } else {
        return (JSON.stringify(this.selectedData) === JSON.stringify(row));
      }
    }
  }

  protected isRowMenuCell(event): boolean {
    // We have one column in our table which might be our row menu.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    let menu = false;
    try {
      if (event.originalEvent && event.originalEvent.path) {
        event.originalEvent.path.some((path) => {
          if (Helper.startsWith(path.id, "rowMenuCell", true)) {
            menu = true;
            // Found match
            return true;
          }
          // Keep looking
          return false;
        });
      } else if (event.originalEvent && event.originalEvent.target) {
        if (Helper.contains(event.originalEvent.target.className, "bars", true) ||
          Helper.contains(event.originalEvent.target.className, "rowMenuCell", true) ||
          Helper.contains(event.originalEvent.target.parentElement.className, "rowMenuCell", true)) {
          menu = true;
        } else {
          //console.error('rowclick');
          //console.error(event.originalEvent.target.className);
          //console.error(event.originalEvent.target.parentElement);
        }
      }
    } catch (err) {
      Log.errorMessage("Error attempting to find 'rowMenuCell' in original event path, target class name, or target parent class name.")
      Log.errorMessage(err);
      Log.errorMessage(event);
    }
    return menu;
  }

  protected isRowCheckboxCell(event): boolean {
    // We have one column in our table which might be our checkbox.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    let checkbox = false;
    try {
      if (event.target && event.target.className) {
        if (Helper.contains(event.target.className, "p-checkbox", true)) {
          checkbox = true;
        }
      } else if (event.srcElement && event.srcElement.className) {
        if (Helper.contains(event.srcElement.className, "p-checkbox", true)) {
          checkbox = true;
        }
      } else if (event.toElement && event.toElement.className) {
        if (Helper.contains(event.toElement.className, "p-checkbox", true)) {
          checkbox = true;
        }
      }
      else if (event.target && event.target.innerHTML) {
        if (Helper.contains(event.target.innerHTML, "p-checkbox", true)) {
          checkbox = true;
        }
      }
    } catch (err) {
      Log.errorMessage("Error attempting to find checkbox cell in event information.")
      Log.errorMessage(err);
      Log.errorMessage(event);
    }
    return checkbox;
  }


  protected isRowExpandCell(event): boolean {
    // We have one column in our table which might be an expand row icon.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    let expand = false;
    try {
      if (event.target && event.target.className) {
        if (Helper.contains(event.target.className, "expand-row-icon", true)) {
          expand = true;
        }
      } else if (event.srcElement && event.srcElement.className) {
        if (Helper.contains(event.srcElement.className, "expand-row-icon", true)) {
          expand = true;
        }
      } else if (event.toElement && event.toElement.className) {
        if (Helper.contains(event.toElement.className, "expand-row-icon", true)) {
          expand = true;
        }
      }
      else if (event.target && event.target.innerHTML) {
        if (Helper.contains(event.target.innerHTML, "expand-row-icon", true)) {
          expand = true;
        }
      }
    } catch (err) {
      Log.errorMessage("Error attempting to find expand-row-icon cell in event information.")
      Log.errorMessage(err);
      Log.errorMessage(event);
    }
    return expand;
  }


  // public logError(message, event) {
  //   if (message) {
  //     console.error(message);
  //   }
  //   if (event) {
  //     console.error(event);
  //   }
  // }


}


export interface TableState {
  filterCriteria?: any;
  filterSelectionData?: FilterSelectionData;
  globalFilterText?: string;
}
