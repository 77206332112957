<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label *ngIf="!fullWidth" for="{{inputControlId}}" class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    <ng-container *ngIf="richTextMode==='optional'">
      <br />
      <a (click)="richText = !richText" [ngbTooltip]="( richText ? 'Switch to Plain Text' : 'Switch to Rich Text' )" class="float-right clickable">
        <i class="text-primary" [ngClass]="{'fas': richText, 'fal': !richText, 'fa-font': true}"></i>
      </a>
    </ng-container>
  </label>
  <div class="{{inputWrapperClass}}" [hidden]="richText">
    <div *ngIf="prefixText || prefixIcon" class="input-group-prepend" [ngClass]="{'clickable': isPrefixClickable()}">
      <span class="input-group-text" ngbTooltip="{{prefixTooltip | translate}}" (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
    </div>
    <textarea class="form-control {{inputSize}} {{controlClass}}"
              id="{{inputControlId}}"
              name="{{name}}"
              placeholder="{{( placeholder | translate ) || placeholder}}"
              ngbTooltip="{{tooltip | translate}}"
              pattern="{{pattern}}"
              [rows]="rows"
              [wrap]="wrap"
              [style]="style"
              [maxlength]="maxlength"
              [minlength]="minlength"
              [disabled]="disabled ? 'disabled' : null"
              [readonly]="readonly ? 'readonly' : null"
              [required]="required ? 'required' : null"
              (focus)="fireFocus($event, inputControl)"
              (blur)="fireBlur($event, inputControl)"
              (keyup)="fireKeyUp($event, inputControl); autoExpandTextArea($event, textArea);"
              (change)="fireChange($event, inputControl)"
              [(ngModel)]="value"
              [ngModelOptions]="{standalone: standalone}"
              #inputControl="ngModel"
              #textArea>
    </textarea>
    <div *ngIf="suffixText || suffixIcon" class="input-group-append" [ngClass]="{'clickable': isSuffixClickable()}">
      <span class="input-group-text" ngbTooltip="{{suffixTooltip | translate}}" (click)="fireSuffixClick($event)">
        {{suffixText | translate}}
        <ib-icon icon="{{suffixIcon}}"></ib-icon>
      </span>
    </div>
    <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
      <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
        {{errorMessage | translate:inputInformationValues}}
      </small>
    </div>
  </div>
  <div class="{{inputWrapperClass}}" *ngIf="richText">
    <!--ngIf because hiding still causes html editor to 'eat' space and enter keys in favor of html, etc.-->
    <ib-html-editor [height]="100"
                    [uploadImageAssets]="uploadImageAssets"
                    [systemAssetGroup]="systemAssetGroup"
                    [assetOwnerResourceType]="assetOwnerResourceType"
                    [assetOwnerResourceId]="assetOwnerResourceId"
                    [assetOwnerResourceId2]="assetOwnerResourceId2"
                    [assetSecondaryOwnerResourceType]="assetSecondaryOwnerResourceType"
                    [assetSecondaryOwnerResourceId]="assetSecondaryOwnerResourceId"
                    [assetSecondaryOwnerResourceId2]="assetSecondaryOwnerResourceId2"
                    [(ngModel)]="value"
                    [ngModelOptions]="{standalone: standalone}"></ib-html-editor>
  </div>
  <div *ngIf="characterCountTemplate" class="{{Constants.Layout.fullWidth}}">
    <small class="form-text float-right">
      {{characterCountTemplate | translate:inputInformationValues}}
      <!--<div [translate]="characterCountTemplate" [translateParams]="inputInformationValues"></div>-->
    </small>
  </div>
</div>
