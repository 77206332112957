import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, PreloadAllModules } from '@angular/router';
import { CanDeactivateGuard } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { AppPreloaderService } from 'projects/core-lib/src/lib/services/app-preloader.service';
import { IntroductionComponent } from './instructions/introduction/introduction.component';
import { CredentialsComponent } from './instructions/credentials/credentials.component';
import { RequestFormatsComponent } from './instructions/request-formats/request-formats.component';
import { RequestFormatsListsComponent } from './instructions/request-formats-lists/request-formats-lists.component';
import { ResponseFormatsComponent } from './instructions/response-formats/response-formats.component';
import { DataFormatsComponent } from './instructions/data-formats/data-formats.component';
import { ResultCodesComponent } from './instructions/result-codes/result-codes.component';
import { VersionsComponent } from './instructions/versions/versions.component';
import { RateLimitsComponent } from './instructions/rate-limits/rate-limits.component';
import { NotesComponent } from './instructions/notes/notes.component';
import { EndpointsComponent } from './instructions/endpoints/endpoints.component';
import { PolicyTermsOfUseComponent } from './instructions/policy-terms-of-use/policy-terms-of-use.component';
import { PolicyApiPolicyComponent } from './instructions/policy-api-policy/policy-api-policy.component';
import { PolicyApiLicenseComponent } from './instructions/policy-api-license/policy-api-license.component';
import { DataModelOverviewComponent } from './instructions/data-model-overview/data-model-overview.component';
import { DataModelListComponent } from './instructions/data-model-list/data-model-list.component';
import { DataModelObjectComponent } from './instructions/data-model-object/data-model-object.component';
import { RequestFormatsMethodsComponent } from './instructions/request-formats-methods/request-formats-methods.component';
import { EndpointComponent } from './endpoint/endpoint/endpoint.component';
import { HomeComponent } from './shared/home/home.component';
import { LoginComponent } from './shared/login/login.component';
import { LoginRecoverComponent } from 'projects/common-lib/src/lib/ux/security/login-recover/login-recover.component';
import { PasswordResetLinkRequestComponent } from 'projects/common-lib/src/lib/ux/security/password-reset-link-request/password-reset-link-request.component';
import { PasswordResetComponent } from 'projects/common-lib/src/lib/ux/security/password-reset/password-reset.component';
import { MultiFactorAuthenticationStepOneComponent } from 'projects/common-lib/src/lib/ux/security/multi-factor-authentication-step-one/multi-factor-authentication-step-one.component';
import { MultiFactorAuthenticationStepTwoComponent } from 'projects/common-lib/src/lib/ux/security/multi-factor-authentication-step-two/multi-factor-authentication-step-two.component';

const appRoutes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'login/forgot', component: LoginRecoverComponent },
  { path: 'login/password-reset', component: PasswordResetLinkRequestComponent },
  { path: 'login/password-change', component: PasswordResetComponent, data: { reason: "change" } },
  { path: 'login/password-expired', component: PasswordResetComponent, data: { reason: "expired" } },
  { path: 'login/mfa-step-1', component: MultiFactorAuthenticationStepOneComponent },
  { path: 'login/mfa-step-2', component: MultiFactorAuthenticationStepTwoComponent },

  { path: 'overview/introduction', component: IntroductionComponent, data: {} },
  { path: 'overview/credentials', component: CredentialsComponent, data: {} },
  { path: 'overview/request-formats', component: RequestFormatsComponent, data: {} },
  { path: 'overview/request-formats/methods', component: RequestFormatsMethodsComponent, data: {} },
  { path: 'overview/request-formats/lists', component: RequestFormatsListsComponent, data: {} },
  { path: 'overview/response-formats', component: ResponseFormatsComponent, data: {} },
  { path: 'overview/data-formats', component: DataFormatsComponent, data: {} },
  { path: 'overview/result-codes', component: ResultCodesComponent, data: {} },
  { path: 'overview/versions', component: VersionsComponent, data: {} },
  { path: 'overview/rate-limits', component: RateLimitsComponent, data: {} },
  { path: 'overview/notes', component: NotesComponent, data: {} },
  { path: 'overview/endpoints', component: EndpointsComponent, data: {} },
  { path: 'overview/terms-of-use', component: PolicyTermsOfUseComponent, data: {} },
  { path: 'overview/api-use-policy', component: PolicyApiPolicyComponent, data: {} },
  { path: 'overview/api-license-agreement', component: PolicyApiLicenseComponent, data: {} },
  { path: 'overview/data-model', component: DataModelOverviewComponent, data: {} },
  { path: 'overview/data-model/categories', component: DataModelListComponent, data: { listType: "category" } },
  { path: 'overview/data-model/tables', component: DataModelListComponent, data: { listType: "table" } },
  { path: 'overview/data-model/views', component: DataModelListComponent, data: { listType: "view" } },
  { path: 'overview/data-model/tables-and-views', component: DataModelListComponent, data: { listType: "all" } },
  { path: 'overview/data-model/object/:id', component: DataModelObjectComponent, data: {} },

  { path: '**', component: EndpointComponent, data: {} },

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: AppPreloaderService })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(protected router: Router) {

    // Add any wildcard catch all
    // e.g. { path: '**', component: ComponentBuilder }
    // Could parse url for form id or asset id and do dynamic content
    // if not found could go to 404 page or to dashboard
    // dynamic components seem tricky with AOT could we have window.ibapp.[appname]
    // object with code that we then hook to form events that gets copy of
    // form, data, etc. passed into the methods for actions?  The form
    // would just know the "appname" and the event method to fire.
    // Or maybe "appname" is just "formid39811" so events that need to execute
    // live at window.ibapp.formid39811.eventname(form, data)? will we be able
    // to attach events and pass data dynamically in form?  Maybe data if referenced
    // at window.ibapp.formid39811.data ?

    // Reset routes with what we've added via helpers
    //this.router.resetConfig(appRoutes);

  }

}
