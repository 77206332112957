import { Component, OnInit } from '@angular/core';
import { CustomContentBaseClass } from '../custom-content-base-class';

@Component({
  selector: 'app-purchase-examples',
  templateUrl: './purchase-examples.component.html',
  styleUrls: ['./purchase-examples.component.scss']
})
export class PurchaseExamplesComponent extends CustomContentBaseClass implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
