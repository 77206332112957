import * as m from "./ngCoreModels";
import * as m5core from "./ngModelsCore5";
import * as m5sec from "./ngModelsSecurity5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export enum AuthenticatedUserReasonHasAssignment {
		Unknown = 0,
		AssignedToContact = 1,
		AssignedToGroupContactBelongsTo = 2,
		AssignedToContactTypeAutomatically = 3
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class MultiFactorAuthenticationOptionsViewModel {
		ContactId: number = null;
		TotpEnabled: boolean = false;
		PhoneNumbers: string[] = [];
		EmailAddresses: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class RestrictionsEditViewModel {
		RequiredModulesAll: string[] = [];
		RequiredModulesAny: string[] = [];
		RequiredPermissionsAll: m5sec.RestrictionPermissionEditViewModel[] = [];
		RequiredPermissionsAny: m5sec.RestrictionPermissionEditViewModel[] = [];
		RequiredGroupIdsAll: number[] = [];
		RequiredGroupIdsAny: number[] = [];
		RequiredRoleIdsAll: number[] = [];
		RequiredRoleIdsAny: number[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class RestrictionPermissionEditViewModel {
		Id: string = null;
		PermissionAreaType: string = "";
		PermissionArea: string = "";
		Rights: string[] = [];
		AllRightsRequired: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class LoginNameAvailableRequestViewModel {
		Login: string = "";
		Email: string = "";
		FirstName: string = "";
		LastName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class MultiFactorAuthenticationRequestViewModel {
		ContactId: number = null;
		Login: string = "";
		Method: string = "";
		Target: string = "";
		Source: string = "";
		Value: string = "";
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SecurityPolicyViewModel {
		SecurityPolicyId: number = null;
		Description: string = "";
		ContactType: string = "";
		ContactTypeDescription: string = "";
		PartitionId: number = null;
		GroupIds: number[] = [];
		Priority: number = 0;
		MinimumPasswordLength: number = 0;
		AlphaCharactersRequired: boolean = false;
		NumericCharactersRequired: boolean = false;
		MixedCaseRequired: boolean = false;
		PunctuationRequired: boolean = false;
		PasswordCannotContainLogin: boolean = false;
		PasswordCannotContainName: boolean = false;
		PasswordCannotContainImportantDates: boolean = false;
		DaysBeforePasswordChangeRequired: number = 0;
		PreviousPasswordsToBlockRepeating: number = 0;
		PreviousPasswordDaysToBlockRepeating: number = 0;
		FailureCountUntilLoginBlocked: number = 0;
		SecondsToBlockLogin: number = 0;
		AuditLoginFailureBadLoginName: boolean = false;
		AuditLoginFailureBadPassword: boolean = false;
		AuditLoginFailureBadMultiFactorAuthentication: boolean = false;
		AuditLoginBlocked: boolean = false;
		AuditPasswordChanged: boolean = false;
		AuditPasswordReset: boolean = false;
		AuditNewPublicIpAddress: boolean = false;
		AuditNewDeviceId: boolean = null;
		AuditSecuritySettingsChanged: boolean = false;
		SecurityQuestionAnswerRequired: boolean = false;
		SecurityQuestionAnswerFreeForm: boolean = false;
		SecurityQuestionAnswerCount: number = 0;
		SecurityQuestions: string[] = [];
		LoginRecoveryToEmailAddress: boolean = false;
		PasswordChangeRequiresSecurityQuestionAnswer: boolean = false;
		PasswordResetRequiresSecurityQuestionAnswer: boolean = false;
		PasswordResetLinkBasedOnLoginToEmailAddress: boolean = false;
		PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress: boolean = false;
		TokenLifetimeHours: number = 0;
		TokenSupportSilentRefresh: boolean = false;
		TokenRejectOnNewPublicIpAddress: boolean = false;
		TokenRejectOnNewDeviceId: boolean = null;
		SupportLoginOperatingSystem: boolean = false;
		SupportLoginFacebook: boolean = false;
		SupportLoginTwitter: boolean = false;
		SupportLoginGoogle: boolean = false;
		SupportLoginMicrosoft: boolean = false;
		SupportLoginApple: boolean = false;
		SupportLoginLinkedIn: boolean = false;
		SupportLoginYahoo: boolean = false;
		SupportLoginInstagram: boolean = false;
		SupportLoginDropbox: boolean = false;
		RequireMultiFactorAuthentication: boolean = false;
		RequireMultiFactorAuthenticationForNewPublicIpAddress: boolean = false;
		RequireMultiFactorAuthenticationForNewDeviceId: boolean = null;
		RequireMultiFactorAuthenticationForGroups: number[] = [];
		MultiFactorAuthenticationCodeLength: number = 0;
		MultiFactorAuthenticationFailOpen: boolean = false;
		SupportMultiFactorAuthenticationEmail: boolean = false;
		SupportMultiFactorAuthenticationSms: boolean = false;
		SupportMultiFactorAuthenticationVoice: boolean = false;
		SupportMultiFactorAuthenticationAppTotp: boolean = false;
		SupportMultiFactorAuthenticationAppDuo: boolean = false;
		NotificationGroupIdNewAccountCreated: number = 0;
		NotificationGroupIdLoginRecovery: number = 0;
		NotificationGroupIdPasswordChanged: number = 0;
		NotificationGroupIdPasswordReset: number = 0;
		NotificationGroupIdPasswordResetLink: number = 0;
		NotificationGroupIdMultiFactorAuthenticationCode: number = 0;
		NotificationGroupIdNewMultiFactorAuthenticationMethod: number = 0;
		NotificationGroupIdNewPublicIpAddress: number = 0;
		NotificationGroupIdNewDeviceId: number = null;
		Comments: string = "";
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PasswordResetLinkRequestViewModel {
		Login: string = "";
		BaseUrl: string = "";
		QueryStringParameterName: string = "";
		ValidMinutes: number = 0;
		ValidCount: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class LoginRequest {
		Login: string = "";
		Password: string = "";
		RememberMe: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserViewModel {
		ContactType: string = "";
		ContactId: number = null;
		ContactName: string = "";
		ContactFirstName: string = "";
		ContactLastName: string = "";
		ContactEmail: string = "";
		PreferredLanguage: string = "";
		Flags: string[] = [];
		ParentContactType: string = "";
		ParentContactId: number = null;
		ParentContactName: string = "";
		PartitionId: number = null;
		PartitionDescription: string = "";
		Login: string = "";
		Token: string = "";
		ApiKey: string = "";
		PartnerTokens: {[key: string]:  string} = null;
		MultiFactorAuthenticationTarget: m5sec.AuthenticatedUserMultiFactorAuthenticationOptionViewModel = null;
		MultiFactorAuthenticationOptions: m5sec.AuthenticatedUserMultiFactorAuthenticationOptionViewModel[] = [];
		MultiFactorAuthenticationSupportsAppTotp: boolean = false;
		Settings: m5sec.UserSettings = null;
		Groups: m5sec.AuthenticatedUserGroupViewModel[] = [];
		Roles: m5sec.AuthenticatedUserRoleViewModel[] = [];
		Permissions: m5sec.AuthenticatedUserPermissionViewModel[] = [];
		AuthenticationData: m.AuthenticationData = null;
		AsOfUtc: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserMultiFactorAuthenticationOptionViewModel {
		Method: string = "";
		Target: string = "";
		Source: string = "";
		DefaultOption: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class UserSettings {
		ApplicationSecurityPreferences: m5sec.UserSettingsApplicationSecurityPreferences = null;
		AsOfUtc: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UserSettingsApplicationSecurityPreferences {
		ExternalErrorTracking: string = "";
		ExternalAnalyticsForUsage: string = "";
		ExternalAnalyticsForScreenCapture: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserGroupViewModel {
		GroupId: number = null;
		GroupDescription: string = "";
		Description: string = "";
		Status: string = "";
		GroupOwner: boolean = false;
		CanImpersonateGroup: boolean = false;
		ContactGroupMembershipId: number = null;
		Reasons: m5sec.AuthenticatedUserAssignmentReason[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserAssignmentReason {
		Reason: m5sec.AuthenticatedUserReasonHasAssignment = null;
		ReasonDescription: string = "";
		ReasonContactId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserRoleViewModel {
		RoleId: number = null;
		Description: string = "";
		RoleRatio: number = 0;
		Reasons: m5sec.AuthenticatedUserAssignmentReason[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserPermissionViewModel {
		AreaType: string = "";
		Area: string = "";
		Rights: string = "";
		Roles: m5sec.AuthenticatedUserRoleViewModel[] = [];
		ContributingRoles: string = "";
		ImplicitAccess: boolean = false;
		Comments: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LoginRecoveryRequestViewModel {
		Email: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LoginChangeRequest {
		ContactId: number = null;
		CurrentLogin: string = "";
		NewLogin: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PasswordChangeRequest {
		Login: string = "";
		CurrentPassword: string = "";
		NewPassword: string = "";
		NewPasswordConfirmed: string = "";
		ClientInformation: string = "";
		ForcedReset: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class PasswordViewRequest {
		Login: string = "";
		ClientInformation: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PermissionEditViewModel {
		SecurityArea: string = "";
		ReadSingle: boolean = false;
		Read: boolean = false;
		Add: boolean = false;
		Edit: boolean = false;
		Delete: boolean = false;
		Output: boolean = false;
		Execute: boolean = false;
		Full: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleDetailEditViewModel {
		RoleDetailId: number = null;
		RoleId: number = null;
		SecurityAreaType: string = "";
		SecurityArea: string = "";
		AllowReadSingle: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowRead: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowAdd: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowEdit: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowDelete: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowOutput: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowExecute: m5sec.RoleDetailPermissionEditViewModel = null;
		AllowFull: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyReadSingle: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyRead: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyAdd: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyEdit: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyDelete: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyOutput: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyExecute: m5sec.RoleDetailPermissionEditViewModel = null;
		DenyFull: m5sec.RoleDetailPermissionEditViewModel = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleDetailPermissionEditViewModel {
		Enabled: boolean = false;
		ScopeNone: boolean = false;
		ScopeOwner: boolean = false;
		ScopeSupervisor: boolean = false;
		ScopeNexus: boolean = false;
		ScopeGroup: boolean = false;
		ScopeChildGroup: boolean = false;
		ScopeGroupMembers: boolean = false;
		ScopeChildGroupMembers: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleListViewModel {
		RoleId: number = null;
		ExternalRoleId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		Anonymous: boolean = false;
		ValidForCustomer: boolean = false;
		ValidForAgent: boolean = false;
		ValidForVendor: boolean = false;
		ValidForDirectory: boolean = false;
		ValidForGroup: boolean = false;
		ValidForServiceProvider: boolean = false;
		AutomaticForCustomer: boolean = false;
		AutomaticForAgent: boolean = false;
		AutomaticForVendor: boolean = false;
		AutomaticForDirectory: boolean = false;
		AutomaticForGroup: boolean = false;
		AutomaticForServiceProvider: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleEditViewModel {
		RoleId: number = null;
		ExternalRoleId: string = null;
		Description: string = "";
		Enabled: boolean = false;
		Anonymous: boolean = false;
		ValidForCustomer: boolean = false;
		ValidForAgent: boolean = false;
		ValidForVendor: boolean = false;
		ValidForDirectory: boolean = false;
		ValidForGroup: boolean = false;
		ValidForServiceProvider: boolean = false;
		AutomaticForCustomer: boolean = false;
		AutomaticForAgent: boolean = false;
		AutomaticForVendor: boolean = false;
		AutomaticForDirectory: boolean = false;
		AutomaticForGroup: boolean = false;
		AutomaticForServiceProvider: boolean = false;
		Comments: string = "";
		Details: m5sec.RoleDetailEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

