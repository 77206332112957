<ngx-avatar name="{{nameForAvatar}}"
            gravatarId="{{emailForAvatar}}"
            [size]="size"
            [round]="true"
            ngbTooltip="{{tooltipText}}"
            container="body"
            [disableTooltip]="!tooltip"
            [placement]="tooltipPlacement"
            class="{{customClass}}"
            (clickOnAvatar)="fireClick($event)">
</ngx-avatar>
