import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'app-rate-limits',
  templateUrl: './rate-limits.component.html',
  styleUrls: ['./rate-limits.component.scss']
})
export class RateLimitsComponent implements OnInit {

  constructor(protected appService: AppService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "Rate Limits";
  }

}
