import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AppCacheService } from './app-cache.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { Helper, Log } from '../helpers/helper';
import { Subject, BehaviorSubject, Observable, of, AsyncSubject } from 'rxjs';
import { ApiProperties, ApiCall, Query, ApiOperationType, IApiResponseWrapperTyped } from '../api/ApiModels';
import { Api } from '../api/Api';
import { ApiHelper } from '../api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { BaseService } from './base.service';
import { TableOptions } from '../../../../common-lib/src/lib/table/table-options';
import { TableHelper } from '../../../../common-lib/src/lib/table/table-helper';
import { TableColumnOptions } from '../../../../common-lib/src/lib/table/table-column-options';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { CanDoWhat, CheckResult } from '../models/security';

@Injectable({
  providedIn: 'root'
})
export class SecurityService extends BaseService {

  public securityAreaTablePickList: m5core.PickListSelectionViewModel[] = [];
  public securityAreaTableGroupPickList: m5core.PickListSelectionViewModel[] = [];
  public securityAreaReportPickList: m5core.PickListSelectionViewModel[] = [];
  public securityAreaReportGroupPickList: m5core.PickListSelectionViewModel[] = [];
  public securityAreaPermissionAreaPickList: m5core.PickListSelectionViewModel[] = [];

  /**
   * Trimmed down from all security area types for scenarios where we only want the most common
   * for things like permissions on menus or forms where reports and sensitive information
   * types are never applicable.
   */
  public securityAreaTypeCommonPickList: m5core.PickListSelectionViewModel[] = [
    { Value: "TB", DisplayText: "Table", DisplayOrder: 1 } as m5core.PickListSelectionViewModel,
    //{ Value: "TG", DisplayText: "Table Group", DisplayOrder: 2 } as m5core.PickListSelectionViewModel,
    { Value: "PA", DisplayText: "Permission Area", DisplayOrder: 3 } as m5core.PickListSelectionViewModel,
  ];

  constructor(protected apiService: ApiService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer) {

    super();

    try {
      this.refreshPickLists();
    } catch (err) {
      Log.errorMessage("Exception refreshing pick lists from service constructor");
      Log.errorMessage(err);
    }

  }


  refreshPickLists(reportErrors: boolean = true) {

    this.apiService.loadPickList(Constants.PickList.__RoleDetail_SecurityAreaTable).subscribe(result => {
      if (result.Data.Success) {
        this.securityAreaTablePickList = result.Data.Data || [];
      }
    });
    this.apiService.loadPickList(Constants.PickList.__RoleDetail_SecurityAreaTableGroup).subscribe(result => {
      if (result.Data.Success) {
        this.securityAreaTableGroupPickList = result.Data.Data || [];
      }
    });
    this.apiService.loadPickList(Constants.PickList.__RoleDetail_SecurityAreaReport).subscribe(result => {
      if (result.Data.Success) {
        this.securityAreaReportPickList = result.Data.Data || [];
      }
    });
    this.apiService.loadPickList(Constants.PickList.__RoleDetail_SecurityAreaReportGroup).subscribe(result => {
      if (result.Data.Success) {
        this.securityAreaReportGroupPickList = result.Data.Data || [];
      }
    });
    this.apiService.loadPickList(Constants.PickList.__RoleDetail_SecurityAreaPermissionArea).subscribe(result => {
      if (result.Data.Success) {
        this.securityAreaPermissionAreaPickList = result.Data.Data || [];
      }
    });

  }

  getSecurityAreaDisplayText(securityAreaType: string, securityArea: string): string {
    let area = securityArea;
    if (Helper.equals(securityAreaType, "PA", true) && this.securityAreaPermissionAreaPickList?.length > 0) {
      area = Helper.pickListDisplayText(securityArea, this.securityAreaPermissionAreaPickList);
    } else if (Helper.equals(securityAreaType, "RP", true) && this.securityAreaReportPickList?.length > 0) {
      area = Helper.pickListDisplayText(securityArea, this.securityAreaReportPickList);
    } else if (Helper.equals(securityAreaType, "RG", true) && this.securityAreaReportGroupPickList?.length > 0) {
      area = Helper.pickListDisplayText(securityArea, this.securityAreaReportGroupPickList);
    } else if (Helper.equals(securityAreaType, "TG", true) && this.securityAreaTableGroupPickList?.length > 0) {
      area = Helper.pickListDisplayText(securityArea, this.securityAreaTableGroupPickList);
    } else if (Helper.equals(securityAreaType, "SI", true)) {
      area = securityArea;
    } else if (this.securityAreaTablePickList?.length > 0) {
      area = Helper.pickListDisplayText(securityArea, this.securityAreaTablePickList);
    }
    return area;
  }


  getSecurityRightsIconsFromRightsArray(securityAreaType: string, rights: string[]): string {
    if (!rights) {
      return "";
    }
    return this.getSecurityRightsIconsFromFlags(securityAreaType,
      rights.some(x => Helper.startsWith(x, "S", true)),
      rights.some(x => Helper.startsWith(x, "R", true)),
      rights.some(x => Helper.startsWith(x, "A", true)),
      rights.some(x => Helper.startsWith(x, "E", true)),
      rights.some(x => Helper.startsWith(x, "D", true)),
      rights.some(x => Helper.startsWith(x, "O", true)),
      rights.some(x => Helper.startsWith(x, "X", true)),
      rights.some(x => Helper.startsWith(x, "F", true)));
  }


  getSecurityRightsIconsFromFlags(securityAreaType: string, readSingle: boolean, read: boolean, add: boolean, edit: boolean, del: boolean, output: boolean, execute: boolean, full: boolean): string {
    let html: string = "";
    if (securityAreaType === "SI") {
      if (readSingle) {
        // Upon request
        html += IconHelper.iconDataFromIconDescription("question", false, true, "Unmask sensitive information upon request", "mr-2").html;
      } else if (read) {
        // Always
        html += IconHelper.iconDataFromIconDescription("check", false, true, "Always unmask sensitive information", "mr-2").html;
      } else {
        // Never
        html += IconHelper.iconDataFromIconDescription("ban", false, true, "Never unmask sensitive information", "mr-2").html;
      }
      return html;
    }
    if (readSingle) {
      html += IconHelper.iconTextOverSearch("1", "search (light)", "Read Single", "mr-1").html;
    }
    if (read) {
      html += IconHelper.iconDataFromIconDescription("search", false, true, "Read", "mr-2").html;
    }
    if (add) {
      html += IconHelper.iconDataFromIconDescription("plus", false, true, "Add", "mr-2").html;
    }
    if (edit) {
      html += IconHelper.iconDataFromIconDescription("pencil", false, true, "Edit", "mr-2").html;
    }
    if (del) {
      html += IconHelper.iconDataFromIconDescription("times", false, true, "Delete", "mr-2").html;
    }
    if (output) {
      html += IconHelper.iconDataFromIconDescription("print", false, true, "Output", "mr-2").html;
    }
    if (execute) {
      html += IconHelper.iconDataFromIconDescription("share-square", false, true, "Execute", "mr-2").html;
    }
    if (full) {
      html += IconHelper.iconDataFromIconDescription("arrows", false, true, "Full", "mr-2").html;
    }
    return html;
  }


  getSecurityRightsTooltipFromRightsArray(securityAreaType: string, rights: string[]): string {
    if (!rights) {
      return "";
    }
    return this.getSecurityRightsTooltipFromFlags(securityAreaType,
      rights.some(x => Helper.startsWith(x, "S", true)),
      rights.some(x => Helper.startsWith(x, "R", true)),
      rights.some(x => Helper.startsWith(x, "A", true)),
      rights.some(x => Helper.startsWith(x, "E", true)),
      rights.some(x => Helper.startsWith(x, "D", true)),
      rights.some(x => Helper.startsWith(x, "O", true)),
      rights.some(x => Helper.startsWith(x, "X", true)),
      rights.some(x => Helper.startsWith(x, "F", true)));
  }


  getSecurityRightsTooltipFromFlags(securityAreaType: string, readSingle: boolean, read: boolean, add: boolean, edit: boolean, del: boolean, output: boolean, execute: boolean, full: boolean): string {
    let tooltip: string = "";
    if (securityAreaType === "SI") {
      if (readSingle) {
        // Upon request
        tooltip += (tooltip ? ", " : "") + "Unmask sensitive information upon request";
      } else if (read) {
        // Always
        tooltip += (tooltip ? ", " : "") + "Always unmask sensitive information";
      } else {
        // Never
        tooltip += (tooltip ? ", " : "") + "Never unmask sensitive information";
      }
      return tooltip;
    }
    if (readSingle) {
      tooltip += (tooltip ? ", " : "") + "Read Single";
    }
    if (read) {
      tooltip += (tooltip ? ", " : "") + "Read";
    }
    if (add) {
      tooltip += (tooltip ? ", " : "") + "Add";
    }
    if (edit) {
      tooltip += (tooltip ? ", " : "") + "Edit";
    }
    if (del) {
      tooltip += (tooltip ? ", " : "") + "Delete";
    }
    if (output) {
      tooltip += (tooltip ? ", " : "") + "Output";
    }
    if (execute) {
      tooltip += (tooltip ? ", " : "") + "Execute";
    }
    if (full) {
      tooltip += (tooltip ? ", " : "") + "Full";
    }
    return tooltip;
  }



  getDefaultRoleDetail(): m5sec.RoleDetailEditViewModel {
    const model = new m5sec.RoleDetailEditViewModel();
    model.SecurityAreaType = "TB";
    model.AllowReadSingle = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowRead = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowAdd = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowEdit = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowDelete = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowOutput = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowExecute = new m5sec.RoleDetailPermissionEditViewModel();
    model.AllowFull = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyReadSingle = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyRead = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyAdd = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyEdit = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyDelete = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyOutput = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyExecute = new m5sec.RoleDetailPermissionEditViewModel();
    model.DenyFull = new m5sec.RoleDetailPermissionEditViewModel();
    return model;
  }



  public checkModules(modules: m.Modules, scenario: string,
    appInfo: m5core.ApplicationInformationModel,
    logDebugCategory: string = "orange", logDebugTitle: string = "Check Modules"): CheckResult {

    const result: CheckResult = new CheckResult();
    result.subjectType = "modules";
    result.subjectScenario = scenario;
    result.subject = modules;

    if (!modules) {
      result.passed = true;
      result.message = "No modules requested.";
      result.trace.push("Modules object was null.");
      return result;
    }
    if (!modules.ModuleList || modules.ModuleList.length === 0) {
      result.passed = true;
      result.message = "No modules requested.";
      result.trace.push("Modules list was empty.");
      return result;
    }

    // Start with assuming we pass until we fail
    result.passed = true;
    modules.ModuleList.forEach(module => {
      if (module.Required) {
        if (!Helper.firstOrDefault(appInfo.Modules, x => Helper.equals(x, module.LicensedModule, true))) {
          result.message = `${scenario} module check failed because of missing module '${module.LicensedModule}'.  Available modules include: ${Helper.buildCsvString(appInfo.Modules)}.`;
          result.passed = false;
          return; // exit forEach
        }
      }
    });

    if (logDebugCategory) {
      result.trace.forEach(trace => Log.debug(logDebugCategory, logDebugTitle, trace));
      Log.debug(logDebugCategory, logDebugTitle, result.message);
    }

    return result;

  }



  public checkPermissions(permissions: m.Permissions, scenario: string,
    user: m5sec.AuthenticatedUserViewModel,
    logDebugCategory: string = "orange", logDebugTitle: string = "Check Permissions"): CheckResult {

    const result: CheckResult = new CheckResult();
    result.subjectType = "permissions";
    result.subjectScenario = scenario;
    result.subject = permissions;

    if (!permissions) {
      result.passed = true;
      result.message = "No permissions requested.";
      result.trace.push("Permissions object was null.");
      return result;
    }
    if (!user) {
      result.passed = true;
      result.message = "No user available for permission check.";
      result.trace.push("User object was null.");
      return result;
    }
    if (!user.Roles) {
      // null ref protection
      user.Roles = [];
    }
    if (!user.Permissions) {
      // null ref protection
      user.Permissions = [];
    }

    // Assume passed until we find out we didn't pass
    result.passed = true;

    // User must have all these roles
    if (result.passed && permissions.RequiredRoleIdsAll && permissions.RequiredRoleIdsAll.length > 0) {
      permissions.RequiredRoleIdsAll.forEach(role => {
        if (!user.Roles.some(x => x.RoleId === role)) {
          result.passed = false;
          result.message = `${scenario} rejected because one or more of these roles is missing '${Helper.buildCsvString(permissions.RequiredRoleIdsAll)}' (all are required).`;
          return; // exit forEach
        }
      });
    }

    // User must have one of these roles
    if (result.passed && permissions.RequiredRoleIdsAny && permissions.RequiredRoleIdsAny.length > 0) {
      let hasRole: boolean = false;
      permissions.RequiredRoleIdsAny.forEach(role => {
        if (user.Roles.some(x => x.RoleId === role)) {
          hasRole = true;
          return; // exit forEach
        }
      });
      if (!hasRole) {
        result.passed = false;
        result.message = `${scenario} rejected because all of these roles are missing '${Helper.buildCsvString(permissions.RequiredRoleIdsAny)}' (at least one is required).`;
      }
    }

    // Check permissions
    if (result.passed && permissions.PermissionList && permissions.PermissionList.length > 0) {
      let hasAnyPermission: boolean = false; // Opt into any
      let hasAllPermission: boolean = true;  // Opt out of all
      permissions.PermissionList.forEach(permissionArea => {
        if (permissionArea.Rights && permissionArea.Rights.length > 0) {
          let hasAnyRight: boolean = false; // Opt into any
          let hasAllRight: boolean = true;  // Opt out of all
          permissionArea.Rights.forEach(right => {
            if (this.hasPermission(user, permissionArea.PermissionArea, right)) {
              result.trace.push(`${scenario}: User has permission area ${permissionArea.PermissionArea} right ${right}.`);
              hasAnyRight = true;
            } else {
              result.trace.push(`${scenario}: User is missing permission area ${permissionArea.PermissionArea} right ${right}.`);
              hasAllRight = false;
            }
          });
          if (!hasAnyRight && permissionArea.Required && !permissions.AllowOnAnyRequired) {
            // We are missing a right, the rights are required, and the permissions is not flagged to allow on any required so we're done
            result.passed = false;
            result.message = `${scenario} rejected because permission area ${permissionArea.PermissionArea} with rights '${Helper.buildCsvString(permissionArea.Rights)}' was not found, is marked as required, and we must have all required permissions.`;
          }
          if ((hasAllRight && permissionArea.AllRightsRequired) || (hasAnyRight && !permissionArea.AllRightsRequired)) {
            // If we have all rights and all are required or if we have any and all are not required then we have have any permission
            result.trace.push(`${scenario}: Permission area ${permissionArea.PermissionArea} with rights '${Helper.buildCsvString(permissionArea.Rights)}' has required rights.`);
            hasAnyPermission = true;
          } else {
            // If we don't have the rights required then we don't have all permissions
            result.trace.push(`${scenario}: Permission area ${permissionArea.PermissionArea} with rights '${Helper.buildCsvString(permissionArea.Rights)}' is missing one or more required rights.`);
            hasAllPermission = false;
          }
        }
      });
      if (permissions.AllowOnAnyRequired && !hasAnyPermission) {
        result.passed = false;
        result.message = `${scenario} rejected because all of these permission areas '${JSON.stringify(permissions.PermissionList)}' are missing rights (at least one is required).`;
      } else if (!permissions.AllowOnAnyRequired && !hasAllPermission) {
        result.passed = false;
        result.message = `${scenario} rejected because one or more of these permission areas '${JSON.stringify(permissions.PermissionList)}' are missing rights (all are required).`;
      }
    }

    if (logDebugCategory) {
      result.trace.forEach(trace => Log.debug(logDebugCategory, logDebugTitle, trace));
      Log.debug(logDebugCategory, logDebugTitle, result.message);
    }

    return result;

  }




  public parsePermissions(user: m5sec.AuthenticatedUserViewModel, accessArea: string): CanDoWhat {

    // Can't do anything without a user object... keep canDoWhat undefined because maybe we'll have a currentUser assigned later and we don't want to assume we've parsed permissions
    if (!user) {
      return;
    }
    // Can't do anything without an access area... keep canDoWhat undefined because maybe we'll have an accessArea assigned later and we don't want to assume it's been parsed
    if (!accessArea) {
      return null;
    }

    const can: CanDoWhat = {
      readSingle: false,
      read: false,
      add: false,
      edit: false,
      delete: false,
      output: false,
      execute: false,
      full: false
    };

    can.readSingle = this.hasPermission(user, accessArea, Constants.Permission.ReadSingle);
    can.read = this.hasPermission(user, accessArea, Constants.Permission.Read);
    can.add = this.hasPermission(user, accessArea, Constants.Permission.Add);
    can.edit = this.hasPermission(user, accessArea, Constants.Permission.Edit);
    can.delete = this.hasPermission(user, accessArea, Constants.Permission.Delete);
    can.output = this.hasPermission(user, accessArea, Constants.Permission.Output);
    can.execute = this.hasPermission(user, accessArea, Constants.Permission.Execute);
    can.full = this.hasPermission(user, accessArea, Constants.Permission.Full);

    return can;

  }


  public hasSysAdminPermission(user: m5sec.AuthenticatedUserViewModel): boolean {
    return this.hasPermission(user, Constants.AccessArea.Everything, Constants.Permission.Full);
  }


  /**
   * Allows for simple checking of an area with the permission desired.
   * @param accessArea The area for which the user desires to check access.
   * @param permission The permission to check for access.
   * @returns {} True if the user is to be permitted access, false if not.
   */
  public hasPermission(user: m5sec.AuthenticatedUserViewModel, accessArea: string, permission: string): boolean {

    // No user object means no permission
    if (!user) {
      return false;
    }

    // Find our access area
    const area: m5sec.AuthenticatedUserPermissionViewModel = Helper.firstOrDefault(user.Permissions, x => Helper.equals(x.Area, accessArea, true), null);
    const everything: m5sec.AuthenticatedUserPermissionViewModel = Helper.firstOrDefault(user.Permissions, x => Helper.equals(x.Area, Constants.AccessArea.Everything, true), null);

    // Read-single permission (S) is a special case and needs special handling.  This is
    // treated as a subset to read permission - if the user has read permission then read
    // single is implicitly permitted.  The opposite is not true.  If the user has read
    // single but not read permission they are only allowed access to a single row - typically
    // scoped - and single row response in enforced in the api and not here.
    let hasReadPermission: boolean = false;
    if (permission === Constants.Permission.ReadSingle) {
      // We only care about this when checking permission "S" and since this is
      // a recursive function call we need to avoid a stack overflow.
      hasReadPermission = this.hasPermission(user, accessArea, Constants.Permission.Read);
    }

    // Step 1: Check to see if this specific access area has been denied
    if (area && area.Rights.indexOf("!" + permission) > -1) {
      if (permission === Constants.Permission.ReadSingle && hasReadPermission) {
        return true;
      }
      return false;
    }

    // Step 2: Check to see if the special "everything" access area has been denied the rights we need
    if (everything && everything.Rights.indexOf("!" + permission) > -1) {
      if (permission === Constants.Permission.ReadSingle && hasReadPermission) {
        return true;
      }
      return false;
    }

    // Step 3: Check to see if this specific access area has the permission we need
    if (area && area.Rights.indexOf(permission) > -1) {
      return true;
    }

    // Step 4: Last ditch effort, check to see if the special "everything" access area has the rights we need
    if (everything && everything.Rights.indexOf(permission) > -1) {
      return true;
    }

    // Finally the answer has to be no (except for read-single to read escalation)
    if (permission === Constants.Permission.ReadSingle && hasReadPermission) {
      return true;
    }
    return false;

  }


  /**
   * Allows checking of an area to see if the permission is expressly denied.  This is not exactly the
   * opposite of HasPermission() since not being denied access does not necessarily mean access has been granted.
   * @param accessArea The area for which the user desires to check access.
   * @param permission The permission to check for denied access.
   * @returns {} True if the user has been expressly denied access, false if not.  That does not,
   * however, mean access should be granted.  Use HasPermission to determine if access should be granted.
   */
  public deniedPermission(user: m5sec.AuthenticatedUserViewModel, accessArea: string, permission: string): boolean {

    // Find our access area
    const area: m5sec.AuthenticatedUserPermissionViewModel = Helper.firstOrDefault(user.Permissions, x => Helper.equals(x.Area, accessArea, true), null);
    const everything: m5sec.AuthenticatedUserPermissionViewModel = Helper.firstOrDefault(user.Permissions, x => Helper.equals(x.Area, Constants.AccessArea.Everything, true), null);

    // Read-single permission (S) is a special case and needs special handling.  This is
    // treated as a subset to read permission - if the user has read permission then read
    // single is implicitly permitted.  The opposite is not true.  If the user has read
    // single but not read permission they are only allowed access to a single row - typically
    // scoped - and single row response in enforced in the api and not here.
    let deniedReadPermission: boolean = false;
    if (permission === "S") {
      // We only care about this when checking permission "S" and since this is
      // a recursive function call we need to avoid a stack overflow.
      deniedReadPermission = this.deniedPermission(user, accessArea, "R");
    }

    // Step 1: Check to see if this specific access area has been denied
    if (area && area.Rights.indexOf("!" + permission) > -1) {
      return true;
    }

    // Step 2: Check to see if the special "everything" access area has been denied the rights we need
    if (everything && everything.Rights.indexOf("!" + permission) > -1) {
      return true;
    }

    // Finally if we have not been expressly denied then return false (except for read-single to read escalation)
    if (permission === "S" && deniedReadPermission) {
      return true;
    }
    return false;

  }



}
