import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'csv'
})
export class CsvPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return "";
    }

    //When testing this method, it was observed that if the array holds a string that has a comma in the string
    //itself, it will have a space added after it. It's unknown to the tester if this situation would arise
    //with actual data, so a note is being left here. For example: value = ["comma,inside", "separated", "value"];
    //becomes: "comma, inside, separated, value"
    let csv: string = JSON.stringify(value);
    csv = Helper.replaceAll(csv, "[", "");
    csv = Helper.replaceAll(csv, "]", "");
    csv = Helper.replaceAll(csv, '"', "");
    csv = Helper.replaceAll(csv, ",", ", ");
    return csv;
  }

}
