import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ib-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent implements OnInit {

  @Input() max: number = 5;
  @Input() rating: number = 0;
  @Input() votes: number = undefined;
  @Input() includeText: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
