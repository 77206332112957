<div class="form-group {{inputFormGroupClass}}">
  <label for="{{inputControlId}}" class="{{inputLabelClass}}">
    <a *ngIf="mapProvider === 'google' && value.City"
       href="https://maps.google.com?q={{value.Address1 | replaceText : ' ' : '+'}},+{{value.City | replaceText : ' ' : '+'}},+{{value.State | replaceText : ' ' : '+'}}+{{value.PostalCode | replaceText : ' ' : '+'}}&t=m"
       target="_blank" tooltip="Map"
       class="btn btn-outline-secondary">
      <i class="far fa-map-marker-alt"></i>
    </a>
    <span *ngIf="label.trim()" class="ml-2"></span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}}">
    <div [ngClass]="{'card': border }">
      <div class="{{controlClass}}" [ngClass]="{'card-body': border , 'p-2': border }">
        <span [hidden]="!value.FirstName && !value.LastName">{{value.FirstName}} {{value.LastName}}<br /></span>
        <span [hidden]="!value.ContactName || (value.ContactName === (value.FirstName + ' ' + value.LastName))">{{value.ContactName}}<br /></span>
        <span [hidden]="!value.Address1">{{value.Address1}}<br /></span>
        <span [hidden]="!value.Address2">{{value.Address2}}<br /></span>
        <span [hidden]="!value.Address3">{{value.Address3}}<br /></span>
        <span [hidden]="!value.City && !value.State">{{value.City}}, {{value.State}} {{value.PostalCode}}<br /></span>
        <span [hidden]="includePhone && !value.Phone" [innerHtml]="value.Phone | telephoneLink"></span>
        <span [hidden]="includePhone && !value.Phone"><br /></span>
        <span [hidden]="includeEmail && !value.Email" [innerHtml]="value.Email | emailLink"></span>
      </div>
    </div>
  </div>
</div>
