import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { ResultCodePurchaseComponent } from './result-code-purchase/result-code-purchase.component';
import { ResultCodeVoucherComponent } from './result-code-voucher/result-code-voucher.component';
import { CustomContentComponent } from './custom-content/custom-content.component';
import { InstructionsModule } from '../instructions/instructions.module';
import { PurchaseOverviewComponent } from './purchase-overview/purchase-overview.component';
import { PurchaseExamplesComponent } from './purchase-examples/purchase-examples.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    CoreLibModule,
    CommonLibModule,
    InstructionsModule
  ],
  declarations: [
    CustomContentComponent,
    ResultCodePurchaseComponent,
    ResultCodeVoucherComponent,
    PurchaseOverviewComponent,
    PurchaseExamplesComponent
  ],
  exports: [
    CustomContentComponent,
  ]
})
export class CustomModule { }
