<div>

  <h3>Request Formats</h3>

  <br />

  <p>
    Since the API is based on REST principles, it's very easy to write and test applications.
    You can use pretty much any HTTP client in any programming language to interact with the API.
  </p>

  <p>
  </p>

  <br />
  <h4>API Hosts</h4>

  <p>
    All URLs referenced in the documentation and callable by your HTTP client will have the one
    of the following base URLs depending on the desired API Host:
  </p>

  <table class="table table-striped table-bordered table-sm table-hover">
    <thead>
      <tr>
        <th>API Host Description</th>
        <th>Code</th>
        <th>API Host URL</th>
        <th>Type</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let host of apiService.apiHosts">
        <td>{{host.description}}</td>
        <td>{{host.code}}</td>
        <td><a href="{{host.url}}" target="_blank">{{host.url}}</a></td>
        <td>
          <span *ngIf="host.isSandbox" class="badge badge-success">Sandbox</span>
          <span *ngIf="host.isProduction" class="badge badge-danger">Production</span>
        </td>
        <td>{{host.comments}}</td>
      </tr>
    </tbody>
  </table>



  <br />
  <h4>Request Headers</h4>

  <p>
    Since the API is stateless each API call requires authentication information to be included in the form of either an Authorization header or X-Auth-Key header.
    All other headers are optional but may be helpful in various scenarios:
  </p>

  <table class="table table-striped table-bordered table-sm table-hover">
    <thead>
      <tr>
        <th width="10%">Header</th>
        <th width="30%">Value</th>
        <th width="60%">Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Accept</td>
        <td>
          application/vnd.ib.api-v?+json
        </td>
        <td>
          Where ? is the version number.  See <a [routerLink]="['/', 'overview', 'versions']">versions</a> for specific versions supported.
        </td>
      </tr>
      <tr>
        <td>Content-Type</td>
        <td>
          application/vnd.ib.api-v?+json
        </td>
        <td>Used when posting data.  Where ? is the version number.  See <a [routerLink]="['/', 'overview', 'versions']">versions</a> for specific versions supported.</td>
      </tr>
      <tr>
        <td>X-Api-Version</td>
        <td>
          API Version Number (e.g. 1)
        </td>
        <td>The API version number to use.  See <a [routerLink]="['/', 'overview', 'versions']">versions</a> for specific versions supported.</td>
      </tr>
      <tr>
        <td>Authorization</td>
        <td>Bearer <em>&lt;token&gt;</em></td>
        <td>
          Token provided by the <a [routerLink]="['/', 'security', 'login']">login</a> API endpoint or included in response to another API endpoint request as either a
          refreshed token or token to use as replacement for a different authentication mechanism like X-Auth-External-Token.
          <br />
          Note that either Authorization, X-Auth-Key, or X-Auth-External-Token header is required as part of each request except for the
          <a [routerLink]="['/', 'security', 'login']">login</a> API request which is used to retrieve the authorization token.
        </td>
      </tr>
      <tr>
        <td>X-Auth-Key</td>
        <td>Your API Key</td>
        <td>
          The API Key provided for API access.
          <br />
          Either Authorization, X-Auth-Key, or X-Auth-External-Token header is required as part of each request except for the <a [routerLink]="['/', 'security', 'login']">login</a> API
          request which is used to retrieve the authorization token.
        </td>
      </tr>
      <tr>
        <td>X-Auth-External-Token</td>
        <td><em>&lt;external token&gt;</em></td>
        <td>
          An external authorization token provided by a partner system to be used for external authorization of the user.
          <br />
          Either Authorization, X-Auth-Key, or X-Auth-External-Token header is required as part of each request except for the <a [routerLink]="['/', 'security', 'login']">login</a> API
          request which is used to retrieve the authorization token.
        </td>
      </tr>
      <tr>
        <td>X-Language</td>
        <td>Language</td>
        <td>
          This is the language to use when the response includes values that can be translated.
          If not specified, the user configured language preference will be used.
        </td>
      </tr>
      <tr>
        <td>X-Meta-Data</td>
        <td>
          A CSV list of meta data to include in the response data's meta object.
        </td>
        <td>
          Possible values in the csv include:
          <ul>
            <li><strong>Options</strong> - If the result data object has properties that have a list of accepted values then the accepted values are documented in the meta data.</li>
            <li><strong>Attributes</strong> - If the result data object has attributes defined then the attribute properties are documented in the meta data.</li>
            <li><strong>Timestamps</strong> - If the api endpoint supports reporting of timestamps then the timestamps for various steps are documented in the meta data.</li>
            <li><strong>Durations</strong> - If the api endpoint supports reporting of durations then the durations (in milliseconds) for various steps are documented in the meta data.</li>
            <li><strong>All</strong> - All supported meta data is included.</li>
            <li><strong>True</strong> - Legacy value that is the equivalent of specifying All.</li>
            <li><strong>False</strong> - Legacy value that is the equivalent of not specifying anything.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>X-Trace</td>
        <td>TRUE or FALSE</td>
        <td>
          When true the response will include additional trace information that can be used for debug purposes.  The trace information is not
          documented and is largely only helpful when needing to communicate an issue to technical support.
        </td>
      </tr>
      <tr>
        <td>X-Overwrite-Changes</td>
        <td>TRUE or FALSE</td>
        <td>
          Some API PUT methods may get a failed response that someone else already edited object and, therefore, the PUT was rejected because it would
          have resulted in another user's edits being lost. The best action to take is for the client to GET the updated object and then merge in the
          current user's edits and to continue. However, if that is not possible or if for other reasons you want to overwrite the other user's edits
          then this header can be set to true and the PUT method retried. Note that <strong>this will result in data loss</strong> and is rarely
          recommended as the best course of action.
        </td>
      </tr>
      <tr>
        <td>X-HTTP-Response-Override</td>
        <td>TRUE or FALSE</td>
        <td>
          Can be used if HTTP library being utilized only accepts 200 OK response for processing.  Some clients intercept any non-OK
          response which prevents normal processing of non-OK responses from the API.  Setting this header to TRUE will result in all
          API responses having a 200 OK response from the server.  The client should then inspect the result code to determine if the API
          call was successful or not.
        </td>
      </tr>
      <tr>
        <td>X-HTTP-Method-Override</td>
        <td>PUT, DELETE, PATCH, or MERGE</td>
        <td>
          Can be used if HTTP library being utilized only supports GET and POST or does not support custom verbs (e.g. MERGE).
          If that is the case use a POST where PUT, DELETE, PATCH, or MERGE (custom verb) are called for but include this
          header to indicate if a PUT, DELETE, PATCH or MERGE was requested.
        </td>
      </tr>
      <!--<tr>
        <td>X-Local-Ip-Address</td>
        <td>Device Local IP Address</td>
        <td>
          This is one of the local (i.e. private) ip addresses assigned to your device (if any). For certain anonymous operations like
          leaving feedback knowing the private ip address of a device allows proper collection of anonymous feedback for many users
          who share the same public ip address as is typical in a multi-user environment. For example, all users in a home or office
          typically use a technology like <a href="https://en.wikipedia.org/wiki/Network_address_translation" target="_blank">NAT</a>
          which causes all users in that environment to share a single public ip address. As a result, all users sharing that public
          ip address are limited to sharing a single feedback. By knowing the local private ip address of a device the anonymous
          feedback mechanism can allow each combination of local private ip address and public ip address to have their own feedback.
          <br /><br />
          Note that <a href="https://en.wikipedia.org/wiki/Dynamic_Host_Configuration_Protocol" target="_blank">DHCP</a> technology is
          frequently used in issuing private ip addresses and this value runs the risk of changing on devices and, therefore, is less
          useful than the X-Local-Device-Id header and is typically only used when the X-Local-Device-Id header is not present.
        </td>
      </tr>-->
      <tr>
        <td>X-Local-Device-Id</td>
        <td>Device Id</td>
        <td>
          This is a short guid like string that uniquely identifies a user or device but does so in an anonymous way. The API does not
          know anything about the user or device represented by this id but can reasonably expect that the id represents the same user
          or device for purposes of collecting anonymous information like anonymously provided feedback.
          <br /><br />
          For certain anonymous operations like leaving feedback knowing a user or device id allows proper collection of anonymous
          feedback for many users who share the same public ip address as is typical in a multi-user environment. For example, all users
          in a home or office typically use a technology like <a href="https://en.wikipedia.org/wiki/Network_address_translation" target="_blank">NAT</a>
          which causes all users in that environment to share a single public ip address.  As a result, all users sharing that public
          ip address are limited to sharing a single feedback. By knowing a user or device id the anonymous feedback mechanism can allow
          each combination of private/device id and public ip address to have their own feedback.
        </td>
      </tr>
      <tr>
        <td>X-Response-Properties</td>
        <td>
          A CSV list of response properties to include in the response data in jpath format (e.g. Response.ResultText, AssociationId, Cargo.TransactionId).
        </td>
        <td>
          This is an optional (and typically omitted) comma separated list of properties that should be included in the response data property.  Note that:
          <ul style="margin-bottom:0;">
            <li>
              It has no impact on the non-data portions of the response object (e.g. errors, links, etc.).
            </li>
            <li>
              When omitted the standard set of response properties are included with the data.
            </li>
            <li>
              With rare exception this can only be used to identify properties from the existing standard response data and not to add
              additional properties not included in the standard response data.
            </li>
            <li>
              To rename a property separate the source and target names with a colon (e.g. Cargo.TransactionId:TransId).
            </li>
            <li>
              In bulk processing scenarios where multiple objects can be submitted each corresponding object included in the
              response will typically also be able to access the common response object properties by referencing them with a
              "Response." prefix in order to provide response values like success, result code, etc. for each object
              (e.g. Response.ResultCode).
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>X-Encryption-Key-Tag</td>
        <td>Encryption Key Tag (e.g. 7qw-1ej2kx4v7e5lb-4s8i49d5iq9l)</td>
        <td>
          This is the encryption key tag of the encryption key used to encrypt some of the properties of this API call.
          Use the <a [routerLink]="['/', 'encryption', 'get']">get encryption key api</a> to retrieve an encryption key and tag.
        </td>
      </tr>
      <tr>
        <td>X-Encrypted-Properties</td>
        <td>
          A CSV list of encrypted properties in jpath format (e.g. Payment.CreditCard.CreditCardNumber, Payment.CreditCard.CardId,
          Packages[*].Payment.CreditCard.CreditCardNumber, Packages[*].Payment.CreditCard.CardId)
        </td>
        <td>
          This is a comma separated list of properties that were encrypted using the encryption key represented by X-Encryption-Key-Tag.  Do not
          encrypt data without specifying those properties in this header and providing a X-Encryption-Key-Tag header.  Currently only string data
          type properties can be encrypted.  Note that most servers only allow 4K-8K of HTTP headers.  Since the API exclusively uses HTTPS there
          is no need to encrypt a large number of properties.  This should primarily be used for passing data that must be encrypted when at rest
          on local systems for compliance with industry security standards (e.g. PCI, HIPAA, etc.).
        </td>
      </tr>
    </tbody>
  </table>

  <br />

</div>
