<li class="{{timelineItemClass}}">
  <div *ngIf="icon" class="timeline-badge {{type}} {{timelineBadgeClass}}">
    <ib-icon icon="{{icon}}"></ib-icon>
  </div>
  <div class="timeline-panel">
    <div class="timeline-body">
      <div class="form-group" style="margin-bottom: 0; padding-left: 20px;">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</li>
