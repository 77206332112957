import * as m from "./ngCoreModels";
import * as m5pay from "./ngModelsPayment5";
import * as m5core from "./ngModelsCore5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class PaymentMethodCreditCardEditViewModel {
		PaymentMethodId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		Tokens: {[key: string]:  string} = null;
		Status: string = "";
		RetentionDays: number = 0;
		Description: string = "";
		CreditCardType: string = "";
		CreditCardTypeId: number = null;
		CreditCardNumberLastFourDigits: string = "";
		CreditCardNumber: string = "";
		ExpirationDate: Date = null;
		CardId: string = null;
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		BillingName: string = "";
		BillingCompanyName: string = "";
		BillingAddress1: string = "";
		BillingAddress2: string = "";
		BillingAddress3: string = "";
		BillingCity: string = "";
		BillingState: string = "";
		BillingPostalCode: string = "";
		BillingCountry: string = "";
		BillingPhone: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionEditViewModel {
		PaymentTransactionId: string = null;
		RequestType: string = "";
		RequestStatus: string = "";
		ReportedStatus: string = "";
		RequestResultCode: number = 0;
		RequestByContactId: number = null;
		RequestByContactName: string = "";
		RequestDateTime: Date = null;
		QueueDateTime: Date = null;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		OrderReferences: string = "";
		PaymentProviderId: number = null;
		PaymentMethodId: number = null;
		PaymentMethodType: string = "";
		PaymentMethodCardTypeId: string = null;
		PaymentMethodTypeId: number = null;
		AccountNumberReferenceSubset: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		Tax: number = 0;
		Shipping: number = 0;
		Fee: number = 0;
		RefundedAmount: number = 0;
		RequestOrigin: string = "";
		Priority: number = 0;
		RequestAttempt: number = 0;
		RunningApplicationServer: string = "";
		RunningDateTime: Date = null;
		TaxInformation: {[key: string]:  string} = null;
		Flags: string = "";
		TransactionDateTime: Date = null;
		TransactionContactReference: string = "";
		TransactionOrderReference: string = "";
		TransactionBatch: string = "";
		TransactionAuthorizationCode: string = "";
		TransactionResultCode: string = "";
		TransactionResultSubCode: string = "";
		TransactionResultMessage: string = "";
		TransactionResultData: string = "";
		TransactionPaymentId: string = null;
		AddressVerificationResult: string = "";
		CardIdVerificationResult: string = "";
		PaymentBatchId: string = null;
		BillingTransactionId: number = null;
		Triggers: string = "";
		LinkedTransactionId: string = null;
		LinkedTransactionRequestDateTime: Date = null;
		Reason: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentMethodCardTypeEditViewModel {
		PaymentMethodCardTypeSurrogateId: number = null;
		PaymentMethodCardTypeId: string = null;
		Description: string = "";
		AccountNumberStartsWith: string = "";
		AccountNumberSelectionRule: string = "";
		AccountNumberLength: string = "";
		AccountNumberValidationType: string = "";
		AccountNumberValidationRule: string = "";
		RuleProcessingOrder: number = 0;
		IsoCurrencyCodeList: string[] = [];
		CountryCodeList: string[] = [];
		ThirdPartyVerificationType: string = "";
		MandatoryDisplayText: string = "";
		PaymentMethodTypeId: number = null;
		PaymentNetworkTypeId: number = null;
		Custom: boolean = false;
		Active: boolean = false;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentMethodExpiringViewModel {
		ContactId: number = null;
		ContactType: string = "";
		ContactColumn: string = "";
		FirstColumn: string = "";
		LastColumn: string = "";
		Phone: string = "";
		Email: string = "";
		BillingAccountId: number = null;
		BillingAccountNumber: string = "";
		BillingAccountColumn: string = "";
		PaymentMethodId: number = null;
		Type: string = "";
		Status: string = "";
		PaymentMethodCardTypeId: string = null;
		Description: string = "";
		AccountNumberReferenceSubset: string = "";
		ExpirationDate: Date = null;
		NextValidationDateTime: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentMethodListViewModel {
		PaymentMethodId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		Type: string = "";
		Status: string = "";
		LastUsedDateTime: Date = null;
		PaymentMethodCardTypeId: string = null;
		Description: string = "";
		AccountNumberReferenceSubset: string = "";
		ExpirationDate: Date = null;
		UtcOffset: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentMethodEditViewModel {
		PaymentMethodId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		BillingAccountId: number = null;
		Tokens: {[key: string]:  string} = null;
		Type: string = "";
		Status: string = "";
		NextValidationDateTime: Date = null;
		LastUsedDateTime: Date = null;
		TransactionCount: number = 0;
		PurchaseCount: number = 0;
		RetentionDays: number = 0;
		RemoveSensitiveInformation: boolean = false;
		SensitiveInformationRemoved: boolean = false;
		PaymentMethodCardTypeId: string = null;
		PaymentMethodTypeId: number = null;
		Description: string = "";
		AccountNumberReferenceSubset: string = "";
		ExpirationDate: Date = null;
		BankName: string = "";
		RoutingNumber: string = "";
		AccountNumber: string = "";
		Expiration: string = "";
		AccountId: string = null;
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		BillingName: string = "";
		BillingCompanyName: string = "";
		BillingAddress1: string = "";
		BillingAddress2: string = "";
		BillingAddress3: string = "";
		BillingCity: string = "";
		BillingState: string = "";
		BillingPostalCode: string = "";
		BillingCountry: string = "";
		BillingPhone: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentProviderEditViewModel {
		PaymentProviderId: number = null;
		ExternalPaymentProviderId: string = null;
		Description: string = "";
		PaymentEngineName: string = "";
		PaymentProviderName: string = "";
		PaymentProviderPlatform: string = "";
		TokenizationProvider: string = "";
		MerchantNumber: string = "";
		MerchantAccountNumber: string = "";
		MerchantAlternateNumber: string = "";
		MerchantName: string = "";
		VendorId: string = null;
		SoftwareId: string = null;
		TerminalId: string = null;
		ContactId: number = null;
		ContactName: string = "";
		Enabled: boolean = false;
		DefaultPaymentProvider: boolean = false;
		ValidForTestTransactions: boolean = false;
		ValidForAutomaticPayments: boolean = false;
		ValidForOnDemandPayments: boolean = false;
		IsoCurrencyCodeList: string[] = [];
		PaymentProviderFlags: string[] = [];
		PaymentProviderSettings: any = null;
		PaymentProcessingFlags: string[] = [];
		PaymentProcessingSettings: any = null;
		ContactInformationCapture: string = "";
		PaymentModel: string = "";
		VerificationModel: string = "";
		AddressVerificationRequired: boolean = false;
		AddressVerificationFailList: string[] = [];
		CardIdVerificationRequired: boolean = false;
		CardIdVerificationFailList: string[] = [];
		Preference: number = 0;
		Encoding: string = "";
		PaymentProcessingRegion: string = "";
		TimeZoneId: string = null;
		Login1: string = "";
		Login2: string = "";
		Password1: string = "";
		Password2: string = "";
		Sandbox: boolean = false;
		CurrentUrl: string = "";
		ProductionUrl1: string = "";
		ProductionUrl2: string = "";
		SandboxUrl1: string = "";
		SandboxUrl2: string = "";
		TransactionDescription: string = "";
		OrderNumberType: string = "";
		PaymentMaximumAttempts: number = 0;
		PaymentRetryIntervalSeconds: number = 0;
		PaymentMonitorTimeoutSeconds: number = 0;
		PaymentProviderTimeoutReportedStatus: string = "";
		PaymentProviderErrorReportedStatus: string = "";
		SuppressBillingTransaction: boolean = false;
		SupportPaymentTransactionDetail: boolean = false;
		RefundTransactionThresholdDays: number = 0;
		PaymentErrorTriggersNewProviderSelection: boolean = false;
		PaymentErrorThreshold: number = 0;
		PaymentErrorWindow: number = 0;
		PaymentErrorDuration: number = 0;
		UrlChangedDateTime: Date = null;
		DisabledUntilDateTime: Date = null;
		NotificationGroupIdAuthSuccess: number = 0;
		NotificationGroupIdAuthExpired: number = 0;
		NotificationGroupIdAuthDeclined: number = 0;
		NotificationGroupIdAuthError: number = 0;
		NotificationGroupIdSaleSuccess: number = 0;
		NotificationGroupIdSaleExpired: number = 0;
		NotificationGroupIdSaleDeclined: number = 0;
		NotificationGroupIdSaleError: number = 0;
		NotificationGroupIdCreditSuccess: number = 0;
		NotificationGroupIdCreditExpired: number = 0;
		NotificationGroupIdCreditDeclined: number = 0;
		NotificationGroupIdCreditError: number = 0;
		CustomTransactionProcessingLogic: m5core.ScriptViewModel = null;
		CustomNotificationProcessingLogic: m5core.ScriptViewModel = null;
		Comments: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		SelectionRules: m5pay.PaymentProviderSelectionRuleEditViewModel[] = [];
		SupportedCardTypes: m5pay.PaymentProviderSupportedCardTypeEditViewModel[] = [];
		Triggers: m5pay.PaymentProviderTriggerEditViewModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentProviderSelectionRuleEditViewModel {
		PaymentProviderSelectionRuleId: number = null;
		PaymentProviderId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		PropertyName: string = "";
		PropertyValue: string = "";
		RuleProcessingOrder: number = 0;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentProviderSupportedCardTypeEditViewModel {
		PaymentProviderSupportedCardTypeId: number = null;
		PaymentProviderId: number = null;
		PaymentMethodCardTypeId: string = null;
		IsoCurrencyCodeList: string[] = [];
		CountryCodeList: string[] = [];
		ThirdPartyVerificationType: string = "";
		MandatoryDisplayText: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentProviderTriggerEditViewModel {
		PaymentProviderTriggerId: number = null;
		PaymentProviderId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		EventType: string = "";
		EventValue: string = "";
		EventValueLookup: string = "";
		EventFilter: string = "";
		PaymentTransactionIdLookup: string = "";
		TransactionPaymentIdLookup: string = "";
		NotificationGroupId: number = null;
		CaseTemplateId: number = null;
		TriggerAssetId: number = null;
		RetryTransaction: boolean = false;
		RetryTransactionAnyPaymentMethod: boolean = false;
		ChangeAttributes: string = "";
		ChangeStatus: string = "";
		TransactionMap: string = "";
		NotificationTransaction: boolean = false;
		NotificationStatus: string = "";
		IsoCurrencyCode: string = "";
		NotificationFee: number = 0;
		NotificationReason: string = "";
		NotificationComments: string = "";
		NotificationMap: string = "";
		InitiateRefund: boolean = false;
		RecordRefund: boolean = false;
		RefundPercent: number = 0;
		RefundReason: string = "";
		RefundComments: string = "";
		RefundMap: string = "";
		TriggerOrder: number = 0;
		StopProcessingTriggers: boolean = false;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentNotificationAddViewModel {
		PaymentNotificationId: string = null;
		NotificationDateTime: Date = null;
		NotificationType: string = "";
		NotificationCode: string = "";
		NotificationMessage: string = "";
		NotificationDetails: string = "";
		IsoCurrencyCode: string = "";
		Fee: number = 0;
		ContactId: number = null;
		BillingAccountId: number = null;
		PaymentProviderId: string = null;
		PaymentMethodId: number = null;
		PaymentTransactionId: string = null;
		TransactionDateTime: Date = null;
		TransactionOrderReference: string = "";
		TransactionPaymentId: string = null;
		Properties: any = null;
		Attributes: any = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionRefundAddViewModel {
		PaymentTransactionRequestDateTime: Date = null;
		PaymentTransactionId: string = null;
		TransactionOrderReference: string = "";
		PaymentProviderTransactionPaymentId: string = null;
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		Reason: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionSaleAddViewModel {
		ContactId: number = null;
		BillingAccountId: number = null;
		PaymentMethodId: number = null;
		PaymentProviderId: number = null;
		TransactionOrderReference: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PayPalPayNowButtonViewModel {
		Id: string = null;
		BusinessId: string = null;
		Description: string = "";
		DisplayAboveMessage: string = "";
		DisplayBelowMessage: string = "";
		IsoCurrencyCode: string = "";
		IpnUrl: string = "";
		ReturnUrl: string = "";
		CancelUrl: string = "";
		Comments: string = "";
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

