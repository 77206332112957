import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCallback',
  pure: false
})
export class FilterCallbackPipe implements PipeTransform {

  transform(items: any[], callback: (item: any) => boolean): any[] {
    if (!items || items.length === 0 || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }

}
