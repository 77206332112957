import * as m from "./ngCoreModels";
import * as m5core from "./ngModelsCore5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class TelecomLocationCustomEditViewModel {
		TelecomLocationCustomId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		Location: string = "";
		LocationEndRange: string = "";
		CountryCode: string = "";
		Npa: string = "";
		Nxx: string = "";
		Subscriber: string = "";
		City: string = "";
		State: string = "";
		Country: string = "";
		IsoCountryCode2: string = "";
		IsoCountryCode3: string = "";
		IsoCountryCodeNumeric3: string = "";
		LocationType: string = "";
		UsageType: string = "";
		Vertical: number = 0;
		Horizontal: number = 0;
		Lata: number = 0;
		LocationIdentifierCode: string = "";
		OperatingCompanyNumber: string = "";
		Tier: number = 0;
		Geocode: string = "";
		PostalCode: string = "";
		TimeZoneId: string = null;
		NpaLength: number = 0;
		NxxLength: number = 0;
		Distance: number = 0;
		DistanceType: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DataStatus: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TelecomLocationGroupLinkEditViewModel {
		TelecomLocationGroupLinkId: number = null;
		SourceGroupNumber: number = 0;
		CallingPlan: number = 0;
		TargetGroupNumber: number = 0;
		UsageType: string = "";
		ServiceType: string = "";
		ServiceArea: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DataStatus: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TelecomLocationGroupListEditViewModel {
		TelecomLocationGroupListId: number = null;
		Location: string = "";
		Lata: number = 0;
		State: string = "";
		GroupNumber: number = 0;
		CallingPlanBusiness: number = 0;
		CallingPlanResidential: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		DataStatus: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TelecomLocationProfileEditViewModel {
		TelecomLocationProfileId: number = null;
		ExternalTelecomLocationProfileId: string = null;
		Description: string = "";
		VisibilityCostCenterType: string[] = [];
		VisibilityListType: string[] = [];
		VisibilityAccountType: string = "";
		VisibilityAccountClass: string = "";
		VisibilityMarketType: string = "";
		VisibilityContactId: number = null;
		VisibilityContactName: string = "";
		DetermineCallTypeFromAni: boolean = false;
		TierMatchUsageType: string = "";
		TierNumberUse: string = "";
		LocalUseLocationGroup: string = "";
		LocalNpa: string = "";
		LocalDistance: number = 0;
		LocalDistanceType: string = "";
		LocalOnlyWithinLata: boolean = false;
		LocalTierNumber: number = 0;
		IntraLataDefault: boolean = false;
		IntraLataNpa: string = "";
		IntraLataTierNumber: number = 0;
		IntrastateDefault: boolean = false;
		IntrastateNpa: string = "";
		IntrastateTierNumber: number = 0;
		CustomLocationDomesticFullNumberSearch: boolean = false;
		CustomLocationDomesticRangeSearch: boolean = false;
		CustomLocationDomesticDigitSearch: string = "";
		CustomLocationInternationalFullNumberSearch: boolean = false;
		CustomLocationInternationalRangeSearch: boolean = false;
		CustomLocationInternationalDigitSearch: string = "";
		IntroducedDateTime: Date = null;
		RetiredDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TelecomLocationStandardEditViewModel {
		TelecomLocationStandardId: number = null;
		Location: string = "";
		CountryCode: string = "";
		Npa: string = "";
		Nxx: string = "";
		Subscriber: string = "";
		City: string = "";
		State: string = "";
		Country: string = "";
		IsoCountryCode2: string = "";
		IsoCountryCode3: string = "";
		IsoCountryCodeNumeric3: string = "";
		LocationType: string = "";
		Vertical: number = 0;
		Horizontal: number = 0;
		Lata: number = 0;
		LocationIdentifierCode: string = "";
		OperatingCompanyNumber: string = "";
		Tier: number = 0;
		Geocode: string = "";
		PostalCode: string = "";
		TimeZoneId: string = null;
		NpaLength: number = 0;
		NxxLength: number = 0;
		DataStatus: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class TelecomNumberingPlanEditViewModel {
		TelecomNumberingPlanId: number = null;
		ExternalTelecomNumberingPlanId: string = null;
		Description: string = "";
		IsoCountryCode2: string = "";
		IsoCountryCode3: string = "";
		IsoCountryCodeNumeric3: string = "";
		TelecomCountryCode: string = "";
		MinimumNpaDigits: number = 0;
		MaximumNpaDigits: number = 0;
		MinimumNxxDigits: number = 0;
		MaximumNxxDigits: number = 0;
		MinimumNumberDigits: number = 0;
		MaximumNumberDigits: number = 0;
		MinimumLocalNumberDigits: number = 0;
		MaximumLocalNumberDigits: number = 0;
		MinimumLongDistanceNumberDigits: number = 0;
		MaximumLongDistanceNumberDigits: number = 0;
		MinimumInternationalNumberDigits: number = 0;
		MaximumInternationalNumberDigits: number = 0;
		InternationalSearchDigits: number = 0;
		InternationalSearchValues: string = "";
		DomesticSearchValues: string = "";
		AllCallsIncludeNpa: boolean = false;
		LocalCallsNeverIncludeNpa: boolean = false;
		AniNeedsLongDistancePrefix: boolean = false;
		IfNotLongDistanceThenLocal: boolean = false;
		InsertNpaForLocalCalls: boolean = false;
		TreatAllCallsAsInternational: boolean = false;
		AccessCodesLongDistance: string = "";
		AccessCodesInternational: string = "";
		AccessCodesFree: string = "";
		AccessCodesOperator: string = "";
		AccessCodesInternationalOperator: string = "";
		AccessCodesLocalInformation: string = "";
		AccessCodesLongDistanceInformation: string = "";
		AccessCodesEmergency: string = "";
		AccessCodesException: string = "";
		MinimumFormattingDigits: number = 0;
		DropDigits: string = "";
		DropLongDistanceAccessCode: boolean = false;
		DropInternationalAccessCode: boolean = false;
		FormatInternationalAccessCodeAsNpa: boolean = false;
		FormatFreeAccessCodeAsNpa: boolean = false;
		FormatOperatorAccessCodeAsNpa: boolean = false;
		FormatInternationalOperatorAccessCodeAsNpa: boolean = false;
		FormatLocalInformationAccessCodeAsNpa: boolean = false;
		FormatLongDistanceInformationAccessCodeAsNpa: boolean = false;
		FormatEmergencyAccessCodeAsNpa: boolean = false;
		FormatExceptionAccessCodeAsNpa: boolean = false;
		FormatAsE164: boolean = false;
		FormatSeparatorsBeforeLongDistanceAccessCode: string = "";
		FormatSeparatorsAfterLongDistanceAccessCode: string = "";
		FormatSeparatorsBeforeNpa: string = "";
		FormatSeparatorsAfterNpa: string = "";
		FormatSeparatorsBeforeNxx: string = "";
		FormatSeparatorsAfterNxx: string = "";
		FormatSeparatorsBeforeInternationalAccessCode: string = "";
		FormatSeparatorsAfterInternationalAccessCode: string = "";
		FormatSeparatorsBeforeCountryCode: string = "";
		FormatSeparatorsAfterCountryCode: string = "";
		FormatSeparatorsBeforeCityCode: string = "";
		FormatSeparatorsAfterCityCode: string = "";
		FormatOther: string = "";
		DefaultNumberingPlan: boolean = false;
		HideNumberingPlan: boolean = false;
		NumberFormatting: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}

// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

