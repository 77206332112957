<div class="m-1">
  <dropzone [config]="config"
            class="dropzone-container"
            (success)="fireSuccess($event)"
            (error)="fireError($event)">
  </dropzone>
  <div class="pull-left" *ngIf="showStats">
    <span *ngIf="countUploading || countPending" class="badge badge-info" style="font-size:100%; margin-right:10px;">
      <i class="fal fa-spinner fa-spin"></i>&nbsp;
      {{countUploading | i18nPlural : {'=0': 'Uploading no files', '=1': 'Uploading one file', 'other': 'Uploading # files'} }}
      {{countPending | i18nPlural : {'=0': '', '=1': ' (one file pending)', 'other': ' (# files pending)'} }}
    </span>
    <span *ngIf="countSuccess" class="badge badge-success" style="font-size:100%; margin-right:10px;">
      <i class="fal fa-check"></i>&nbsp;
      {{countSuccess | i18nPlural : {'=0': 'No files uploaded', '=1': 'One file uploaded', 'other': '# files uploaded'} }}
    </span>
    <span *ngIf="countError" class="badge badge-danger" style="font-size:100%; margin-right:10px;">
      <i class="fal fa-times"></i>&nbsp;
      {{countError | i18nPlural : {'=0': 'No errors', '=1': 'One error', 'other': '# errors'} }}
    </span>
  </div>
</div>
<div class="clearfix"></div>
