<div class="{{inputFormGroupClass}} {{outerClass}}">
  <!--form-group-->
  <label *ngIf="!plainInput || label"
         for="{{inputControlId}}"
         class="{{inputLabelClass}}"
         ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)"
               ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}}">
    <div class="flex-input-wrapper">
      <ng-container *ngIf="inputType==='date' || inputType==='datetime'">
        <div class="input-group {{inputGroupClass}} flex-input-date">
          <ng-container *ngTemplateOutlet="prefix; context: {prefixText: prefixText, prefixIcon: prefixIcon, prefixTooltip: prefixTooltip}"></ng-container>

          <input class="form-control {{inputSize}} {{controlClass}} date-input"
                 id="{{inputControlId}}"
                 name="{{name}}"
                 placeholder="{{placeholder | translate}}"
                 ngbTooltip="{{tooltip | translate}}"
                 [disabled]="disabled ? 'disabled' : null"
                 [readonly]="readonly ? 'readonly' : null"
                 [required]="required ? 'required' : null"
                 (focus)="fireFocus($event, inputControl)"
                 (blur)="fireBlur($event, inputControl)"
                 (keyup)="fireKeyUp($event, inputControl)"
                 (change)="fireChange($event, inputControl)"
                 (click)="toggleDatePicker(picker)"
                 (keypress)="preventInput($event)"
                 (keydown)="preventInput($event)"
                 ngbDatepicker
                 #picker="ngbDatepicker"
                 (dateSelect)="fireChange($event, inputControl)"
                 [(ngModel)]="date"
                 [ngModelOptions]="{standalone: standalone}"
                 #inputControl="ngModel" />
          <div class="input-group-append clickable">
            <span class="input-group-text"
                  (click)="toggleDatePicker(picker)">
              <ib-icon icon="calendar-alt"></ib-icon>
            </span>
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="inputType==='time' || inputType==='datetime'">
        <div class="input-group {{inputGroupClass}} flex-input-time">
          <ng-container *ngIf="inputType==='time'">
            <ng-container *ngTemplateOutlet="prefix; context: {prefixText: prefixText, prefixIcon: prefixIcon, prefixTooltip: prefixTooltip}"></ng-container>
          </ng-container>
          <input type="number"
                 class="form-control {{inputSize}} {{controlClass}} time-input"
                 min="0"
                 max="23"
                 [step]="stepHours"
                 [disabled]="disabled ? 'disabled' : null"
                 [readonly]="readonly ? 'readonly' : null"
                 (focus)="fireFocus($event)"
                 (blur)="fireBlur($event)"
                 (change)="fireChange($event)"
                 [(ngModel)]="hour"
                 [ngModelOptions]="{standalone: standalone}"
                 #inputControlHours="ngModel" />
          <input type="number"
                 class="form-control {{inputSize}} {{controlClass}} time-input"
                 min="0"
                 max="59"
                 [step]="stepMinutes"
                 [disabled]="disabled ? 'disabled' : null"
                 [readonly]="readonly ? 'readonly' : null"
                 (focus)="fireFocus($event)"
                 (blur)="fireBlur($event)"
                 (change)="fireChange($event)"
                 [(ngModel)]="minute"
                 [ngModelOptions]="{standalone: standalone}"
                 #inputControlMinutes="ngModel" />
          <input *ngIf="includeSeconds"
                 type="number"
                 class="form-control {{inputSize}} {{controlClass}} time-input"
                 min="0"
                 max="59"
                 [step]="stepSeconds"
                 [disabled]="disabled ? 'disabled' : null"
                 [readonly]="readonly ? 'readonly' : null"
                 (focus)="fireFocus($event)"
                 (blur)="fireBlur($event)"
                 (change)="fireChange($event)"
                 [(ngModel)]="second"
                 [ngModelOptions]="{standalone: standalone}"
                 #inputControlSeconds="ngModel" />
          <div class="input-group-append">
            <span class="input-group-text">
              <ib-icon icon="clock"></ib-icon>
            </span>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="inputType==='date' || inputType==='datetime'">
      <div *ngIf="inputControl?.invalid && (inputControl?.dirty || inputControl?.touched)">
        <ng-container *ngTemplateOutlet="errorsList; context: {$implicit: errorMessages}"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="inputType==='time'">
      <div *ngIf="inputControlHours?.invalid && (inputControlHours?.dirty || inputControlHours?.touched)
               || inputControlMinutes?.invalid && (inputControlMinutes?.dirty || inputControlMinutes?.touched)
               || inputControlSeconds?.invalid && (inputControlSeconds?.dirty || inputControlSeconds?.touched)">
        <ng-container *ngTemplateOutlet="errorsList; context: {$implicit: errorMessages}"></ng-container>
      </div>
    </ng-container>
  </div>
</div>


<ng-template #errorsList
             let-errorMessages>
  <small *ngFor="let errorMessage of errorMessages"
         class="form-text input-invalid input-error-message">
    {{errorMessage | translate:inputInformationValues}}
  </small>
</ng-template>

<ng-template #prefix
             let-text="prefixText"
             let-icon="prefixIcon"
             let-tooltip="prefixTooltip">
  <div *ngIf="text || icon"
       class="input-group-prepend"
       [ngClass]="{'clickable': isPrefixClickable()}">
    <span class="input-group-text"
          ngbTooltip="{{tooltip | translate}}"
          (click)="firePrefixClick($event)">
      {{text | translate}}
      <ib-icon icon="{{icon}}"></ib-icon>
    </span>
  </div>
</ng-template>
