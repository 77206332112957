import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";


export class ApiModuleCore {


  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleCore).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleCore[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleCore[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleCore.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleCore.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions


  public static ApplicationInformation(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Application Information";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ApplicationInformation;
    api.documentation.requestAndResponseDataModelObject = new m5core.ApplicationInformationModel();
    api.documentation.documentationUrlBase = "/application-information/";
    api.documentation.readOnly = true;
    api.pathVariables = "{domain}";
    api.pathModelProperties = "Domain";
    api.cacheName = "pseudoStaticObjectCache";
    api.endpoints.push(new ApiEndpoint("/application-information?domain={domain}", ApiOperationType.Get));
    return api;
  };

  public static Partition(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Partition";
    api.documentation.objectPrimaryKey = "PartitionId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "CustomerName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Partition;
    api.documentation.requestAndResponseDataModelObject = new m5core.PartitionEditViewModel();
    api.documentation.documentationUrlBase = "/partition/";
    api.documentation.securityAccessArea = Constants.AccessArea.Partition;
    api.documentation.readOnly = true;
    api.pathVariables = "{partitionId}";
    api.pathModelProperties = "PartitionId";
    api.cacheName = "pseudoStaticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Partitions}`, new m5core.PartitionListViewModel(), Constants.DataModelName.PartitionList, true);
    return api;
  };

  public static PartitionService(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Partition Service";
    api.documentation.objectPrimaryKey = "PartitionServiceId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ServiceName", "ServiceType", "ServiceClass"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PartitionService;
    api.documentation.requestAndResponseDataModelObject = new m5core.PartitionServiceEditViewModel();
    api.documentation.documentationUrlBase = "/partition/service/";
    api.documentation.securityAccessArea = Constants.AccessArea.PartitionService;
    api.documentation.readOnly = true;
    api.pathVariables = ["{partitionId}", "{partitionServiceId}"];
    api.pathModelProperties = ["PartitionId", "PartitionServiceId"];
    api.cacheName = "pseudoStaticObjectCache";
    api.parentApi = "Partition";
    api.useStandardEndpoints(`/${m.RouteSegment.Partitions}/{partitionId}/${m.RouteSegment.Services}`);
    return api;
  };

  public static PartitionDomain(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Partition Domain";
    api.documentation.objectPrimaryKey = "PartitionDomainId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "DomainName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PartitionDomain;
    api.documentation.requestAndResponseDataModelObject = new m5core.PartitionDomainEditViewModel();
    api.documentation.documentationUrlBase = "/partition/domain/";
    api.documentation.securityAccessArea = Constants.AccessArea.PartitionDomain;
    api.documentation.readOnly = true;
    api.pathVariables = ["{partitionId}", "{partitionDomainId}"];
    api.pathModelProperties = ["PartitionId", "PartitionDomainId"];
    api.cacheName = "pseudoStaticObjectCache";
    api.parentApi = "Partition";
    api.useStandardEndpoints(`/${m.RouteSegment.Partitions}/{partitionId}/${m.RouteSegment.Domains}`);
    return api;
  };

  public static PartitionRoute(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Partition Route";
    api.documentation.objectPrimaryKey = "PartitionRouteId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "RouteUrl"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PartitionRoute;
    api.documentation.requestAndResponseDataModelObject = new m5core.PartitionRouteEditViewModel();
    api.documentation.documentationUrlBase = "/partition-routes/";
    api.documentation.securityAccessArea = Constants.AccessArea.PartitionRoute;
    api.pathVariables = "{partitionRouteId}";
    api.pathModelProperties = "PartitionRouteId";
    api.cacheName = "pseudoStaticObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.PartitionRoutes}`, null, "", false);
    return api;
  };



  public static SystemSettingsApp(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "App Settings";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestAndResponseDataModelDocumentationName = ""; // TODO Constants.DataModelName.Visibility;
    api.documentation.requestAndResponseDataModelObject = new m5core.AppSettingsEditViewModel();
    api.documentation.documentationUrlBase = "/system-app-settings/";
    api.documentation.securityAccessArea = Constants.AccessArea.Setting;
    api.pathVariables = null;
    api.pathModelProperties = null;
    api.cacheName = "staticObjectCache";
    api.endpoints.push(new ApiEndpoint("/system/app-settings", ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint("/system/app-settings", ApiOperationType.Edit));
    return api;
  };


  public static InputPickList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Pick List";
    api.documentation.objectDescriptionPlural = api.documentation.objectDescription;
    api.documentation.objectPrimaryKey = "PickListId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.InputPickList;
    api.documentation.requestAndResponseDataModelObject = new m5core.PickListSelectionViewModel();
    api.documentation.documentationUrlBase = "/system/pick-list/";
    api.pathVariables = "{pickListId}";
    api.pathModelProperties = "PickListId";
    api.cacheName = "pickListObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PickLists}/${m.RouteSegment.PickListSelections}/{pickListId}?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}&id={id}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Pick List";
    api.endpoints.slice(-1)[0].documentation.menuText = "Pick List";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a pick list for a given pick list id.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressPromptForOwnerKey = true;
    return api;
  };

  public static CustomPickList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Custom Pick List";
    api.documentation.objectName = "PickList";
    api.documentation.objectPrimaryKey = "PickListId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.CustomPickList;
    api.documentation.requestAndResponseDataModelObject = new m5core.PickListEditViewModel();
    api.documentation.documentationUrlBase = "/system/pick-list-custom/";
    api.pathVariables = "{customPickListPrefix}";
    api.pathModelProperties = "CustomPickListPrefix";
    api.cacheName = "pickListObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PickLists}/${m.RouteSegment.CustomPickLists}/{customPickListPrefix}`, ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a list of custom pick lists for a given custom pick list prefix.";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static PickListCategory(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Pick List Category";
    api.documentation.objectPrimaryKey = "PickListCategoryId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PickListCategory;
    api.documentation.requestAndResponseDataModelObject = new m5core.PickListCategoryEditViewModel();
    api.documentation.documentationUrlBase = "/pick-list-categories/";
    api.documentation.securityAccessArea = Constants.AccessArea.PickListCategory;
    api.pathVariables = "{pickListCategoryId}";
    api.pathModelProperties = "PickListCategoryId";
    api.cacheName = "staticObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.PickListCategories}`, null, null, true);
    return api;
  };

  public static PickList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Pick List";
    api.documentation.objectPrimaryKey = "PickListSurrogateId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "PickListId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PickList;
    api.documentation.requestAndResponseDataModelObject = new m5core.PickListEditViewModel();
    api.documentation.documentationUrlBase = "/pick-lists/";
    api.documentation.securityAccessArea = Constants.AccessArea.PickList;
    api.pathVariables = "{pickListSurrogateId}";
    api.pathModelProperties = "PickListSurrogateId";
    api.cacheName = "staticObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.PickLists}`, null, null, true);
    // Custom endpoint for add new / get existing from list of pick list objects
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PickLists}/${m.RouteSegment.AddNewGetExisting}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = [];
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.PickList;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = [];
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.PickList;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.title = "Add New / Get Existing";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add New / Get Existing";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint accepts a list of pick list objects and returns a list of objects " +
      "with those same PickListId values with any that do not already exist being added.";
    return api;
  };

  public static PickListValue(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Pick List Value";
    api.documentation.objectPrimaryKey = ["PickListValueId"];
    api.documentation.objectDescriptionPropertyNames = ["DisplayText", "Value"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PickListValue;
    api.documentation.requestAndResponseDataModelObject = new m5core.PickListValueEditViewModel();
    api.documentation.documentationUrlBase = "/pick-list-values/";
    api.documentation.securityAccessArea = Constants.AccessArea.PickListValue;
    api.pathVariables = ["{pickListValueId}"];
    api.pathModelProperties = ["PickListValueId"];
    api.cacheName = "pseudoStaticObjectCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.PickListValues}`, null, null, true);
    return api;
  };

  public static Translation(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Translation";
    api.documentation.objectPrimaryKey = "TranslationId";
    api.documentation.objectDescriptionPropertyNames = ["NativeText"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Translation;
    api.documentation.requestAndResponseDataModelObject = new m5core.TranslationEditViewModel();
    api.documentation.documentationUrlBase = "/translations/";
    api.documentation.securityAccessArea = Constants.AccessArea.Translation;
    api.pathVariables = "{translationId}";
    api.pathModelProperties = "TranslationId";
    api.cacheName = "systemCache";
    api.useStandardEndpoints(`/${m.RouteSegment.Translations}`, null, null, true);
    // Custom endpoint for executing query
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Translations}/${m.RouteSegment.Find}`, ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = new m5core.TranslationRequestViewModel();
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = Constants.DataModelName.TranslationRequest;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5core.TranslationResponseViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.TranslationResponse;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.title = "Find Translation";
    api.endpoints.slice(-1)[0].documentation.menuText = "Find Translation";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint finds a translation.";
    return api;
  };

  public static TranslationAddMissing(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Translation";
    api.documentation.objectPrimaryKey = "TranslationId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TranslationAddMissing;
    api.documentation.requestAndResponseDataModelObject = new m5core.TranslationAddMissingViewModel();
    api.documentation.documentationUrlBase = "/translations-add-missing/";
    api.documentation.securityAccessArea = Constants.AccessArea.Translation;
    api.pathVariables = "{translationId}";
    api.pathModelProperties = "TranslationId";
    api.cacheName = null; //"staticObjectCache";
    api.parentApi = "Translation";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Translations}/${m.RouteSegment.Missing}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add Missing";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add Missing";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint adds a missing translation.";
    return api;
  };

  public static SubscriptionConfig(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Subscription Config";
    api.documentation.objectPrimaryKey = "SubscriptionConfigId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SubscriptionConfig;
    api.documentation.requestAndResponseDataModelObject = new m5core.SubscriptionConfigViewModel();
    api.documentation.documentationUrlBase = "/subscription-config/";
    api.documentation.securityAccessArea = Constants.AccessArea.SubscriptionConfig;
    api.pathVariables = "{subscriptionConfigId}";
    api.pathModelProperties = "SubscriptionConfigId";
    api.cacheName = "subscriptionCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.Subscriptions}/${m.RouteSegment.Config}`, null, "", true);
    return api;
  };

  public static SubscriptionOption(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Subscription Option";
    api.documentation.objectPrimaryKey = "SubscriptionOptionId";
    api.documentation.objectDescriptionPropertyNames = ["Name", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SubscriptionOption;
    api.documentation.requestAndResponseDataModelObject = new m5core.SubscriptionOptionViewModel();
    api.documentation.documentationUrlBase = "/subscription-options/";
    api.documentation.securityAccessArea = Constants.AccessArea.SubscriptionOption;
    api.pathVariables = "{subscriptionOptionId}";
    api.pathModelProperties = "SubscriptionOptionId";
    api.cacheName = "subscriptionCache";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.Subscriptions}/${m.RouteSegment.Options}`, null, "", true);
    return api;
  };

  public static SubscriptionAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Subscription";
    api.documentation.objectPrimaryKey = "SubscriptionOptionId";
    api.documentation.objectDescriptionPropertyNames = ["SubscriptionOptionName"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.SubscriptionRequest;
    api.documentation.requestAndResponseDataModelObject = new m5core.SubscriptionSignUpRequestViewModel();
    api.documentation.documentationUrlBase = "/subscription-add/";
    api.pathVariables = "{subscriptionOptionId}";
    api.pathModelProperties = "SubscriptionOptionId";
    api.cacheName = null;
    api.cacheLevel = CacheLevel.None;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Subscriptions}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add Subscription";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add Subscription";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This api endpoint adds a new subscription.";
    return api;
  };

}
