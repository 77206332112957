import { Component, OnInit, Input } from '@angular/core';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { StaticPickList } from '../../../../../core-lib/src/lib/models/model-helpers';

@Component({
  selector: 'app-endpoint-test-header',
  templateUrl: './endpoint-test-header.component.html',
  styleUrls: ['./endpoint-test-header.component.scss']
})
export class EndpointTestHeaderComponent implements OnInit {

  @Input() apiCall: m.ApiCall = null;
  @Input() apiEndpoint: m.ApiEndpoint = null;

  apiHostPickList: m5core.PickListSelectionViewModel[] = [];
  metaDataOptionsPickList: m5core.PickListSelectionViewModel[] = [];

  get httpMethod(): string {
    if (!this.apiEndpoint) {
      return "";
    }
    return m.ApiMethodType[this.apiEndpoint.method].toString(); // enum as string
  }

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(public apiService: ApiService) { }

  ngOnInit() {

    this.metaDataOptionsPickList = StaticPickList.stringArrayToPickList(["Options", "Attributes", "Timestamps", "Durations", "All", "True", "False"]);

    this.apiCall.baseUrl = this.apiService.selectedHost.url;
    this.apiService.apiHosts.forEach(host => {
      this.apiHostPickList.push(<m5core.PickListSelectionViewModel>{ Value: host.url, DisplayText: `${host.description} (${host.url})` });
    });

  }

  onSelectApiHost($event) {
    //this.apiCall.baseUrl = $event.data;
    this.apiService.selectApiHost(this.apiCall.baseUrl, this.apiCall);
  }

  onMetaDataChange($event) {
    //console.error($event);
    if ($event && $event.data) {
      this.apiCall.meta = $event.data;
    }
  }

}
