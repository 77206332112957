import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5usage from "projects/core-lib/src/lib/models/ngModelsUsage5";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";


export class ApiModuleUsage {


  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleUsage).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleUsage[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleUsage[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleUsage.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleUsage.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions


  //#region System APIs


  public static UsageDataSource(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Usage Data Source";
    api.documentation.objectPrimaryKey = "UsageDataSourceId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalUsageDataSourceId", "ExternalUsageDataLayoutId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.UsageDataSource;
    api.documentation.requestAndResponseDataModelObject = new m5usage.UsageDataSourceEditViewModel();
    api.documentation.documentationUrlBase = "/usage/data-source/";
    api.documentation.securityAccessArea = Constants.AccessArea.UsageDataSource;
    api.pathVariables = "{usageDataSourceId}";
    api.pathModelProperties = "UsageDataSourceId";
    api.cacheName = "usage";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.Usage}/${m.RouteSegment.DataSources}`);
    return api;
  };



  public static UsageImportLog(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Usage Import Log";
    api.documentation.objectPrimaryKey = "UsageImportLogId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ImportDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.UsageImportLog;
    api.documentation.requestAndResponseDataModelObject = new m5usage.UsageImportLogEditViewModel();
    api.documentation.documentationUrlBase = "/usage/import-log/";
    api.documentation.securityAccessArea = Constants.AccessArea.UsageImportLog;
    api.pathVariables = "{usageImportLogId}";
    api.pathModelProperties = "UsageImportLogId";
    api.cacheName = "usage";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.Usage}/${m.RouteSegment.ImportLogs}`);
    return api;
  };


  public static UsageDataFeed(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Usage Data Feed";
    api.documentation.objectPrimaryKey = "UsageDataFeedId";
    api.documentation.objectDescriptionPropertyNames = ["UsageDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.UsageDataFeed;
    api.documentation.requestAndResponseDataModelObject = new m5usage.UsageDataFeedEditViewModel();
    api.documentation.documentationUrlBase = "/usage/data-feed/";
    api.documentation.securityAccessArea = Constants.AccessArea.UsageDataFeed;
    api.pathVariables = "{usageDataFeedId}";
    api.pathModelProperties = "UsageDataFeedId";
    api.cacheName = null;
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.Usage}/${m.RouteSegment.DataFeed}`);
    return api;
  };


  public static UsageDataFeedBulkAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Usage Data Feed Bulk Add";
    api.documentation.objectPrimaryKey = "UsageDataFeedId";
    api.documentation.objectDescriptionPropertyNames = ["UsageDateTime"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.UsageDataFeed;
    api.documentation.requestAndResponseDataModelObject = [new m5usage.UsageDataFeedEditViewModel(), new m5usage.UsageDataFeedEditViewModel()];
    api.documentation.documentationUrlBase = "/usage/data-feed/bulk-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.UsageDataFeed;
    api.pathVariables = "{usageDataFeedId}";
    api.pathModelProperties = "UsageDataFeedId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Usage}/${m.RouteSegment.DataFeed}/${m.RouteSegment.BulkAdd}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Usage Data Feed Bulk Add";
    api.endpoints.slice(-1)[0].documentation.menuText = "Usage Data Feed Bulk Add";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API is utilized to bulk add a collection of usage data feed objects.`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    return api;
  };



  public static UsageCostCenterRating(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Usage Cost Center Rating";
    api.documentation.objectPrimaryKey = "UsageCostCenterRatingId";
    api.documentation.objectDescriptionPropertyNames = ["CostCenterType"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.UsageCostCenterRating;
    api.documentation.requestAndResponseDataModelObject = new m5usage.UsageCostCenterRatingEditViewModel();
    api.documentation.documentationUrlBase = "/usage/cost-center-rating/";
    api.documentation.securityAccessArea = Constants.AccessArea.UsageCostCenterRating;
    api.pathVariables = "{usageCostCenterRatingId}";
    api.pathModelProperties = "UsageCostCenterRatingId";
    api.cacheName = "usage";
    api.cacheLevel = CacheLevel.ChangesOften;
    api.useStandardEndpoints(`/${m.RouteSegment.Usage}/${m.RouteSegment.CostCenterRating}`);
    return api;
  };





  public static ServiceIdentification(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Service Identification";
    api.documentation.objectPrimaryKey = "UsageServiceIdentificationId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ServiceId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.UsageServiceIdentification;
    api.documentation.requestAndResponseDataModelObject = new m5usage.UsageServiceIdentificationEditViewModel();
    api.documentation.documentationUrlBase = "/usage/service-identification/";
    api.documentation.securityAccessArea = Constants.AccessArea.UsageServiceIdentification;
    api.pathVariables = "{usageServiceIdentificationId}";
    api.pathModelProperties = "UsageServiceIdentificationId";
    api.cacheName = "usage";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.Usage}/${m.RouteSegment.ServiceIdentifications}`, null, null, true);
    return api;
  };



  public static NetworkElement(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Network Element";
    api.documentation.objectPrimaryKey = "NetworkElementId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalNetworkElementId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NetworkElement;
    api.documentation.requestAndResponseDataModelObject = new m5usage.NetworkElementEditViewModel();
    api.documentation.documentationUrlBase = "/network-element/";
    api.documentation.securityAccessArea = Constants.AccessArea.NetworkElement;
    api.pathVariables = "{networkElementId}";
    api.pathModelProperties = "NetworkElementId";
    api.cacheName = "networkObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.NetworkElements}`, null, null, true);
    return api;
  };

  public static NetworkPortGroup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Network Port Group";
    api.documentation.objectPrimaryKey = "NetworkPortGroupId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalNetworkPortGroupId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NetworkPortGroup;
    api.documentation.requestAndResponseDataModelObject = new m5usage.NetworkPortGroupEditViewModel();
    api.documentation.documentationUrlBase = "/network-element/port-group/";
    api.documentation.securityAccessArea = Constants.AccessArea.NetworkPortGroup;
    api.pathVariables = ["{networkElementId}", "{networkPortGroupId}"];
    api.pathModelProperties = ["NetworkElementId", "NetworkPortGroupId"];
    api.cacheName = "networkObjectCache";
    api.parentApi = "NetworkElement";
    api.useStandardEndpoints(`/${m.RouteSegment.NetworkElements}/{networkElementId}/${m.RouteSegment.PortGroups}`, null, null, true);
    return api;
  };

  public static NetworkPort(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Network Port";
    api.documentation.objectPrimaryKey = "NetworkPortId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalNetworkPortId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.NetworkPort;
    api.documentation.requestAndResponseDataModelObject = new m5usage.NetworkPortEditViewModel();
    api.documentation.documentationUrlBase = "/network-element/port-group/port/";
    api.documentation.securityAccessArea = Constants.AccessArea.NetworkPort;
    api.pathVariables = ["{networkElementId}", "{networkPortGroupId}", "{networkPortId}"];
    api.pathModelProperties = ["NetworkElementId", "NetworkPortGroupId", "NetworkPortId"];
    api.cacheName = "networkObjectCache";
    api.parentApi = "NetworkPortGroup";
    api.useStandardEndpoints(`/${m.RouteSegment.NetworkElements}/{networkElementId}/${m.RouteSegment.PortGroups}/{networkPortGroupId}/${m.RouteSegment.Ports}`, null, null, true);
    return api;
  };



  public static RatingProfile(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Profile";
    api.documentation.objectPrimaryKey = "RatingProfileId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalRatingProfileId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingProfile;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingProfileEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingProfile;
    api.pathVariables = "{ratingProfileId}";
    api.pathModelProperties = "RatingProfileId";
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.impactedPickListIds = [Constants.PickList._RatingProfile];
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}`, null, null, true);
    return api;
  };


  public static RatingGroup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Group";
    api.documentation.objectPrimaryKey = "RatingGroupId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingGroup;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingGroupEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/group/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingGroup;
    api.pathVariables = ["{ratingProfileId}", "{ratingGroupId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingGroupId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}`, null, null, true);
    return api;
  };


  public static RatingGroupAutoAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Group";
    api.documentation.objectPrimaryKey = "RatingGroupId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.RatingGroup;
    api.documentation.responseDataModelObject = new m5usage.RatingGroupEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/group/auto-add/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingGroup;
    api.pathVariables = ["{ratingProfileId}", "{groupUsage}"];
    api.pathModelProperties = ["RatingProfileId", "GroupUsage"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingStep";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}/${m.RouteSegment.AutoAdd}?groupUsage={groupUsage}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Auto-Add Rating Groups";
    api.endpoints.slice(-1)[0].documentation.menuText = "Auto-Add Rating Groups";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to automatically add a rating group for each usage type that " +
      "is not already covered by a rating group for the specified group usage.";
    return api;
  };


  public static RatingGroupPurge(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Group";
    api.documentation.objectPrimaryKey = "RatingGroupId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/rating/profile/group/purge/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingGroup;
    api.pathVariables = ["{ratingProfileId}", "{groupUsage}"];
    api.pathModelProperties = ["RatingProfileId", "GroupUsage"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingStep";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}/${m.RouteSegment.Purge}/{groupUsage}`, ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Purge Rating Groups";
    api.endpoints.slice(-1)[0].documentation.menuText = "Purge Rating Groups";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to purge rating groups for the specified group usage.";
    return api;
  };



  public static RatingStep(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Step";
    api.documentation.objectPrimaryKey = "RatingStepId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingStep;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingStepEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/group/step/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingStep;
    api.pathVariables = ["{ratingProfileId}", "{ratingGroupId}", "{ratingStepId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingGroupId", "RatingStepId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingGroup";
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}/{ratingGroupId}/${m.RouteSegment.Steps}`, null, null, true);
    return api;
  };


  public static RatingStepFlatten(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Step";
    api.documentation.objectPrimaryKey = "RatingStepId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.RatingStep;
    api.documentation.responseDataModelObject = new m5usage.RatingStepEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/group/step/flatten/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingStep;
    api.pathVariables = ["{ratingProfileId}", "{ratingGroupId}", "{ratingStepId}", "{targetGroupId}", "{targetStepDescription}"];
    api.pathModelProperties = ["RatingProfileId", "RatingGroupId", "RatingStepId", "TargetGroupId", "TargetStepDescription"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingStep";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}/{ratingGroupId}/${m.RouteSegment.Steps}/{ratingStepId}/${m.RouteSegment.Flatten}?targetGroupId={targetGroupId}&targetStepDescription={targetStepDescription}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Flatten Rating Step";
    api.endpoints.slice(-1)[0].documentation.menuText = "Flatten Rating Step";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to flatten the rates in rating step into a new rating step.  " +
      "The process of flattening rates merges multiple rate entries that have the same rate values into a single rate record with a range of look up codes.";
    return api;
  };




  public static RatingRate(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Rate";
    api.documentation.objectPrimaryKey = "RatingRateId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingRate;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingRateEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/group/step/rate/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingRate;
    api.pathVariables = ["{ratingProfileId}", "{ratingGroupId}", "{ratingStepId}", "{ratingRateId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingGroupId", "RatingStepId", "RatingRateId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingStep";
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}/{ratingGroupId}/${m.RouteSegment.Steps}/{ratingStepId}/${m.RouteSegment.Rates}`, null, null, true);
    return api;
  };


  public static RatingRatePurge(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Rate";
    api.documentation.objectPrimaryKey = "RatingRateId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/rating/profile/group/step/rate/purge/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingRate;
    api.pathVariables = ["{ratingProfileId}", "{ratingGroupId}", "{ratingStepId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingGroupId", "RatingStepId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingStep";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Groups}/{ratingGroupId}/${m.RouteSegment.Steps}/{ratingStepId}/${m.RouteSegment.Rates}/${m.RouteSegment.Purge}`, ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Purge Rating Rates";
    api.endpoints.slice(-1)[0].documentation.menuText = "Purge Rating Rates";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to purge all rates for the specified rating step.";
    return api;
  };




  public static RatingSurchargeDiscount(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Surcharge/Discount";
    api.documentation.objectPrimaryKey = "RatingSurchargeDiscountId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingSurchargeDiscount;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingSurchargeDiscountEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/surcharge-discount/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingSurchargeDiscount;
    api.pathVariables = ["{ratingProfileId}", "{ratingSurchargeDiscountId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingSurchargeDiscountId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.SurchargeDiscounts}`, null, null, true);
    return api;
  };

  public static RatingSurchargeDiscountPurge(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Surcharge/Discount";
    api.documentation.objectPrimaryKey = "RatingSurchargeDiscountId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/rating/profile/surcharge-discount/purge/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingSurchargeDiscount;
    api.pathVariables = ["{ratingProfileId}", "{ratingSurchargeDiscountId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingSurchargeDiscountId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.SurchargeDiscounts}/${m.RouteSegment.Purge}`, ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Purge Rating Surcharges/Discounts";
    api.endpoints.slice(-1)[0].documentation.menuText = "Purge Rating Surcharges/Discounts";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to purge rating surcharges and discounts for the specified rating profile.";
    return api;
  };


  public static RatingZone(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Zone";
    api.documentation.objectPrimaryKey = "RatingZoneId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingZone;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingZoneEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/zone/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingZone;
    api.pathVariables = ["{ratingProfileId}", "{ratingZoneId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingZoneId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Zones}`, null, null, true);
    return api;
  };


  public static RatingZoneSetup(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Zone";
    api.documentation.objectPrimaryKey = "RatingZoneId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/rating/profile/zone/setup/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingZone;
    api.pathVariables = ["{ratingProfileId}", "{sourceLocationType}", "{sourceCountryCode}", "{targetLocationType}", "{targetCountryCode}", "{tierNumber}"];
    api.pathModelProperties = ["RatingProfileId", "SourceLocationType", "SourceCountryCode", "TargetLocationType", "TargetCountryCode", "TierNumber"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingZone";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Zones}?sourceLocationType={sourceLocationType}&sourceCountryCode={sourceCountryCode}&targetLocationType={targetLocationType}&targetCountryCode={targetCountryCode}&tierNumber={tierNumber}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Setup Rating Zones";
    api.endpoints.slice(-1)[0].documentation.menuText = "Setup Rating Zones";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to set up zones based on standard location data and specified parameters.";
    return api;
  };

  public static RatingZonePurge(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Zone";
    api.documentation.objectPrimaryKey = "RatingZoneId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/rating/profile/zone/purge/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingZone;
    api.pathVariables = ["{ratingProfileId}", "{ratingZoneId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingZoneId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Zones}/${m.RouteSegment.Purge}`, ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Purge Rating Zones";
    api.endpoints.slice(-1)[0].documentation.menuText = "Purge Rating Zones";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to purge rating zones for the specified rating profile.";
    return api;
  };

  public static RatingHoliday(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Holiday";
    api.documentation.objectPrimaryKey = "RatingHolidayId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.RatingHoliday;
    api.documentation.requestAndResponseDataModelObject = new m5usage.RatingHolidayEditViewModel();
    api.documentation.documentationUrlBase = "/rating/profile/holiday/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingHoliday;
    api.pathVariables = ["{ratingProfileId}", "{ratingHolidayId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingHolidayId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.useStandardEndpoints(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Holidays}`, null, null, true);
    return api;
  };

  public static RatingHolidayPurge(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.version = version;
    api.documentation.objectDescription = "Rating Holiday";
    api.documentation.objectPrimaryKey = "RatingHolidayId";
    api.documentation.objectDescriptionPropertyNames = ["Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = null;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/rating/profile/holiday/purge/";
    api.documentation.securityAccessArea = Constants.AccessArea.RatingHoliday;
    api.pathVariables = ["{ratingProfileId}", "{ratingHolidayId}"];
    api.pathModelProperties = ["RatingProfileId", "RatingHolidayId"];
    api.cacheName = "rating";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.parentApi = "RatingProfile";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Rating}/${m.RouteSegment.Profiles}/{ratingProfileId}/${m.RouteSegment.Holidays}/${m.RouteSegment.Purge}`, ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Purge Rating Holidays";
    api.endpoints.slice(-1)[0].documentation.menuText = "Purge Rating Holidays";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to purge rating holidays for the specified rating profile.";
    return api;
  };

  //#endregion

}
