import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { EventModel } from '../../ux-models';
import { TileRuleModel } from './tile-rule-model';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'ib-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css']
})
export class TileComponent implements OnInit, OnChanges {

  /**
   * the width specified in number of grid columns
   */
  @Input() width: number = 2;
  widthDefault: number = 2;

  window = window;

  /**
   * true if tile should be short
   */
  @Input() short: boolean = false;

  @Input() color: "light" | "dark" | "primary" | "secondary" | "info" | "success" | "warning" | "danger" = "primary";
  colorDefault: "light" | "dark" | "primary" | "secondary" | "info" | "success" | "warning" | "danger" = "primary";

  textClass: string = "";

  @Input() icon: string = "asterisk";
  iconDefault: string = "asterisk";

  defaultsSaved: boolean = false;

  @Input() value: number = null;

  /**
   * font awesome w/o leading fa- ... best with sync | circle-o-notch | cog | spinner ... defaults to refresh
   */
  @Input() valuePendingIcon: string = "spinner";

  @Input() text: string = "";

  // array with objects [{ lowValue: 1 , highValue: 10 , tileStyle: "success" , tileIcon: "thumbs-up" , tileWidth: 5 }]
  @Input() rules: TileRuleModel[] = [];

  @Input() linkText: string = "View Details";
  @Input() linkUrl: string = "";
  @Input() linkOpenUrlInNewWindow: boolean = false;

  @Input() customStyle: string = "";

  @Output() click: EventEmitter<EventModel> = new EventEmitter();


  constructor(protected appService: AppService) { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  protected configure() {

    // Make sure we're ready
    if (!this.value && !this.text) {
      return;
    }

    // Sanitize inputs
    if (!this.width || this.width < 1) {
      this.width = 1;
    } else if (this.width > 12) {
      this.width = 12;
    }

    // Save our defaults for rule fallback in case we don't find a rule match
    if (!this.defaultsSaved) {
      this.widthDefault = this.width;
      this.colorDefault = this.color;
      this.iconDefault = this.icon;
      this.defaultsSaved = true;
    }

    // Even without a value we may have some style to apply based on text alone
    let rule: TileRuleModel = Helper.firstOrDefault(this.rules, x => x.tileText && Helper.equals(x.tileText, this.text, true) && !x.lowValue && !x.highValue);
    if (rule) {
      if (rule.tileWidth) {
        this.width = rule.tileWidth;
        this.widthDefault = rule.tileWidth;
      }
      if (rule.tileStyle) {
        this.color = rule.tileStyle;
        this.colorDefault = rule.tileStyle;
      }
      if (rule.tileIcon) {
        this.icon = rule.tileIcon;
        this.iconDefault = rule.tileIcon;
      }
    }

    // Since we have a value assigned (or changed) let's reevaluate our rules to pick a style and icon
    // Can't just do falsy test in this area since 0 is a valid value
    if (this.value !== null && this.value !== undefined && this.value !== NaN) {
      var widthSelected: boolean = false;
      var styleSelected: boolean = false;
      var iconSelected: boolean = false;
      this.rules.forEach((value: TileRuleModel, index: number, array: TileRuleModel[]) => {
        if (value.lowValue !== null && value.lowValue !== undefined && value.lowValue !== NaN &&
          value.highValue !== null && value.highValue !== undefined && value.highValue !== NaN &&
          this.value >= value.lowValue && this.value <= value.highValue &&
          (!value.tileText || Helper.equals(value.tileText, this.text, true))) {
          if (value.tileWidth) {
            this.width = value.tileWidth;
            widthSelected = true;
          }
          if (value.tileStyle) {
            this.color = value.tileStyle;
            styleSelected = true;
          }
          if (value.tileIcon) {
            this.icon = value.tileIcon;
            iconSelected = true;
          }
        } else if (value.lowValue !== null && value.lowValue !== undefined && value.lowValue !== NaN &&
          (value.highValue === null || value.highValue === undefined || value.highValue === NaN) &&
          this.value >= value.lowValue &&
          (!value.tileText || Helper.equals(value.tileText, this.text, true))) {
          if (value.tileWidth) {
            this.width = value.tileWidth;
            widthSelected = true;
          }
          if (value.tileStyle) {
            this.color = value.tileStyle;
            styleSelected = true;
          }
          if (value.tileIcon) {
            this.icon = value.tileIcon;
            iconSelected = true;
          }
        } else if ((value.lowValue === null || value.lowValue === undefined || value.lowValue === NaN) &&
          value.highValue !== null && value.highValue !== undefined && value.highValue !== NaN &&
          this.value <= value.highValue &&
          (!value.tileText || Helper.equals(value.tileText, this.text, true))) {
          if (value.tileWidth) {
            this.width = value.tileWidth;
            widthSelected = true;
          }
          if (value.tileStyle) {
            this.color = value.tileStyle;
            styleSelected = true;
          }
          if (value.tileIcon) {
            this.icon = value.tileIcon;
            iconSelected = true;
          }
        }
      });
      // If no rules gave us a style or icon use the defaults
      if (!widthSelected) {
        this.width = this.widthDefault;
      }
      if (!styleSelected) {
        this.color = this.colorDefault;
      }
      if (!iconSelected) {
        this.icon = this.iconDefault;
      }
    }

    // Our style may call for a text color class
    if (this.color === "dark" || this.color === "primary" || this.color === "secondary" || this.color === "info" ||
      this.color === "success" || this.color === "warning" || this.color === "danger") {
      this.textClass = "text-white";
    }

  }


  public isClickable(): boolean {
    return (this.click.observers.length > 0);
  }

  onCardClick($event) {
    let payload: EventModel = new EventModel("click", $event, { text: this.text, icon: this.icon, color: this.color, value: this.value });
    this.click.emit(payload);
  }

  onViewDetailsClick($event) {
    if (this.linkUrl) {
      this.appService.redirectToUrl(this.linkUrl, this.linkOpenUrlInNewWindow);
    }
    let payload: EventModel = new EventModel("click", $event, { text: this.text, icon: this.icon, color: this.color, value: this.value });
    this.click.emit(payload);
  }

}
