import { Component, OnInit, Input } from '@angular/core';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";

@Component({
  selector: 'app-endpoint-data-model',
  templateUrl: './endpoint-data-model.component.html',
  styleUrls: ['./endpoint-data-model.component.scss']
})
export class EndpointDataModelComponent implements OnInit {

  @Input() data: m.ApiDocDataModel = null; // no default so we can use this to know when our component has been initialized
  @Input() sort: "natural" | "asc" | "desc" = "natural";

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor() { }

  ngOnInit() {
  }

  onSort(sort) {
    this.sort = sort;
  }

}
