import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { EndpointTestFormBaseClass } from '../endpoint-test-form-base-class';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';

@Component({
  selector: 'app-endpoint-test-form-edit-controls',
  templateUrl: './endpoint-test-form-edit-controls.component.html',
  styleUrls: ['./endpoint-test-form-edit-controls.component.scss']
})
export class EndpointTestFormEditControlsComponent extends EndpointTestFormBaseClass implements OnInit, OnChanges {

  constructor(protected docsService: ApiDocsService) {
    super(docsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }


}
