<lib-doka-modal #myDoka
                *ngIf="editImage"
                [src]="getUrlString(editImage)"
                [options]="editImageOptions"
                (oninit)="handleDokaInit()"
                (onconfirm)="handleDokaConfirm($event)"
                (oncancel)="handleDokaCancel()"
                (onclose)="handleDokaClose()">
</lib-doka-modal>


<div class="attachment-management loading-container">

  <div>
    <div class="float-left">
      <!--{ Button: 'External' , Label: 'Create Report' , Icon: 'file-word-o' , Color: 'success' , Event: 'FileCreateReport' } ,
      { Button: 'Upload' }-->
      <!--<span ng-repeat="button in $ctrl.AttachmentOptions.Buttons">
        <ib-button ng-if="button.Button==='External'"
                   label="{{button.Label}}" icon="{{button.Icon}}" button-class="{{button.Color}}"
                   on-click="$ctrl.$rootScope.$broadcast(button.Event, null);"
                   disable-when-pristine="false"
                   disable-when-invalid="false"
                   warn-on-exit="false"
                   form-controller="$ctrl">
        </ib-button>
        <ib-button ng-if="button.Button==='Upload'"
                   label="Upload Attachments" icon="cloud-upload" button-class="primary"
                   on-click="$ctrl.showUploadComponent = !$ctrl.showUploadComponent"
                   disable-when-pristine="false"
                   disable-when-invalid="false"
                   warn-on-exit="false"
                   form-controller="$ctrl">
        </ib-button>
        &nbsp;&nbsp;
      </span>-->
      <ib-button *ngIf="allowUpload" name="Upload" [icon]="uploadButtonIcon" [label]="uploadButtonText" [color]="uploadButtonColor"
                 (click)="uploadVisible = !uploadVisible">
      </ib-button>
      &nbsp;&nbsp;
      <ib-button *ngIf="allowAddLink" name="Add Link" [icon]="addLinkButtonIcon" [label]="addLinkButtonText" [color]="addLinkButtonColor"
                 (click)="addLink()">
      </ib-button>
      &nbsp;&nbsp;
      <ib-button name="Refresh" icon="sync" label="Refresh" color="primary" (click)="load(true)"></ib-button>
    </div>
    <div class="float-right">
      <div *ngIf="availableViews.length > 1" class="btn-group btn-group-toggle" role="group" aria-label="...">
        <button *ngIf="availableViews.indexOf('DET') !== -1" type="button" class="btn btn-light" [ngClass]="{'active': currentView === 'DET'}" (click)="currentView = 'DET'">
          <i class="fas fa-list"></i>&nbsp;Details
        </button>
        <button *ngIf="availableViews.indexOf('ICO') !== -1" type="button" class="btn btn-light" [ngClass]="{'active': currentView === 'ICO'}" (click)="currentView = 'ICO'">
          <i class="fas fa-th"></i>&nbsp;Icons
        </button>
        <button *ngIf="availableViews.indexOf('ILG') !== -1" type="button" class="btn btn-light" [ngClass]="{'active': currentView === 'ILG'}" (click)="currentView = 'ILG'">
          <i class="fas fa-th-large"></i>&nbsp;Large
        </button>
        <button *ngIf="availableViews.indexOf('IXL') !== -1" type="button" class="btn btn-light" [ngClass]="{'active': currentView === 'IXL'}" (click)="currentView = 'IXL'">
          <i class="fas fa-square"></i>&nbsp;Extra Large
        </button>
        <button *ngIf="availableViews.indexOf('GAL') !== -1" type="button" class="btn btn-light" [ngClass]="{'active': currentView === 'GAL'}" (click)="currentView = 'GAL'">
          <i class="fas fa-image"></i>&nbsp;Gallery
        </button>
      </div>
    </div>
  </div>


  <div class="clearfix"></div>


  <div class="form-group p-2" [hidden]="!allowUpload || !uploadVisible">
    <ib-file-upload assetSystemGroup="Attachment"
                    [ownerType]="ownerType"
                    [ownerId]="ownerId"
                    [secondaryOwnerType]="secondaryOwnerType"
                    [secondaryOwnerId]="secondaryOwnerId"
                    [uploadIcon]="uploadIcon"
                    [uploadText]="uploadText"
                    [acceptedFileTypes]="acceptedFileTypes"
                    [maxFileSize]="maxFileSize"
                    [showStats]="true"
                    (success)="fireUploadSuccess($event)"
                    (error)="fireUploadError($event)">
    </ib-file-upload>
  </div>

  <!-- detail view -->
  <div [hidden]="currentView !== 'DET'">
    <ib-standard-table [options]="filesTableOptions"
                       [data]="data"
                       [reloadCount]="filesTableReloadCount">
    </ib-standard-table>
  </div>

  <!-- gallery view -->
  <div *ngIf="currentView === 'GAL'" class="mt-2">
    <p-galleria [value]="galleryImages"
                [showItemNavigators]="true"
                [showThumbnailNavigators]="true"
                [showIndicators]="true"
                [showIndicatorsOnItem]="true"
                [responsiveOptions]="galleryResponsiveOptions"
                [numVisible]="5">
      <ng-template pTemplate="item" let-item>
        <img [src]="item.source" [alt]="item.alt" style="max-width: 40%; max-height: 40%;" />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="p-grid p-nogutter p-justify-center mr-2">
          <img [src]="item.source" style="width:80px; height:80px;" />
        </div>
      </ng-template>
    </p-galleria>
    <!--<ngb-carousel #carousel [interval]="1000" [pauseOnHover]="true" [pauseOnFocus]="true" (slide)="onSlide($event)">
      <ng-template ngbSlide *ngFor="let img of galleryImages">
        <div class="carousel-caption">
          <h3>{{img.title}}</h3>
        </div>
        <div class="picsum-img-wrapper">
          <img [src]="img.source" [alt]="img.alt" style="width: 50%; height: 50%;" />
        </div>
      </ng-template>
    </ngb-carousel>-->
  </div>

  <!-- thumbnail view -->
  <div [hidden]="currentView === 'DET' || currentView === 'GAL'" (mousedown)="onFileAreaClick($event)" #files>
    <ng-container *ngFor="let row of data; let i = index; trackBy: trackByIndex">
      <div class="float-left">
        <div class="icon-container" [ngClass]="{'icon': currentView === 'ICO', 'icon-lg': currentView === 'ILG', 'icon-xl': currentView === 'IXL'}">
          <div class="center-horizontal">
            <div class="center-vertical">
              <a [href]="getUrl(row)" target="_blank" (mousedown)="onFileClick($event, row, i)">
                <img *ngIf="isImage(row)"
                     [src]="getUrl(row)"
                     title="{{row.Title}}"
                     ngbTooltip="{{row.Title}}" />
                <i *ngIf="!isImage(row)"
                   class="far fa-{{getIcon(row)}}"
                   [ngClass]="{'fa-6x': currentView === 'ICO', 'fa-10x': currentView === 'ILG', 'fa-15x': currentView === 'IXL'}"
                   ngbTooltip="{{row.Title}}"></i>
                <div class="attachment-title" ngbTooltip="{{row.FriendlyName}}.{{row.FileType}}">{{row.FriendlyName}}.{{row.FileType}}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <p-contextMenu [target]="files" [model]="fileContextMenu" appendTo="body"></p-contextMenu>
  </div>

  <div class="clearfix"></div>
  <ngx-loading [show]="loading" [config]="{animationType: 'none'}" [template]="loadingTemplate"></ngx-loading>
  <ng-template #loadingTemplate><div class="loading-template"><h3><i class="{{Constants.Layout.loadingIcon}}"></i>&nbsp;&nbsp;{{Constants.Layout.loadingText}}</h3></div></ng-template>
</div>
