// This is generated code... DO NOT modify this file.
	export enum InputControlTypeOption {
		Auto = 0,
		SingleLineEditor = 11,
		SingleLineEditorAutoComplete = 12,
		MultiLineEditor = 21,
		Integer = 31,
		Float = 41,
		DateTime = 51,
		Date = 52,
		CheckBox = 61,
		RadioButton = 71,
		DropDownList = 81,
		DropDown = 82,
		MultiSelectDropDownList = 83
	}
	export enum InputControlWidthOption {
		Auto = 0,
		Full = 1,
		XLarge = 14,
		Large = 15,
		Medium = 25,
		Small = 35,
		XSmall = 36
	}
	export enum PropertyMetaDataAction {
		Ignore = 0,
		PostWarning = 10,
		PostError = 20,
		Fail = 21
	}
	export enum PropertyMetaDataMapMatchMode {
		First = 0,
		Exact = 1,
		All = 2
	}
	export enum PropertyMetaDataMapValueMode {
		Replace = 0,
		Append = 1
	}
// This is generated code... DO NOT modify this file.
	export enum SettingInputType {
		SingleLineText = 0,
		MultiLineText = 1,
		NumericInteger = 2,
		NumericDouble = 3,
		DateTime = 4,
		YesNo = 5,
		Boolean = 6,
		FileSelection = 7,
		FileSelectionImage = 8,
		AssetId = 9,
		CaseTemplateId = 10,
		NotificationGroupId = 11,
		InvoiceMessageId = 12,
		ItemId = 13,
		GroupId = 14,
		RoleId = 15,
		RoleIdList = 16,
		QueryId = 17,
		SwitchIdPrimary = 18,
		SwitchId = 19,
		TimeZoneId = 20,
		IsoCurrencyCode = 21,
		StringList = 22,
		IntegerList = 23,
		ConstantList = 24,
		ConstantListMultiSelectCsv = 25,
		UserInputCsv = 26,
		Json = 27
	}
	export enum BrandId {
		Unknown = 0,
		Nubill = 1,
		IntelliBOSS = 2,
		ReportCompiler = 3,
		Qupport = 4,
		NetWiseCRM = 5,
		Custom = 2147483647
	}
	export enum SupportUrlAuthenticationTokenType {
		None = 0,
		PartnerToken = 1,
		NativeToken = 2,
		Custom = 2147483647
	}
// This is generated code... DO NOT modify this file.
	export enum StandardResultCode {
		DataStoreError = -1,
		Success = 0,
		PartialSuccess = 1,
		Pending = 51,
		Waiting = 52,
		Processing = 53,
		UnknownError = 90,
		DataStoreInformationMissing = 99,
		SubsystemSpecificError = 100,
		RequiredValueEmpty = 1001,
		InvalidValue = 1011,
		InvalidValueTooShort = 1012,
		InvalidValueTooLong = 1013,
		InvalidValueTooLow = 1014,
		InvalidValueTooHigh = 1015,
		InvalidFormat = 1051,
		ValidationFailure = 1061,
		PrerequisiteFailure = 1071,
		NotFound = 1101,
		TooManyFound = 1151,
		DuplicateFound = 1201,
		InvalidForeignKeyReference = 1211,
		Dependency = 1301,
		OutOfIdentitySpace = 1401,
		InsufficientPermission = 1501,
		InvalidUserNamePasswordCombination = 1511,
		InvalidAuthenticationToken = 1512,
		InvalidAuthenticationKey = 1513,
		UnknownThirdPartyAuthentication = 1519,
		PasswordExpired = 1520,
		AccessBlocked = 1521,
		AccessRejected = 1522,
		AccessRequiresMultiFactorAuthentication = 1531,
		AccessRequiresMultiFactorAuthenticationForNewPublicIpAddress = 1532,
		AccessRequiresMultiFactorAuthenticationForNewDeviceId = 1533,
		NoneAffected = 1601,
		ChangedByAnotherUser = 1611,
		RequestedActionNotSupported = 1701,
		RequestedActionAlreadyCompleted = 1711,
		DatabaseNotConfiguredMissingDatabaseId = 2001,
		EncryptionKeyNotFound = 2101,
		EncryptionKeyMissingValues = 2201,
		EncryptionNotConfigured = 2301,
		PartnerSystemOffline = 3101,
		PartnerSystemTimeout = 3111,
		PartnerSystemError = 3121,
		Rejected = 4101,
		Timeout = 4201,
		Cancelled = 4301,
		ExceptionTimeout = 2147483645,
		ExceptionUpdatingData = 2147483646,
		Exception = 2147483647
	}
// This is generated code... DO NOT modify this file.
export module System {
	export enum TypeCode {
		Empty = 0,
		Object = 1,
		DBNull = 2,
		Boolean = 3,
		Char = 4,
		SByte = 5,
		Byte = 6,
		Int16 = 7,
		UInt16 = 8,
		Int32 = 9,
		UInt32 = 10,
		Int64 = 11,
		UInt64 = 12,
		Single = 13,
		Double = 14,
		Decimal = 15,
		DateTime = 16,
		String = 18
	}
}
// This is generated code... DO NOT modify this file.
	export enum VoucherStatus {
		NotFound = 0,
		Hold = 1,
		Pending = 2,
		Reserved = 3,
		Active = 4,
		Inactive = 5,
		Expired = 6,
		Lapsed = 7,
		Suspended = 8,
		Used = 9,
		Unknown = 10
	}


// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class AuthenticationData {
		IsValid: boolean = null;
		Method: string = "";
		ContactId: number = null;
		PartitionId: number = null;
		Issued: Date = null;
		Expires: Date = null;
		ErrorMessage: string = "";
		Flags: string = "";
		Raw: string = "";
		Formatted: any = null;
		Permissions: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MetaDataModel {
		AddedDateTime: Date = null;
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		LastEditedDateTime: Date = null;
		LastEditedByContactId: number = null;
		LastEditedByContactName: string = "";
		MarkedForDeletionDateTime: Date = null;
		MarkedForDeletionByContactId: number = null;
		MarkedForDeletionByContactName: string = "";
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		IncludedDataObjectTypes: string[] = [];
		DeletedDataObjects: IDeletedDataObject[] = [];
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MetaDataLightModel {
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class DeletedDataObject {
		ObjectType: string = "";
		ObjectId: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PickListSelectionViewModel {
		PickListId: string = null;
		DisplayOrder: number = 0;
		GroupText: string = "";
		DisplayText: string = "";
		Value: string = "";
		Icon: string = "";
		IconColor: string = "";
		TextColor: string = "";
		Description: string = "";
		Properties: any = null;
		IsDefaultValue: boolean = false;
		IsMutuallyExclusive: boolean = false;
		Children: PickListSelectionViewModel[] = [];
		PickListValueId: number = null;
		ParentPickListValueId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class KeyValuePairModel<TKey, TValue> {
		Key: TKey = null;
		Value: TValue = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PropertyMetaDataViewModel {
		PropertyConfigurationContainerId: number = null;
		PropertyConfigurationId: number = null;
		PropertyNumber: number = 0;
		PropertyName: string = "";
		Description: string = "";
		DataType: System.TypeCode = null;
		DataTypeInformation: string = "";
		IsNullable: boolean = false;
		IsCollection: boolean = false;
		FullyQualifiedTypeName: string = "";
		DataTypeDescription: string = "";
		Properties: PropertyMetaDataViewModel[] = [];
		IsKeyProperty: boolean = false;
		IsDescriptionProperty: boolean = false;
		IsPublicProperty: boolean = false;
		DefaultValue: any = null;
		DefaultValueTemplate: string = "";
		PickList: PickListSelectionViewModel[] = [];
		PickListId: string = null;
		PickListFilter: string = "";
		PickListIncludeOptionForNone: boolean = false;
		PickListTreatMultipleSelectionsAsMultipleSubmissionsWhenPossible: boolean = false;
		IfPropertyValueEmptyAction: PropertyMetaDataAction = null;
		IsRequired: boolean = false;
		IsReadOnly: boolean = false;
		IsHidden: boolean = false;
		MapFromPropertyName: string = "";
		MapFromPropertyMatchMode: PropertyMetaDataMapMatchMode = null;
		MapFromTemplate: string = "";
		MapFromStaticValue: string = "";
		MappedValueTransformation: string = "";
		MappedValueTrim: boolean = false;
		MappedValueMode: PropertyMetaDataMapValueMode = null;
		MappedValueDelimiter: string = "";
		InputLabel: string = "";
		InputDescription: string = "";
		InputDisplayGroup: number = 0;
		InputDisplayOrder: number = 0;
		InputControlType: InputControlTypeOption = null;
		InputControlWidth: InputControlWidthOption = null;
		InputMask: string = "";
		IfValidationFailedAction: PropertyMetaDataAction = null;
		ValidationMinimum: number = 0;
		ValidationMaximum: number = 0;
		ValidationAllowedValues: any[] = [];
		ValidationTemplate: string = "";
		ValidationTransformation: string = "";
		ValidationExpression: string = "";
		ValidationExpressionFailedMessage: string = "";
		ValidationOtherDescription: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class RouteSegment {
	}

// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class IDeletedDataObject {
		ObjectType: string = "";
		ObjectId: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class IMetaDataModel {
		AddedDateTime: Date = null;
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		LastEditedDateTime: Date = null;
		LastEditedByContactId: number = null;
		LastEditedByContactName: string = "";
		MarkedForDeletionDateTime: Date = null;
		MarkedForDeletionByContactId: number = null;
		MarkedForDeletionByContactName: string = "";
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		IncludedDataObjectTypes: string[] = [];
		DeletedDataObjects: IDeletedDataObject[] = [];
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class IMetaDataLightModel {
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class IProcessStatusModel {
		ProcessStatusId: number = null;
		Description: string = "";
		ProcessType: string = "";
		ProcessTypeId: number = null;
		ProcessTypeId2: string = "";
		SessionId: string = null;
		StatusIntervalMinutes: number = 0;
		ServerName: string = "";
		ServerProcessId: number = null;
		ProcessCount: number = 0;
		ProcessTotal: number = 0;
		ProcessTime: number = 0;
		StartDateTime: Date = null;
		LastUpdateDateTime: Date = null;
		DataProcessedDateTime: Date = null;
		FinishDateTime: Date = null;
		CancelNow: boolean = false;
		Status: string = "";
		ProcessMemoryUsedKb: number = 0;
		ServerMemoryFreeKb: number = 0;
		ServerMemoryTotalKb: number = 0;
		ErrorCount: number = 0;
		ErrorMessage: string = "";
		WarningCount: number = 0;
		WarningMessage: string = "";
		OmittedCount: number = 0;
		OmittedMessage: string = "";
		AlarmCount: number = 0;
		AlarmMessage: string = "";
		Feedback: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		TimerCount: number = 0;
		TimerTotalMilliseconds: number = 0;
		TimerMaximumMilliseconds: number = 0;
		TimerMinimumMilliseconds: number = 0;
		TimerAverageMilliseconds: number = 0;
		TimerMedianMilliseconds: number = 0;
		TimerPercentile90Milliseconds: number = 0;
		TimerStandardDeviationMilliseconds: number = 0;
		TimerEventsMilliseconds: string = "";
		TimerStatsAsOfDateTime: Date = null;
		Details: IProcessStatusDetailModel[] = [];
		TimeZone: string = "";
		MetaData: IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class IProcessStatusDetailModel {
		ProcessStatusDetailId: number = null;
		ProcessStatusId: number = null;
		StartDateTime: Date = null;
		Description: string = "";
		Value: number = 0;
		Information: string = "";
		DisplayOrder: number = 0;
		DetailGroup: string = "";
		DetailGroupDisplayOrder: number = 0;
		ValueIsDenominator: boolean = false;
		LastUpdateDateTime: Date = null;
		TimerCount: number = 0;
		TimerTotalMilliseconds: number = 0;
		TimerMaximumMilliseconds: number = 0;
		TimerMinimumMilliseconds: number = 0;
		TimerAverageMilliseconds: number = 0;
		TimerMedianMilliseconds: number = 0;
		TimerPercentile90Milliseconds: number = 0;
		TimerStandardDeviationMilliseconds: number = 0;
		TimerEventsMilliseconds: string = "";
		TimerStatsAsOfDateTime: Date = null;
		TimeZone: string = "";
		MetaData: IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionBrandModel {
		BrandId: BrandId = null;
		Brand: string = "";
		BrandTrademark: string = "";
		ApplicationNameLong: string = "";
		ApplicationNameShort: string = "";
		ShowCopyrightMessage: boolean = false;
		CopyrightYears: string = "";
		CopyrightCompany: string = "";
		CopyrightCompanyUrl: string = "";
		CopyrightIncludeLicensors: boolean = false;
		CopyrightIncludeLicensorsMessage: string = "";
		PatentText: string = "";
		ShowPoweredByMessage: boolean = false;
		PoweredByName: string = "";
		PoweredByTrademark: string = "";
		PoweredByUrl: string = "";
		SupportEmailAddress: string = "";
		SupportUrlPortal: string = "";
		SupportUrlIndex: string = "";
		SupportUrlTopicTemplate: string = "";
		SupportUrlAuthenticationQueryStringParameter: string = "";
		SupportUrlAuthenticationTokenType: SupportUrlAuthenticationTokenType = null;
		BrandTags: string[] = [];
		PrivateBranded: boolean = false;
		IconName: string = "";
		LogoName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class JobBaseModel {
		JobId: number = null;
		Description: string = "";
		Tags: string[] = [];
		Type: string = "";
		TypeDescription: string = "";
		Priority: number = 0;
		Scope: string = "";
		Flags: string = "";
		ScheduleType: string = "";
		RealTimeInterval: number = 0;
		TriggeredByJobId: number = null;
		TriggeredByJobWhen: string = "";
		Status: string = "";
		RunAsApiKey: string = "";
		RunAsContactId: number = null;
		RunAsDomain: string = "";
		RunAsUserName: string = "";
		RunAsPassword: string = "";
		TargetDatabaseServer: string = "";
		TargetApplicationServer: string = "";
		RunningApplicationServer: string = "";
		RunningPingDateTime: Date = null;
		WaitingApplicationServer: string = "";
		WaitingPingDateTime: Date = null;
		WatchDogInterval: number = 0;
		Frequency: string = "";
		FrequencyDescription: string = "";
		LastRunDateTime: Date = null;
		NextRunDateTime: Date = null;
		NotBeforeTime: string = "";
		NotAfterTime: string = "";
		RunNow: boolean = false;
		CancelNow: boolean = false;
		TriggeredByResourceType: string = "";
		TriggeredByResourceId: number = null;
		TriggeredByResourceId2: string = "";
		LastResultCode: number = 0;
		LastResultMessage: string = "";
		Locked: boolean = false;
		Disabled: boolean = false;
		AllowLateExecution: boolean = false;
		AutoDelete: boolean = false;
		LogType: string = "";
		StatusIntervalMinutes: number = 0;
		RetryMinutes: number = 0;
		RetryMaximumCount: number = 0;
		RetryCount: number = 0;
		MinimumMemoryAvailableMb: number = 0;
		MinimumCpuAvailable: number = 0;
		RecycleMinutes: number = 0;
		RecycleMemoryMb: number = 0;
		RecycleTimeOfDay: string = "";
		RecyclePauseSeconds: number = 0;
		RecycleLastDateTime: Date = null;
		LoadBalanceNumber: number = 0;
		LoadBalanceTotal: number = 0;
		DatabaseLoadBalanceNumber: number = 0;
		DatabaseLoadBalanceTotal: number = 0;
		CountStart: number = 0;
		CountSuccess: number = 0;
		CountFailure: number = 0;
		NotificationGroupIdStart: number = 0;
		NotificationStartFlags: string = "";
		NotificationGroupIdProgress: number = 0;
		NotificationProgressFlags: string = "";
		NotificationProgressMinutes: number = 0;
		NotificationProgressDateTime: Date = null;
		NotificationProgressEvents: number = 0;
		NotificationProgressCounter: number = 0;
		NotificationGroupIdNoProgress: number = 0;
		NotificationNoProgressFlags: string = "";
		NotificationNoProgressDays1: string = "";
		NotificationNoProgressStartTime1: string = "";
		NotificationNoProgressEndTime1: string = "";
		NotificationNoProgressMinutes1: number = 0;
		NotificationNoProgressDays2: string = "";
		NotificationNoProgressStartTime2: string = "";
		NotificationNoProgressEndTime2: string = "";
		NotificationNoProgressMinutes2: number = 0;
		NotificationNoProgressDays3: string = "";
		NotificationNoProgressStartTime3: string = "";
		NotificationNoProgressEndTime3: string = "";
		NotificationNoProgressMinutes3: number = 0;
		NotificationNoProgressDays4: string = "";
		NotificationNoProgressStartTime4: string = "";
		NotificationNoProgressEndTime4: string = "";
		NotificationNoProgressMinutes4: number = 0;
		NotificationNoProgressDateTime: Date = null;
		NotificationGroupIdLogEvent: number = 0;
		NotificationLogEventKeywords: string = "";
		NotificationGroupIdError: number = 0;
		NotificationGroupIdFatalError: number = 0;
		NotificationGroupIdSuccess: number = 0;
		NotificationGroupIdFailure: number = 0;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: IMetaDataModel = null;
	}

// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class IConstantInformation {
		Value: string = "";
		NativeDescription: string = "";
		Language: string = "";
		Description: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ConstantInformation {
		Value: string = "";
		NativeDescription: string = "";
		Language: string = "";
		Description: string = "";
	}

// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class MenuItem {
		Id: string = null;
		Label: string = "";
		Description: string = "";
		Icon: string = "";
		IconType: string = "";
		LinkParam: string = "";
		LinkType: string = "";
		InputParameters: MenuInputParameter[] = [];
		AssetId: number = null;
		FormId: number = null;
		AllowOpenInNewTab: boolean = false;
		AlwaysOpenInNewTab: boolean = false;
		ValidContactTypes: string[] = [];
		FilterContactTypes: string[] = [];
		FilterControls: string[] = [];
		Modules: Modules = null;
		Permissions: Permissions = null;
		Custom: boolean = false;
		Visible: boolean = false;
		Children: MenuItem[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class MenuInputParameter {
		Id: string = null;
		Key: string = "";
		Value: string = "";
		PassThru: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class Modules {
		Flags: string = "";
		AllowOnAnyRequired: boolean = false;
		ModuleList: Module[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class Module {
		Id: string = null;
		LicensedModule: string = "";
		Required: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class Permissions {
		Flags: string = "";
		AllowOnAnyRequired: boolean = false;
		PermissionList: Permission[] = [];
		RequiredRoleIdsAll: number[] = [];
		RequiredRoleIdsAny: number[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class Permission {
		Id: string = null;
		PermissionAreaType: string = "";
		PermissionArea: string = "";
		Required: boolean = false;
		Rights: string[] = [];
		AllRightsRequired: boolean = false;
	}


// This is generated code... DO NOT modify this file.
	export module RouteSegment {
		export var Customers: string = "customers";
		export var Directory: string = "directory";
		export var Locations: string = "locations";
		export var Vendors: string = "vendors";
		export var Warehouses: string = "warehouses";
		export var Groups: string = "groups";
		export var Contacts: string = "contacts";
		export var ContactTypes: string = "contact-types";
		export var WorkSchedules: string = "work-schedules";
		export var WorkScheduleExceptions: string = "work-schedule-exceptions";
		export var ExternalAuthentications: string = "external-authentications";
		export var Access: string = "access";
		export var Members: string = "members";
		export var SalesOpportunities: string = "sales-opportunities";
		export var Documentation: string = "documentation";
		export var ApiConfig: string = "api-config";
		export var Endpoints: string = "endpoints";
		export var DataModels: string = "data-models";
		export var RawDataModels: string = "raw-data-models";
		export var DataOptions: string = "data-options";
		export var Data: string = "data";
		export var Security: string = "security";
		export var Authenticate: string = "authenticate";
		export var Login: string = "login";
		export var MultiFactorAuthentication: string = "multi-factor-authentication";
		export var TOTP: string = "totp";
		export var Token: string = "token";
		export var LoginAvailable: string = "login-available";
		export var LoginChange: string = "login-change";
		export var LoginRecover: string = "login-recover";
		export var PasswordReset: string = "password-reset";
		export var PasswordChange: string = "password-change";
		export var PasswordView: string = "password-view";
		export var ComputeUserPermissions: string = "compute-user-permissions";
		export var Policy: string = "policy";
		export var Mail: string = "mail";
		export var Servers: string = "servers";
		export var Addresses: string = "addresses";
		export var DataImportDefinitions: string = "data-import-definitions";
		export var Inventory: string = "inventory";
		export var History: string = "history";
		export var InventoryEvents: string = "inventory-events";
		export var InventoryTypes: string = "inventory-types";
		export var InventoryQuantityTypes: string = "inventory-quantity-types";
		export var Versions: string = "versions";
		export var Reports: string = "reports";
		export var Jobs: string = "jobs";
		export var JobTypes: string = "job-types";
		export var TimeZones: string = "time-zones";
		export var Roles: string = "roles";
		export var Encryption: string = "encryption";
		export var Echo: string = "echo";
		export var Cache: string = "cache";
		export var PackageSignups: string = "package-signups";
		export var Actions: string = "actions";
		export var Start: string = "start";
		export var Stop: string = "stop";
		export var Request: string = "request";
		export var Approve: string = "approve";
		export var Accept: string = "accept";
		export var Reject: string = "reject";
		export var Analyze: string = "analyze";
		export var Move: string = "move";
		export var Sign: string = "sign";
		export var Download: string = "download";
		export var Upload: string = "upload";
		export var Export: string = "export";
		export var Import: string = "import";
		export var View: string = "view";
		export var Validate: string = "validate";
		export var Run: string = "run";
		export var Execute: string = "execute";
		export var Preview: string = "preview";
		export var Prepare: string = "prepare";
		export var AddNewGetExisting: string = "add-new-get-existing";
		export var New: string = "new";
		export var AddFromTemplate: string = "add-from-template";
		export var AddFromList: string = "add-from-list";
		export var AutoAdd: string = "auto-add";
		export var BulkAdd: string = "bulk-add";
		export var BatchAdd: string = "batch-add";
		export var RefreshStats: string = "refresh-stats";
		export var Activate: string = "activate";
		export var Suspend: string = "suspend";
		export var Available: string = "available";
		export var Reserve: string = "reserve";
		export var Reset: string = "reset";
		export var Test: string = "test";
		export var Check: string = "check";
		export var Calc: string = "calc";
		export var Count: string = "count";
		export var Purge: string = "purge";
		export var Setup: string = "setup";
		export var Flatten: string = "flatten";
		export var Events: string = "events";
		export var Preferences: string = "preferences";
		export var Internal: string = "internal";
		export var External: string = "external";
		export var Assets: string = "assets";
		export var Tags: string = "tags";
		export var References: string = "references";
		export var ObjectStatus: string = "object-status";
		export var Related: string = "related";
		export var Selections: string = "selections";
		export var Feedback: string = "feedback";
		export var AccessLog: string = "access-log";
		export var AssetAccessLog: string = "asset-access-log";
		export var AssetLicenses: string = "asset-licenses";
		export var SeeAlso: string = "see-also";
		export var Notice: string = "notice";
		export var Files: string = "files";
		export var Draft: string = "draft";
		export var Final: string = "final";
		export var Create: string = "create";
		export var Trace: string = "trace";
		export var MostRecent: string = "most-recent";
		export var ReviewSubmit: string = "review-submit";
		export var ReviewRetract: string = "review-retract";
		export var ReviewResult: string = "review-result";
		export var Library: string = "library";
		export var ReviewDocument: string = "review-document";
		export var Coverage: string = "coverage";
		export var ReportParser: string = "report-parser";
		export var Version: string = "version";
		export var Parents: string = "parents";
		export var Children: string = "children";
		export var List: string = "list";
		export var Status: string = "status";
		export var Stats: string = "stats";
		export var Organization: string = "organization";
		export var Industry: string = "industry";
		export var IndustryLibrary: string = "industry-library";
		export var TaskListTemplates: string = "task-list-templates";
		export var TaskLists: string = "task-lists";
		export var Tasks: string = "tasks";
		export var Subtasks: string = "subtasks";
		export var CaseTemplates: string = "case-templates";
		export var Correspondence: string = "correspondence";
		export var Cases: string = "cases";
		export var Notes: string = "notes";
		export var Attachments: string = "attachments";
		export var Suggestions: string = "suggestions";
		export var Assign: string = "assign";
		export var Remove: string = "remove";
		export var ProcessTemplates: string = "process-templates";
		export var Processes: string = "processes";
		export var RawAttributes: string = "raw-attributes";
		export var Attributes: string = "attributes";
		export var AttributeSets: string = "attribute-sets";
		export var Configurations: string = "configurations";
		export var Forms: string = "forms";
		export var Controls: string = "controls";
		export var Html: string = "html";
		export var HelpLinks: string = "help-links";
		export var Templates: string = "templates";
		export var Sync: string = "sync";
		export var DataStores: string = "data-stores";
		export var PublisherArticleTypes: string = "publisher-article-types";
		export var Subscriptions: string = "subscriptions";
		export var Articles: string = "articles";
		export var PaymentProviders: string = "payment-providers";
		export var PaymentTransactions: string = "payment-transactions";
		export var Payments: string = "payments";
		export var Sales: string = "sales";
		export var Refunds: string = "refunds";
		export var PaymentNotifications: string = "payment-notifications";
		export var SendReceipt: string = "send-receipt";
		export var PaymentMethodCardTypes: string = "payment-method-card-types";
		export var PaymentMethods: string = "payment-methods";
		export var PaymentMethodCreditCards: string = "credit-cards";
		export var SelectionRules: string = "selection-rules";
		export var Triggers: string = "triggers";
		export var Billing: string = "billing";
		export var BillingProfiles: string = "billing-profiles";
		export var BillingReportProfiles: string = "billing-report-profiles";
		export var BillingAccounts: string = "billing-accounts";
		export var BillingTransactions: string = "billing-transactions";
		export var BillingEvents: string = "billing-events";
		export var Packages: string = "packages";
		export var PackageOccurrences: string = "package-occurrences";
		export var Items: string = "items";
		export var Rates: string = "rates";
		export var RateAdjustments: string = "rate-adjustments";
		export var Lists: string = "lists";
		export var Purchases: string = "purchases";
		export var PayPal: string = "pay-pal";
		export var Braintree: string = "braintree";
		export var Adyen: string = "adyen";
		export var IPN: string = "ipn";
		export var Authorization: string = "authorization";
		export var Tax: string = "tax";
		export var Geocodes: string = "geocodes";
		export var GeocodeLookups: string = "geocode-lookups";
		export var Jurisdictions: string = "jurisdictions";
		export var Matrix: string = "matrix";
		export var TransactionDetails: string = "transaction-details";
		export var AuthorizationCodes: string = "authorization-codes";
		export var Associations: string = "associations";
		export var Vouchers: string = "vouchers";
		export var Batches: string = "batches";
		export var Lots: string = "lots";
		export var NextSerialNumber: string = "next-serial-number";
		export var NotificationContacts: string = "notification-contacts";
		export var NotificationMessages: string = "notification-messages";
		export var NotificationOptOuts: string = "notification-opt-outs";
		export var NotificationGroups: string = "notification-groups";
		export var Details: string = "details";
		export var NotificationEvents: string = "notification-events";
		export var Notifications: string = "notifications";
		export var ActionLinks: string = "action-links";
		export var AlarmRules: string = "alarm-rules";
		export var Currency: string = "currency";
		export var Visibility: string = "visibility";
		export var RestrictionRules: string = "restriction-rules";
		export var Aliases: string = "aliases";
		export var Filters: string = "filters";
		export var System: string = "system";
		export var Settings: string = "settings";
		export var AppSettings: string = "app-settings";
		export var Query: string = "query";
		export var Queries: string = "queries";
		export var Translations: string = "translations";
		export var Find: string = "find";
		export var Missing: string = "missing";
		export var Management: string = "management";
		export var Merge: string = "merge";
		export var Duplicate: string = "duplicate";
		export var NotDuplicate: string = "not-duplicate";
		export var PossibleDuplicates: string = "possible-duplicates";
		export var ChildSynopsis: string = "child-synopsis";
		export var Sections: string = "sections";
		export var Text: string = "text";
		export var Documents: string = "documents";
		export var MetaData: string = "meta-data";
		export var Config: string = "config";
		export var Options: string = "options";
		export var OptionsList: string = "options-list";
		export var ObjectPickList: string = "object-pick-list";
		export var CustomPickLists: string = "custom-pick-lists";
		export var PickListSelections: string = "pick-list-selections";
		export var PickListCategories: string = "pick-list-categories";
		export var PickLists: string = "pick-lists";
		export var PickListValues: string = "pick-list-values";
		export var DataSources: string = "data-sources";
		export var FileServers: string = "file-servers";
		export var DataTableSupport: string = "data-table-support";
		export var FreeFormTextParsers: string = "free-form-text-parsers";
		export var ServerResources: string = "server-resources";
		export var Favorites: string = "favorites";
		export var Dashboards: string = "dashboards";
		export var Widgets: string = "widgets";
		export var Bookmarks: string = "bookmarks";
		export var Webhooks: string = "webhooks";
		export var WebhookEvents: string = "webhook-events";
		export var Target: string = "target";
		export var WatchEvents: string = "watch-events";
		export var ApplicationInformation: string = "application-information";
		export var Partitions: string = "partitions";
		export var Services: string = "services";
		export var Domains: string = "domains";
		export var PartitionRoutes: string = "partition-routes";
		export var ApiAccess: string = "api-access";
		export var Clients: string = "clients";
		export var Activity: string = "activity";
		export var NetworkElements: string = "network-elements";
		export var PortGroups: string = "port-groups";
		export var Ports: string = "ports";
		export var Telecom: string = "telecom";
		export var NumberingPlans: string = "numbering-plans";
		export var Standard: string = "standard";
		export var Custom: string = "custom";
		export var LocationProfiles: string = "location-profiles";
		export var LocationGroups: string = "location-groups";
		export var Links: string = "links";
		export var Usage: string = "usage";
		export var ServiceIdentifications: string = "service-identifications";
		export var ImportLogs: string = "import-logs";
		export var DataFeed: string = "data-feed";
		export var CostCenterRating: string = "cost-center-rating";
		export var Rating: string = "rating";
		export var Profiles: string = "profiles";
		export var Step: string = "step";
		export var Steps: string = "steps";
		export var Zones: string = "zones";
		export var SurchargeDiscounts: string = "surcharge-discounts";
		export var Holidays: string = "holidays";
		export var Logs: string = "logs";
		export var ProcessStatus: string = "process-status";
		export var QrCode: string = "qr-code";
		export var ReportCompiler: string = "report-compiler";
		export var LibraryGroups: string = "library-groups";
		export var TemplateTypes: string = "template-types";
		export var IndustryLibraryTypeInformation: string = "industry-library-type-information";
		export var SubjectMatter: string = "subject-matter";
		export var Other: string = "other";
		export var Structural: string = "structural";
		export var IndustryLibraryApproval: string = "industry-library-approval";
		export var AppInit: string = "app-init";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

