import { TableColumnOptions } from "projects/common-lib/src/lib/table/table-column-options";
import { ApiProperties } from "projects/core-lib/src/lib/api/ApiModels";
import { ButtonItem, Action } from "projects/common-lib/src/lib/ux-models";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as moment from "moment";
import { Log, Helper } from "projects/core-lib/src/lib/helpers/helper";

export class TableOptions {

  /**
  An identifier used to identify this table instance so we can save/fetch the configuration and any customizations.
  */
  public tableId: string = "";

  public canSaveLayout: boolean = true;


  /**
   * Optional title to use with the table.  This will appear to the left side above the table.
   */
  public title: string = "";

  public rowsPerPage: number = 10;

  /**
  If not set the table defaults will be used.  Otherwise we can force certain rows per page options to be available here.
  */
  public rowsPerPageOptions: number[] = null;

  public paging: boolean = true;

  public page: number = 1;

  public rowsPerPageSelector: boolean = true;

  public rowCounterMessage: boolean = true;

  public globalSearch: boolean = true;

  public filterAllowSaved: boolean = true;

  public filterAllowBuilder: boolean = true;

  public filterAllowAdvanced: boolean = true;

  public get filterAllow(): boolean {
    if ((this.filterAllowSaved || this.filterAllowBuilder || this.filterAllowAdvanced) && this.filterObjectName) {
      return true;
    } else {
      return false;
    }
  }

  public set filterAllow(filter: boolean) {
    if (!filter) {
      this.filterAllowSaved = false;
      this.filterAllowBuilder = false;
      this.filterAllowAdvanced = false;
    }
  }


  private _filterObjectName: string = "";
  public get filterObjectName(): string {
    if (this._filterObjectName) {
      return this._filterObjectName;
    } else if (this.apiProperties && this.apiProperties.documentation) {
      const name = (this.apiProperties.documentation.responseDataModelDocumentationName || this.apiProperties.documentation.requestDataModelDocumentationName || "");
      if (Helper.startsWith(name, "_")) {
        return "";
      } else {
        return name;
      }
    } else if (this.apiName) {
      return this.apiName;
    } else {
      return "";
    }
  }
  public set filterObjectName(objectName: string) {
    this._filterObjectName = objectName;
  }

  public sortMode: "none" | "single" | "multiple" = "multiple"; // options: none, single, multiple

  public sort: string = ""; // Sort csv with "-" property name prefix when descending

  public showRefreshOption: boolean = true;

  /**
   * If sortMode is "none" and a url is provided then header click will route to the specified url.  The url can contain
   * the following macros: {{ClickedPropertyName}} or {{ClickedHeaderLabel}}.
   */
  public headerClickUrl: string = "";

  public headerClickUrlNewWindow: boolean = false;

  /*
   * This will enable checkbox for selecting rows.
   */
  public selectRows: boolean = false;

  /*
   * When true we allow a row to be selected by clicking anywhere in the row.  This defaults to true because we virtually
   * always want some sort of click action on a row.  If selectRows is set to true to enable checkboxes then in that case
   * we may want this set to false if we don't want row click to be the same as a checkbox click.
   */
  public selectRowViaRowClick: boolean = true;

  public reorderRows: boolean = false;

  /**
   * If reorderRows is true and there is a property name that holds the order assign that property name here
   * so the values can be updated when reorder is done.
   */
  public orderPropertyName: string = "";

  public expandRows: boolean = false;

  public footer: boolean = false;

  public expandedRowChildTableOptions: TableOptions = null;

  /**
   * When allowing row expansion to show child data this is the property name on the parent
   * object that holds that collection of child data.
   */
  public expandedRowChildDataPropertyName: string = "";

  /**
   * If child data is lazy loaded this function can be used to retrieve that data and assign
   * it to the property specified in expandedRowChildDataPropertyName.
   */
  public expandedRowEventHandler: (row: any, selectedRows: any[], allRows: any[], cargo?: any) => any;

  /**
   * If child data is lazy loaded this is the indicator used on the table to show data is being loaded.
   * It can be set in expandedRowEventHandler.
   */
  public expandedRowChildDataLoading: boolean = false;

  /**
   * When building custom html for row expansion this is the method that gets called to get that html.
   */
  public expandedRowHtmlBuilder: (row: any, selectedRows: any[], allRows: any[], cargo?: any) => string;

  /**
   * Each column has it's own filter type (including none) but this flag can globally turn of column filtering.
   */
  public filterColumns: boolean = true;

  public reorderColumns: boolean = true;

  public resizableColumns: boolean = true;

  public columnResizeMode: "none" | "fit" | "expand" = "fit"; // options: none, fit, expand

  public responsive: boolean = true;

  public inlineEdit: boolean = false;

  /**
   * Note that scrollable only works well with fixed with columns so column headers line up with column contents.
   */
  public scrollable: boolean = false;

  public scrollHeight: string = "200px";

  public theme: "default" | "plain" | "striped" = "striped"; // default, plain, striped

  public rowStyle: string = "";

  public getRowStyle: (row: any) => string;

  public allRowsSelectedAction: (visibleRows: any[], cargo?: any) => void;

  public allRowsUnselectedAction: (visibleRows: any[], cargo?: any) => void;

  public rowSelectedAction: (row: any, selectedRows: any[], allRows: any[], cargo?: any) => void;

  public rowUnselectedAction: (row: any, selectedRows: any[], allRows: any[], cargo?: any) => void;

  /**
   * When provided a row click will route to the specified url.  The url can contain {{macros}} that map
   * properties found on the row or static values {{ClickedPropertyName}} or {{ClickedHeaderLabel}}.
   */
  public rowClickUrl: string = "";

  public rowClickUrlNewWindow: boolean = false;

  public dataSelectedBooleanPropertyName: string = "";

  /**
   * Action to fire when lazy load has completed.  The loaded data is passed in.
   */
  public lazyLoadAction: (rows: any[], cargo?: any) => void;

  /*
   * If table supports a loading indicator this setting controls if that indicator is used or not.
   */
  public showLoader: boolean = true;

  public apiName: string = "";

  public apiProperties: ApiProperties = null;

  public apiCallSilent: boolean = false;

  public apiCallIgnoreCache: boolean = false;

  public loadDataFromServer: boolean = true;

  public filter: string = ""; // Any hard coded filter that should be applied when loading data from server

  public expand: string = ""; // Any hard coded expand option that should be applied when loading data from server to get full objects instead of list, include children, etc.

  public primaryKey: string = "";

  public columns: TableColumnOptions[] = [];

  public actionButtonLeft1: ButtonItem = null;

  public actionButtonLeft2: ButtonItem = null;

  public actionButtonRight1: ButtonItem = null;

  public actionButtonRight2: ButtonItem = null;

  public rowActionButton: ButtonItem = null;


  /**
   * If the table has static filters available this pick list has those options where
   * display text property is the text to show and the value is the filter value.
   */
  public staticFilterPickList: m5core.PickListSelectionViewModel[] = [];
  public staticFilterLabel: string = "Filter";
  public staticFilterValue: string = "";
  public staticFilterQueryProperty: string = "";
  public staticFilterType: "ValueInApiUrl" | "ValueInExpand" | "ValueInFilter" | "" = "";


  public rememberLayout: boolean = true;

  public rememberSort: boolean = true;

  public rememberFilter: boolean = true;

  public rememberPaging: boolean = true;

}
