import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as moment from "moment";
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { SecurityPolicyItemStatus, ContactService } from 'projects/core-lib/src/lib/services/contact.service';

@Component({
  selector: 'ib-security-policy-password-compliance',
  templateUrl: './security-policy-password-compliance.component.html',
  styleUrls: ['./security-policy-password-compliance.component.css']
})
export class SecurityPolicyPasswordComplianceComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() policy: m5sec.SecurityPolicyViewModel = null;
  @Input() doubleEntry: boolean = false;
  @Input() login: string = "";
  @Input() password1: string = "";
  @Input() password2: string = "";
  @Input() contactName: string = "";
  @Input() firstName: string = "";
  @Input() lastName: string = "";
  @Input() importantDates: any[] = [];

  normalizedDates: moment.Moment[] = [];

  status: SecurityPolicyItemStatus[] = [];

  constructor(protected contactService: ContactService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.importantDates && this.importantDates) {
      this.normalizedDates = [];
      if (this.importantDates && this.importantDates.length > 0) {
        this.importantDates.forEach(date => {
          if (date) {
            this.normalizedDates.push(moment(date));
          }
        });
      }
    }
    // On any change we need to update our status since ... each keypress should update things
    // like password input attribute which will fire this on changes method which can then update.
    this.updateStatus(null);
  }

  ngAfterViewInit() {
    this.updateStatus(null);
  }

  updateStatus($event) {
    this.status = this.contactService.getSecurityPolicyStatus(this.policy, this.doubleEntry, this.login,
      this.password1, this.password2, this.contactName, this.firstName, this.lastName, this.normalizedDates);
  }

}
