import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  transform(datetime: Date | string, format: string): any {
    return Helper.formatDateTime(datetime, format);
  }

}
