<div *ngIf="mode==='list' && options.headerShow">
  <div class="float-left">
    <h3>{{options.headerTextListMode}}</h3>
  </div>
</div>
<div *ngIf="(mode==='download' || mode==='upload') && options.headerShow">
  <div class="float-left">
    <h3>{{options.headerTextDownloadUploadMode}}</h3>
  </div>
</div>
<div *ngIf="mode==='add' && options.headerShow">
  <div class="float-left">
    <h3>{{options.headerTextAddMode}}</h3>
  </div>
  <div *ngIf="options.actionButtonAddMode" class="float-right">
    <ib-action-button [button]="options.actionButtonAddMode" [data]="data" [cargo]="cargo"></ib-action-button>
  </div>
</div>
<div *ngIf="(mode==='edit' || mode==='view') && options.headerShow" class="header-wrapper">
  <div class="header-description">
    <div class="header-text">
      <h3>{{options.headerTextEditModePrefix}}{{objectDescriptionShort}}</h3>
    </div>
  </div>
  <div *ngIf="options.actionButtonEditMode && mode==='edit'" class="header-button-wrapper">
    <ib-button *ngIf="formStatus && formStatus.isValid && !formStatus.isPristine"
               name="Save Header Button" icon="save" label="Save" color="primary" buttonClasses="btn-save" [displayErrorMessages]="false" [working]="working"
               style="padding-right:5px;"
               (click)="fireSave($event)">
    </ib-button>
    <ib-action-button [button]="options.actionButtonEditMode" [data]="data" [cargo]="cargo"></ib-action-button>
  </div>
  <div *ngIf="options.actionButtonViewMode && mode==='view'" class="button">
    <ib-action-button [button]="options.actionButtonViewMode" [data]="data" [cargo]="cargo"></ib-action-button>
  </div>
</div>
<div class="clearfix"></div>
