<div class="{{containerClass}}" [style]="style">
  <ul class="nav nav-pills flex-column" cdkDropList [cdkDropListDisabled]="!dragDrop" (cdkDropListDropped)="drop($event)">
    <li *ngFor="let item of data; let i = index; trackBy: trackByFn" class="nav-item" cdkDrag>
      <div class="drop-placeholder" *cdkDragPlaceholder></div>
      <span class="nav-link clickable" [ngClass]="{ 'active': isActive(item) }" (click)="onSelect($event, item, i)">
        <ng-container *ngIf="propertyNameEnabled">
          <span class="float-left">
            <i class="far fa-check-square" [hidden]="!getEnabled(item)" (click)="toggleEnabled($event, item, i)"></i>
            <i class="far fa-square" [hidden]="getEnabled(item)" (click)="toggleEnabled($event, item, i)"></i>
            &nbsp;&nbsp;
          </span>
        </ng-container>
        <span class="float-left d-inline-block text-truncate" style="max-width:80%;" ngbTooltip="{{getTooltip(item)}}" container="body">
          <ib-icon icon="{{getIcon(item)}}" label="{{getLabel(item)}}"></ib-icon>
        </span>
        <span class="float-right" ngbTooltip="{{getStatusTooltip(item)}}" container="body">
          <ib-icon icon="{{getStatusIcon(item)}}"></ib-icon>
        </span>
        <span class="clearfix"></span>
      </span>
    </li>
  </ul>
</div>
