<span *ngIf="icon || label"
      class="badge badge-{{color}}">
  <ib-icon [icon]="icon"
           [label]="label"
           [labelClasses]="labelClasses"
           [labelStyles]="labelStyles"
           [labelFirst]="labelFirst"
           [tooltip]="tooltip"
           [tooltipPlacement]="tooltipPlacement"
           [size]="iconSize"
           [color]="iconColor">
  </ib-icon>
</span>
