import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiEndpointViewModel } from 'projects/core-lib/src/lib/api/ApiModels';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';

@Component({
  selector: 'app-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss']
})
export class EndpointsComponent implements OnInit {

  data: ApiEndpointViewModel[] = [];
  tableOptions: TableOptions = null;
  tableReloadCount: number = 0;

  constructor(
    protected appService: AppService,
    protected docsService: ApiDocsService,
    protected router: Router
  ) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "API Endpoints";
    this.loadData();
    this.tableOptions = this.getTableOptions();
    this.tableReloadCount++;
  }

  loadData() {
    this.data = this.docsService.validEndpoints;
  }

  getTableOptions(): TableOptions {

    const options = new TableOptions();
    options.theme = "striped";
    options.tableId = null;
    options.rowsPerPage = 10;
    options.apiName = null;
    options.apiProperties = null;
    options.loadDataFromServer = false;
    options.primaryKey = "documentationUrl";
    options.columns = [];
    options.columns.push(new TableColumnOptions("objectDescription", "Description"));
    options.columns.push(new TableColumnOptions("path", "Path"));
    options.columns.push(new TableColumnOptions("method", "Method"));
    options.columns.push(new TableColumnOptions("objectName", "Object Name"));

    return options;

  }

  onRowSelected($event: EventModelTyped<m.ApiEndpointViewModel>) {
    //console.error("row click", $event);
    this.router.navigate(["/endpoints" + $event.data.documentationUrl]);
  }

}
