import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { CredentialsComponent } from './credentials/credentials.component';
import { DataFormatsComponent } from './data-formats/data-formats.component';
import { DataModelOverviewComponent } from './data-model-overview/data-model-overview.component';
import { EndpointsComponent } from './endpoints/endpoints.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { NotesComponent } from './notes/notes.component';
import { PolicyApiLicenseComponent } from './policy-api-license/policy-api-license.component';
import { PolicyApiPolicyComponent } from './policy-api-policy/policy-api-policy.component';
import { PolicyTermsOfUseComponent } from './policy-terms-of-use/policy-terms-of-use.component';
import { RateLimitsComponent } from './rate-limits/rate-limits.component';
import { RequestFormatsListsComponent } from './request-formats-lists/request-formats-lists.component';
import { RequestFormatsComponent } from './request-formats/request-formats.component';
import { ResponseFormatsComponent } from './response-formats/response-formats.component';
import { ResultCodesComponent } from './result-codes/result-codes.component';
import { VersionsComponent } from './versions/versions.component';
import { ResponseBodyComponent } from './response-body/response-body.component';
import { ResponseHeadersComponent } from './response-headers/response-headers.component';
import { StandardResultCodesComponent } from './standard-result-codes/standard-result-codes.component';
import { DataModelListComponent } from './data-model-list/data-model-list.component';
import { DataModelObjectComponent } from './data-model-object/data-model-object.component';
import { DataModelPropertyTableComponent } from './data-model-property-table/data-model-property-table.component';
import { RequestFormatsMethodsComponent } from './request-formats-methods/request-formats-methods.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    CoreLibModule,
    CommonLibModule,
  ],
  declarations: [
    IntroductionComponent,
    CredentialsComponent,
    RequestFormatsComponent,
    RequestFormatsMethodsComponent,
    RequestFormatsListsComponent,
    ResponseFormatsComponent,
    DataFormatsComponent,
    ResultCodesComponent,
    VersionsComponent,
    RateLimitsComponent,
    NotesComponent,
    EndpointsComponent,
    PolicyTermsOfUseComponent,
    PolicyApiPolicyComponent,
    PolicyApiLicenseComponent,
    DataModelOverviewComponent,
    ResponseBodyComponent,
    ResponseHeadersComponent,
    StandardResultCodesComponent,
    DataModelListComponent,
    DataModelObjectComponent,
    DataModelPropertyTableComponent,
  ],
  exports: [
    DataModelPropertyTableComponent,
    ResponseBodyComponent,
    StandardResultCodesComponent,
  ]
})
export class InstructionsModule { }
