import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ib-peek-with-header',
  templateUrl: './peek-with-header.component.html',
  styleUrls: ['./peek-with-header.component.css']
})
export class PeekWithHeaderComponent implements OnInit {

  @Input() class: string = "";
  @Input() visible: boolean = false;
  @Input() clearFloats: boolean = true;
  @Input() buttonWrapperClass: string = "";
  @Input() buttonWrapperStyle: string = "";
  @Input() buttonClass: string = "";
  @Input() buttonStyle: string = "";

  constructor() { }

  ngOnInit() {
  }

}
