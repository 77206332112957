import { Component, OnInit, Input, Directive } from '@angular/core';

@Directive()
export abstract class CustomContentBaseClass implements OnInit {

  @Input() dataModelDocumentationParts: any = {};

  constructor() { }

  ngOnInit() {
  }

}
