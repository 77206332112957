<div [ngClass]="{'p-2': alerts && alerts.length > 0}"
     id="alert-wrapper">
  <ng-container *ngFor="let alert of alerts; let i = index">
    <div class="alert alert-{{alert.typeAsString.toLowerCase()}} alert-dismissible fade show"
         role="alert">
      <h4 class="alert-heading"
          *ngIf="alert.title">{{alert.title}}</h4>
      <p class="mb-0"
         [innerHtml]="alert.message | safe: 'html'"></p>
      <button *ngIf="alert.canClose"
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              (click)="close(alert.id)">
        <span aria-hidden="true">&times;</span>
      </button>
      <div *ngIf="alert.buttons && alert.buttons.length > 0">
        <ib-button *ngFor="let button of alert.buttons"
                   name="{{button.label}}"
                   icon="{{button.icon}}"
                   label="{{button.label}}"
                   tooltip="{{button.description}}"
                   size="{{button.size}}"
                   color="{{button.contextColor}}"
                   buttonClasses="mr-2"
                   (click)="button.action()">
        </ib-button>
      </div>
    </div>
  </ng-container>
</div>
