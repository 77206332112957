import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { UxService } from '../../services/ux.service';
import { InputKeyValuePairListComponent } from '../input-key-value-pair-list/input-key-value-pair-list.component';

@Component({
  selector: 'ib-object-viewer',
  templateUrl: './object-viewer.component.html',
  styleUrls: ['./object-viewer.component.css']
})
export class ObjectViewerComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() dataObject: any;
  @Input() dataString: string;
  @Input() format: string;
  @Input() expandDepth: number = 1;

  constructor(
    protected appService: AppService,
    protected uxService: UxService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (this.dataObject && !this.dataString) {
      this.dataString = JSON.stringify(this.dataObject, null, 4);
      this.format = "JSON";
    } else if (this.dataString && !this.dataObject) {
      if (Helper.isJson(this.dataString)) {
        try {
          this.dataObject = JSON.parse(this.dataString);
          this.format = "JSON";
        } catch (err) {
          this.format = "STRING";
        }
      } else {
        this.format = "STRING";
      }
    }
  }


}
