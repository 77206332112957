import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { EndpointComponent } from './endpoint/endpoint.component';
import { EndpointHeaderComponent } from './endpoint-header/endpoint-header.component';
import { EndpointDataModelComponent } from './endpoint-data-model/endpoint-data-model.component';
import { EndpointSampleComponent } from './endpoint-sample/endpoint-sample.component';
import { EndpointTestHeaderComponent } from './endpoint-test-header/endpoint-test-header.component';
import { InstructionsModule } from '../instructions/instructions.module';
import { CustomModule } from '../custom/custom.module';
import { ProductionWarningComponent } from './production-warning/production-warning.component';
import { EndpointTestFormListControlsComponent } from './endpoint-test-form-list-controls/endpoint-test-form-list-controls.component';
import { EndpointTestFormPathControlsComponent } from './endpoint-test-form-path-controls/endpoint-test-form-path-controls.component';
import { EndpointTestFormEditControlsComponent } from './endpoint-test-form-edit-controls/endpoint-test-form-edit-controls.component';
import { EndpointTestFormCustomControlsComponent } from './endpoint-test-form-custom-controls/endpoint-test-form-custom-controls.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    CoreLibModule,
    CommonLibModule,
    InstructionsModule,
    CustomModule
  ],
  declarations: [
    EndpointComponent,
    EndpointHeaderComponent,
    EndpointDataModelComponent,
    EndpointSampleComponent,
    EndpointTestHeaderComponent,
    ProductionWarningComponent,
    EndpointTestFormListControlsComponent,
    EndpointTestFormPathControlsComponent,
    EndpointTestFormEditControlsComponent,
    EndpointTestFormCustomControlsComponent,
  ],
})
export class EndpointModule { }
