<div>
  <div>
    <ng-template #star let-fill="fill">
      <span class="star" [class.full]="fill === 100">
        <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
      </span>
    </ng-template>
    <ngb-rating [rate]="rating" [max]="max" [starTemplate]="star" [readonly]="true" [resettable]="false"></ngb-rating>
  </div>
  <span *ngIf="includeText">
    {{rating | number : '1.0-2'}} out of {{max}} stars 
    <ng-container *ngIf="votes !== undefined">
      ({{votes}} total votes)
    </ng-container>
  </span>
</div>
