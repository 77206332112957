import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'newLines'
})
export class NewLinesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (Helper.isUndefinedOrNull(value)) {
      return "";
    }
    if (!Helper.isString(value)) {
      return value;
    }
    return value.replace(/\n/g, '<br/>');
  }

}
