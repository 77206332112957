import * as m from "./ngCoreModels";

// This is generated code... DO NOT modify this file.
	export enum Language {
		CSharp = 0,
		VisualBasic = 10,
		CPlusPlus = 20,
		JavaScriptOnServer = 30,
		JavaScript = 1100,
		TypeScript = 1101,
		Html = 1200,
		Css = 1300,
		Inherited = 2147483647
	}
	export enum AuthenticatedUserReasonHasRole {
		Unknown = 0,
		AssignedToContact = 1,
		AssignedToGroupContactBelongsTo = 2,
		AssignedToContactTypeAutomatically = 3
	}
	export enum WalletProxyTokenPropertyType {
		String = 0,
		KeyValuePair = 1
	}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class MetaDataModel {
		AddedDateTime: Date = null;
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		LastEditedDateTime: Date = null;
		LastEditedByContactId: number = null;
		LastEditedByContactName: string = "";
		MarkedForDeletionDateTime: Date = null;
		MarkedForDeletionByContactId: number = null;
		MarkedForDeletionByContactName: string = "";
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		IncludedDataObjectTypes: string[] = [];
		DeletedDataObjects: m.IDeletedDataObject[] = [];
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
		TimeZoneName: string = "";
		TimeZoneDescription: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class MetaDataLightModel {
		CurrentRowVersion: string = "";
		ObjectHashCode: string = "";
		DataSourceTableName: string = "";
		PartitionId: number = null;
		ReadOnly: boolean = false;
		Properties: any = null;
		TimeZoneName: string = "";
		TimeZoneDescription: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class DeletedDataObject {
		ObjectType: string = "";
		ObjectId: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ScriptViewModel {
		Name: string = "";
		Version: number = 0;
		Code: ScriptSourceViewModel[] = [];
		FullyQualifiedTypeName: string = "";
		Interface: string = "";
		InstanceTypedAs: string = "";
		EntryPoint: string = "";
		EntryPointParameters: any = null;
		PartialSourceCodeWrappers: ScriptSourceViewModel[] = [];
		Snippets: ScriptSourceViewModel[] = [];
		Language: Language = null;
		SupportedLanguages: Language[] = [];
		AllowMultipleLanguages: boolean = false;
		ReferencedAssemblyDefaultFolder: string = "";
		ReferencedAssemblies: string[] = [];
		CompiledCode: ScriptSourceViewModel[] = [];
		UseCache: boolean = false;
		CompilerOptions: any = null;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ScriptSourceViewModel {
		Group: string = "";
		Order: number = 0;
		Name: string = "";
		SourceCode: string = "";
		Partial: boolean = false;
		Language: Language = null;
		Usings: string[] = [];
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class EncryptionViewModel {
		Tag: string = "";
		Description: string = "";
		KeyType: string = "";
		KeyLength: number = 0;
		PublicKey: string = "";
		PrivateKey: string = "";
		Comments: string = "";
		AddedDateTime: Date = null;
		UtcOffset: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class EncryptionEchoEditViewModel {
		Tag: string = "";
		PlainText: string = "";
		EncryptedText: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LoginRequest {
		Login: string = "";
		Password: string = "";
		RememberMe: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserViewModel {
		ContactType: string = "";
		ContactId: number = null;
		ContactName: string = "";
		ContactFirstName: string = "";
		ContactLastName: string = "";
		ContactEmail: string = "";
		PreferredLanguage: string = "";
		ParentContactType: string = "";
		ParentContactId: number = null;
		ParentContactName: string = "";
		PartitionId: number = null;
		PartitionDescription: string = "";
		Login: string = "";
		Token: string = "";
		ApiKey: string = "";
		Settings: UserSettings = null;
		Roles: AuthenticatedUserRoleViewModel[] = [];
		Permissions: AuthenticatedUserPermissionViewModel[] = [];
		AuthenticationData: m.AuthenticationData = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UserSettings {
		AsOfUtc: Date = null;
		Menu: m.MenuItem[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserRoleViewModel {
		RoleId: number = null;
		Description: string = "";
		RoleRatio: number = 0;
		Reasons: AuthenticatedUserRoleReason[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserRoleReason {
		RoleReason: AuthenticatedUserReasonHasRole = null;
		RoleReasonDescription: string = "";
		RoleReasonContactId: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class AuthenticatedUserPermissionViewModel {
		AreaType: string = "";
		Area: string = "";
		Rights: string = "";
		Roles: AuthenticatedUserRoleViewModel[] = [];
		ContributingRoles: string = "";
		ImplicitAccess: boolean = false;
		Comments: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class LoginChangeRequest {
		ContactId: number = null;
		CurrentLogin: string = "";
		NewLogin: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PasswordChangeRequest {
		Login: string = "";
		CurrentPassword: string = "";
		NewPassword: string = "";
		NewPasswordConfirmed: string = "";
		ClientInformation: string = "";
		ForcedReset: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class PermissionEditViewModel {
		SecurityArea: string = "";
		Read: boolean = false;
		Add: boolean = false;
		Edit: boolean = false;
		Delete: boolean = false;
		Output: boolean = false;
		Execute: boolean = false;
		Full: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ApiAccessClientEditViewModel {
		ApiAccessClientId: number = null;
		ApiAccessId: number = null;
		Description: string = "";
		ClientIdentifierType: string = "";
		ClientIdentifier: string = "";
		ClientType: string = "";
		DebugUntilDateTime: Date = null;
		Flags: string = "";
		SuspendedOnDateTime: Date = null;
		SuspendedByContactId: number = null;
		SuspendedByContactName: string = "";
		SuspendedUntilDateTime: Date = null;
		SuspendedReason: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApiAccessEditViewModel {
		ApiAccessId: number = null;
		ApiKey: string = "";
		Description: string = "";
		ContactId: number = null;
		ContactName: string = "";
		RoleId: number = null;
		Anonymous: boolean = false;
		ShortTermMinutes: number = 0;
		EncryptionType: string = "";
		EncryptionKey: string = "";
		Flags: string = "";
		RateLimitPerMinute: number = 0;
		RateLimitPerHour: number = 0;
		SuspendedOnDateTime: Date = null;
		SuspendedByContactId: number = null;
		SuspendedByContactName: string = "";
		SuspendedUntilDateTime: Date = null;
		SuspendedReason: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		Clients: ApiAccessClientEditViewModel[] = [];
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationModel {
		PartitionId: number = null;
		PartitionDescription: string = "";
		Partition: PartitionEditViewModel = null;
		Domain: any = null;
		Branding: m.PartitionBrandModel = null;
		Tags: {[key: string]:  string[]} = null;
		AllowAnonymousAccess: boolean = false;
		AnonymousApiKey: string = "";
		Theme: string = "";
		LogoUrl: string = "";
		DomainName: string = "";
		SslEnabled: boolean = false;
		SslRequired: boolean = false;
		Services: ApplicationInformationServiceModel[] = [];
		Modules: string[] = [];
		Routes: ApplicationInformationRouteModel[] = [];
		Logos: ApplicationInformationLogoModel[] = [];
		Settings: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PartitionEditViewModel {
		PartitionId: number = null;
		Description: string = "";
		Brand: string = "";
		CustomerName: string = "";
		ContactName: string = "";
		ContactPhone: string = "";
		ContactEmail: string = "";
		ContactAlternateEmail: string = "";
		ProductionPartitionId: number = null;
		SandboxPartitionId: number = null;
		PrimaryShardId: number = null;
		ReportShardId: number = null;
		CacheShardId: number = null;
		LogShardId: number = null;
		ActivityShardId: number = null;
		ArchiveActivityShardId: number = null;
		ArchiveShardId: number = null;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationServiceModel {
		Name: string = "";
		Class: string = "";
		Type: string = "";
		Status: string = "";
		Modules: string[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationRouteModel {
		Description: string = "";
		RouteUrl: string = "";
		Template: string = "";
		TemplateUrl: string = "";
		RedirectToUrl: string = "";
		Controller: string = "";
		ControllerAs: string = "";
		Properties: any = null;
		AllowAnonymousAccess: boolean = false;
		HideStandardSiteElements: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ApplicationInformationLogoModel {
		Title: string = "";
		FriendlyName: string = "";
		AssetId: number = null;
		Url: string = "";
		FileType: string = "";
		ContentType: string = "";
		Height: number = 0;
		Width: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactAccessEditViewModel {
		ContactAccessId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		AccessIdentifierType: string = "";
		AccessIdentifier: string = "";
		AccessLocation: string = "";
		AccessLocationDetail: string = "";
		ActionLinkDateTime: Date = null;
		ActionLinkId: number = null;
		LastUsedToken: string = "";
		AccessCount: number = 0;
		LastAccessDateTime: Date = null;
		Suspect: boolean = false;
		BlockedAutomatically: boolean = false;
		BlockedManually: boolean = false;
		Whitelisted: boolean = false;
		NotificationDateTime: Date = null;
		NotificationEventId: number = null;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactExternalAuthenticationEditViewModel {
		ContactExternalAuthenticationId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		AuthenticationService: string = "";
		UserId: string = null;
		UserName: string = "";
		DisplayName: string = "";
		FirstName: string = "";
		LastName: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		Language: string = "";
		ProfileUrl: string = "";
		ImageUrl: string = "";
		ThumbnailUrl: string = "";
		AuthenticationCount: number = 0;
		LastAuthenticationDateTime: Date = null;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactRoleEditViewModel {
		ContactRoleId: number = null;
		ContactId: number = null;
		ContactName: string = "";
		RoleId: number = null;
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		RoleRatio: number = 0;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ContactEditViewModel {
		ContactId: number = null;
		ParentContactId: number = null;
		ParentContactName: string = "";
		ContactType: string = "";
		ExternalContactId: string = null;
		ContactName: string = "";
		FirstName: string = "";
		LastName: string = "";
		OtherName: string = "";
		Title: string = "";
		Address1: string = "";
		Address2: string = "";
		Address3: string = "";
		City: string = "";
		State: string = "";
		PostalCode: string = "";
		Country: string = "";
		Phone: string = "";
		Extension: string = "";
		AlternatePhone: string = "";
		Fax: string = "";
		Cellular: string = "";
		Email: string = "";
		AlternateEmail: string = "";
		WebSite: string = "";
		Login: string = "";
		Password: string = "";
		PasswordChangedDateTime: Date = null;
		RevokeAuthenticationDateTime: Date = null;
		LoginFailedAttempts: number = 0;
		LoginBlockedUntilDateTime: Date = null;
		TaxId: string = null;
		SystemTimeZoneId: string = null;
		Language: string = "";
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class MessageExchangeEditViewModel {
		MessageExchangeId: string = null;
		Description: string = "";
		Configuration: string = "";
		KeyType: string = "";
		KeyLength: number = 0;
		PublicKey: string = "";
		PrivateKey: string = "";
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentNotificationAddViewModel {
		PaymentNotificationId: string = null;
		NotificationDateTime: Date = null;
		NotificationType: string = "";
		NotificationCode: string = "";
		NotificationMessage: string = "";
		NotificationDetails: string = "";
		IsoCurrencyCode: string = "";
		Fee: number = 0;
		PaymentProviderId: string = null;
		PaymentMethodId: string = null;
		PaymentTransactionId: string = null;
		TransactionDateTime: Date = null;
		TransactionOrderReference: string = "";
		TransactionPaymentId: string = null;
		Properties: any = null;
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		TimeZone: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionTraceDataViewModel {
		PaymentTransactionId: string = null;
		RequestDateTime: Date = null;
		TransactionRequestData: string = "";
		TransactionResultData: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class WalletProxyModel {
		PostUrl: string = "";
		PostTimeoutSeconds: number = 0;
		PostHeaders: {[key: string]:  string} = null;
		PassThroughHeaders: string[] = [];
		WebExceptionTriggerEmail: boolean = false;
		TokenPropertyName: string = "";
		TokenKeyName: string = "";
		TokenType: WalletProxyTokenPropertyType = null;
		PaymentMethodTypeIdPropertyName: string = "";
		CreditCardTypePropertyName: string = "";
		DescriptionPropertyName: string = "";
		AccountNumberReferenceSubsetPropertyName: string = "";
		BankNamePropertyName: string = "";
		RoutingNumberPropertyName: string = "";
		AccountNumberPropertyName: string = "";
		ExpirationDatePropertyName: string = "";
		AccountIdPropertyName: string = "";
		Value001PropertyName: string = "";
		Value002PropertyName: string = "";
		Value003PropertyName: string = "";
		Value004PropertyName: string = "";
		Value005PropertyName: string = "";
		Value006PropertyName: string = "";
		Value007PropertyName: string = "";
		Value008PropertyName: string = "";
		Value009PropertyName: string = "";
		Value010PropertyName: string = "";
		BillingNamePropertyName: string = "";
		BillingFirstNamePropertyName: string = "";
		BillingLastNamePropertyName: string = "";
		BillingCompanyNamePropertyName: string = "";
		BillingAddress1PropertyName: string = "";
		BillingAddress2PropertyName: string = "";
		BillingAddress3PropertyName: string = "";
		BillingCityPropertyName: string = "";
		BillingStatePropertyName: string = "";
		BillingPostalCodePropertyName: string = "";
		BillingCountryPropertyName: string = "";
		BillingPhonePropertyName: string = "";
		BillingNameAlternatePropertyName: string = "";
		BillingFirstNameAlternatePropertyName: string = "";
		BillingLastNameAlternatePropertyName: string = "";
		BillingCompanyNameAlternatePropertyName: string = "";
		BillingAddress1AlternatePropertyName: string = "";
		BillingAddress2AlternatePropertyName: string = "";
		BillingAddress3AlternatePropertyName: string = "";
		BillingCityAlternatePropertyName: string = "";
		BillingStateAlternatePropertyName: string = "";
		BillingPostalCodeAlternatePropertyName: string = "";
		BillingCountryAlternatePropertyName: string = "";
		BillingPhoneAlternatePropertyName: string = "";
		VisaCheckoutCheckPropertyName: string = "";
		VisaCheckoutCheckValue: string = "";
		VisaCheckoutCallIdPropertyName: string = "";
		VisaCheckoutEncPaymentDataPropertyName: string = "";
		VisaCheckoutEncryptionKeyTagPropertyName: string = "";
		VisaCheckoutApiKeyPropertyName: string = "";
		VisaCheckoutDynamicKeyPropertyName: string = "";
		SampleRequest: any = null;
		SampleResponse: any = null;
		Comments: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentMethodEditViewModel {
		PaymentMethodId: string = null;
		Tokens: {[key: string]:  string} = null;
		Type: string = "";
		Status: string = "";
		NextValidationDateTime: Date = null;
		LastUsedDateTime: Date = null;
		TransactionCount: number = 0;
		PurchaseCount: number = 0;
		RetentionDays: number = 0;
		RemoveSensitiveInformation: boolean = false;
		SensitiveInformationRemoved: boolean = false;
		PaymentMethodTypeId: number = null;
		Description: string = "";
		AccountNumberReferenceSubset: string = "";
		BankName: string = "";
		RoutingNumber: string = "";
		AccountNumber: string = "";
		ExpirationDate: string = "";
		AccountId: string = null;
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		BillingName: string = "";
		BillingCompanyName: string = "";
		BillingAddress1: string = "";
		BillingAddress2: string = "";
		BillingAddress3: string = "";
		BillingCity: string = "";
		BillingState: string = "";
		BillingPostalCode: string = "";
		BillingCountry: string = "";
		BillingPhone: string = "";
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentProviderTriggerEditViewModel {
		PaymentProviderTriggerId: number = null;
		PaymentProviderId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		EventType: string = "";
		EventValue: string = "";
		EventValueLookup: string = "";
		EventFilter: string = "";
		PaymentTransactionIdLookup: string = "";
		TransactionPaymentIdLookup: string = "";
		WebhookId: number = null;
		RetryTransaction: boolean = false;
		ChangeAttributes: string = "";
		ChangeStatus: string = "";
		TransactionMap: string = "";
		NotificationTransaction: boolean = false;
		NotificationStatus: string = "";
		IsoCurrencyCode: string = "";
		NotificationFee: number = 0;
		NotificationReason: string = "";
		NotificationComments: string = "";
		NotificationMap: string = "";
		InitiateRefund: boolean = false;
		RecordRefund: boolean = false;
		RefundPercent: number = 0;
		RefundReason: string = "";
		RefundComments: string = "";
		RefundMap: string = "";
		TriggerOrder: number = 0;
		StopProcessingTriggers: boolean = false;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentProviderEditViewModel {
		PaymentProviderId: string = null;
		ExternalPaymentProviderId: string = null;
		Description: string = "";
		PaymentEngineName: string = "";
		PaymentProviderName: string = "";
		PaymentProviderPlatform: string = "";
		TokenizationProvider: string = "";
		MerchantNumber: string = "";
		MerchantAccountNumber: string = "";
		MerchantAlternateNumber: string = "";
		MerchantName: string = "";
		VendorId: string = null;
		SoftwareId: string = null;
		TerminalId: string = null;
		Enabled: boolean = false;
		ValidForTestTransactions: boolean = false;
		IsoCurrencyCodeList: string[] = [];
		PaymentProviderFlags: string[] = [];
		PaymentProviderSettings: any = null;
		PaymentProcessingFlags: string[] = [];
		PaymentProcessingSettings: any = null;
		ContactInformationCapture: string = "";
		PaymentModel: string = "";
		VerificationModel: string = "";
		AddressVerificationRequired: boolean = false;
		AddressVerificationFailList: string[] = [];
		CardIdVerificationRequired: boolean = false;
		CardIdVerificationFailList: string[] = [];
		Encoding: string = "";
		PaymentProcessingRegion: string = "";
		SystemTimeZoneId: string = null;
		EncryptionKeyVersion: string = "";
		Login1: string = "";
		Login2: string = "";
		Password1: string = "";
		Password2: string = "";
		Sandbox: boolean = false;
		CurrentUrl: string = "";
		ProductionUrl1: string = "";
		ProductionUrl2: string = "";
		SandboxUrl1: string = "";
		SandboxUrl2: string = "";
		TransactionDescription: string = "";
		OrderNumberType: string = "";
		PaymentMaximumAttempts: number = 0;
		PaymentRetryIntervalSeconds: number = 0;
		PaymentMonitorTimeoutSeconds: number = 0;
		PaymentProviderTimeoutReportedStatus: string = "";
		PaymentProviderErrorReportedStatus: string = "";
		RefundTransactionThresholdDays: number = 0;
		PaymentErrorThreshold: number = 0;
		PaymentErrorWindow: number = 0;
		PaymentErrorDuration: number = 0;
		UrlChangedDateTime: Date = null;
		DisabledUntilDateTime: Date = null;
		CustomTransactionProcessingLogic: ScriptViewModel = null;
		CustomNotificationProcessingLogic: ScriptViewModel = null;
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Properties: string = "";
		Comments: string = "";
		Triggers: PaymentProviderTriggerEditViewModel[] = [];
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionRefundAddViewModel {
		PaymentTransactionId: string = null;
		TransactionOrderReference: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		Reason: string = "";
		Comments: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		UtcOffset: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionSaleAddViewModel {
		PaymentProviderId: string = null;
		PaymentMethodId: string = null;
		TransactionOrderReference: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		Comments: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		UtcOffset: number = null;
	}


    // This is generated code... DO NOT modify this file.
	export class PaymentTransactionEditViewModel {
		PaymentTransactionId: string = null;
		RequestType: string = "";
		RequestStatus: string = "";
		ReportedStatus: string = "";
		RequestResultCode: number = 0;
		RequestByContactId: number = null;
		RequestByContactName: string = "";
		RequestDateTime: Date = null;
		QueueDateTime: Date = null;
		PaymentProviderId: string = null;
		PaymentMethodId: string = null;
		PaymentMethodType: string = "";
		PaymentMethodTypeId: number = null;
		AccountNumberReferenceSubset: string = "";
		IsoCurrencyCode: string = "";
		Amount: number = 0;
		RefundedAmount: number = 0;
		Priority: number = 0;
		RequestAttempt: number = 0;
		RunningApplicationServer: string = "";
		RunningDateTime: Date = null;
		TransactionDateTime: Date = null;
		TransactionContactReference: string = "";
		TransactionOrderReference: string = "";
		TransactionBatch: string = "";
		TransactionAuthorizationCode: string = "";
		TransactionResultCode: string = "";
		TransactionResultSubCode: string = "";
		TransactionResultMessage: string = "";
		TransactionRequestData: string = "";
		TransactionResultData: string = "";
		TransactionPaymentId: string = null;
		AddressVerificationResult: string = "";
		CardIdVerificationResult: string = "";
		PaymentBatchId: string = null;
		LinkedTransactionId: string = null;
		LinkedTransactionRequestDateTime: Date = null;
		Reason: string = "";
		Comments: string = "";
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleDetailEditViewModel {
		RoleDetailId: number = null;
		RoleId: number = null;
		SecurityAreaType: string = "";
		SecurityArea: string = "";
		AllowRead: RoleDetailPermissionEditViewModel = null;
		AllowAdd: RoleDetailPermissionEditViewModel = null;
		AllowEdit: RoleDetailPermissionEditViewModel = null;
		AllowDelete: RoleDetailPermissionEditViewModel = null;
		AllowOutput: RoleDetailPermissionEditViewModel = null;
		AllowExecute: RoleDetailPermissionEditViewModel = null;
		AllowFull: RoleDetailPermissionEditViewModel = null;
		DenyRead: RoleDetailPermissionEditViewModel = null;
		DenyAdd: RoleDetailPermissionEditViewModel = null;
		DenyEdit: RoleDetailPermissionEditViewModel = null;
		DenyDelete: RoleDetailPermissionEditViewModel = null;
		DenyOutput: RoleDetailPermissionEditViewModel = null;
		DenyExecute: RoleDetailPermissionEditViewModel = null;
		DenyFull: RoleDetailPermissionEditViewModel = null;
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleDetailPermissionEditViewModel {
		Enabled: boolean = false;
		ScopeNone: boolean = false;
		ScopeOwner: boolean = false;
		ScopeSupervisor: boolean = false;
		ScopeNexus: boolean = false;
		ScopeGroup: boolean = false;
		ScopeChildGroup: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class RoleEditViewModel {
		RoleId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		Anonymous: boolean = false;
		ValidContactTypes: string = "";
		AutomaticContactTypes: string = "";
		Properties: string = "";
		Comments: string = "";
		Details: RoleDetailEditViewModel[] = [];
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class SettingEditViewModel {
		SettingId: number = null;
		Category: string = "";
		Attribute: string = "";
		Value: string = "";
		Protected: boolean = false;
		Hidden: boolean = false;
		ReadOnly: boolean = false;
		Encrypted: boolean = false;
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessStatusDetailEditViewModel {
		ProcessStatusDetailId: number = null;
		ProcessStatusId: number = null;
		StartDateTime: Date = null;
		Description: string = "";
		Value: number = 0;
		Information: string = "";
		DisplayOrder: number = 0;
		DetailGroup: string = "";
		DetailGroupDisplayOrder: number = 0;
		ValueIsDenominator: boolean = false;
		LastUpdateDateTime: Date = null;
		TimerCount: number = 0;
		TimerTotalMilliseconds: number = 0;
		TimerMaximumMilliseconds: number = 0;
		TimerMinimumMilliseconds: number = 0;
		TimerAverageMilliseconds: number = 0;
		TimerMedianMilliseconds: number = 0;
		TimerPercentile90Milliseconds: number = 0;
		TimerStandardDeviationMilliseconds: number = 0;
		TimerEventsMilliseconds: string = "";
		TimerStatsAsOfDateTime: Date = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ProcessStatusEditViewModel {
		ProcessStatusId: number = null;
		Description: string = "";
		ProcessType: string = "";
		ProcessTypeId: number = null;
		ProcessTypeId2: string = "";
		SessionId: string = null;
		StatusIntervalMinutes: number = 0;
		ServerName: string = "";
		ServerProcessId: number = null;
		ProcessCount: number = 0;
		ProcessTotal: number = 0;
		ProcessTime: number = 0;
		StartDateTime: Date = null;
		LastUpdateDateTime: Date = null;
		DataProcessedDateTime: Date = null;
		FinishDateTime: Date = null;
		CancelNow: boolean = false;
		Status: string = "";
		ProcessMemoryUsedKb: number = 0;
		ServerMemoryFreeKb: number = 0;
		ServerMemoryTotalKb: number = 0;
		ErrorCount: number = 0;
		ErrorMessage: string = "";
		WarningCount: number = 0;
		WarningMessage: string = "";
		OmittedCount: number = 0;
		OmittedMessage: string = "";
		AlarmCount: number = 0;
		AlarmMessage: string = "";
		Feedback: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		TimerCount: number = 0;
		TimerTotalMilliseconds: number = 0;
		TimerMaximumMilliseconds: number = 0;
		TimerMinimumMilliseconds: number = 0;
		TimerAverageMilliseconds: number = 0;
		TimerMedianMilliseconds: number = 0;
		TimerPercentile90Milliseconds: number = 0;
		TimerStandardDeviationMilliseconds: number = 0;
		TimerEventsMilliseconds: string = "";
		TimerStatsAsOfDateTime: Date = null;
		Details: m.IProcessStatusDetailModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class WebhookEditViewModel {
		WebhookId: number = null;
		Description: string = "";
		Type: string = "";
		Enabled: boolean = false;
		Incoming: boolean = false;
		Url: string = "";
		EventTypes: string = "";
		ContentType: string = "";
		Secret: string = "";
		Login: string = "";
		Password: string = "";
		SignatureEncryptionKeyServerId: number = null;
		SignatureEncryptionKeyTag: string = "";
		Configuration: string = "";
		Headers: string = "";
		Format: string = "";
		FormatDataSource: string = "";
		ResponseFormat: string = "";
		ResponseFormatDataSource: string = "";
		SignatureData: string = "";
		SignatureAlgorithm: string = "";
		NotificationGroupId: number = null;
		CaseTemplateId: number = null;
		TriggerAssetId: number = null;
		RealTimeDelivery: boolean = false;
		RetryIntervalMinutes: number = 0;
		RetryLimitMinutes: number = 0;
		Trace: boolean = false;
		Properties: string = "";
		Comments: string = "";
		UtcOffset: number = null;
		MetaData: m.IMetaDataModel = null;
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}

