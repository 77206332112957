import { Component, OnInit } from '@angular/core';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { StandardResultCode } from 'projects/core-lib/src/lib/models/ngCoreModels';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  apiProp: m.ApiProperties = null;
  apiCall: m.ApiCall = null;

  data: m5sec.LoginRequest = new m5sec.LoginRequest();
  errorMessage: string = "";

  apiHostPickList: m5core.PickListSelectionViewModel[] = [];

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(
    public apiService: ApiService,
    protected appService: AppService,
    protected router: Router) { }

  ngOnInit() {
    this.appService.title = "Login";
    this.apiProp = ApiModuleSecurity.SecurityLogin();
    this.apiCall = ApiHelper.createApiCall(this.apiProp, m.ApiOperationType.Call);
    //this.apiCall.baseUrl = this.apiService.selectedHost.url;
    this.apiService.apiHosts.forEach(host => {
      this.apiHostPickList.push(<m5core.PickListSelectionViewModel>{ Value: host.url, DisplayText: `${host.description} (${host.url})` });
    });
  }

  onSelectApiHost($event) {
    //console.error($event);
    this.apiCall.baseUrl = $event.data;
    this.apiService.selectApiHost(this.apiCall.baseUrl, this.apiCall);
  }

  login($event) {
    if (this.data.Login) {
      this.loginWithPassword();
    } else {
      // Don't allow login using our partition 0 anon api key
      if (!this.apiCall.apiKey) {
        this.errorMessage = "A valid login or api key is required for accessing the api documentation.";
      } else if (Helper.equals(this.apiCall.apiKey, this.appService.appInfoOrDefault.AnonymousApiKey, true) || Helper.equals(this.apiCall.apiKey, this.appService.config.anonymousApiKey, true)) {
        this.errorMessage = "An api key used for anonymous access cannot be used for login.";
      } else {
        this.loginWithApiKey();
      }
    }
  }


  loginWithPassword() {

    this.errorMessage = "";
    // Force login using login and password no remembered tokens or api keys
    this.apiCall.token = null;
    this.apiCall.apiKey = null;

    this.apiService.execute(this.apiCall, this.data).subscribe((response: m.IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (response.Data.Success) {
        this.appService.userSet(response.Data.Data, this.data.RememberMe);
        this.appService.redirectToHome();
      } else {
        if (response.Data.ResultCode === StandardResultCode.PasswordExpired) {
          this.router.navigate(["/", "login", "password-expired"]);
        } else if (response.Data.ResultCode === StandardResultCode.AccessRequiresMultiFactorAuthentication ||
          response.Data.ResultCode === StandardResultCode.AccessRequiresMultiFactorAuthenticationForNewPublicIpAddress ||
          response.Data.ResultCode === StandardResultCode.AccessRequiresMultiFactorAuthenticationForNewDeviceId) {
          // Save a trimmed down user auth object with our result code to a session object for MFA components to utilize
          const pending: any = response.Data.Data;
          pending.LoginResultCode = response.Data.ResultCode;
          delete pending.Settings;
          delete pending.Groups;
          delete pending.Roles;
          delete pending.Permissions;
          Helper.sessionStorageSaveObject(Constants.LocalStorage.UserObjectPending, pending);
          const params: any = {};
          if (this.data.RememberMe) {
            params.rememberMe = this.data.RememberMe;
          }
          this.router.navigate(["/", "login", "mfa-step-1"], { queryParams: params });
        } else {
          // Erase the token when login fails
          this.appService.userClear();
          this.errorMessage = "Login failed.";
        }
      }
    });

  }


  protected loginWithApiKey() {

    this.errorMessage = "";
    let apiProp: m.ApiProperties = ApiModuleSecurity.SecurityAuthenticate();
    let apiCall = ApiHelper.createApiCall(apiProp, m.ApiOperationType.Get);
    // Force login using the api key... no remembered tokens
    this.appService.userClear();
    apiCall.token = null;
    apiCall.apiKey = this.apiCall.apiKey;

    this.apiService.execute(apiCall, null).subscribe((response: m.IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (response.Data.Success) {
        let apiKey = response.Data.Data.ApiKey;
        if (apiKey) {
          // API Key saved by our API service for future use so just save our user object w/o token saving
          this.appService.userSet(response.Data.Data, true, false);
          this.appService.redirectToHome();
        } else {
          // Erase the token when login fails
          this.appService.userClear();
          this.errorMessage = "Invalid API Key.";
        }
      } else {
        // Erase the token when login fails
        this.appService.userClear();
        this.errorMessage = "Invalid API Key.";
      }
    });

  }


}
