import { Component, OnInit } from '@angular/core';
import { CustomContentBaseClass } from '../custom-content-base-class';

@Component({
  selector: 'app-result-code-voucher',
  templateUrl: './result-code-voucher.component.html',
  styleUrls: ['./result-code-voucher.component.scss']
})
export class ResultCodeVoucherComponent extends CustomContentBaseClass implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
