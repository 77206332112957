<!-- We do not have test form properties defined so each part of our path properties that is mapped in our url variables should be listed as a control. -->
<ng-container *ngIf="!testFormProperties">
  <ng-container *ngFor="let col of pathModelPropertiesMappedInUrl;trackBy: trackByIndex">
    <ib-input-text *ngIf="!testFormPathModelPropertiesExcludedFromPayload"
                   name="{{col}}" label="{{col | formatText : 'SpaceDelimited'}}"
                   [(ngModel)]="requestData[col]"></ib-input-text>
    <ib-input-text *ngIf="testFormPathModelPropertiesExcludedFromPayload"
                   name="{{col}}" label="{{col | formatText : 'SpaceDelimited'}}"
                   [(ngModel)]="requestPathProperties[col]"></ib-input-text>
  </ng-container>
</ng-container>

<!-- We have test form properties defined so use that to build our test form. -->
<div *ngIf="testFormProperties && testFormProperties.length > 0">
  <app-endpoint-test-form-custom-controls [requestData]="requestData"
                                          [requestPathProperties]="requestPathProperties">
  </app-endpoint-test-form-custom-controls>
</div>

<!-- we asked for testFormAllowFreeFormQueryString -->
<ib-input-text *ngIf="testFormAllowFreeFormQueryString"
               name="Other Query String" label="Other Query String"
               placeholder="Other Query String Parameters"
               tooltip="{{testFormAllowFreeFormQueryStringTooltip}}"
               [(ngModel)]="requestData.TestFreeFormQueryStringParameters"></ib-input-text>

<div class="row mt-1 mb-2">
  <div class="{{Constants.Layout.percent33}}"></div>
  <div class="{{Constants.Layout.percent66}}">
    <ib-button *ngIf="testButtonText" name="Test Submit Button"
               icon="{{testButtonIcon}}" label="{{testButtonText}}" color="{{testButtonContextColor}}" [working]="working"
               (click)="onSubmit($event)">
    </ib-button>
    &nbsp;&nbsp;
    <ib-button *ngIf="testButton2Text && testButton2Action" name="Test Submit2 Button"
               icon="{{testButton2Icon}}" label="{{testButton2Text}}" color="{{testButton2ContextColor}}" [working]="working"
               (click)="onSubmit2($event)">
    </ib-button>
  </div>
</div>
