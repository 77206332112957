import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ib-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrls: ['./nav-side.component.css']
})
export class NavSideComponent implements OnInit, OnDestroy {

  public primeMenu: MenuItem[] = [];

  /**
  We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  only need to unsubscribe from this one shared subject in ngOnDestroy as it
  is passed as parameter to takeUntil.  Subclasses with their own ngOnDestroy
  need to call super.ngOnDestroy().
  */
  protected ngUnsubscribe = new Subject();

  constructor(public nav: NavService) {
  }

  ngOnInit() {
    //this.nav.primeMenuSubject.subscribe(menu => {
    this.nav.primeMenuSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(menu => {
      //console.error("nav side got new menu", menu);
      if (menu) {
        this.primeMenu = [...menu];
      }
    });
  }

  ngOnDestroy() {
    /**
    We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
    only need to unsubscribe from this one shared subject in ngOnDestroy as it
    is passed as parameter to takeUntil.  Subclasses with their own ngOnDestroy
    need to call super.ngOnDestroy().
    */
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
