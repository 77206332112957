import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { ButtonItem } from '../../ux-models';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDropdownComponent),
  multi: true
};

@Component({
  selector: 'ib-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputDropdownComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() editable: boolean = false;
  @Input() scrollHeight: string = "200px";

  @Input() headerIcon: string = "";
  @Input() headerText: string = "";
  @Input() headerActionButton: ButtonItem = null;
  @Input() footerActionButton: ButtonItem = null;

  //@Input() actionIcon: string = "";
  //@Input() actionTooltip: string = "";
  //@Output() onAction: EventEmitter<any> = new EventEmitter();

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
    // This ui component wants SelectItem[] array
    this.mapPickListToOptions = true;
    // Flag that we're using primeng for this input
    this.inputLibrary = "primeng";
  }

  ngOnInit() {
  }

  //ngOnChanges(changes: SimpleChanges) {
  //  super.ngOnChanges(changes);
  //  this.configure();
  //}

  //configure() {
  //  super.configure();
  //}

  showHeader() {
    if (this.headerIcon || this.headerText || this.headerActionButton) {
      return true;
    }
    return false;
  }

  //fireAction($event) {
  //  this.onAction.emit($event);
  //}

}

