<h4>Possible Result Codes</h4>

<p>
  When the response ResultCode is SubsystemSpecificError (100) the voucher response may have one of the following values for the ResponseCode:
</p>

<table class="table table-striped table-bordered table-sm table-hover">
  <thead>
    <tr>
      <th>ResponseCode</th>
      <th>Purchase Response Text</th>
      <th>Http Status Code</th>
      <th>Message</th>
      <th>Comments</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>0</td>
      <td>Valid</td>
      <td>OK (200)</td>
      <td>Ok - Voucher is valid.</td>
      <td></td>
    </tr>
    <tr>
      <td>710</td>
      <td>VoucherNotFound</td>
      <td>Not Found (404)</td>
      <td>The specified voucher was not found.</td>
      <td></td>
    </tr>
    <tr>
      <td>711</td>
      <td>VoucherOnHold</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher is on hold (not in distribution channel yet).</td>
      <td></td>
    </tr>
    <tr>
      <td>712</td>
      <td>VoucherExpired</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher is expired (hit expiration date from first use).</td>
      <td></td>
    </tr>
    <tr>
      <td>713</td>
      <td>VoucherLapsed</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher's shelf-life has lapsed (hit lapsed date from lot activation).</td>
      <td></td>
    </tr>
    <tr>
      <td>714</td>
      <td>VoucherSuspended</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher has been manually suspended.</td>
      <td></td>
    </tr>
    <tr>
      <td>715</td>
      <td>VourcherUsed</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher has been used and has no remaining uses left.</td>
      <td></td>
    </tr>
    <tr>
      <td>720</td>
      <td>VoucherStatusUnknown</td>
      <td>Internal Server Error (500)</td>
      <td>The specified voucher's status cannot be determined.</td>
      <td></td>
    </tr>
    <tr>
      <td>721</td>
      <td>VoucherNotValidIsoCurrencyCode</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher is configured for a different currency and is not valid for this transaction.</td>
      <td></td>
    </tr>
    <tr>
      <td>722</td>
      <td>VoucherNotValidVisibility</td>
      <td>Not Acceptable (406)</td>
      <td>The specified voucher has visibility rules that do not match this transaction and, therefore, cannot be used for this transaction.</td>
      <td></td>
    </tr>
    <tr>
      <td>730</td>
      <td>VoucherLotNotPendingActivation</td>
      <td>Precondition Failed (412)</td>
      <td>The voucher lot for the specified voucher is not ready for activation.</td>
      <td></td>
    </tr>
    <tr>
      <td>-1</td>
      <td>Unknown</td>
      <td>Internal Server Error (500)</td>
      <td>Unknown Error. </td>
      <td></td>
    </tr>
  </tbody>
</table>

<p>
  Under other scenarios these result codes may be found in the response ResultCode:
</p>

<app-standard-result-codes></app-standard-result-codes>
