<ib-input-wrapper [label]="label">
  <p-multiSelect [options]="licenses" optionLabel="ExternalAssetLicenseId" [(ngModel)]="selectedLicenses"
                 [style]="{width:'100%'}"
                 [readonly]="readonly"
                 [disabled]="disabled"
                 dropdownIcon="pi pi-caret-down"
                 [maxSelectedLabels]="10"
                 (onChange)="onChange($event)">
    <ng-template let-value pTemplate="selectedItems">
      <ng-container *ngFor="let val of value; let last = last;">
        {{val.ExternalAssetLicenseId}}<ng-container *ngIf="!last">,&nbsp;</ng-container>
      </ng-container>
      <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">&lt;None&gt;</span>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <ib-input-asset-license-select-display [license]="item.value"></ib-input-asset-license-select-display>
    </ng-template>
  </p-multiSelect>
</ib-input-wrapper>
