import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiEndpointViewModel } from 'projects/core-lib/src/lib/api/ApiModels';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';

@Component({
  selector: 'app-data-model-list',
  templateUrl: './data-model-list.component.html',
  styleUrls: ['./data-model-list.component.scss']
})
export class DataModelListComponent implements OnInit {

  listType: "category" | "table" | "view" | "all" = "all";
  loading: boolean = false;
  categoryFilter: string = "";
  data: m.DataModelList[] = [];
  tableOptions: TableOptions = null;
  tableReloadCount: number = 0;

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(
    protected appService: AppService,
    protected docsService: ApiDocsService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.listType = this.route.snapshot.data.listType;
    if (this.listType === "category") {
      this.appService.title = "Data Model Categories";
    } else if (this.listType === "table") {
      this.appService.title = "Data Model Tables";
    } else if (this.listType === "view") {
      this.appService.title = "Data Model Views";
    } else if (this.listType === "all") {
      this.appService.title = "Data Model Tables & Views";
    }
    this.tableOptions = this.getTableOptions();
    // This could be a drill down from category so check url for query parameter
    if (this.listType !== "category") {
      this.categoryFilter = Helper.getQueryStringParameter("category") || "";
    }
    // Now with possible category filter in place load the data
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.docsService.getDataModelList(this.listType).subscribe(list => {
      if (this.categoryFilter) {
        this.data = list.filter(x => Helper.equals(x.Category, this.categoryFilter, true));
      } else {
        this.data = list;
      }
      this.tableReloadCount++;
      this.loading = false;
    });
  }

  getTableOptions(): TableOptions {
    let options = new TableOptions();
    options.theme = "striped";
    options.tableId = null;
    options.rowsPerPage = 10;
    options.apiName = null;
    options.apiProperties = null;
    options.loadDataFromServer = false;
    options.primaryKey = "Name";
    options.sort = "Name";
    options.columns = [];
    options.columns.push(new TableColumnOptions("Name"));
    options.columns.push(new TableColumnOptions("Category"));
    options.columns.push(new TableColumnOptions("Type"));
    return options;
  }

  onRowSelected($event: EventModelTyped<m.DataModelList>) {
    //console.error("row click", $event);
    if (this.listType === "category") {
      this.router.navigate(["/", "overview", "data-model", "tables-and-views"], { queryParams: { category: $event.data.Name } });
    } else {
      this.router.navigate(["/", "overview", "data-model", "object", $event.data.Name]);
    }
  }

}
