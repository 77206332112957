import { Injectable, OnDestroy } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { Api } from '../api/Api';
import { ApiService } from '../api/api.service';
import { ApiHelper } from '../api/ApiHelper';
import { ApiCall, ApiOperationType, ApiProperties, IApiResponseWrapper, Query } from '../api/ApiModels';
import { Helper, Log } from '../helpers/helper';
import { Dictionary } from '../models/dictionary';
import { AppCacheService } from './app-cache.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ContactPreferenceService extends BaseService implements OnDestroy {

  /**
   * String/object dictionary of contact preferences cache.
   */
  protected cache: Dictionary<any> = new Dictionary<any>();

  protected apiProperties: ApiProperties;
  protected apiCallRead: ApiCall;
  protected apiCallUpdate: ApiCall;
  protected apiCallUpdateOne: ApiCall;

  constructor(
    protected apiService: ApiService) {
    super();
  }


  init(): void {
    if (this.apiCallUpdateOne) {
      // init already done
      return;
    }
    this.apiProperties = Api.ContactPreferences();
    // In case we call preferences prior to being authenticated we block login redirect.
    this.apiCallRead = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
    this.apiCallRead.silent = true;
    this.apiCallRead.redirectToLoginOnAuthenticationErrors = false;
    this.apiCallUpdate = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Edit);
    this.apiCallUpdate.silent = true;
    this.apiCallUpdate.redirectToLoginOnAuthenticationErrors = false;
    this.apiCallUpdateOne = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);
    this.apiCallUpdateOne.silent = true;
    this.apiCallUpdateOne.redirectToLoginOnAuthenticationErrors = false;
  }


  preferenceObjectGet(contactId: number, preferences: string, reportErrors: boolean = true): Observable<any> {

    this.init();
    const subject = new AsyncSubject<any>();

    if (!contactId || !preferences) {
      subject.next({});
      subject.complete();
      return subject.asObservable();
    }

    // If we have this in our cache then return from cache via observable
    if (this.cache.containsKey(preferences)) {
      subject.next(this.cache.item(preferences));
      subject.complete();
      return subject.asObservable();
    }

    // Get from server and then add to cache and return the value via observable
    this.apiService.execute(this.apiCallRead, { ContactId: contactId, Preferences: preferences }).subscribe((response: IApiResponseWrapper) => {
      if (response.Data.Success) {
        this.cache.add(preferences, response.Data.Data);
        subject.next(response.Data.Data);
        subject.complete();
      } else {
        if (reportErrors) {
          Log.errorMessage(response);
        } else {
          Log.warningMessage(response);
        }
      }
    });

    return subject.asObservable();

  }


  preferenceObjectSet(contactId: number, preferences: string, value: any, reportErrors: boolean = true): void {

    this.init();
    if (!contactId || !preferences) {
      return;
    }

    if (!value) {
      value = {};
    }

    // Update our cache with the new value
    this.cache.add(preferences, value);

    // Properties that we need for url parameter resolution
    value.ContactId = contactId;
    value.Preferences = preferences;

    // Update on server
    this.apiService.execute(this.apiCallUpdate, value).subscribe((response: IApiResponseWrapper) => {
      if (!response.Data.Success) {
        if (reportErrors) {
          Log.errorMessage(response);
        } else {
          Log.warningMessage(response);
        }
      }
    });

    return;

  }


  preferenceValueSet(contactId: number, preferences: string, setting: string, value: any, reportErrors: boolean = true): void {

    this.init();
    if (!contactId || !preferences || !setting) {
      return;
    }

    // Update our cache with the new value
    if (this.cache.containsKey(preferences)) {
      const obj = this.cache.item(preferences);
      obj[setting] = value;
      this.cache.add(preferences, obj);
    }

    // Update on server
    this.apiService.execute(this.apiCallUpdateOne, { ContactId: contactId, Preferences: preferences, Setting: setting, Value: value }).subscribe((response: IApiResponseWrapper) => {
      if (!response.Data.Success) {
        if (reportErrors) {
          Log.errorMessage(response);
        } else {
          Log.warningMessage(response);
        }
      }
    });

    return;

  }



  clearCache() {
    this.cache = new Dictionary<any>();
  }


}
