<div class="card bg-light">
  <div class="card-header"><h5>Request Header Values</h5></div>
  <div class="card-body">
    <ib-input-select name="Api Host" label="Api Host" [tight]="true" [(ngModel)]="apiCall.baseUrl"
                     [optionsIncludeNone]="false"
                     [optionsPickList]="apiHostPickList"
                     (change)="onSelectApiHost($event)"></ib-input-select>
    <app-production-warning [hidden]="!apiService.selectedHost.isProduction"></app-production-warning>
    <ng-container *ngIf="!apiEndpoint || !apiEndpoint.documentation || !apiEndpoint.documentation.testFormSuppressAuthenticationHeaders">
      <ib-input-text name="Authorization Header" label="Authorization Header" placeholder="Token" prefixText="Bearer "
                     [tight]="true"
                     [(ngModel)]="apiCall.token"></ib-input-text>
      <ib-input-text name="X-Auth-Key Header" label="X-Auth-Key Header" placeholder="API Key"
                     [tight]="true"
                     [(ngModel)]="apiCall.apiKey"></ib-input-text>
    </ng-container>

    <div class="clearfix"></div>
    <a class="float-right clickable" (click)="apiService.isHttpHeadersAdvancedCollapsed = !apiService.isHttpHeadersAdvancedCollapsed">
      Advanced
      <i *ngIf="apiService.isHttpHeadersAdvancedCollapsed" class="far fa-angle-double-down"></i>
      <i *ngIf="!apiService.isHttpHeadersAdvancedCollapsed" class="far fa-angle-double-up"></i>
    </a>
    <div class="clearfix"></div>

    <div [ngbCollapse]="apiService.isHttpHeadersAdvancedCollapsed">

      <ng-container *ngIf="!apiEndpoint || !apiEndpoint.documentation || !apiEndpoint.documentation.testFormSuppressAuthenticationHeaders">
        <ib-input-text name="X-Auth-External-Token Header" label="X-Auth-External-Token Header" placeholder="Partner Token" [tight]="true"
                       [(ngModel)]="apiCall.partnerToken"></ib-input-text>
      </ng-container>

      <ib-input-text name="X-Response-Properties Header" label="X-Response-Properties Header" placeholder="Response Properties CSV" [tight]="true"
                     [(ngModel)]="apiCall.responseProperties"></ib-input-text>

      <ng-container *ngIf="!apiEndpoint || !apiEndpoint.documentation || !apiEndpoint.documentation.testFormSuppressEncryptionHeaders">
        <ib-input-text *ngIf='httpMethod==="Post" || httpMethod==="Put" || httpMethod==="Merge"'
                       name="X-Encryption-Key-Tag Header" label="X-Encryption-Key-Tag Header" placeholder="Encryption Key Tag" [tight]="true"
                       [(ngModel)]="apiCall.encryptionKeyTag"></ib-input-text>
        <ib-input-text *ngIf='httpMethod==="Post" || httpMethod==="Put" || httpMethod==="Merge"'
                       name="X-Encrypted-Properties Header" label="X-Encrypted-Properties Header" placeholder="Encrypted Properties CSV" [tight]="true"
                       [(ngModel)]="apiCall.encryptedProperties"></ib-input-text>
      </ng-container>

      <ib-input-checkboxes-from-pick-list name="X-Meta-Data Header" label="X-Meta-Data Header"
                                          [inline]="true"
                                          [optionsPickList]="metaDataOptionsPickList"
                                          [tight]="true"
                                          [data]="apiCall.meta"
                                          (change)="onMetaDataChange($event)"></ib-input-checkboxes-from-pick-list>
      <!--<ib-input-checkbox name="X-Meta-Data Header" label="X-Meta-Data Header (Include meta-data information in response when available)"
                         [tight]="true"
                         [(ngModel)]="apiCall.meta"></ib-input-checkbox>-->
      <ib-input-checkbox name="X-Trace Header" label="X-Trace Header (Include trace information in response)"
                         [tight]="true"
                         [(ngModel)]="apiCall.trace"></ib-input-checkbox>
      <ib-input-checkbox name="X-HTTP-Response-Override Header" label="X-HTTP-Response-Override Header (All responses report as 200 OK)"
                         [tight]="true"
                         [(ngModel)]="apiCall.httpResponseOverride"></ib-input-checkbox>
      <ib-input-checkbox *ngIf='httpMethod==="Put" || httpMethod==="Merge" || httpMethod==="Patch" || httpMethod==="Delete"'
                         name="X-HTTP-Method-Override Header" label="X-HTTP-Method-Override Header (Support PUT, PATCH, MERGE, DELETE via HTTP POST)"
                         [tight]="true"
                         [(ngModel)]="apiCall.httpMethodOverride"></ib-input-checkbox>

    </div>
  </div>
  <app-production-warning class="ml-4 mr-4" [hidden]="!apiService.selectedHost.isProduction"></app-production-warning>
</div>
