import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SystemService extends BaseService {

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router) {

    super();

  }



  getAuditTrailCustomizationScript(tableName: string = ""): m5core.ScriptViewModel {

    const className: string = `AuditTrail_${tableName}_${Helper.formatDateTime(new Date(), "YYYY_MM_DD")}`;
    const script = this.getDefaultScript(className, `AuditTrail_${tableName}`);

    script.FullyQualifiedTypeName = `IB.Data.Service.Audit.${className}`;
    script.Interface = `IAuditTrailCustomization<TEntity>`;

    let source: string =
      `namespace IB.Data.Service.Audit
{

	public class #ClassNamePlaceHolder#<TEntity> : IAuditTrailCustomization<TEntity> where TEntity : class , new()
	{

        public Func<DatabaseInfo , AuditTrailModel , TEntity , TEntity , Result<AuditTrailModel>> AuditTrailCustomization()
        {
            return #ClassNamePlaceHolder#<TEntity>.AuditTrailCustomizationLogic;
        }

        public static Result<AuditTrailModel> AuditTrailCustomizationLogic( DatabaseInfo dataInfo , AuditTrailModel model , TEntity oldData , TEntity newData )
        {

            Result<AuditTrailModel> result = new Result<AuditTrailModel>( StandardResultCode.Success );
            result.OperationType = OperationType.Helper;
            result.Value = model;

            // oldData will be null on insert, newData will be null on delete
            // If desired we can map these entities to models for more user friendly access to complex properties that get morphed with model mapping.

            // AuditTrailModel.AuditInformation is a string that contains JSON that looks similar to this:
            /*
            {
                "Action":  "Insert | Update | Delete",
                "ActionMessage":  "Assigned to Fred.",
                "Old": { "DateOfBirth":, "1972-04-15 00:00:00", "Age": "41" } ,
                "New": { "DateOfBirth":, "1971-04-15 00:00:00", "Age": "42" } ,
                "Messages": [
	                "Did one certain thing." ,
	                "Did another thing as well."
                ]
            }
            */

            // Parse audit information into an object
            AuditInformationDynamic info = AuditTrailService.AuditInformationParser( model.AuditInformation );

            // Set this bool to true if we change anything about our audit information
            bool auditInfoChanged = false;

            // In some cases instead of having a certain property listed in Old/New collection we may
            // simply want it replaced with a message like this example:
            /*
            if ( model.TransactionType.TryEquals( "U" , true ) )
            {
                string key = "SubjectMatter";
                if ( info.Old.ContainsKey( key ) || info.New.ContainsKey( key ) )
                {
                    auditInfoChanged = true;
                    info.Old.Remove( key );
                    info.New.Remove( key );
                    info.Messages.Add( "Subject matter changed." );
                }
                key = "Notes";
                if ( info.Old.ContainsKey( key ) || info.New.ContainsKey( key ) )
                {
                    auditInfoChanged = true;
                    info.Old.Remove( key );
                    info.New.Remove( key );
                    // Changes to notes logged separately
                }
            }
            */

            // If we modified any audit information then post that back
            if ( auditInfoChanged )
            {
                result.Value.AuditInformation = info.ToJson();
            }

            return result;

        }

    }

}
`;

    source = Helper.replaceAll(source, "#ClassNamePlaceHolder#", className);
    script.Code[0].SourceCode = source;

    return script;

  }


  getTriggerScript(tableName: string = ""): m5core.ScriptViewModel {

    const triggerName: string = `${tableName}_${Helper.formatDateTime(new Date(), "YYYY_MM_DD")}_${Helper.createBase36Guid(5)}`;
    const className: string = `Triggers_${triggerName}`;
    const script = this.getDefaultScript(className, triggerName);

    script.FullyQualifiedTypeName = `IB.Data.Service.Triggers.${className}`;
    script.Interface = `ITriggerDeclaration<TModel,TEntity>`;

    const wrapperSource: string =
      `namespace IB.Data.Service.Triggers
{

	public class #ClassNamePlaceHolder#<TModel,TEntity> : ITriggerDeclaration<TModel,TEntity>
        where TModel : class , new()
        where TEntity : class , new()
	{

        // This method returns the list of triggers to be used.
        // This is appended to any existing triggers provided the trigger id values are unique.
        public List<Trigger<TModel , TEntity>> Triggers()
        {
            var triggers = new List<Trigger<TModel , TEntity>>();
            // Each trigger that is declared is added to our list here
            triggers.Add( this.SampleTrigger1() );
            return triggers;
        }

        // When there are a lot of triggers registering each trigger as its own partial code may enhance readability.
        // All partials that share the same 'Group' setting with this wrapper are combined and injected at the marker.

        /*INJECT-CODE-HERE*/

    }

}
`;

    const wrapper = this.getDefaultScriptSource("Triggers");
    wrapper.Group = "Triggers";
    wrapper.SourceCode = Helper.replaceAll(wrapperSource, "#ClassNamePlaceHolder#", className);
    script.PartialSourceCodeWrappers.push(wrapper);

    let triggerSource: string =
      `public Trigger<TModel , TEntity> SampleTrigger1()
{

    // This is a sample trigger only.  There is already a built-in trigger for checking permissions and this is only for example purposes.

    var trigger = new Trigger<TModel , TEntity>();

    // The trigger id must be unique among all triggers for a table.  To avoid conflict with build-in triggers prefix the trigger id with 'Custom'.
    trigger.Id = "Custom_#TriggerNamePlaceHolder#_PermissionCheck";
    trigger.Description = "Check Permissions";
    // Set IsAsync to true if the trigger can run async.
    trigger.IsAsync = false;
    // Set IsForgettable to true if the trigger is running async and you do not care about the results.
    trigger.IsForgettable = false;
    // Triggers are executed in priority order with the lowest priority going first.
    // Depending on what happens when a trigger fails other triggers may never be executed.
    trigger.Priority = 100;
    // Define what events this trigger will execute for.  Multiple events can be combined with |.
    // Possible values include:
    // ReadBefore, ReadAfterSuccess, ReadAfterFail
    // AddBefore, AddAfterSuccess, AddAfterFail
    // EditBefore, EditAfterSuccess, EditAfterFail
    // DeleteBefore, DeleteAfterSuccess, DeleteAfterFail
    // DeleteSoftBefore, DeleteSoftAfterSuccess, DeleteSoftAfterFail
    // DeleteSoftUndoBefore, DeleteSoftUndoAfterSuccess, DeleteSoftUndoAfterFail
    // DeleteHardBefore, DeleteHardAfterSuccess, DeleteHardAfterFail
    // WriteBefore, WriteAfterSuccess, WriteAfterFail
    // AnyBefore, AnyAfterSuccess, AnyAfterFail
    // For triggers assigned to explicit event objects (e.g. DataTableSupportModel.Triggers[*].TriggerOnAddSuccess.Triggers) this can
    // be skipped since it is known from the context (e.g. TriggerOnAddSuccess) but for shared triggers (e.g. DataTableSupportModel.Triggers[*].Triggers)
    // the event(s) the trigger applies to must be set here.
    trigger.Event = Trigger.TriggerEvent.AnyBefore;
    // Define what should happen when a trigger fails.  Multiple rules can be combined with |.
    // Possible values include:
    // Ignore - Ignore the trigger failure
    // LogWarning - Log a warning about the trigger failure
    // LogError - Log an error about the trigger failure
    // LogFatalError - Log a fatal error about the trigger failure
    // TriggeringEventResultIncludeError - Update the trigger event's result object to include the error
    // TriggeringEventResultIsPartialSuccess - Demote the trigger event's result code from Success to PartialSuccess
    // TriggeringEventResultIsFailed - Demote the trigger event's result code to failure reported by the trigger
    // TriggeringEventRollbackRequest - Request that the triggering event rollback it's operation (if possible and supported)
    // AbortRemainingTriggers - Abort any remaining triggers
    // The routine that executes the trigger handles all of the OnFail actions
    trigger.OnFail = Trigger.OnTriggerFail.AbortRemainingTriggers | Trigger.OnTriggerFail.TriggeringEventResultIsFailed;

    // The trigger action is the meat of the trigger.  It accepts 5 parameters and returns a result object.  The parameters are:
    // 1. A data access layer object that is the context the trigger is executed under.
    // 2. The triggering event enum.  This can be used to execute different logic for different event types.  e.g. triggerEvent.HasFlag( Trigger.TriggerEvent.AddAfterSuccess )
    // 3. The before entity object.  For add and read event types this is null.
    // 4. The after entity object.  For delete event types this is null.
    // 5. The triggering event's operation result object.
    // The trigger action returns a Result<object> object which indicates if the trigger action was successful or not.
    // If the trigger result indicates a failure the routine that fired the trigger will handle the OnFail actions.
    // Assigning a meaningful Id to the result object and using stopwatches can provide insight when there are performance or other unexpected problems.
    trigger.TriggerAction = ( DataAccess<TModel , TEntity> dal , Trigger.TriggerEvent triggerEvent , TEntity before , TEntity after , Result<TEntity> operationResult ) =>
    {
        Result<object> result = new Result<object>( StandardResultCode.Success );
        result.OperationType = OperationType.Trigger;
        result.Id = $"PermissionCheck-{triggerEvent}-{dal?.EntityMetaData?.EntityName}";
        Permission permission = Permission.None;
        try
        {
            permission = triggerEvent.ToPermission();
            result.Id = $"PermissionCheck-{permission}-{dal?.EntityMetaData?.EntityName}";
            result.Stopwatches.Start( "PermissionCheck" , Stopwatches.Timer.TimerType.Trigger );
            Result permissionResult = dal.HasPermission( permission );
            result.PostResultIfFailure( permissionResult );
            result.Stopwatches.Stop( "PermissionCheck" , Stopwatches.Timer.TimerType.Trigger );
        }
        catch ( Exception ex )
        {
            result.PostResult( ex , $"Checking permissions for {permission}" );
        }
        return result;
    };

    return trigger;

}
`;

    triggerSource = Helper.replaceAll(triggerSource, "#TriggerNamePlaceHolder#", triggerName);
    script.Code[0].SourceCode = triggerSource;
    script.Code[0].Partial = true;
    script.Code[0].Group = "Triggers";

    return script;

  }


  getDefaultScript(scriptName: string = "", sourceCodeName: string = ""): m5core.ScriptViewModel {

    const script = new m5core.ScriptViewModel();
    script.Name = scriptName || "";
    script.Enabled = true;
    script.Version = 1;
    script.Language = m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp]; // Enum value as string
    script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp]); // Enum value as string
    script.UseCache = true;
    script.ReferencedAssemblies.push("IB.Core.dll");
    script.ReferencedAssemblies.push("IB.Data.Entity.dll");
    script.ReferencedAssemblies.push("IB.Data.Model.dll");
    script.ReferencedAssemblies.push("IB.Data.Service.dll");

    const code = this.getDefaultScriptSource(sourceCodeName);
    script.Code.push(code);

    return script;

  }


  getDefaultScriptSource(sourceCodeName: string = ""): m5core.ScriptSourceViewModel {

    const code = new m5core.ScriptSourceViewModel();
    code.Name = sourceCodeName || "";
    code.Enabled = true;
    code.Order = 100;
    code.SourceCode = "";
    code.Language = m5core.ScriptLanguage[m5core.ScriptLanguage.Inherited]; // Enum value as string

    code.Usings.push("using System;");
    code.Usings.push("using System.Collections.Generic;");
    code.Usings.push("using System.Dynamic;");
    code.Usings.push("using System.IO;");
    code.Usings.push("using System.Linq;");
    code.Usings.push("using System.Text;");
    code.Usings.push("using System.Threading;");
    code.Usings.push("using System.Threading.Tasks;");
    //code.Usings.push("using System.Reflection;");
    //code.Usings.push("using System.Runtime.CompilerServices;");
    //code.Usings.push("using System.Xml;");
    code.Usings.push("using IB.Core;");
    code.Usings.push("using IB.Data.Entity;");
    code.Usings.push("using IB.Data.Model;");
    code.Usings.push("using IB.Data.Service;");
    code.Usings.push("using IB.Data.Service.Repository;");
    code.Usings.push("using IB.Data;");

    return code;

  }


  getDefaultSensitiveInformationHandlers(tableName: string): m5.DataTableSupportSensitiveInformationHandlerViewModel[] {

    const handlers: m5.DataTableSupportSensitiveInformationHandlerViewModel[] = [];

    if (tableName === "CONTACT") {
      const contactName = this.getDefaultSensitiveInformationHandlerModel("Name");
      contactName.PropertyName = "CONTACT_NAME";
      handlers.push(contactName);
      const firstName = this.getDefaultSensitiveInformationHandlerModel("Name");
      firstName.PropertyName = "FIRST_NAME";
      handlers.push(firstName);
      const lastName = this.getDefaultSensitiveInformationHandlerModel("Name");
      lastName.PropertyName = "LAST_NAME";
      handlers.push(lastName);
      const middleName = this.getDefaultSensitiveInformationHandlerModel("Name");
      middleName.PropertyName = "MIDDLE_NAME";
      handlers.push(middleName);
      const otherName = this.getDefaultSensitiveInformationHandlerModel("Name");
      otherName.PropertyName = "OTHER_NAME";
      handlers.push(otherName);
      const address1 = this.getDefaultSensitiveInformationHandlerModel("Address");
      address1.PropertyName = "ADDRESS1";
      handlers.push(address1);
      const address2 = this.getDefaultSensitiveInformationHandlerModel("Address");
      address2.PropertyName = "ADDRESS2";
      handlers.push(address2);
      const address3 = this.getDefaultSensitiveInformationHandlerModel("Address");
      address3.PropertyName = "ADDRESS3";
      handlers.push(address3);
      const phone = this.getDefaultSensitiveInformationHandlerModel("Phone");
      phone.PropertyName = "PHONE";
      handlers.push(phone);
      const altPhone = this.getDefaultSensitiveInformationHandlerModel("Phone");
      altPhone.PropertyName = "ALTERNATE_PHONE";
      handlers.push(altPhone);
      const fax = this.getDefaultSensitiveInformationHandlerModel("Phone");
      fax.PropertyName = "FAX";
      handlers.push(fax);
      const homePhone = this.getDefaultSensitiveInformationHandlerModel("Phone");
      homePhone.PropertyName = "HOME_PHONE";
      handlers.push(homePhone);
      const cellular = this.getDefaultSensitiveInformationHandlerModel("Phone");
      cellular.PropertyName = "CELLULAR";
      handlers.push(cellular);
      const pager = this.getDefaultSensitiveInformationHandlerModel("Phone");
      pager.PropertyName = "PAGER";
      handlers.push(pager);
      const voiceMail = this.getDefaultSensitiveInformationHandlerModel("Phone");
      voiceMail.PropertyName = "VOICE_MAIL";
      handlers.push(voiceMail);
      const extension = this.getDefaultSensitiveInformationHandlerModel("Phone");
      extension.PropertyName = "EXTENSION";
      handlers.push(extension);
      const email = this.getDefaultSensitiveInformationHandlerModel("Email", m5.SensitiveInformationMaskType.Email, m5.SensitiveInformationSanitizeType.Email);
      email.PropertyName = "EMAIL";
      handlers.push(email);
      const altEmail = this.getDefaultSensitiveInformationHandlerModel("Email", m5.SensitiveInformationMaskType.Email, m5.SensitiveInformationSanitizeType.Email);
      altEmail.PropertyName = "ALTERNATE_EMAIL";
      handlers.push(altEmail);
      const imAddress = this.getDefaultSensitiveInformationHandlerModel("Other");
      imAddress.PropertyName = "IM_ADDRESS";
      handlers.push(imAddress);
      const altImAddress = this.getDefaultSensitiveInformationHandlerModel("Other");
      altImAddress.PropertyName = "ALTERNATE_IM_ADDRESS";
      handlers.push(altImAddress);
      const webSite = this.getDefaultSensitiveInformationHandlerModel("Other");
      webSite.PropertyName = "WEB_SITE";
      handlers.push(webSite);
      const ftpSite = this.getDefaultSensitiveInformationHandlerModel("Other");
      ftpSite.PropertyName = "FTP_SITE";
      handlers.push(ftpSite);
      const taxId = this.getDefaultSensitiveInformationHandlerModel("Tax Id");
      taxId.PropertyName = "TAX_ID";
      handlers.push(taxId);
      const referredBy = this.getDefaultSensitiveInformationHandlerModel("Name");
      referredBy.PropertyName = "REFERRED_BY";
      handlers.push(referredBy);
      const login = this.getDefaultSensitiveInformationHandlerModel("Security Information");
      login.PropertyName = "LOGIN";
      handlers.push(login);
      const salutation = this.getDefaultSensitiveInformationHandlerModel("Name");
      salutation.PropertyName = "SALUTATION";
      handlers.push(salutation);
      const assistant = this.getDefaultSensitiveInformationHandlerModel("Name");
      assistant.PropertyName = "ASSISTANT_NAME";
      handlers.push(assistant);
      const manager = this.getDefaultSensitiveInformationHandlerModel("Name");
      manager.PropertyName = "MANAGER_NAME";
      handlers.push(manager);
      const spouse = this.getDefaultSensitiveInformationHandlerModel("Name");
      spouse.PropertyName = "SPOUSE_NAME";
      handlers.push(spouse);
      const children = this.getDefaultSensitiveInformationHandlerModel("Name");
      children.PropertyName = "CHILDREN_NAMES";
      handlers.push(children);
      const birth = this.getDefaultSensitiveInformationHandlerModel("Other");
      birth.PropertyName = "BIRTH_DATE";
      handlers.push(birth);
      const anniversary = this.getDefaultSensitiveInformationHandlerModel("Other");
      anniversary.PropertyName = "ANNIVERSARY_DATE";
      handlers.push(anniversary);
    }

    return handlers;

  }


  getDefaultSensitiveInformationHandlerModel(
    category: string = "",
    maskType: m5.SensitiveInformationMaskType = m5.SensitiveInformationMaskType.Default,
    sanitizeType: m5.SensitiveInformationSanitizeType = m5.SensitiveInformationSanitizeType.Default): m5.DataTableSupportSensitiveInformationHandlerViewModel {

    const model = new m5.DataTableSupportSensitiveInformationHandlerViewModel();
    model.Category = category;
    model.MaskType = m5.SensitiveInformationMaskType[maskType];
    model.MaskCharactersToShowPrefix = 1;
    model.MaskCharactersToShowSuffix = 1;
    model.MaskRemovalRequestAudited = true;
    model.SanitizeType = m5.SensitiveInformationSanitizeType[sanitizeType];
    model.SanitizeCharactersToKeepPrefix = 1;
    model.SanitizeCharactersToKeepSuffix = 1;
    model.SanitizeRequestAudited = true;

    return model;

  }


  getProcessTemplateStepActionProperties(stepActionType: string): m5.ProcessTemplateStepActionPropertiesEditViewModel<any> {

    const model = new m5.ProcessTemplateStepActionPropertiesEditViewModel<any>();
    model.StepActionType = stepActionType;
    model.Version = 1;
    model.Properties = {};
    model.Attributes = {};

    if (Helper.equals(stepActionType, "Process", true)) {
      model.Properties = new m5.ProcessTemplateStepActionChildProcessPropertiesModel();
    } else if (Helper.equals(stepActionType, "Case", true)) {
      model.Properties = new m5.ProcessTemplateStepActionCasePropertiesEditViewModel();
      model.Properties.CaseSuccessOutcome = "S";
      model.Properties.CaseFailureOutcome = "F";
      model.Properties.CaseIndeterminateOutcome = "S";
    } else if (Helper.equals(stepActionType, "Script", true)) {
      model.Properties = new m5.ProcessTemplateStepActionScriptPropertiesEditViewModel();
      model.Properties.ScriptSuccessResultCodes = [0];
      model.Properties.ScriptWaitingResultCodes = [52];
    } else if (Helper.equals(stepActionType, "RunExecutable", true) || Helper.equals(stepActionType, "RunPowerShell", true)) {
      model.Properties = new m5.ProcessTemplateStepActionRunExecutablePropertiesModel();
      model.Properties.WindowStyle = "Hidden";
      model.Properties.MaximumRunMinutes = 0;
      model.Properties.ShellExecute = true;
      model.Properties.WaitForExit = true;
      model.Properties.TreatErrorOutputAsFailure = false;
      model.Properties.ExecutableSuccessResultCodes = [0];
    } else if (Helper.equals(stepActionType, "Job", true)) {
      model.Properties = new m5.ProcessTemplateStepActionJobPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Plugin", true)) {
      model.Properties = new m5.ProcessTemplateStepActionPluginPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Notification", true)) {
      model.Properties = new m5.ProcessTemplateStepActionNotificationPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Step", true)) {
      model.Properties = new m5.ProcessTemplateStepActionStepPropertiesEditViewModel();
    } else if (Helper.equals(stepActionType, "Sql", true)) {
      model.Properties = new m5.ProcessTemplateStepActionSqlPropertiesEditViewModel();
      model.Properties.SqlStatementRowsAffected = "N";
      model.Properties.SqlStatementNoRowsAffected = "N";
    } else if (Helper.equals(stepActionType, "DbBackup", true)) {
      model.Properties = new m5.ProcessTemplateStepActionDbBackupPropertiesEditViewModel();
      model.Properties.BackupType = "Log";
      model.Properties.CompressionType = "DbServer";
    } else if (Helper.equals(stepActionType, "DbRestore", true)) {
      model.Properties = new m5.ProcessTemplateStepActionDbRestorePropertiesEditViewModel();
      model.Properties.RestoreType = "Database";
    } else if (Helper.equals(stepActionType, "CopyFiles", true)) {
      model.Properties = new m5.ProcessTemplateStepActionCopyFilesPropertiesEditViewModel();
      model.Properties.SourceFileServerId = 0;
      model.Properties.TargetFileServerId = 0;
    } else if (Helper.equals(stepActionType, "DeleteFiles", true)) {
      model.Properties = new m5.ProcessTemplateStepActionDeleteFilesPropertiesEditViewModel();
      model.Properties.FileServerId = 0;
      model.Properties.Exclude = [];
    } else if (Helper.equals(stepActionType, "ConvertFile", true)) {
      model.Properties = new m5.ProcessTemplateStepActionConvertFilePropertiesModel();
      model.Properties.Overwrite = true;
    } else if (Helper.equals(stepActionType, "AttachFile", true)) {
      model.Properties = new m5.ProcessTemplateStepActionAttachFilePropertiesModel();
      model.Properties.MoveFile = true;
    } else if (Helper.equals(stepActionType, "SystemWatchEvent", true)) {
      model.Properties = new m5.ProcessTemplateStepActionSystemWatchEventPropertiesModel();
      model.Properties.PayloadVersion = 1;
      model.Properties.Wait = true;
    } else if (Helper.equals(stepActionType, "Webhook", true)) {
      model.Properties = new m5web.WebApiRequestViewModel();
      model.Properties.Method = "HttpPost";
      //model.Properties.Headers = { Auth: "test" };
      const auth = new m.KeyValuePairModel<string, string>();
      auth.Key = "Auth";
      auth.Value = "test";
      model.Properties.Headers.push(auth);
      model.Properties.ContentType = "application/json";
      model.Properties.Protocol = "Tls12";
      model.Properties.ExceptionHandling = "Log";
    }

    return model;

  }



}
