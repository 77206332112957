import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, PLATFORM_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CacheModule, CACHE } from '@ngx-cache/core';
import { BrowserCacheModule, MemoryCacheService } from '@ngx-cache/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from '@ngx-progressbar/core';
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { AppRoutingModule } from 'projects/ib-api-docs-app/src/app/app.routing.module';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory, ApiMissingTranslationHandler } from 'projects/core-lib/src/lib/helpers/i18n-helpers';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { InstructionsModule } from './instructions/instructions.module';
import { RouterModule } from '@angular/router';
import { EndpointModule } from './endpoint/endpoint.module';
import { SharedModule } from './shared/shared.module';
import { AppInitService } from '../../../core-lib/src/lib/services/app-init.service';
import { GlobalErrorHandler } from '../../../core-lib/src/lib/services/global-error-handler';

/**
 * We need to execute the appInitService.Init(). We cannot do it directly from the provider.
 * We need to create a function which invokes the appInitService.Init() and returns a Promise.
 * We do that in initializeApp1 function.
 * See https://www.tektutorialshub.com/angular/angular-how-to-use-app-initializer/
 * @param appInitService
 */
export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  }
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    CacheModule.forRoot(),
    BrowserCacheModule.forRoot([
      {
        provide: CACHE,
        useClass: MemoryCacheService, // or LocalStorageCacheService
        deps: [PLATFORM_ID]
      }
    ]),
    BrowserTransferStateModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: ApiMissingTranslationHandler, deps: [ApiService] },
      useDefaultLang: false
    }),
    NgProgressModule.withConfig({
      //trickleSpeed: 50,
      //speed: 50,
      color: "red",
      thick: true,
      meteor: true
    }), // See https://github.com/MurhafSousli/ngx-progressbar
    CoreLibModule,
    CommonLibModule,
    InstructionsModule,
    EndpointModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
