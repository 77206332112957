import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

/**
 * Displays a timeline.
 * Example:
     <ib-timeline>
        <ib-timeline-item side="left" color="info" icon="calendar">
            time line item contents
        </ib-timeline-item>
        <ib-timeline-item side="right" color="warning" icon="calendar">
            time line item contents
        </ib-timeline-item>
    </ib-timeline>
 */
@Component({
  selector: 'ib-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  @Input() tight: boolean = false;
  @Input() singleSided: boolean = false;

  public timelineClass: string = "";

  constructor() { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  protected configure() {
    if (this.singleSided) {
      this.timelineClass = "timeline-single-sided";
    } else {
      this.timelineClass = "";
    }
  }

}
