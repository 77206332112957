<app-endpoint-header [apiProperties]="apiProp"
                     [apiCall]="apiCall"
                     [apiEndpoint]="apiEndpoint"></app-endpoint-header>

<ul ngbNav
    #nav='ngbNav'
    class="{{isMobile ? 'nav-pills' : 'nav-tabs'}}"
    [destroyOnHide]="false"
    (navChange)="onNavChange($event)">

  <li ngbNavItem
      title="Overview"
      *ngIf="apiCall && showOverview">
    <a ngbNavLink>Overview</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="{{Constants.Layout.fullWidth}}">
          <div *ngIf="overviewTemplateUrl">
            <app-custom-content [type]="overviewTemplateUrl"
                                [dataModelDocumentationParts]="dataModelDocumentationParts"></app-custom-content>
          </div>
          <div *ngIf="!overviewTemplateUrl && overviewText">
            <span [innerHTML]="overviewText | safe : 'html'"></span>
          </div>
          <br /><br />
          <app-endpoint-data-model *ngIf="showOverviewRequestDataModel"
                                   [data]="requestDataModelDocumentation">
          </app-endpoint-data-model>
          <app-endpoint-data-model *ngIf="showOverviewResponseDataModel"
                                   [data]="responseDataModelDocumentation">
          </app-endpoint-data-model>
        </div>
      </div>
    </ng-template>
  </li>

  <li ngbNavItem
      title="Configuration"
      *ngIf="apiCall && showApiEndpointConfig">
    <a ngbNavLink>Configuration</a>
    <ng-template ngbNavContent>
      <div class="row"
           *ngIf="apiEndpointConfig && apiEndpointConfig.Settings && apiEndpointConfig.Settings.SampleRequest">
        <div class="{{Constants.Layout.fullWidth}}">
          <div class="float-left">
            <h4>API Sample Request</h4>
          </div>
          <ib-peek [visible]="true"
                   buttonWrapperClass="float-left"
                   buttonWrapperStyle="padding: 5px 0 0 5px;">
            <div class="clearfix"></div>
            <ib-code-block>{{apiEndpointConfig.Settings.SampleRequest | json}}</ib-code-block>
          </ib-peek>
        </div>
      </div>
      <div class="row"
           *ngIf="apiEndpointConfig && apiEndpointConfig.Settings && apiEndpointConfig.Settings.SampleResponse">
        <div class="{{Constants.Layout.fullWidth}}">
          <div class="float-left">
            <h4>API Sample Response</h4>
          </div>
          <ib-peek [visible]="true"
                   buttonWrapperClass="float-left"
                   buttonWrapperStyle="padding: 5px 0 0 5px;">
            <div class="clearfix"></div>
            <ib-code-block>{{apiEndpointConfig.Settings.SampleResponse | json}}</ib-code-block>
          </ib-peek>
        </div>
      </div>
      <div class="row"
           *ngIf="apiEndpointConfig">
        <div class="{{Constants.Layout.fullWidth}}">
          <div class="float-left">
            <h4>API Endpoint Configuration</h4>
          </div>
          <ib-peek [visible]="false"
                   buttonWrapperClass="float-left"
                   buttonWrapperStyle="padding: 5px 0 0 5px;">
            <div class="clearfix"></div>
            <ib-code-block>{{apiEndpointConfig | json}}</ib-code-block>
          </ib-peek>
        </div>
      </div>
    </ng-template>
  </li>

  <li ngbNavItem
      title="Format"
      *ngIf="apiCall && showFormat">
    <a ngbNavLink>Format</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="{{Constants.Layout.fullWidth}}">
          <div *ngIf="formatTemplateUrl">
            <app-custom-content [type]="formatTemplateUrl"
                                [dataModelDocumentationParts]="dataModelDocumentationParts"></app-custom-content>
          </div>
          <div *ngIf="!formatTemplateUrl">
            <app-endpoint-sample [version]="apiVersion"
                                 [httpMethod]="apiCall.methodName"
                                 [url]="apiCall.url"
                                 [requestBody]="sampleRequestBody"
                                 [requestModelDocumentation]="requestDataModelDocumentation"
                                 [responseBody]="sampleResponseBody"
                                 [responseModelDocumentation]="responseDataModelDocumentation">
            </app-endpoint-sample>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <li ngbNavItem
      title="Result Codes"
      *ngIf="apiCall && showResultCodes">
    <a ngbNavLink>Result Codes</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="{{Constants.Layout.fullWidth}}">
          <div *ngIf="resultCodesTemplateUrl">
            <app-custom-content [type]="resultCodesTemplateUrl"
                                [dataModelDocumentationParts]="dataModelDocumentationParts"></app-custom-content>
          </div>
          <div *ngIf="!resultCodesTemplateUrl && resultCodesText">
            <span [innerHTML]="resultCodesText | safe : 'html'"></span>
          </div>
          <div *ngIf="!resultCodesTemplateUrl && !resultCodesText">
            <h4>Possible Result Codes</h4>
            <app-standard-result-codes></app-standard-result-codes>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <div *ngIf="apiCall">
    <li ngbNavItem
        title="{{page.title}}"
        *ngFor="let page of pages">
      <a ngbNavLink>{{page.title}}</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="{{Constants.Layout.fullWidth}}">
            <div *ngIf="page.templateUrl">
              <app-custom-content [type]="page.templateUrl"
                                  [dataModelDocumentationParts]="dataModelDocumentationParts"></app-custom-content>
            </div>
            <div *ngIf="!page.templateUrl && page.text">
              <span [innerHTML]="page.text | safe : 'html'"></span>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </div>

  <li ngbNavItem
      title="Test"
      ngbNavItem="Test"
      *ngIf="apiCall && showTest">
    <a ngbNavLink>Test</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="{{Constants.Layout.fullWidth}}">

          <form class="form-horizontal"
                role="form">

            <app-endpoint-test-header [apiCall]="apiCall"
                                      [apiEndpoint]="apiEndpoint">
            </app-endpoint-test-header>

            <div class="clearfix row-fluid"
                 style="padding-bottom:8px;">
              <div class="float-left mt-2">
                <h4>Test Form</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 10px 0 0 5px;">

                <app-endpoint-test-form-list-controls *ngIf="apiCall.typeName === 'List' || apiCall.typeName === 'Report'"
                                                      [apiProperties]="apiProp"
                                                      [apiEndpoint]="apiEndpoint"
                                                      [apiCall]="apiCall"
                                                      [modelDocumentation]="requestDataModelDocumentation"
                                                      [requestData]="requestData"
                                                      [working]="working"
                                                      (submit)="onTestSubmit($event)"
                                                      (submit2)="onTestSubmit2($event)">
                </app-endpoint-test-form-list-controls>

                <app-endpoint-test-form-path-controls *ngIf="apiCall.typeName === 'Get' || apiCall.typeName === 'Delete' || apiCall.typeName === 'Copy'"
                                                      [apiProperties]="apiProp"
                                                      [apiEndpoint]="apiEndpoint"
                                                      [apiCall]="apiCall"
                                                      [modelDocumentation]="requestDataModelDocumentation"
                                                      [requestData]="requestData"
                                                      [working]="working"
                                                      (submit)="onTestSubmit($event)"
                                                      (submit2)="onTestSubmit2($event)">
                </app-endpoint-test-form-path-controls>

                <!-- we provide get controls on edit test page for ease of loading data for edit.... note different endpoint, call, and on-submit action. -->
                <app-endpoint-test-form-path-controls *ngIf="apiCall.typeName === 'Edit' || apiCall.typeName === 'Merge' || apiCall.typeName === 'Patch'"
                                                      [apiProperties]="apiProp"
                                                      [apiEndpoint]="apiGetEndpoint"
                                                      [apiCall]="apiGetCall"
                                                      [modelDocumentation]="requestDataModelDocumentation"
                                                      [requestData]="requestData"
                                                      [working]="working"
                                                      (submit)="onTestLoad($event)"
                                                      (submit2)="onTestSubmit2($event)">
                </app-endpoint-test-form-path-controls>
                <app-endpoint-test-form-edit-controls *ngIf="loadJsonEditor && ( apiCall.typeName === 'Add' || apiCall.typeName === 'Edit' || apiCall.typeName === 'Merge' || apiCall.typeName === 'Patch' || apiCall.typeName === 'Call' )"
                                                      [apiProperties]="apiProp"
                                                      [apiEndpoint]="apiEndpoint"
                                                      [apiCall]="apiCall"
                                                      [modelDocumentation]="requestDataModelDocumentation"
                                                      [requestData]="requestData"
                                                      [working]="working"
                                                      (submit)="onTestSubmit($event)"
                                                      (submit2)="onTestSubmit2($event)">
                </app-endpoint-test-form-edit-controls>

              </ib-peek>
              <div class="clearfix"></div>
            </div>

          </form>

          <div>
            <app-production-warning [hidden]="!apiService.selectedHost.isProduction"></app-production-warning>
            <div class="clearfix row-fluid"
                 style="padding-bottom:8px;"
                 [hidden]="!testResults.requestUrl">
              <div class="float-left">
                <h4>Request Url</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 5px 0 0 5px;">
                <ib-code-block>{{testResults.requestMethod | uppercase}} {{testResults.requestUrl}}</ib-code-block>
              </ib-peek>
            </div>
            <div class="clearfix row-fluid"
                 [hidden]="!testResults.requestHeaders">
              <div class="float-left">
                <h4>Request Headers</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 5px 0 0 5px;">
                <ib-code-block>{{testResults.requestHeaders | json}}</ib-code-block>
              </ib-peek>
            </div>
            <div class="clearfix row-fluid"
                 style="padding:8px 0px 8px 0px;"
                 [hidden]="!testResults.requestData">
              <div class="float-left">
                <h4>Request</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 5px 0 0 5px;">
                <ib-code-block>{{testResults.requestData | json}}</ib-code-block>
              </ib-peek>
            </div>
            <div class="clearfix row-fluid"
                 style="padding:8px 0px 8px 0px;"
                 [hidden]="!testResults.responseStatus">
              <div class="float-left">
                <h4>Response Status</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 5px 0 0 5px;">
                <ib-code-block>{{testResults.responseStatus}} {{testResults.responseStatusText}}</ib-code-block>
              </ib-peek>
            </div>
            <div class="clearfix row-fluid"
                 [hidden]="!testResults.responseHeaders">
              <div class="float-left">
                <h4>Response Headers</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 5px 0 0 5px;">
                <ib-code-block>{{testResults.responseHeaders | json }}</ib-code-block>
              </ib-peek>
            </div>
            <div class="clearfix row-fluid"
                 style="padding:8px 0px 8px 0px;"
                 [hidden]="!testResults.responseData">
              <div class="float-left">
                <h4>Response</h4>
              </div>
              <ib-peek [visible]="true"
                       buttonWrapperClass="float-left"
                       buttonWrapperStyle="padding: 5px 0 0 5px;">
                <ib-code-block>{{testResults.responseData | json }}</ib-code-block>
              </ib-peek>
            </div>
            <div class="clearfix"></div>
          </div>


        </div>
      </div>
    </ng-template>
  </li>

</ul>
<div [ngbNavOutlet]="nav"
     class="mt-2"></div>
