<div class="form-group {{inputFormGroupClass}}">
  <label for="{{inputControlId}}" class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{label | translate}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}} {{controlClass}}">
    <!--<div *ngIf="prefixText || prefixIcon" class="ui-inputgroup-addon" [ngClass]="{'clickable': isPrefixClickable()}">
      <span class="input-group-text" ngbTooltip="{{prefixTooltip | translate}}" (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
    </div>--> 
    <ib-dropdown id="{{inputControlId}}"
                 name="{{name}}"
                 ngbTooltip="{{tooltip | translate}}"
                 [disabled]="( disabled || readonly ) ? 'disabled' : null"
                 [required]="required ? 'required' : null"
                 (focus)="fireFocus($event, inputControl)"
                 (blur)="fireBlur($event, inputControl)"
                 (keyup)="fireKeyUp($event, inputControl)"
                 (onChange)="fireChange($event, inputControl)"
                 [actionIcon]="(pickListAddButtonSlot > 0 ? 'plus' : '')"
                 [actionTooltip]="optionsPickListAddTooltip"
                 (onAction)="addPickListValue($event)"
                 [options]="selectItems"
                 [style]="{width:'100%'}"
                 [scrollHeight]="scrollHeight"
                 [editable]="editable"
                 [filter]="true"
                 filterPlaceHolder="Search Items"
                 [(ngModel)]="value"
                 [ngModelOptions]="{standalone: standalone}"
                 #inputControl="ngModel">
      <p-header *ngIf="headerIcon || headerText || headerActionButton">
        <div class="clearfix m-2" (click)="$event.stopPropagation()">
          <div class="float-left" *ngIf="headerIcon || headerText">
            <ib-icon [icon]="headerIcon" [label]="headerText"></ib-icon>
          </div>
          <div class="float-right" *ngIf="headerActionButton">
            <ib-action-button [button]="headerActionButton" [data]="value" [control]="inputControl"></ib-action-button>
          </div>
        </div>
      </p-header>
      <p-footer *ngIf="footerActionButton">
        <div class="clearfix m-2" (click)="$event.stopPropagation()">
          <div class="float-left">
            &nbsp;
          </div>
          <div class="float-right" *ngIf="footerActionButton">
            <ib-action-button [button]="footerActionButton" [data]="value"></ib-action-button>
          </div>
        </div>
      </p-footer>
    </ib-dropdown>
    <!--<span *ngIf="suffixText || suffixIcon"
          class="ui-inputgroup-addon"
          [ngClass]="{'clickable': isSuffixClickable()}"
          ngbTooltip="{{suffixTooltip | translate}}"
          (click)="fireSuffixClick($event)">
      {{suffixText | translate}}
      <ib-icon icon="{{suffixIcon}}"></ib-icon>
    </span>-->
    <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
      <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
        {{errorMessage | translate:inputInformationValues}}
      </small>
    </div>
  </div>
</div>
