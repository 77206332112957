<div>

  <h3>Data Model Overview</h3>

  <br />

  <p>
    The data model documented here is for use in creating queries and reports.  While it documents some of the back end data stores
    used for persisting API data the tables and views may not directly correlate to API objects and the columns may not directly
    correlate to API object properties.  Additionally, any correlation that does exist is subject to change.
    Use the documentation provided with each documented API to understand the object and properties related to that API.
  </p>

</div>
