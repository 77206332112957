<div class="row" style="padding-top:20px;">
  <div class="{{Constants.Layout.percent25Offset}} {{Constants.Layout.split2column}}">
    <div class="card">
      <h5 class="card-header">Find My Login Name</h5>
      <div class="card-body">
        <ib-input-text name="Email" label="Email" [autofocus]="true" (keyUpIsEnter)="recoverLogin($event)" [(ngModel)]="data.Email"></ib-input-text>
        <ib-input-wrapper label="">
          <ib-button color="success" label="Find My Login Name" (click)="recoverLogin($event)"></ib-button>
          <a [routerLink]="['/login']" class="ml-4">Return to login</a>
        </ib-input-wrapper>
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
          {{errorMessage}}
        </div>
        <div *ngIf="responseMessage" class="alert alert-warning" role="alert">
          {{responseMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
