<ng-container *ngFor="let col of testFormProperties;trackBy: trackByIndex">
  <ng-container [ngSwitch]="col.type">

    <ng-container *ngSwitchCase="testFormPropertyTypes.Bool">
      <ib-input-checkbox *ngIf="col.isPathPropertyOnly"
                         name="{{col.name}}" label="{{col.label}}"
                         [(ngModel)]="requestPathProperties[col.name]"></ib-input-checkbox>
      <ib-input-checkbox *ngIf="!col.isPathPropertyOnly"
                         name="{{col.name}}" label="{{col.label}}"
                         [(ngModel)]="requestData[col.name]"></ib-input-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="testFormPropertyTypes.Select">
      <ib-input-select *ngIf="col.isPathPropertyOnly"
                       name="{{col.name}}" label="{{col.label}}" width="{{inputWidth(col.inputWidth)}}"
                       [optionsIncludeNone]="false"
                       [optionsPickList]="getPickList(col)"
                       [(ngModel)]="requestPathProperties[col.name]"></ib-input-select>
      <ib-input-select *ngIf="!col.isPathPropertyOnly"
                       name="{{col.name}}" label="{{col.label}}" width="{{inputWidth(col.inputWidth)}}"
                       [optionsIncludeNone]="false"
                       [optionsPickList]="getPickList(col)"
                       [(ngModel)]="requestData[col.name]"></ib-input-select>
    </ng-container>

    <ng-container *ngSwitchCase="testFormPropertyTypes.TextArea">
      <ib-input-textarea *ngIf="col.isPathPropertyOnly"
                         name="{{col.name}}" label="{{col.label}}" width="{{inputWidth(col.inputWidth)}}" [rows]="col.height"
                         [(ngModel)]="requestPathProperties[col.name]"></ib-input-textarea>
      <ib-input-textarea *ngIf="!col.isPathPropertyOnly"
                         name="{{col.name}}" label="{{col.label}}" width="{{inputWidth(col.inputWidth)}}" [rows]="col.height"
                         [(ngModel)]="requestData[col.name]"></ib-input-textarea>
    </ng-container>

    <ng-container *ngSwitchCase="testFormPropertyTypes.Json">
      <ib-code-editor mode="json" name="{{col.name}}"
                      [ngModel]="getPropertyAsJsonString(col.name, col.isPathPropertyOnly)"
                      (change)="onPropertyJsonStringChange($event, col.name, col.isPathPropertyOnly)"></ib-code-editor>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ib-input-text *ngIf="col.isPathPropertyOnly"
                     name="{{col.name}}" label="{{col.label}}" width="{{inputWidth(col.inputWidth)}}"
                     [(ngModel)]="requestPathProperties[col.name]"></ib-input-text>
      <ib-input-text *ngIf="!col.isPathPropertyOnly"
                     name="{{col.name}}" label="{{col.label}}" width="{{inputWidth(col.inputWidth)}}"
                     [(ngModel)]="requestData[col.name]"></ib-input-text>
    </ng-container>

  </ng-container>
</ng-container>
