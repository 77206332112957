import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    CoreLibModule,
    CommonLibModule,
  ],
  declarations: [
    HomeComponent,
    LoginComponent,
  ],
})
export class SharedModule { }
