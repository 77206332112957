import { Component, OnInit, OnChanges, SimpleChanges, Input, ViewChild, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { FormHelper } from 'projects/common-lib/src/lib/form/form-helper';
import { NgForm, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { InputStatusChangeModel } from 'projects/common-lib/src/lib/input/input-status-change-model';
import { HandlebarsHelpers } from 'projects/core-lib/src/lib/helpers/handlebars-helpers';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-form-group-render',
  templateUrl: './form-group-render.component.html',
  styleUrls: ['./form-group-render.component.css']
})
export class FormGroupRenderComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() formModel: m5web.FormEditViewModel;
  //@Input() formStatus: FormStatusModel;
  @Input() parentGroupModel: m5web.FormControlGroupEditViewModel;
  @Input() groupModel: m5web.FormControlGroupEditViewModel;
  @Input() data: any;
  @Input() dataChangeCount: number = 0;
  @Input() formIsReadOnly: boolean = false;
  @Input() loading: boolean = false;
  @Input() working: boolean = false;

  @Input() designMode: boolean = false;
  @Input() designModeStyling: boolean = false;
  @Input() designModeGroupActions: ButtonItem = null;
  @Input() designModeControlActions: ButtonItem = null;

  /**
  G = General
  S = Success
  C = Cancel
  There is a group type A but that indicates All and isn't a setting used here since it's applicable everywhere.
  This gets passed in by the form that hosts this group so it can change the type being displayed as appropriate.
  */
  @Input() groupType: "G" | "S" | "C" = "G";


  @Input() contextResourceType: string = "";
  @Input() contextResourceId: number = 0;
  @Input() contextResourceId2: string = "";
  @Input() context: any = null;


  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() status: EventEmitter<EventModel> = new EventEmitter();
  @Output() addSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() addClose: EventEmitter<EventModel> = new EventEmitter();
  @Output() editSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() editClose: EventEmitter<EventModel> = new EventEmitter();

  @Output() designModeEditGroupProperties: EventEmitter<EventModel> = new EventEmitter();
  @Output() designModeEditControlProperties: EventEmitter<EventModel> = new EventEmitter();

  formProperties: any;
  groupProperties: any;

  constructor(public formStatus: FormStatusService, protected sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formModel) {
      this.formProperties = FormHelper.parseFormProperties(this.formModel);
    }
    if (changes.groupModel) {
      this.groupProperties = FormHelper.parseGroupProperties(this.groupModel);
      // We don't get control status until touched but when there are validation requirements on a
      // control then we need an initial status report so we know our form status right up top.
      this.groupModel.Controls.forEach((control) => {
        try {
          // Some static controls do not have object name or property name
          if (control.ObjectName && control.PropertyName) {
            let value: any = FormHelper.getInputControlValue(control, this.data);
            let status: InputStatusChangeModel = new InputStatusChangeModel();
            status.name = FormHelper.getInputControlName(control);
            status.label = control.Label || control.Watermark;
            status.pristine = true;
            status.errors = null;
            if (control.Required) {
              if (value) {
                status.valid = true;
              } else {
                status.valid = false;
                if (!status.errors) {
                  status.errors = {};
                }
                status.errors.required = true;
              }
            } else if (control.Minimum) {
              if (value && value.toString().length >= control.Minimum) {
                status.valid = true;
              } else {
                status.valid = false;
                if (!status.errors) {
                  status.errors = {};
                }
                status.errors.minlength = { requiredLength: control.Minimum, actualLength: (value || "").toString().length };
              }
            }
            status.dirty = !status.pristine;
            status.invalid = !status.valid;
            let payload: EventModel = new EventModel("status", status, value, new EventElementModel("input", null, status.name, control.Label, control.Watermark));
            // Emit after delay in case status listener is not wired up yet
            setTimeout(() => {
              this.status.emit(payload);
            }, 1000);
          }
        } catch (err) {
          Log.errorMessage(err);
        }
      });
    }
  }

  onChange($event) {
    // $event is already in our EventModel format
    //console.error("group change event", $event);
    this.change.emit($event);
  }

  onStatusChange($event) {
    //console.error("group onStatusChange", $event);
    //console.error($event)
    // $event is already in our EventModel format
    this.status.emit($event);
  }

  onAddSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    // $event bubbles up from our form control render component and is already in expected format
    this.addSave.emit($event);
  }

  onAddClose($event) {
    // $event bubbles up from our form control render component and is already in expected format
    this.addClose.emit($event);
  }

  onEditSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    // $event bubbles up from our form control render component and is already in expected format
    this.editSave.emit($event);
  }

  onEditClose($event) {
    // $event bubbles up from our form control render component and is already in expected format
    this.editClose.emit($event);
  }

  onDesignModeEditGroupProperties(event: any, group: m5web.FormControlGroupEditViewModel) {
    let payload: EventModel = new EventModel("designModeEditGroupProperties", event, group, new EventElementModel("group", (group.FormControlGroupId || 0).toString(), group.Description));
    this.designModeEditGroupProperties.emit(payload);
  }

  onDesignModeEditControlProperties(event: any, group: m5web.FormControlGroupEditViewModel, control: m5web.FormControlEditViewModel) {
    if (!group) {
      if (event && event.cargo && event.cargo.group) {
        group = event.cargo.group;
      }
    }
    if (!control) {
      if (event && event.data) {
        control = event.data;
      } else {
        control = new m5web.FormControlEditViewModel();
        control.Description = "No Control Model Specified";
      }
    }
    let cargo: any = { group: group, control: control };
    let payload: EventModel = new EventModel("designModeEditControlProperties", event, control, new EventElementModel("control", (control.FormControlId || 0).toString(), control.Description), cargo);
    this.designModeEditControlProperties.emit(payload);
  }

  getGroupColumnClasses(group: m5web.FormControlGroupEditViewModel) {
    if (!group.ColumnWidth) {
      return "";
    }
    if (Helper.endsWith(group.ColumnWidth, "%") || Helper.endsWith(group.ColumnWidth, "px", true)) {
      return "";
    }
    if (group.ColumnWidth === "12") {
      return Constants.Layout.fullWidth;
    } else if (group.ColumnWidth === "6") {
      return Constants.Layout.split2column;
    } else if (group.ColumnWidth === "4") {
      return Constants.Layout.split3column;
    } else if (group.ColumnWidth === "3") {
      return Constants.Layout.split4column;
    } else if (group.ColumnWidth === "9") {
      return Constants.Layout.splitPercent75FlexColumn;
    } else {
      return `col-${group.ColumnWidth}`;
    }
  }

  getGroupColumnStyles(group: m5web.FormControlGroupEditViewModel): SafeStyle {
    if (!group.ColumnWidth) {
      return this.sanitizer.bypassSecurityTrustStyle(group.GroupStyles);
    }
    if (!Helper.endsWith(group.ColumnWidth, "%") && !Helper.endsWith(group.ColumnWidth, "px", true)) {
      return this.sanitizer.bypassSecurityTrustStyle(group.GroupStyles);
    }
    return this.sanitizer.bypassSecurityTrustStyle(`width: ${group.ColumnWidth}; ${group.GroupStyles}`);
  }

  /**
  * Determine if the group should be displayed or not.
  * Fat arrow function to keep context of this tied to our component and not the pipe that invokes this function.
  */
  showGroup = (group: m5web.FormControlGroupEditViewModel): boolean => {

    if (this.designMode) {
      // When in design mode we want to see all the groups
      return true;
    }

    // Make sure our group type is the expected group type or "All"
    if (!Helper.equals(group.GroupType, this.groupType, true) && !Helper.equals(group.GroupType, "A", true)) {
      return false;
    }

    // If we have a display when macro expression let's evaluate it to figure out if we're going to display the control or not
    if (group.DisplayWhenExpression && Helper.contains(group.DisplayWhenExpression, "{{")) {
      let result = HandlebarsHelpers.handlebarsTemplateResolve(group.DisplayWhenExpression, this.data, `FormControlGroupId-${group.FormControlGroupId}-DisplayWhenExpression`);
      if (result && !Helper.equals(result, "false", true)) {
        return true;
      } else {
        return false;
      }
    }

    // Nobody said not to show it so we're showing it
    return true;

  }



  groupTrackByFn(index: number, group: m5web.FormControlGroupEditViewModel) {
    if (group && group.FormControlGroupId) {
      return group.FormControlGroupId;
    }
    // Return negative index to avoid possible collision with FormControlGroupId
    return (-1 * index);
  }

  controlTrackByFn(index: number, group: m5web.FormControlEditViewModel) {
    if (group && group.FormControlId) {
      return group.FormControlId;
    }
    // Return negative index to avoid possible collision with FormControlGroupId
    return (-1 * index);
  }


}
