import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { ApiProperties, ApiOperationType, IApiResponseWrapper, ApiCall, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { FormHelper } from 'projects/common-lib/src/lib/form/form-helper';
import { EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { HandlebarsHelpers } from 'projects/core-lib/src/lib/helpers/handlebars-helpers';
import { TranslationHelperService } from 'projects/core-lib/src/lib/services/translation-helper.service';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-form-control-render',
  templateUrl: './form-control-render.component.html',
  styleUrls: ['./form-control-render.component.css']
})
export class FormControlRenderComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() formModel: m5web.FormEditViewModel;
  //@Input() formStatus: FormStatusModel;
  @Input() parentGroupModel: m5web.FormControlGroupEditViewModel;
  @Input() groupModel: m5web.FormControlGroupEditViewModel;
  @Input() controlModel: m5web.FormControlEditViewModel;
  @Input() data: any;
  @Input() dataChangeCount: number = 0;
  @Input() formIsReadOnly: boolean = false;
  @Input() loading: boolean = false;
  @Input() working: boolean = false;

  @Input() designMode: boolean = false;
  @Input() designModeStyling: boolean = false;
  @Input() designModeControlActions: ButtonItem = null;

  @Input() contextResourceType: string = "";
  @Input() contextResourceId: number = 0;
  @Input() contextResourceId2: string = "";
  @Input() context: any = null;

  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() status: EventEmitter<EventModel> = new EventEmitter();
  @Output() addSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() addClose: EventEmitter<EventModel> = new EventEmitter();
  @Output() editSave: EventEmitter<EventModel> = new EventEmitter();
  @Output() editClose: EventEmitter<EventModel> = new EventEmitter();

  @Output() designModeEditControlProperties: EventEmitter<EventModel> = new EventEmitter();

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  /**
   * controlModel.OptionsPickListFilter may contain macros when this is a cascading
   * pick list.  For example: select#1 asks for X.  select#2 has pick list filter
   * where Property = "{{X}}" and we need to dynamically resolve the macro based on
   * the value from select#1.
   */
  public optionsPickListFilter: string = "";
  protected isDynamicPickListFilter: boolean = false;

  protected assetTextCache = new Dictionary<string>();

  formProperties: any;
  groupProperties: any;
  controlProperties: any;

  constructor(
    public formStatus: FormStatusService,
    protected translate: TranslationHelperService,
    protected apiService: ApiService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formModel) {
      this.formProperties = FormHelper.parseFormProperties(this.formModel);
    }
    if (changes.groupModel) {
      this.groupProperties = FormHelper.parseGroupProperties(this.groupModel);
    }
    if (changes.controlModel) {
      this.controlProperties = FormHelper.parseControlProperties(this.controlModel);
      this.optionsPickListFilter = this.controlModel.OptionsPickListFilter;
      this.isDynamicPickListFilter = Helper.contains(this.controlModel.OptionsPickListFilter, "{{", false);
      this.refreshOptionsPickListFilter();
    }
    if (changes.data || changes.dataChangeCount) {
      this.refreshOptionsPickListFilter();
    }
  }


  refreshOptionsPickListFilter(): void {
    if (!this.isDynamicPickListFilter) {
      return;
    }
    this.optionsPickListFilter = HandlebarsHelpers.handlebarsTemplateResolve(this.controlModel.OptionsPickListFilter, this.data, `DynamicForm-${this.controlModel.ObjectFullName}-${this.controlModel.PropertyName}-${this.controlModel.OptionsPickListId}-${this.controlModel.OptionsPickListFilter}`);
    //console.error(this.controlModel.OptionsPickListFilter, this.optionsPickListFilter, this.data);
  }


  getInputControlName(): string {
    return FormHelper.getInputControlName(this.controlModel);
  }


  getContents(): string {

    let contents = this.controlModel.Contents;

    if (!contents && this.controlModel.ObjectName && this.controlModel.PropertyName) {
      // This is text from the data so we don't translate it.
      contents = this.getModel();
      if (this.controlModel.ControlType === "InputStatic" && !contents) {
        // Static controls aren't very happy with no content
        return contents || "&nbsp;";
      } else {
        return contents || "";
      }
    }

    if (!contents && this.controlModel.AssetId) {
      const query: any = { AssetId: this.controlModel.AssetId };
      if (this.contextResourceType) {
        query.ContextResourceType = this.contextResourceType;
      }
      if (this.contextResourceId) {
        query.ContextResourceId = this.contextResourceId;
      }
      if (this.contextResourceId2) {
        query.ContextResourceId2 = this.contextResourceId2;
      }
      if (this.context) {
        query.Context = JSON.stringify(this.context);
      }
      const cacheKey = `${this.controlModel.AssetId}-${JSON.stringify(query)}`;
      if (this.assetTextCache.containsKey(cacheKey)) {
        contents = this.assetTextCache.item(cacheKey);
      } else {
        // Push a temp empty string into cache to prevent multiple server submissions for this data
        this.assetTextCache.add(cacheKey, "");
        // Get the text from the server
        const apiProp = Api.AssetText();
        const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
        apiCall.cacheKey = cacheKey; // Use same cache key at api service layer
        this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<string>) => {
          if (result.Data.Success) {
            contents = result.Data.Data;
            this.assetTextCache.add(cacheKey, contents);
            if (!contents && this.designModeStyling) {
              // In design mode we often will not have any text from the server if we don't have the correct
              // context so put some placeholder there so we have something visible in the designer.
              if (Helper.equals(this.controlModel.ControlType, "HTML", true)) {
                contents = `<span class="badge badge-info">Asset Id ${this.controlModel.AssetId}</span>`;
              } else {
                contents = `Asset Id ${this.controlModel.AssetId}`;
              }
              this.assetTextCache.add(cacheKey, contents);
            }
          } else {
            // We may have an error in design mode due to lack of expected context.
            if (this.designModeStyling) {
              if (Helper.equals(this.controlModel.ControlType, "HTML", true)) {
                contents = `<span class="badge badge-info">Asset Id ${this.controlModel.AssetId}</span>`;
              } else {
                contents = `Asset Id ${this.controlModel.AssetId}`;
              }
              this.assetTextCache.add(cacheKey, contents);
            } else {
              console.error(result);
            }
          }
        });
      }
    }

    if (this.controlModel.ControlType === "InputStatic" && !contents) {
      // Static controls aren't very happy with no content
      contents = "&nbsp;"
    } else if (!contents) {
      return "";
    }

    // Translation will handle translation plus {{variable}} replacement provided it's a simple variable reference
    contents = this.translate.getTranslation(contents, this.data);

    // Any remaining {{}} macros need to be handled using handlebars as there may be helper functions, conditional expressions, etc.
    // that cannot be handled by the translation service as that only does simple value replacements.
    if (Helper.contains(contents, "{{")) {
      contents = HandlebarsHelpers.handlebarsTemplateResolve(contents, this.data, `FormControlId-${this.controlModel.FormControlId}`);
    }

    if (this.controlModel.ControlType === "InputStatic" && !contents) {
      // Static controls aren't very happy with no content
      contents = "&nbsp;"
    }

    return contents;

  }


  /**
   * Determine if the control should be displayed or not.
   */
  showControl(): boolean {

    if (this.designMode) {
      // When in design mode we want to see all the controls
      return true;
    }

    // Input controls that are not static must have an object name and property name before we can consider showing them
    if (Helper.startsWith(this.controlModel.ControlType, "Input", true) && !Helper.equals(this.controlModel.ControlType, "InputStatic", true)) {
      if (!this.controlModel.ObjectName) {
        return false;
      } else if (!this.controlModel.PropertyName) {
        return false;
      }
    }

    // If we have a display when macro expression let's evaluate it to figure out if we're going to display the control or not
    if (this.controlModel.DisplayWhenExpression && Helper.contains(this.controlModel.DisplayWhenExpression, "{{")) {
      let result = HandlebarsHelpers.handlebarsTemplateResolve(this.controlModel.DisplayWhenExpression, this.data, `FormControlId-${this.controlModel.FormControlId}-DisplayWhenExpression`);
      if (result && !Helper.equals(result, "false", true)) {
        return true;
      } else {
        return false;
      }
    }

    // Nobody said not to show it so we're showing it
    return true;

  }


  getContactPickerData(): any {

    let contactId: number = null;
    let contactName: string = "";

    if (this.controlModel.ObjectGrandchildName) {
      contactId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        contactName = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName];
      }
    } else if (this.controlModel.ObjectChildName) {
      contactId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        contactName = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName];
      }
    } else {
      contactId = this.data[this.controlModel.ObjectName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        contactName = this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName];
      }
    }

    return { ContactId: contactId, ContactName: contactName, ContactType: "" };

  }


  getContactPickerContactTypes(): string[] {

    let contactTypes: string[] = [];

    if (this.controlModel.ControlProperties) {
      try {
        let properties: any = JSON.parse(this.controlModel.ControlProperties);
        if (properties) {
          if (properties.ContactTypes) {
            contactTypes = properties.ContactTypes;
          } else if (properties.contactTypes) {
            contactTypes = properties.contactTypes;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    return contactTypes;

  }


  getAssetPickerData(): any {

    let assetId: number = null;
    let assetTitle: string = "";

    if (this.controlModel.ObjectGrandchildName) {
      assetId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        assetTitle = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName];
      }
    } else if (this.controlModel.ObjectChildName) {
      assetId = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        assetTitle = this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName];
      }
    } else {
      assetId = this.data[this.controlModel.ObjectName][this.controlModel.PropertyName];
      if (this.controlModel.PropertyDescriptionName) {
        assetTitle = this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName];
      }
    }

    return { AssetId: assetId, Title: assetTitle };

  }

  getModel(): any {
    // We need our data assigned before we can decide this
    if (!this.data) {
      return "";
    }
    return FormHelper.getInputControlValue(this.controlModel, this.data);
  }

  onModelChange($event) {
    if (this.controlModel.ObjectGrandchildName) {
      if ($event && $event.data !== undefined) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event.data;
      } else {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event;
      }
    } else if (this.controlModel.ObjectChildName) {
      if ($event && $event.data !== undefined) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event.data;
      } else {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event;
      }
    } else {
      if ($event && $event.data !== undefined) {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event.data;
      } else {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event;
      }
    }
    //console.error($event);
    //console.error(this.data);
    let payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.change.emit(payload);
  }

  onStatusChange($event) {
    //console.error("control onStatusChange", $event);
    //console.error($event)
    // $event is already in our EventModel format
    this.status.emit($event);
  }

  onContactSelected($event) {
    if (this.controlModel.ObjectGrandchildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event.data.ContactId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName] = $event.data.ContactName;
      }
    } else if (this.controlModel.ObjectChildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event.data.ContactId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName] = $event.data.ContactName;
      }
    } else {
      this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event.data.ContactId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName] = $event.data.ContactName;
      }
    }
    let payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.change.emit(payload);
    this.status.emit($event);
  }

  onAssetSelected($event) {
    if (this.controlModel.ObjectGrandchildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyName] = $event.data.AssetId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.ObjectGrandchildName][this.controlModel.PropertyDescriptionName] = $event.data.Title;
      }
    } else if (this.controlModel.ObjectChildName) {
      this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyName] = $event.data.AssetId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.ObjectChildName][this.controlModel.PropertyDescriptionName] = $event.data.Title;
      }
    } else {
      this.data[this.controlModel.ObjectName][this.controlModel.PropertyName] = $event.data.AssetId;
      if (this.controlModel.PropertyDescriptionName) {
        this.data[this.controlModel.ObjectName][this.controlModel.PropertyDescriptionName] = $event.data.Title;
      }
    }
    let payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.controlModel.ObjectFullName, this.controlModel.PropertyName));
    this.change.emit(payload);
    this.status.emit($event);
  }


  onAddSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    if (this.working) {
      return;
    }
    // Build in our expected format and attach data
    let controlId = `FormControlId-${this.controlModel.FormControlId}`;
    let controlName = this.controlModel.ControlType;
    let model: EventModel = new EventModel("addSave", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.addSave.emit(model);
  }

  onAddClose($event) {
    // Build in our expected format and attach data
    let controlId = `FormControlId-${this.controlModel.FormControlId}`;
    let controlName = this.controlModel.ControlType;
    let model: EventModel = new EventModel("addClose", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.addClose.emit(model);
  }

  onEditSave($event) {
    if (this.formStatus && !this.formStatus.isValid) {
      return;
    }
    if (this.working) {
      return;
    }
    // Build in our expected format and attach data
    let controlId = `FormControlId-${this.controlModel.FormControlId}`;
    let controlName = this.controlModel.ControlType;
    let model: EventModel = new EventModel("editSave", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.editSave.emit(model);
  }

  onEditClose($event) {
    // Build in our expected format and attach data
    let controlId = `FormControlId-${this.controlModel.FormControlId}`;
    let controlName = this.controlModel.ControlType;
    let model: EventModel = new EventModel("editClose", $event, this.data, new EventElementModel("button", controlId, controlName));
    this.editClose.emit(model);
  }

  onDesignModeEditControlProperties(event: any) {
    let cargo: any = { group: this.groupModel, control: this.controlModel };
    let payload: EventModel = new EventModel("designModeEditControlProperties", event, this.controlModel, new EventElementModel("control", (this.controlModel.FormControlId || 0).toString(), this.controlModel.Description), cargo);
    this.designModeEditControlProperties.emit(payload);
  }

}
