<div class="{{wrapperClass}}">
  <div *ngIf="headerIcon || headerText || addButtonIcon || addButtonText">
    <div *ngIf="headerIcon || headerText" class="{{headerClass}} float-left mr-2">
      <ib-icon icon="{{headerIcon}}" label="{{headerText}}"></ib-icon>
    </div>
    <div *ngIf="!disabled && !readonly && ( addButtonIcon || addButtonText )" class="{{headerClass}} float-left mr-2">
      <ib-button icon="{{addButtonIcon}}" label="{{addButtonText}}" color="{{addButtonColor}}" size="{{addButtonSize}}" tooltip="Add {{itemLabel}}" (click)="add($event)"></ib-button>
    </div>
    <div *ngIf="!disabled && !readonly && canBulkAdd && itemRows === 1" class="{{headerClass}} float-left mr-2">
      <ib-button icon="{{bulkAddButtonIcon}}" label="{{bulkAddButtonText}}" color="{{bulkAddButtonColor}}" size="{{bulkAddButtonSize}}" tooltip="{{bulkAddButtonTooltip}}" (click)="bulkItemModal($event,'add')"></ib-button>
    </div>
    <div *ngIf="!disabled && !readonly && canBulkEdit && itemRows === 1" class="{{headerClass}} float-left mr-2">
      <ib-button icon="{{bulkEditButtonIcon}}" label="{{bulkEditButtonText}}" color="{{bulkEditButtonColor}}" size="{{bulkEditButtonSize}}" tooltip="{{bulkEditButtonTooltip}}" (click)="bulkItemModal($event,'edit')"></ib-button>
    </div>
    <div *ngIf="canCopyListToClipboard" class="{{headerClass}} float-left mr-2">
      <ib-button icon="clipboard" color="{{addButtonColor}}" size="{{addButtonSize}}" tooltip="Copy List To Clipboard" (click)="copyListToClipboard($event)"></ib-button>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="{{itemsWrapperClass}}" cdkDropList [cdkDropListDisabled]="!canSort || disabled || readonly" (cdkDropListDropped)="drop($event)">
    <ng-container *ngIf="modelIsJsonString || modelIsCsvString">
      <div *ngFor="let item of list; let i = index; trackBy: trackByFn" class="{{itemClass}}" cdkDrag>
        <div class="drop-placeholder" *cdkDragPlaceholder></div>
        <div *ngIf="pickList && pickList.length > 0" class="float-left" [innerHTML]="item | newLines | pickListObjectText : pickList"></div>
        <div *ngIf="!pickList || pickList.length === 0" class="float-left" [innerHTML]="item | newLines"></div>
        <div class="float-left ml-2"><ib-action-button *ngIf="!disabled && !readonly" [button]="actionButton" [data]="item" [cargo]="{ itemIndex: i }"></ib-action-button></div>
        <div class="clearfix"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!modelIsJsonString && !modelIsCsvString && isArray">
      <div *ngFor="let item of value; let i = index; trackBy: trackByFn" class="{{itemClass}}" cdkDrag>
        <div class="drop-placeholder" *cdkDragPlaceholder></div>
        <div *ngIf="pickList && pickList.length > 0" class="float-left" [innerHTML]="item | newLines | pickListObjectText : pickList"></div>
        <div *ngIf="!pickList || pickList.length === 0" class="float-left" [innerHTML]="item | newLines"></div>
        <div class="float-left ml-2"><ib-action-button *ngIf="!disabled && !readonly" [button]="actionButton" [data]="item" [cargo]="{ itemIndex: i }"></ib-action-button></div>
        <div class="clearfix"></div>
      </div>
    </ng-container>
  </div>
</div>
