import { NgModule } from '@angular/core';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { AppCacheService } from 'projects/core-lib/src/lib/services/app-cache.service';
import { SafePipe } from './pipes/safe.pipe';
import { CanDeactivateGuard } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { EmailLinkPipe } from './pipes/email-link.pipe';
import { IntegerPipe } from './pipes/integer.pipe';
import { DistinctPropertyValuesPipe } from './pipes/distinct-property-values.pipe';
import { FilterPropertyValuePipe } from './pipes/filter-property-value.pipe';
import { FilterCallbackPipe } from './pipes/filter-callback.pipe';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory, ApiMissingTranslationHandler } from 'projects/core-lib/src/lib/helpers/i18n-helpers';
import { NewLinesPipe } from './pipes/new-lines.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { TelephoneLinkPipe } from './pipes/telephone-link.pipe';
import { ReplaceTextPipe } from './pipes/replace-text.pipe';
import { CsvPipe } from './pipes/csv.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { PagePipe } from './pipes/page.pipe';
import { PickListTextPipe } from './pipes/pick-list-text.pipe';
import { ContactTypeDescriptionPipe } from './pipes/contact-type-description.pipe';
import { DefaultTextWhenEmptyPipe } from './pipes/default-text-when-empty.pipe';
import { FormatTextPipe } from './pipes/format-text.pipe';
import { NgForage, NgForageConfig, InstanceFactory, NgForageCache } from 'ngforage';
import { PickListObjectTextPipe } from './pipes/pick-list-object-text.pipe';
import { CommonModule } from '@angular/common';

//function NgForageFactory(ngForageConfig: NgForageConfig) {
//  console.error("NgForageFactory");
//  // @ts-ignore
//  const instance = new InstanceFactory(ngForageConfig);
//  console.error(instance);
//  // @ts-ignore
//  const forge = new NgForage(ngForageConfig, instance);
//  console.error("fact", forge.fact);
//  return forge;
//}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: ApiMissingTranslationHandler, deps: [ApiService] },
      useDefaultLang: false,
      isolate: false
    })
  ],
  declarations: [
    SafePipe,
    EmailLinkPipe,
    TelephoneLinkPipe,
    IntegerPipe,
    DistinctPropertyValuesPipe,
    FilterPropertyValuePipe,
    FilterCallbackPipe,
    NewLinesPipe,
    DateTimeFormatPipe,
    ReplaceTextPipe,
    CsvPipe,
    LimitToPipe,
    PagePipe,
    PickListTextPipe,
    PickListObjectTextPipe,
    ContactTypeDescriptionPipe,
    DefaultTextWhenEmptyPipe,
    FormatTextPipe,
  ],
  // Pretty much all pipes get exported for general use
  exports: [
    TranslateModule,
    SafePipe,
    EmailLinkPipe,
    TelephoneLinkPipe,
    IntegerPipe,
    DistinctPropertyValuesPipe,
    FilterPropertyValuePipe,
    FilterCallbackPipe,
    NewLinesPipe,
    DateTimeFormatPipe,
    ReplaceTextPipe,
    CsvPipe,
    LimitToPipe,
    PagePipe,
    PickListTextPipe,
    PickListObjectTextPipe,
    ContactTypeDescriptionPipe,
    DefaultTextWhenEmptyPipe,
    FormatTextPipe,
  ],
  providers: [
    {
      provide: NgForage,
      useFactory: (ngForageConfig: NgForageConfig) =>
        // @ts-ignore
        new NgForage({}, new InstanceFactory(ngForageConfig)),
      deps: [NgForageConfig]
    },
    {
      provide: NgForageCache,
      useFactory: (ngForageConfig: NgForageConfig) =>
        // @ts-ignore
        new NgForageCache({}, new InstanceFactory(ngForageConfig)),
      deps: [NgForageConfig]
    }
  ],
})
export class CoreLibModule { }

