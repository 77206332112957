<div *ngIf="options.title || options.titleIcon"
     class="modal-header">

  <h3 class="modal-title text-{{options.titleContextColor}} text-nowrap text-truncate"
      id="modal-basic-title">
    <ib-icon *ngIf="options.titleIcon"
             [icon]="options.titleIcon"
             [color]="options.titleIconContextColor"
             style="padding-left:5px;padding-right:5px;"></ib-icon>
    <span [innerHtml]="options.title | safe: 'html'"></span>
  </h3>

  <div *ngIf="options.featureToggleSupport"
       class="float-right mr-2">
    <ib-icon [stateIcons]="options.featureToggleStateIcons"
             [state]="options.featureToggleOn.toString()"
             color="#007bff"
             size="1.5rem"
             tooltip="{{options.featureToggleTooltip}}"
             label="{{options.featureToggleLabel}}"
             labelStyles="vertical-align: top;"
             (click)="options.featureToggleClick($event,options)">
    </ib-icon>
  </div>

  <div *ngIf="helpTopic"
       class="float-right">
    <ib-help-button [helpTopic]="helpTopic">
    </ib-help-button>
  </div>

  <button *ngIf="showCloseIcon"
          type="button"
          class="close"
          aria-label="Close"
          (click)="fireCancel($event)">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<ib-alert *ngIf="options.showAlerts"></ib-alert>
