import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ib-code-block',
  templateUrl: './code-block.component.html',
  styleUrls: ['./code-block.component.css']
})
export class CodeBlockComponent implements OnInit {

  @Input() border: boolean = true;
  @Input() small: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
