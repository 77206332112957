import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit {

  constructor(protected appService: AppService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "Versions";
  }

}
