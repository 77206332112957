import * as m from "./ngCoreModels";
import * as m5core from "./ngModelsCore5";
import * as m5usage from "./ngModelsUsage5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class NetworkElementEditViewModel {
		NetworkElementId: number = null;
		ExternalNetworkElementId: string = null;
		Description: string = "";
		Grouping: string = "";
		Type: string = "";
		Role: string = "";
		Network: string = "";
		OnNetGroup: string = "";
		LocationContactId: number = null;
		LocationContactName: string = "";
		Location: string = "";
		Latitude: number = 0;
		Longitude: number = 0;
		TimeZoneId: string = null;
		DomainName: string = "";
		EquipmentId: string = null;
		IpAddressV4: string = "";
		IpAddressV6: string = "";
		Capacity: number = 0;
		Bandwidth: number = 0;
		QosShortUsageThreshold: number = 0;
		QosJitterThreshold: number = 0;
		QosLatencyThreshold: number = 0;
		QosPacketLossThreshold: number = 0;
		IsoCurrencyCode: string = "";
		TelecomLocationProfileId: number = null;
		TelecomHomeCountryCode: string = "";
		TelecomHomeNpa: string = "";
		TelecomHomeNxx: string = "";
		TelecomHomeLata: number = 0;
		TelecomHomeState: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NetworkPortGroupEditViewModel {
		NetworkPortGroupId: number = null;
		ExternalNetworkPortGroupId: string = null;
		Description: string = "";
		NetworkElementId: number = null;
		Circuit: string = "";
		Route: string = "";
		Type: string = "";
		Role: string = "";
		LastInPath: boolean = false;
		PortCount: number = 0;
		Capacity: number = 0;
		Bandwidth: number = 0;
		IsoCurrencyCode: string = "";
		TelecomLocationProfileId: number = null;
		TelecomHomeCountryCode: string = "";
		TelecomHomeNpa: string = "";
		TelecomHomeNxx: string = "";
		TelecomHomeLata: number = 0;
		TelecomHomeState: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class NetworkPortEditViewModel {
		NetworkPortId: number = null;
		ExternalNetworkPortId: string = null;
		Description: string = "";
		NetworkPortGroupId: number = null;
		NetworkElementId: number = null;
		Circuit: string = "";
		Route: string = "";
		Type: string = "";
		Role: string = "";
		LastInPath: boolean = false;
		Capacity: number = 0;
		Bandwidth: number = 0;
		IsoCurrencyCode: string = "";
		TelecomLocationProfileId: number = null;
		TelecomHomeCountryCode: string = "";
		TelecomHomeNpa: string = "";
		TelecomHomeNxx: string = "";
		TelecomHomeLata: number = 0;
		TelecomHomeState: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		Attributes: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingGroupEditViewModel {
		RatingGroupId: number = null;
		RatingProfileId: number = null;
		Description: string = "";
		UsageTypeList: string[] = [];
		GroupUsage: string[] = [];
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		RateDescriptionUse: string = "";
		RateDescriptionSeparator: string = "";
		RateCode: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingHolidayEditViewModel {
		RatingHolidayId: number = null;
		RatingProfileId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		HolidayStartDateTime: Date = null;
		HolidayEndDateTime: Date = null;
		UsageTypeList: string[] = [];
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingProfileEditViewModel {
		RatingProfileId: number = null;
		ExternalRatingProfileId: string = null;
		Description: string = "";
		ProfileType: string = "";
		TimeZoneId: string = null;
		RateCode: string = "";
		VisibilityCostCenterType: string[] = [];
		VisibilityAccountType: string = "";
		VisibilityAccountClass: string = "";
		VisibilityMarketType: string = "";
		VisibilityContactId: number = null;
		VisibilityContactName: string = "";
		IsoCurrencyCode: string = "";
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		InheritLocationRatingProfileId: number = null;
		InheritSurchargeRatingProfileId: number = null;
		InheritZoneRatingProfileId: number = null;
		InheritHolidayRatingProfileId: number = null;
		RoundUnits: string = "";
		RoundBaseCost: string = "";
		RoundFullCost: string = "";
		DelayedRatingMethod: string = "";
		DelayedRatingBasis: string = "";
		DelayedRatingMultiplier: number = 0;
		RateUsageIncoming: boolean = false;
		RateUsageInternal: boolean = false;
		RateUsageIncomplete: boolean = false;
		CheckHoliday: boolean = false;
		CheckSurchargeDiscount: boolean = false;
		CheckOnNetStatusByNumber: string = "";
		CheckOnNetStatusByNetworkElement: boolean = false;
		CheckTypeForVoiceInternal: boolean = false;
		CheckTypeForVoiceNoCharge: boolean = false;
		IdentifySourceNumber: boolean = false;
		IdentifyCostCenterLocation: boolean = false;
		ReportSourceLocationIncoming: boolean = false;
		CustomLocationDomesticFullNumberSearch: boolean = false;
		CustomLocationDomesticRangeSearch: boolean = false;
		CustomLocationDomesticDigitSearch: string = "";
		CustomLocationInternationalFullNumberSearch: boolean = false;
		CustomLocationInternationalRangeSearch: boolean = false;
		CustomLocationInternationalDigitSearch: string = "";
		FlagMissingFullTarget: string = "";
		FlagMissingPartialTarget: string = "";
		FlagMissingFullSource: string = "";
		FlagMissingPartialSource: string = "";
		FlagMissingFullCostCenter: string = "";
		FlagMissingPartialCostCenter: string = "";
		FlagMissingServiceIdentificationForData: string = "";
		FlagMissingServiceIdentificationForVideo: string = "";
		FlagMissingServiceIdentificationForContent: string = "";
		FlagMissingHolidayRate: string = "";
		FlagZeroCostIncoming: string = "";
		FlagZeroCostOutgoing: string = "";
		FlagZeroCostInternal: string = "";
		FlagZeroCostExternal: string = "";
		FlagZeroCostCompleted: string = "";
		FlagZeroCostIncomplete: string = "";
		FlagZeroCostNoUnits: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingRateEditViewModel {
		RatingRateId: number = null;
		RatingStepId: number = null;
		Description: string = "";
		RateCode: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		BeginningCode: string = "";
		EndingCode: string = "";
		BeginningCodeSource: string = "";
		EndingCodeSource: string = "";
		BeginningAccessCode: string = "";
		EndingAccessCode: string = "";
		BeginningQualityOfService: string = "";
		EndingQualityOfService: string = "";
		BeginningClassOfService: string = "";
		EndingClassOfService: string = "";
		BeginningSourceOperatingCompanyNumber: string = "";
		EndingSourceOperatingCompanyNumber: string = "";
		BeginningTargetOperatingCompanyNumber: string = "";
		EndingTargetOperatingCompanyNumber: string = "";
		BeginningSourceLata: number = 0;
		EndingSourceLata: number = 0;
		BeginningTargetLata: number = 0;
		EndingTargetLata: number = 0;
		BeginningSourceLocationType: string = "";
		EndingSourceLocationType: string = "";
		BeginningTargetLocationType: string = "";
		EndingTargetLocationType: string = "";
		BeginningSourceExternalNetworkElementId: string = null;
		EndingSourceExternalNetworkElementId: string = null;
		BeginningTargetExternalNetworkElementId: string = null;
		EndingTargetExternalNetworkElementId: string = null;
		BeginningSourceNetwork: string = "";
		EndingSourceNetwork: string = "";
		BeginningTargetNetwork: string = "";
		EndingTargetNetwork: string = "";
		BeginningServiceId: string = null;
		EndingServiceId: string = null;
		ClassificationType: string[] = [];
		Filter: string = "";
		Days1: string[] = [];
		StartTime1: string = "";
		EndTime1: string = "";
		Days2: string[] = [];
		StartTime2: string = "";
		EndTime2: string = "";
		Days3: string[] = [];
		StartTime3: string = "";
		EndTime3: string = "";
		SecondsPerPulse: number = 0;
		UnitType: string = "";
		RateStep1Units: number = 0;
		RateStep1Rate: number = 0;
		RateStep1MaximumUnits: number = 0;
		RateStep2Units: number = 0;
		RateStep2Rate: number = 0;
		RateStep2MaximumUnits: number = 0;
		RateStep3Units: number = 0;
		RateStep3Rate: number = 0;
		RateStep3MaximumUnits: number = 0;
		RateStep4Units: number = 0;
		RateStep4Rate: number = 0;
		RateStep4MaximumUnits: number = 0;
		CostSetupRate: number = 0;
		CostMinimum: number = 0;
		CostMaximum: number = 0;
		SurchargeUnitType: string = "";
		SurchargeStep1Units: number = 0;
		SurchargeStep1Rate: number = 0;
		SurchargeStep1MaximumUnits: number = 0;
		SurchargeStep2Units: number = 0;
		SurchargeStep2Rate: number = 0;
		SurchargeStep2MaximumUnits: number = 0;
		SurchargeStep3Units: number = 0;
		SurchargeStep3Rate: number = 0;
		SurchargeStep3MaximumUnits: number = 0;
		SurchargeStep4Units: number = 0;
		SurchargeStep4Rate: number = 0;
		SurchargeStep4MaximumUnits: number = 0;
		SurchargeSetupRate: number = 0;
		SurchargePercent: number = 0;
		SurchargeMinimum: number = 0;
		SurchargeMaximum: number = 0;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingStepEditViewModel {
		RatingStepId: number = null;
		RatingGroupId: number = null;
		SequenceNumber: number = 0;
		Description: string = "";
		RateCode: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		RatingStepCode: string = "";
		RatingStepCodeSource: string = "";
		TierFromZoneSourceFlag: string = "";
		TierFromZoneTargetFlag: string = "";
		InheritZoneRatingProfileId: number = null;
		InheritRateRatingProfileId: number = null;
		CheckRatingStepCodeRange: boolean = false;
		CheckRatingStepCodeSourceRange: boolean = false;
		CheckAccessCode: boolean = false;
		CheckQualityOfService: boolean = false;
		CheckClassOfService: boolean = false;
		CheckSourceOperatingCompanyNumber: boolean = false;
		CheckTargetOperatingCompanyNumber: boolean = false;
		CheckSourceLata: boolean = false;
		CheckTargetLata: boolean = false;
		CheckSourceLocationType: boolean = false;
		CheckTargetLocationType: boolean = false;
		CheckSourceNetworkElement: boolean = false;
		CheckTargetNetworkElement: boolean = false;
		CheckSourceNetwork: boolean = false;
		CheckTargetNetwork: boolean = false;
		CheckServiceId: boolean = null;
		CheckClassificationType: boolean = false;
		CheckDayOfWeek: boolean = false;
		CheckTimeOfDay: boolean = false;
		CheckValidDates: boolean = false;
		CheckFilter: boolean = false;
		ImportRatesDtsFileName: string = "";
		ImportRatesSourceFileName: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingSurchargeDiscountEditViewModel {
		RatingSurchargeDiscountId: number = null;
		RatingProfileId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Filter: string = "";
		ValueFlatRate: number = 0;
		ValuePerMinute: number = 0;
		ValueDurationRoundingFactor: number = 0;
		ValuePercent: number = 0;
		ValueMinimum: number = 0;
		ValueMaximum: number = 0;
		RateCodeUse: string = "";
		RateCodeSeparator: string = "";
		RateCode: string = "";
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class RatingZoneEditViewModel {
		RatingZoneId: number = null;
		RatingProfileId: number = null;
		Description: string = "";
		Enabled: boolean = false;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		SourceLocationType: string = "";
		SourceLocation: string = "";
		TargetLocationType: string = "";
		TargetLocation: string = "";
		Tier: number = 0;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UsageCostCenterRatingEditViewModel {
		UsageCostCenterRatingId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		OwnerResourceId2: string = "";
		CostCenterType: string = "";
		CostCenterLevel: number = 0;
		RatingProfileId: number = null;
		ValidStartBillingPeriod: number = 0;
		ValidEndBillingPeriod: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UsageDataFeedEditViewModel {
		UsageDataFeedId: string = null;
		UsageDataSourceId: number = null;
		ExternalUsageDataSourceId: string = null;
		MediationStatus: string = "";
		QueueDateTime: Date = null;
		Status: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		UsageImportDateTime: Date = null;
		UsageImportLogId: string = null;
		UsageRecordType: number = 0;
		UsageDateTime: Date = null;
		UsageEndDateTime: Date = null;
		UsageTimeZone: string = "";
		SerialNumber: string = "";
		GroupSerialNumber: string = "";
		SourceIdentifier: string = "";
		SourceIdentifierDescription: string = "";
		SourceIdentifierInformation: string = "";
		OtherIdentifier1: string = "";
		OtherIdentifier2: string = "";
		OriginalTargetIdentifier: string = "";
		TargetIdentifier: string = "";
		TargetIdentifierDescription: string = "";
		TargetIdentifierDetails: string = "";
		AccessCode: string = "";
		Extension: string = "";
		TransferredExtension: string = "";
		AccountCode: string = "";
		AuthorizationCode: string = "";
		OtherCode: string = "";
		ClassificationType: string = "";
		Phase: string = "";
		Flags: string = "";
		ServiceType: string = "";
		ServiceId: string = null;
		NetworkId: string = null;
		SubscriberId: string = null;
		AccountingId: string = null;
		GroupId: string = null;
		ClassOfService: string = "";
		Quality: string = "";
		EncodingType: string = "";
		SourceDisconnectCode: string = "";
		TargetDisconnectCode: string = "";
		ContactType: string = "";
		ContactFullName: string = "";
		ContactFirstName: string = "";
		ContactLastName: string = "";
		ContactEmail: string = "";
		TotalUnits: number = 0;
		ConnectDurationSeconds: number = 0;
		HoldDurationSeconds: number = 0;
		UsageDurationSeconds: number = 0;
		Completed: boolean = false;
		Direction: string = "";
		SourceNetworkElementId: number = null;
		SourceExternalNetworkElementId: string = null;
		SourceDomainName: string = "";
		SourceEquipmentId: string = null;
		SourceIpAddress: string = "";
		SourceGroup: string = "";
		SourceInPortGroupId: number = null;
		SourceInExternalPortGroupId: string = null;
		SourceInPortNumberId: number = null;
		SourceInExternalPortNumberId: string = null;
		SourceOutPortGroupId: number = null;
		SourceOutExternalPortGroupId: string = null;
		SourceOutPortNumberId: number = null;
		SourceOutExternalPortNumberId: string = null;
		SourceInformation: string = "";
		SourceType: string = "";
		TargetNetworkElementId: number = null;
		TargetExternalNetworkElementId: string = null;
		TargetDomainName: string = "";
		TargetEquipmentId: string = null;
		TargetIpAddress: string = "";
		TargetGroup: string = "";
		TargetInPortGroupId: number = null;
		TargetInExternalPortGroupId: string = null;
		TargetInPortNumberId: number = null;
		TargetInExternalPortNumberId: string = null;
		TargetOutPortGroupId: number = null;
		TargetOutExternalPortGroupId: string = null;
		TargetOutPortNumberId: number = null;
		TargetOutExternalPortNumberId: string = null;
		TargetInformation: string = "";
		TargetType: string = "";
		DisconnectNetworkElementId: number = null;
		DisconnectExternalNetworkElementId: string = null;
		DisconnectDomainName: string = "";
		DisconnectEquipmentId: string = null;
		DisconnectIpAddress: string = "";
		PacketsSentSource: number = 0;
		PacketsReceivedSource: number = 0;
		PacketsSentTarget: number = 0;
		PacketsReceivedTarget: number = 0;
		PacketsLostSource: number = 0;
		PacketsLostTarget: number = 0;
		JitterSource: number = 0;
		JitterTarget: number = 0;
		LatencySource: number = 0;
		LatencyTarget: number = 0;
		OctetsSentSource: number = 0;
		OctetsReceivedSource: number = 0;
		OctetsSentTarget: number = 0;
		OctetsReceivedTarget: number = 0;
		Stat01SentSource: number = 0;
		Stat01ReceivedSource: number = 0;
		Stat01SentTarget: number = 0;
		Stat01ReceivedTarget: number = 0;
		Stat02SentSource: number = 0;
		Stat02ReceivedSource: number = 0;
		Stat02SentTarget: number = 0;
		Stat02ReceivedTarget: number = 0;
		Stat03: number = 0;
		Stat04: number = 0;
		Stat05: number = 0;
		Stat06: number = 0;
		Stat07: number = 0;
		Stat08: number = 0;
		Stat09: number = 0;
		Stat10: number = 0;
		BandwidthSpeed: number = 0;
		BandwidthConsumed: number = 0;
		ContentPageCount: number = 0;
		Value001: string = "";
		Value002: string = "";
		Value003: string = "";
		Value004: string = "";
		Value005: string = "";
		Value006: string = "";
		Value007: string = "";
		Value008: string = "";
		Value009: string = "";
		Value010: string = "";
		Value011: string = "";
		Value012: string = "";
		Value013: string = "";
		Value014: string = "";
		Value015: string = "";
		Value016: string = "";
		Value017: string = "";
		Value018: string = "";
		Value019: string = "";
		Value020: string = "";
		Value021: string = "";
		Value022: string = "";
		Value023: string = "";
		Value024: string = "";
		Value025: string = "";
		Value026: string = "";
		Value027: string = "";
		Value028: string = "";
		Value029: string = "";
		Value030: string = "";
		Value031: string = "";
		Value032: string = "";
		Value033: string = "";
		Value034: string = "";
		Value035: string = "";
		Value036: string = "";
		Value037: string = "";
		Value038: string = "";
		Value039: string = "";
		Value040: string = "";
		Value041: string = "";
		Value042: string = "";
		Value043: string = "";
		Value044: string = "";
		Value045: string = "";
		Value046: string = "";
		Value047: string = "";
		Value048: string = "";
		Value049: string = "";
		Value050: string = "";
		Value051: string = "";
		Value052: string = "";
		Value053: string = "";
		Value054: string = "";
		Value055: string = "";
		Value056: string = "";
		Value057: string = "";
		Value058: string = "";
		Value059: string = "";
		Value060: string = "";
		Value061: string = "";
		Value062: string = "";
		Value063: string = "";
		Value064: string = "";
		Value065: string = "";
		Value066: string = "";
		Value067: string = "";
		Value068: string = "";
		Value069: string = "";
		Value070: string = "";
		Value071: string = "";
		Value072: string = "";
		Value073: string = "";
		Value074: string = "";
		Value075: string = "";
		Value076: string = "";
		Value077: string = "";
		Value078: string = "";
		Value079: string = "";
		Value080: string = "";
		Value081: string = "";
		Value082: string = "";
		Value083: string = "";
		Value084: string = "";
		Value085: string = "";
		Value086: string = "";
		Value087: string = "";
		Value088: string = "";
		Value089: string = "";
		Value090: string = "";
		Value091: string = "";
		Value092: string = "";
		Value093: string = "";
		Value094: string = "";
		Value095: string = "";
		Value096: string = "";
		Value097: string = "";
		Value098: string = "";
		Value099: string = "";
		Value100: string = "";
		RawData: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UsageDataSourceEditViewModel {
		UsageDataSourceId: number = null;
		ExternalUsageDataSourceId: string = null;
		Description: string = "";
		DataCaptureMethod: string = "";
		DataCaptureInformation: string = "";
		UsageLogFile: string = "";
		ExternalUsageDataLayoutId: string = null;
		UsageStorageType: string = "";
		TimeZoneId: string = null;
		ServiceType: string = "";
		OutgoingConnectSeconds: number = 0;
		DurationAdjustmentFactor: number = 0;
		ImportMinimumSeconds: number = 0;
		MinimumSeconds: number = 0;
		ForceCompletedSeconds: number = 0;
		InferUsageType: boolean = false;
		CheckDuplicates: boolean = false;
		SupportDirectionInternal: boolean = false;
		ExtensionLength: number = 0;
		BaseExtension: number = 0;
		MaximumTargetIdentifierLength: number = 0;
		AssociateInProgressUsage: boolean = false;
		AssociationLookupOptions: string = "";
		AutoAddServiceIdentification: boolean = false;
		AutoAddTelecomLocation: boolean = false;
		AutoAddNetworkElement: string = "";
		AutoAddPortGroupAndPort: boolean = false;
		AutoAddAccountCode: string = "";
		AutoAddAuthorizationCode: string = "";
		AutoAddExtension: string = "";
		AutoAddSourceIdentifier: string = "";
		FormattingOptions: string = "";
		ImportEngineOptions: string[] = [];
		MediationOptions: string[] = [];
		UsageDataBackupRetentionDays: number = 0;
		LogRawUsageDataSampleSize: number = 0;
		TraceImportProcessSampleSize: number = 0;
		TraceRatingProcessSampleSize: number = 0;
		UsageDataBatchSize: number = 0;
		IsoCurrencyCode: string = "";
		TelecomLocationProfileId: number = null;
		TelecomHomeCountryCode: string = "";
		TelecomHomeNpa: string = "";
		TelecomHomeNxx: string = "";
		TelecomHomeLata: number = 0;
		TelecomHomeState: string = "";
		TelecomNumberingPlanId: number = null;
		NumberFormat: string = "";
		AccessCodesLongDistance: string = "";
		AccessCodesInternational: string = "";
		AccessCodesFree: string = "";
		AccessCodesOperator: string = "";
		AccessCodesInternationalOperator: string = "";
		AccessCodesLocalInformation: string = "";
		AccessCodesLongDistanceInformation: string = "";
		AccessCodesEmergency: string = "";
		AccessCodesException: string = "";
		DropDigits: string = "";
		FlagNoDirectoryEntry: string = "";
		FlagNoCustomer1: string = "";
		FlagNoCustomer2: string = "";
		FlagCustomer12Mismatch: string = "";
		FlagNoAgent1: string = "";
		FlagNoAgent2: string = "";
		FlagNoServiceProvider1: string = "";
		FlagNoServiceProvider2: string = "";
		FlagNoServiceProvider3: string = "";
		FlagNoR1RatingProfile: string = "";
		FlagNoR2RatingProfile: string = "";
		FlagNoA1RatingProfile: string = "";
		FlagNoA2RatingProfile: string = "";
		FlagNoW1RatingProfile: string = "";
		FlagNoW2RatingProfile: string = "";
		FlagNoW3RatingProfile: string = "";
		FlagNoT1RatingProfile: string = "";
		LastImportDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UsageImportLogEditViewModel {
		UsageImportLogId: string = null;
		Description: string = "";
		UsageDataSourceId: number = null;
		JobId: number = null;
		JobSessionId: number = null;
		ImportDateTime: Date = null;
		ImportType: string = "";
		LogFileName: string = "";
		Status: string = "";
		ResultCode: number = 0;
		ResultMessage: string = "";
		Cancelled: boolean = false;
		PercentDone: number = 0;
		ProcessingServer: string = "";
		ProcessingDateTime: Date = null;
		ArchiveFileName: string = "";
		BackupFileName: string = "";
		ErrorFileName: string = "";
		WarningFileName: string = "";
		OmittedFileName: string = "";
		AlarmFileName: string = "";
		InfoFileName: string = "";
		TotalRecordCount: number = 0;
		ImportedRecordCount: number = 0;
		BlankRecordCount: number = 0;
		ErrorRecordCount: number = 0;
		WarningRecordCount: number = 0;
		OmittedRecordCount: number = 0;
		AlarmRecordCount: number = 0;
		CompletedRecordCount: number = 0;
		IncompleteRecordCount: number = 0;
		DirectionIncomingRecordCount: number = 0;
		DirectionOutgoingRecordCount: number = 0;
		DirectionInternalRecordCount: number = 0;
		DirectionExternalRecordCount: number = 0;
		AverageImportSpeedRecordsPerSecond: number = 0;
		Flags: string = "";
		Properties: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataLightModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class UsageServiceIdentificationEditViewModel {
		UsageServiceIdentificationId: number = null;
		Description: string = "";
		ServiceType: string = "";
		ServiceId: string = null;
		UsageDescription: string = "";
		UsageDetail: string = "";
		UsageType: string = "";
		ClassificationType: string[] = [];
		Tier: number = 0;
		ValidStartDateTime: Date = null;
		ValidEndDateTime: Date = null;
		Flags: string = "";
		Properties: string = "";
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

