import { Component, OnInit, Input } from '@angular/core';
import { CustomContentBaseClass } from '../custom-content-base-class';

@Component({
  selector: 'app-purchase-overview',
  templateUrl: './purchase-overview.component.html',
  styleUrls: ['./purchase-overview.component.scss']
})
export class PurchaseOverviewComponent extends CustomContentBaseClass implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
