import { Pipe, PipeTransform } from '@angular/core';
import { Helper, Log } from '../helpers/helper';
import * as Enumerable from 'linq';

@Pipe({
  name: 'page'
})
export class PagePipe implements PipeTransform {

  public transform(value: any[], pageNumber: number, objectsPerPage: number): any[] {

    if (!value || !Helper.isArray(value) || value.length === 0) {
      return [];
    }
    if (!pageNumber) {
      pageNumber = 1;
      Log.warningMessage("No page number specified.  Defaulting to page 1.  This pipe uses 1-based page numbers.");
    }
    if (!objectsPerPage) {
      objectsPerPage = 10;
      Log.warningMessage("No objects per page specified.  Defaulting to 10.");
    }

    return Enumerable.from(value).skip(objectsPerPage * (pageNumber - 1)).take(objectsPerPage).toArray();

  }

}
