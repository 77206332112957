import { Pipe, PipeTransform } from '@angular/core';
import { ContactService } from '../services/contact.service';

@Pipe({
  name: 'contactTypeDescription'
})
export class ContactTypeDescriptionPipe implements PipeTransform {

  constructor(protected contactService: ContactService) { }

  public transform(contactType: any): string {

    if (!contactType) {
      return "";
    }

    return this.contactService.getContactTypeDescription(contactType);

  }

}
