import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(protected router: Router) { }

  ngOnInit() {
    // For now redirect to intro
    //console.error("ready to redirect");
    this.router.navigate(["/", "overview", "introduction"]);
  }

}
