<div class="{{wrapperClass}}">
  <div *ngIf="headerIcon || headerText || addButtonIcon || addButtonText">
    <div *ngIf="headerIcon || headerText" class="{{headerClass}} float-left mr-2">
      <ib-icon icon="{{headerIcon}}" label="{{headerText}}"></ib-icon>
    </div>
    <div *ngIf="!disabled && !readonly && ( addButtonIcon || addButtonText )" class="{{headerClass}} float-left mr-2">
      <ib-button icon="{{addButtonIcon}}" label="{{addButtonText}}" color="{{addButtonColor}}" size="{{addButtonSize}}"
        tooltip="Add {{itemLabel}}" (click)="add($event)"></ib-button>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="{{itemsWrapperClass}}">
    <ng-container *ngIf="modelIsJsonString && obj">
      <div *ngFor="let key of Object.keys(obj); let i = index; trackBy: trackByFn" class="{{itemClass}}">
        <div class="float-left">{{key}} : {{obj[key]}}</div>
        <div class="float-left ml-2">
          <ib-action-button *ngIf="!disabled && !readonly" [button]="actionButton" [data]="key"
            [cargo]="{ itemIndex: i }"></ib-action-button>
        </div>
        <div class="clearfix"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!modelIsJsonString && value">
      <div *ngFor="let key of Object.keys(value); let i = index; trackBy: trackByFn" class="{{itemClass}}">
        <div class="float-left">{{key}} : {{value[key]}}</div>
        <div class="float-left ml-2">
          <ib-action-button *ngIf="!disabled && !readonly" [button]="actionButton" [data]="key"
            [cargo]="{ itemIndex: i }"></ib-action-button>
        </div>
        <div class="clearfix"></div>
      </div>
    </ng-container>
  </div>
</div>
