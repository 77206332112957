<div class="{{buttonWrapperClass}}" [style]="'display:inline;' + buttonWrapperStyle | safe : 'style'">
  <button type="submit" class="btn btn-xs btn-light btn-outline-dark {{buttonClass}}" [style]="buttonStyle | safe : 'style'" (click)="visible = !visible;">
    <i [hidden]="visible" class="far fa-angle-double-down"></i>
    <i [hidden]="!visible" class="far fa-angle-double-up"></i>
  </button>
</div>
<div class="clearfix" *ngIf="visible && clearFloats"></div>
<div [hidden]="!visible">
  <ng-content></ng-content>
</div>
<div class="clearfix" *ngIf="visible && clearFloats"></div>
