<div>

  <h3>{{appName}} Terms of Use</h3>

  <h5><em>Last Updated: October 20, 2015</em></h5>
  <br />


  <br />
  <h4>
    Acceptance of Terms
  </h4>
  <p>
    The services that {{appName}} provides to you are subject to the following Terms of Use ("TOU"). {{appName}} reserves the right to
    update the TOU at any time without notice to you. The most current version of the TOU can be reviewed by clicking on the "Terms of Use"
    hypertext link located at our web site.
  </p>

  <br />
  <h4>
    Description of Services
  </h4>
  <p>
    Through its web sites, {{appName}} provides you with access to a variety of resources, including developer tools, download areas,
    communication forums and product information (collectively "Services"). The Services, including any updates, enhancements, new features,
    and/or the addition of any new web sites, are subject to the TOU.
  </p>
  <p>
    You are responsible for all of your activity in connection with the Services. Additionally, you agree to abide by all applicable local,
    state, national and international laws and regulations and, if you represent a business, any advertising, marketing, privacy, or
    other self-regulatory code(s) applicable to your industry.
  </p>

  <br />
  <h4>
    Things You Cannot Do
  </h4>
  <ul>
    <li>
      Take any action that imposes or may impose (as determined by {{appName}} in its sole discretion) an unreasonable or
      disproportionately large load on {{appName}}'s (or its third party providers') infrastructure;
    </li>
    <li>
      Interfere or attempt to interfere with the proper working of the Services or any activities conducted on the services;
    </li>
    <li>
      Bypass any measures {{appName}} may use to prevent or restrict access to Services (or other accounts, computer systems
      or networks connected to the Services) or tamper with, or use non-public areas of the Services, or the computer or
      delivery systems of {{appName}} and/or its service providers;
    </li>
    <li>
      Run any form of auto-responder or "spam" on the Services;
    </li>
    <li>
      Use manual or automated software, devices, or other processes to access or search or attempt to access or search the
      Services by any means other than through currently available, published interfaces that are provided by {{appName}}
      (and only pursuant to those terms and conditions) or unless permitted by {{appName}}’s robots.txt file or other
      robot exclusion mechanisms;
    </li>
    <li>
      Harvest or scrape any content from the Services;
    </li>
    <li>
      Probe, scan, or test any system or network (particularly for vulnerabilities), or otherwise attempt to breach or
      circumvent any security or authentication measures;
    </li>
    <li>
      Use the Services to send altered, deceptive, or false source-identifying information, including without limitation
      by forging TCP-IP packet headers or email headers;
    </li>
    <li>
      Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display,
      republish, repurpose, sell, trade, or in any way exploit the Services or content (other than your User Submissions
      (as defined in the Terms of Use)), except as expressly authorized by {{appName}};
    </li>
    <li>
      Decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or
      algorithms of any part of the Services (including without limitation any application or widget), except to the limited
      extent applicable laws specifically prohibit such restriction;
    </li>
    <li>
      Copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder;
    </li>
    <li>
      Otherwise take any action in violation of {{appName}}'s guidelines and policies incorporated in the Terms of Use.
    </li>
    <li>
      You cannot post any content on or through the Services that:
      <ul>
        <li>
          Infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any other
          person or entity or violates any law or contractual duty;
        </li>
        <li>
          You know is false, misleading, untruthful or inaccurate;
        </li>
        <li>
          Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious,
          obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity, contains or depicts sexual activity, promotes bigotry,
          discrimination or violence, or is otherwise inappropriate as determined by {{appName}} in its sole discretion;
        </li>
        <li>
          Constitutes unauthorized or unsolicited advertising, junk or bulk e-mail ("spamming");
        </li>
        <li>
          Contains software viruses or any other computer codes, files, worms, logic bombs or programs that are designed or intended to
          disrupt, disable, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment
          or to damage or obtain unauthorized access to any system, data, password or other information of {{appName}} or any third party;
        </li>
        <li>
          Impersonates any person or entity, including any employee or representative of {{appName}};
        </li>
        <li>
          Includes anyone's identification documents or sensitive financial information;
        </li>
        <li>
          Breaches any of the other policies and rules incorporated in the Terms of Use.
        </li>
        <li>
          Violates {{appName}}'s Photo Guidelines. {{appName}} has a zero-tolerance policy against child pornography, and will terminate
          and report to the appropriate authorities any user who publishes or distributes child pornography.
        </li>
      </ul>
    </li>
  </ul>


  <br />
  <h4>
    Notice Specific to Content Available
  </h4>
  <p>
    {{appName}} does not guarantee that any content or User Submissions will be made available on the Site or through the Services. {{appName}} has no
    obligation to monitor the Services, content, or User Submissions. However, {{appName}} reserves the right to (i) remove, suspend, edit or modify
    any content in its sole discretion, including without limitation any User Submissions at any time, without notice to you and for any reason
    (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such content or if
    {{appName}} is concerned that you may have violated the Terms of Use), or for no reason at all and (ii) to remove, suspend or block any User
    Submissions from the Services. {{appName}} also reserves the right to access, read, preserve, and disclose any information as {{appName}}
    reasonably believes is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce the Terms of Use,
    including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues,
    (iv) respond to user support requests, or (v) protect the rights, property or safety of {{appName}}, its users and the public.
  </p>


  <br />
  <h4>
    Notice Specific to Software Available
  </h4>
  <p>
    Any software that is made available to download from the Services ("Software") is the copyrighted work of {{appName}} and/or its suppliers.
    Use of the Software is governed by the terms of the end user license agreement, if any, which accompanies or is included with the
    Software ("License Agreement"). An end user will be unable to install any Software that is accompanied by or includes a License Agreement,
    unless he or she first agrees to the License Agreement terms.
  </p>

  <p>
    The Software is made available for download solely for use by end users according to the License Agreement. Any reproduction or redistribution of the
    Software not in accordance with the License Agreement is expressly prohibited by law, and may result in severe civil and criminal penalties.
    Violators will be prosecuted to the maximum extent possible.
  </p>

  <p>
    WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER REPRODUCTION OR REDISTRIBUTION
    IS EXPRESSLY PROHIBITED, UNLESS SUCH REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PERMITTED BY THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE.
  </p>

  <p>
    THE SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT. EXCEPT AS WARRANTED IN THE LICENSE AGREEMENT,
    {{appName | uppercase}} HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SOFTWARE, INCLUDING ALL WARRANTIES AND CONDITIONS OF
    MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
  </p>

  <p>
    FOR YOUR CONVENIENCE, {{appName | uppercase}} MAY MAKE AVAILABLE AS PART OF THE SERVICES OR IN ITS SOFTWARE PRODUCTS, TOOLS AND UTILITIES FOR USE AND/OR DOWNLOAD.
    {{appName | uppercase}} DOES NOT MAKE ANY ASSURANCES WITH REGARD TO THE ACCURACY OF THE RESULTS OR OUTPUT THAT DERIVES FROM SUCH USE OF ANY SUCH TOOLS AND
    UTILITIES. PLEASE RESPECT THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS WHEN USING THE TOOLS AND UTILITIES MADE AVAILABLE ON THE SERVICES OR IN
    {{appName | uppercase}} SOFTWARE PRODUCTS.
  </p>

  <br />
  <h4>
    Notice Specific to Documents Available
  </h4>
  <p>
    Permission to use Documents (such as white papers, press releases, datasheets and FAQs) from the Services is granted, provided that (1) the below
    copyright notice appears in all copies and that both the copyright notice and this permission notice appear, (2) use of such Documents from the
    Services is for informational and non-commercial or personal use only and will not be copied or posted on any network computer or broadcast in any
    media, and (3) no modifications of any Documents are made. Accredited educational institutions, such as K-12, universities, private/public colleges,
    and state community colleges, may download and reproduce the Documents for distribution in the classroom. Distribution outside the classroom requires
    express written permission. Use for any other purpose is expressly prohibited by law, and may result in severe civil and criminal penalties.
    Violators will be prosecuted to the maximum extent possible.
  </p>

  <p>
    {{appName | uppercase}} AND/OR ITS RESPECTIVE SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED
    GRAPHICS PUBLISHED AS PART OF THE SERVICES FOR ANY PURPOSE. ALL SUCH DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND.
    {{appName}} AND/OR ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES
    AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT
    SHALL {{appName | uppercase}} AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING
    FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE
    USE OR PERFORMANCE OF INFORMATION AVAILABLE FROM THE SERVICES.
  </p>

  <p>
    THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE SERVICES COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY
    ADDED TO THE INFORMATION HEREIN. {{appName | uppercase}} AND/OR ITS RESPECTIVE SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S) AND/OR
    THE PROGRAM(S) DESCRIBED HEREIN AT ANY TIME.
  </p>

  <br />
  <h4>
    Notice Regarding Content, Software, Documents, and Services Available
  </h4>
  <p>
    IN NO EVENT SHALL {{appName | uppercase}} AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
    RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION
    WITH THE USE OR PERFORMANCE OF SOFTWARE, DOCUMENTS, PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR INFORMATION AVAILABLE FROM THE SERVICES.
  </p>

  <p>
    {{appName}} reserves the right at all times to disclose any information as {{appName}} deems necessary to satisfy any applicable law, regulation,
    legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in {{appName}}'s sole discretion.
  </p>

  <br />
  <h4>
    Links to Third Party Sites
  </h4>
  <p>
    THE LINKS IN THIS AREA WILL LET YOU LEAVE THIS {{appName | uppercase}} SITE. THE LINKED SITES ARE NOT UNDER THE CONTROL OF {{appName | uppercase}} AND {{appName | uppercase}} IS NOT
    RESPONSIBLE FOR THE CONTENTS OF ANY LINKED SITE OR ANY LINK CONTAINED IN A LINKED SITE, OR ANY CHANGES OR UPDATES TO SUCH SITES. {{appName | uppercase}} IS
    PROVIDING THESE LINKS TO YOU ONLY AS A CONVENIENCE, AND THE INCLUSION OF ANY LINK DOES NOT IMPLY ENDORSEMENT BY {{appName | uppercase}} OF THE SITE.
  </p>

  <br />
  <h4>
    Copyright Notice
  </h4>
  <p>
    Copyright 2015-2019 {{appName}} and/or its licensors. All rights reserved.
  </p>



</div>
