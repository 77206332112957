<div class="row" style="padding-top:20px;">
  <div class="{{Constants.Layout.percent25Offset}} {{Constants.Layout.split2column}}">
    <div class="card">
      <h5 class="card-header">
        <ng-container *ngIf="reason === 'reset'">Reset Password</ng-container>
        <ng-container *ngIf="reason !== 'reset'">Change Password</ng-container>
      </h5>
      <div class="card-body">
        <div *ngIf="!passwordChanged && infoMessage" class="alert alert-info" role="alert">
          {{infoMessage}}
        </div>
        <ib-input-text name="Login" label="Login" [readonly]="passwordChanged" [autofocus]="true" [(ngModel)]="data.Login"></ib-input-text>
        <ib-input-text *ngIf="reason !== 'reset'" name="Password" label="Password" type="password" [readonly]="passwordChanged" [(ngModel)]="data.CurrentPassword"></ib-input-text>
        <ib-input-text name="New Password" label="New Password" type="password" [readonly]="passwordChanged" [(ngModel)]="data.NewPassword"></ib-input-text>
        <ib-input-text name="Confirm Password" label="Confirm Password" type="password" (keyUpIsEnter)="resetPassword($event)" [readonly]="passwordChanged" [(ngModel)]="data.NewPasswordConfirmed"></ib-input-text>
        <ib-input-wrapper label="">
          <ib-security-policy-password-compliance [policy]="policy"
                                                  [doubleEntry]="true"
                                                  [login]="data.Login"
                                                  [password1]="data.NewPassword"
                                                  [password2]="data.NewPasswordConfirmed">
          </ib-security-policy-password-compliance>
        </ib-input-wrapper>
        <ib-input-wrapper label="">
          <ib-button *ngIf="!passwordChanged && reason === 'reset'" color="success" label="Reset Password" (click)="resetPassword($event)"></ib-button>
          <ib-button *ngIf="!passwordChanged && reason !== 'reset'" color="success" label="Change Password" (click)="resetPassword($event)"></ib-button>
          <a *ngIf="includeReturnToLoginLink" [routerLink]="['/login']" [ngClass]="{'btn btn-success': passwordChanged, 'ml-4': !passwordChanged}">Return to login</a>
        </ib-input-wrapper>
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
          <span [innerHtml]="errorMessage | safe: 'html'"></span>
        </div>
        <div *ngIf="responseMessage" class="alert alert-warning" role="alert">
          {{responseMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
