<h4>Possible Result Codes</h4>

<p>
  When the response ResultCode is SubsystemSpecificError (100) the purchase response and/or package response objects may have one of the following values for the ResponseCode:
</p>

<table class="table table-striped table-bordered table-sm table-hover">
  <thead>
    <tr>
      <th>ResponseCode</th>
      <th>Purchase Response Text</th>
      <th>Http Status Code</th>
      <th>Message</th>
      <th>Comments</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>0</td>
      <td>Success</td>
      <td>OK (200)</td>
      <td>Ok - Purchase Successful. </td>
      <td></td>
    </tr>
    <tr>
      <td>1</td>
      <td>PartialSuccess</td>
      <td>OK (200)</td>
      <td>Ok - Purchase Partially Successful. </td>
      <td>Some packages succeeded and some failed.</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Pending</td>
      <td>OK (200)</td>
      <td>Ok - Purchase Pending. </td>
      <td>The purchase transaction is pending action.  Check status later.</td>
    </tr>
    <tr>
      <td>110</td>
      <td>AuthorizationCodeAddError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Authorization Code. </td>
      <td></td>
    </tr>
    <tr>
      <td>120</td>
      <td>AuthorizationCodeAlreadyRegistered</td>
      <td>Forbidden (403)</td>
      <td>Authorization Code already active. </td>
      <td></td>
    </tr>
    <tr>
      <td>130</td>
      <td>AuthorizationCodeCreationError</td>
      <td>Forbidden (403)</td>
      <td>Error creating authorization code. </td>
      <td></td>
    </tr>
    <tr>
      <td>140</td>
      <td>AuthorizationCodeValidDateRangeError</td>
      <td>Forbidden (403)</td>
      <td>Authorization code valid date range is invalid. </td>
      <td></td>
    </tr>
    <tr>
      <td>210</td>
      <td>PackageOccurrenceAddError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Package Occurrence. </td>
      <td></td>
    </tr>
    <tr>
      <td>220</td>
      <td>PackageOccurrenceAttributeError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Package Attribute. </td>
      <td></td>
    </tr>
    <tr>
      <td>230</td>
      <td>PackageNotFound</td>
      <td>Not Found (404)</td>
      <td>Package(s) Not Found. </td>
      <td></td>
    </tr>
    <tr>
      <td>240</td>
      <td>PackageValidationError</td>
      <td>Forbidden (403)</td>
      <td>Package(s) Not Valid. </td>
      <td></td>
    </tr>
    <tr>
      <td>310</td>
      <td>BillingAccountAddError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Billing Account. </td>
      <td></td>
    </tr>
    <tr>
      <td>320</td>
      <td>BillingAccountNotFound</td>
      <td>Not Found (404)</td>
      <td>Billing Account Not Found. </td>
      <td></td>
    </tr>
    <tr>
      <td>321</td>
      <td>BillingAccountTooManyFound</td>
      <td>Bad Request (400)</td>
      <td>No billing account was specified and the customer has more than one billing account.</td>
      <td></td>
    </tr>
    <tr>
      <td>410</td>
      <td>ContactAddError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Contact. </td>
      <td></td>
    </tr>
    <tr>
      <td>420</td>
      <td>ContactNotFound</td>
      <td>Not Found (404)</td>
      <td>Contact Not Found. </td>
      <td></td>
    </tr>
    <tr>
      <td>421</td>
      <td>ContactTooManyFound</td>
      <td>Bad Request (400)</td>
      <td>No contact was specified and more than one possible match was found.</td>
      <td></td>
    </tr>
    <tr>
      <td>510</td>
      <td>PaymentMethodAddError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Payment Method. </td>
      <td></td>
    </tr>
    <tr>
      <td>511</td>
      <td>PaymentMethodNotFound</td>
      <td>Not Found (404)</td>
      <td>Payment Method Not Found. </td>
      <td></td>
    </tr>
    <tr>
      <td>515</td>
      <td>PaymentMethodEncryptionTagNotFound</td>
      <td>Not Found (404)</td>
      <td>Specified encryption tag not found. </td>
      <td></td>
    </tr>
    <tr>
      <td>516</td>
      <td>PaymentMethodEncryptionError</td>
      <td>Bad Request (400)</td>
      <td>Error Decrypting Payment Method Information. </td>
      <td></td>
    </tr>
    <tr>
      <td>520</td>
      <td>PaymentMethodMissingStreetAddress</td>
      <td>Payment Required (402)</td>
      <td>Missing Billing Street Address. </td>
      <td></td>
    </tr>
    <tr>
      <td>530</td>
      <td>PaymentMethodMissingCity</td>
      <td>Payment Required (402)</td>
      <td>Missing Billing City. </td>
      <td></td>
    </tr>
    <tr>
      <td>540</td>
      <td>PaymentMethodMissingPostalCode</td>
      <td>Payment Required (402)</td>
      <td>Missing Billing Postal Code. </td>
      <td></td>
    </tr>
    <tr>
      <td>550</td>
      <td>PaymentMethodMissingCardNumber</td>
      <td>Payment Required (402)</td>
      <td>Missing Card Number. </td>
      <td></td>
    </tr>
    <tr>
      <td>560</td>
      <td>PaymentMethodMissingCardExpiration</td>
      <td>Payment Required (402)</td>
      <td>Missing Card Expiration. </td>
      <td></td>
    </tr>
    <tr>
      <td>570</td>
      <td>PaymentMethodMissingCardId</td>
      <td>Payment Required (402)</td>
      <td>Missing Card Id (e.g. CVV2). </td>
      <td></td>
    </tr>
    <tr>
      <td>575</td>
      <td>PaymentMethodMissingExternalPaymentProviderCredential</td>
      <td>Payment Required (402)</td>
      <td>Payment Method Missing External Payment Provider Credential</td>
      <td></td>
    </tr>
    <tr>
      <td>580</td>
      <td>PaymentMethodCardNumberNotValid</td>
      <td>Payment Required (402)</td>
      <td>Card number is not valid. </td>
      <td></td>
    </tr>
    <tr>
      <td>590</td>
      <td>PaymentMethodCardExpired</td>
      <td>Payment Required (402)</td>
      <td>Card is expired. </td>
      <td></td>
    </tr>
    <tr>
      <td>601</td>
      <td>PaymentProviderNotFound</td>
      <td>Payment Required (402)</td>
      <td>Unable to find Payment Provider. </td>
      <td></td>
    </tr>
    <tr>
      <td>610</td>
      <td>PaymentTransactionAddError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Adding Payment Transaction. </td>
      <td></td>
    </tr>
    <tr>
      <td>620</td>
      <td>PaymentTransactionWatchError</td>
      <td>Internal Server Error (500)</td>
      <td>Error Watching Payment Transaction. </td>
      <td></td>
    </tr>
    <tr>
      <td>630</td>
      <td>PaymentTransactionTimeout</td>
      <td>Internal Server Error (500)</td>
      <td>Timeout waiting for transaction to complete. </td>
      <td></td>
    </tr>
    <!--<tr>
        <td>635</td>
        <td>PaymentTransactionTimeoutComplimentaryRequestNotSupported</td>
        <td>Internal Server Error (500)</td>
        <td>Timeout waiting for transaction to complete. </td>
        <td></td>
    </tr>-->
    <tr>
      <td>640</td>
      <td>PaymentTransacitonProcessingError</td>
      <td>Internal Server Error (500)</td>
      <td>Payment gateway processing error. </td>
      <td></td>
    </tr>
    <tr>
      <td>650</td>
      <td>PaymentTransactionDuplicated</td>
      <td>OK (200)</td>
      <td>Payment request duplicated. </td>
      <td>This is a duplicate submission and is being ignored.</td>
    </tr>
    <tr>
      <td>670</td>
      <td>PaymentTransactionCardDeclined</td>
      <td>Payment Required (402)</td>
      <td>Card declined. </td>
      <td></td>
    </tr>
    <tr>
      <td>671</td>
      <td>PaymentTransactionCardDoNotHonor</td>
      <td>Payment Required (402)</td>
      <td>Card do not honor. </td>
      <td></td>
    </tr>
    <tr>
      <td>672</td>
      <td>PaymentTransactionCardLostOrStolen</td>
      <td>Payment Required (402)</td>
      <td>Card lost or stolen. </td>
      <td></td>
    </tr>
    <tr>
      <td>680</td>
      <td>PaymentTransactionFailed</td>
      <td>Payment Required (402)</td>
      <td>Payment Transaction Failed. </td>
      <td></td>
    </tr>
    <tr>
      <td>681</td>
      <td>PaymentTransactionFailedAvs</td>
      <td>Payment Required (402)</td>
      <td>Failed address verification. </td>
      <td></td>
    </tr>
    <tr>
      <td>682</td>
      <td>PaymentTransactionFailedCardId</td>
      <td>Payment Required (402)</td>
      <td>Card Id (e.g. CVV2) invalid. </td>
      <td></td>
    </tr>
    <tr>
      <td>683</td>
      <td>PaymentTransactionFailedCreditLimit</td>
      <td>Payment Required (402)</td>
      <td>Credit limit exceeded. </td>
      <td></td>
    </tr>
    <tr>
      <td>684</td>
      <td>PaymentTransactionFailedInvalidInformation</td>
      <td>Payment Required (402)</td>
      <td>Invalid card information. </td>
      <td></td>
    </tr>
    <tr>
      <td>685</td>
      <td>PaymentTransactionFailedInvalidCardNumber</td>
      <td>Payment Required (402)</td>
      <td>Invalid card number. </td>
      <td></td>
    </tr>
    <tr>
      <td>686</td>
      <td>PaymentTransactionFailedInvalidExpirationDate</td>
      <td>Payment Required (402)</td>
      <td>Invalid card expiration date. </td>
      <td></td>
    </tr>
    <tr>
      <td>710</td>
      <td>VoucherNotFound</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher was not found.</td>
      <td></td>
    </tr>
    <tr>
      <td>711</td>
      <td>VoucherOnHold</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher is on hold (not in distribution channel yet).</td>
      <td></td>
    </tr>
    <tr>
      <td>712</td>
      <td>VoucherExpired</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher is expired (hit expiration date from first use).</td>
      <td></td>
    </tr>
    <tr>
      <td>713</td>
      <td>VoucherLapsed</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher's shelf-life has lapsed (hit lapsed date from lot activation).</td>
      <td></td>
    </tr>
    <tr>
      <td>714</td>
      <td>VoucherSuspended</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher has been manually suspended.</td>
      <td></td>
    </tr>
    <tr>
      <td>715</td>
      <td>VourcherUsed</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher has been used and has no remaining uses left.</td>
      <td></td>
    </tr>
    <tr>
      <td>720</td>
      <td>VoucherStatusUnknown</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher's status cannot be determined.</td>
      <td></td>
    </tr>
    <tr>
      <td>721</td>
      <td>VoucherNotValidIsoCurrencyCode</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher is configured for a different currency and is not valid for this transaction.</td>
      <td></td>
    </tr>
    <tr>
      <td>722</td>
      <td>VoucherNotValidVisibility</td>
      <td>Payment Required (402)</td>
      <td>The specified voucher has visibility rules that do not match this transaction and, therefore, cannot be used for this transaction.</td>
      <td></td>
    </tr>
    <tr>
      <td>730</td>
      <td>VoucherLotNotPendingActivation</td>
      <td>Payment Required (402)</td>
      <td>The voucher lot for the specified voucher is not ready for activation.</td>
      <td></td>
    </tr>
    <tr>
      <td>990</td>
      <td>PurchaseRequestValidationError</td>
      <td>Forbidden (403)</td>
      <td>Purchase Request Not Valid. </td>
      <td></td>
    </tr>
    <tr>
      <td>-1</td>
      <td>Unknown</td>
      <td>Internal Server Error (500)</td>
      <td>Unknown Error. </td>
      <td></td>
    </tr>
  </tbody>
</table>

<p>
  Under other scenarios these result codes may be found in the response ResultCode:
</p>

<app-standard-result-codes></app-standard-result-codes>
