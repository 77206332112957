import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'defaultTextWhenEmpty'
})
export class DefaultTextWhenEmptyPipe implements PipeTransform {

  public transform(value: string, alternates: string[]): string {
    return Helper.getFirstDefinedString(value, ...alternates);
  }

  //transform(value: any, args?: any): any {
  //  return null;
  //}

}
