<div class="{{window.innerWidth < 576 ? 'ml-auto mr-auto' : 'col-xl-' + width + ' col-lg-' + width + ' col-md-' + width*2 + ' col-sm-' + width*3 + ' col-' + width*4 + ' float-left'}} narrow-gutter">
  <div class="card tile bg-{{color}} {{textClass}}" [style]="customStyle | safe : 'style'">
    <div class="card-header"
         [ngClass]="{'clickable': isClickable()}"
         (click)="onCardClick($event)">
      <!-- style="{{short?'padding-top: 2px; padding-bottom: 2px;':''}}{{width==1?'padding-left: 12px; padding-right: 12px;':''}}" -->
      <div class="row">
        <div class="col-3 narrow-gutter"> <!-- style="{{width===1?'padding-left:5px; padding-right:5px;':''}}" -->
          <i class="far fa-{{icon}} fa-{{short?'3':'5'}}x"></i>
        </div>
        <div class="col-9 narrow-gutter text-right"> <!-- style="{{width===1?'padding-left:5px; padding-right:5px;':''}}" -->
          <div class="{{short?'less-huge':'huge'}}" *ngIf="value !== null && value !== undefined">{{value | number}}</div>
          <div class="{{short?'less-huge':'huge'}}" *ngIf="value === null || value === undefined"><i class="far fa-{{valuePendingIcon}} fa-spin"></i></div>
          <div>{{text}}</div>
        </div>
      </div>
    </div>
    <!--<a href="{{linkUrl}}" target="{{linkOpenUrlInNewWindow ? '_blank' : '_self'}}">-->
    <div *ngIf="linkText" class="card-footer card-footer-small"
         [ngClass]="{'clickable': isClickable() || linkUrl}"
         (click)="onViewDetailsClick($event)"> <!--style="{{short?'padding-top: 2px; padding-bottom: 2px;':''}}{{width==1?'padding-left: 2px; padding-right: 2px;':''}}" -->
      <span class="float-left {{textClass}}" style="max-width:80%; overflow:hidden; white-space: nowrap; text-overflow: ellipsis;">{{linkText}}</span>
      <span class="float-right {{textClass}}"><i class="far fa-arrow-circle-right"></i></span>
      <div class="clearfix"></div>
    </div>
    <!--</a>-->
  </div>
</div>
