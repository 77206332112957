<pre>
{{'{'}}
    "Success": true,
    "ResultCode": 0,
    "ResultText": "Success",
    "Message": "Success",
    "Scope": {{'{'}}
        "TotalResults": 1,
        "IncludedResults": 1,
        "PageNumber": 1,
        "PageSize": 1,
        "PageCount": 1,
        "Sort": "",
        "FilterId": null,
        "Filter": "",
        "Q": "",
        "Expand": ""
    {{'}'}},
    "Data": null,
    "Meta": null,
    "Links": [
    {{'{'}}
      "Rel": "self",
      "Url": "http://...",
      "Method": "GET"
    {{'}'}}
    ],
    "Errors": [
    {{'{'}}
      "ResultCode": 0,
      "Type": "",
      "Subtype": "",
      "Reference": "",
      "Message": "",
      "Details": "",
      "Warning": false
    {{'}'}}
    ],
    "TimeStamp": "2014-05-28T12:30:07.2447Z",
    "Trace": [
    ""
    ]
{{'}'}}</pre>

<br />
    The common response properties include:
<br /><br />
<ul>
  <li><strong>Success</strong> - A boolean value indicating if the request was successful or not.</li>
  <li>
    <strong>ResultCode</strong> - The result code
    <ib-peek><app-standard-result-codes></app-standard-result-codes></ib-peek>
    of the request.  This provides additional insight into failed requests.
  </li>
  <li><strong>ResultText</strong> - The result text that provides information about the result of the request.</li>
  <li><strong>Message</strong> - A message detailing the result of the request.</li>
  <li>
    <strong>Scope</strong> - An object that includes scope information for requests that call for a collection of data results.
    <ul>
      <li><strong>TotalResults</strong> - The total number of results available in response to the request.</li>
      <li><strong>IncludedResults</strong> - The number of results included in this response.</li>
      <li><strong>PageNumber</strong> - The page number of this response.</li>
      <li><strong>PageSize</strong> - The page size of the response.</li>
      <li><strong>PageCount</strong> - The number of pages available with results.</li>
      <li><strong>Sort</strong> - The sort expression used for retrieving the results.</li>
      <li><strong>FilterId</strong> - The filter id of a saved filter used for retrieving the results.</li>
      <li><strong>Filter</strong> - The filter used for retrieving the results.</li>
      <li><strong>Q</strong> - Any free form filter text used for retrieving the results.</li>
      <li><strong>Expand</strong> - A comma delimited list of expanded objects in the results (if any).</li>
    </ul>
  </li>
  <li><strong>Data</strong> - The result data for the request.  Requests that do not call for data to be returned or which failed may have null or an empty object for this property.</li>
  <li>
    <strong>Meta</strong> - Meta data about the result data.  Typically, meta data needs to be enabled via the X-Meta-Data request header.
    Even then, not all results will include meta data and the contents will differ for different result types.
    Examples of things included with meta data:
    <ul>
      <li><strong>Options</strong> - For results with data properties that have a list of accepted values the possible values that are accepted will be enumerated here.</li>
      <li><strong>Attributes</strong> - For results with a data object that has attributes defined this documents the attribute properties.</li>
      <li><strong>Timestamps</strong> - For results that support reporting of timestamps this object holds keys that represent various steps in the process with values that represent the timestamp when each step occurred.  If not otherwise specified in the name of the key the timestamp is when the step started.</li>
      <li><strong>Durations</strong> - For results that support reporting of durations this object holds keys that represent various steps in the process with values that represent the duration in milliseconds that the step took to complete.</li>
      <li><strong>VersionConflictInformation</strong> - For edit results that failed with result code 1611 (ChangedByAnotherUser) this property will include version conflict information if any was available.</li>
    </ul>
  </li>
  <li>
    <strong>Links</strong> - A collection of links related to this response.
    <ul>
      <li><strong>Rel</strong> - The relationship of the link to the response.  For example: Self, First, Last, Next, Prev.</li>
      <li><strong>Url</strong> - The url of the link.</li>
      <li><strong>Method</strong> - The http method to use with the link.</li>
    </ul>
  </li>
  <li>
    <strong>Errors</strong> - A collection of errors related to this response.  Note that if the result code for the response indicates
    partial success this error collection may include not only errors but also information about each parts of the request so it is
    clear which parts of the request were successful and which failed.
    <ul>
      <li><strong>ResultCode</strong> - The result code related to this type of error.</li>
      <li><strong>Type</strong> - The type of error.</li>
      <li><strong>Subtype</strong> - The subtype of error.</li>
      <li><strong>Reference</strong> - The reference for the error.</li>
      <li><strong>Message</strong> - The error message.</li>
      <li><strong>Details</strong> - Any details that are available for the error.</li>
      <li><strong>Warning</strong> - If true the error object represents a warning.</li>
    </ul>
  </li>
  <li><strong>TimeStamp</strong> - The timestamp of the response.</li>
  <li><strong>Trace</strong> - A collection of strings that provides trace or debug information about the response.</li>
</ul>
