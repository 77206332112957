<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label *ngIf="( !vertical || required || label || labelPrefix || labelSuffix )"
         for="{{inputControlId}}"
         class="{{inputLabelClass}}"
         ngbTooltip="{{( tooltip | translate ) || tooltip}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)"
               ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    <ng-container *ngIf="!labelButton">
      {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    </ng-container>
  </label>
  <div class="{{inputWrapperClass}}">
    <div class="unit-inputs-wrapper">
      <div class="input-group {{inputGroupClass}} unit-input-group"
           *ngIf="showUnitField('day')">
        <input type="number"
               class="form-control {{inputSize}} time-input"
               ngbTooltip="Days"
               [disabled]="disabled ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               [required]="required ? 'required' : null"
               (change)="reportChanges()"
               [(ngModel)]="days"
               [ngModelOptions]="{standalone: standalone}" />

        <div class="input-group-append">
          <span class="input-group-text">day</span>
        </div>
      </div>
      <div class="input-group {{inputGroupClass}} unit-input-group"
           *ngIf="showUnitField('hour')">
        <input type="number"
               class="form-control {{inputSize}} time-input"
               ngbTooltip="Hours"
               [disabled]="disabled ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               [required]="required ? 'required' : null"
               (change)="reportChanges()"
               [(ngModel)]="hours"
               [ngModelOptions]="{standalone: standalone}" />
        <div class="input-group-append">
          <span class="input-group-text">hr</span>
        </div>
      </div>

      <div class="input-group {{inputGroupClass}} unit-input-group"
           *ngIf="showUnitField('minute')">
        <input type="number"
               class="form-control {{inputSize}} time-input"
               ngbTooltip="Minutes"
               [disabled]="disabled ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               [required]="required ? 'required' : null"
               (change)="reportChanges()"
               [(ngModel)]="minutes"
               [ngModelOptions]="{standalone: standalone}" />
        <div class="input-group-append">
          <span class="input-group-text">min</span>
        </div>
      </div>

      <div class="input-group {{inputGroupClass}} unit-input-group"
           *ngIf="showUnitField('second')">
        <input type="number"
               class="form-control {{inputSize}} time-input"
               ngbTooltip="Seconds"
               [disabled]="disabled ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               [required]="required ? 'required' : null"
               (change)="reportChanges()"
               [(ngModel)]="seconds"
               [ngModelOptions]="{standalone: standalone}" />
        <div class="input-group-append">
          <span class="input-group-text">sec</span>
        </div>
      </div>

      <div class="input-group {{inputGroupClass}} unit-input-group"
           *ngIf="showUnitField('ms')">
        <input type="number"
               class="form-control {{inputSize}} time-input"
               ngbTooltip="Milliseconds"
               [disabled]="disabled ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               [required]="required ? 'required' : null"
               (change)="reportChanges()"
               [(ngModel)]="ms"
               [ngModelOptions]="{standalone: standalone}" />
        <div class="input-group-append">
          <span class="input-group-text">ms</span>
        </div>
      </div>
    </div>
  </div>
</div>