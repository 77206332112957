import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonDataEditorOptions, EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { Log } from 'projects/core-lib/src/lib/helpers/helper';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';

@Component({
  selector: 'ib-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.css']
})
export class FormHeaderComponent implements OnInit, OnChanges, OnDestroy {

  /**
  Text (if any) to display in the header.
  */
  @Input() headerText: string = "";

  /**
  Text (if any) to use for the document title (e.g. shown in browser tab).  If set
  the destroy method will reset this back to default for the app.
  */
  @Input() documentTitle: string = "";

  /**
  Action button (if any) to display in the header.
  */
  @Input() actionButton: ButtonItem;

  /**
  Action button #2 (if any) to display in the header.
  */
  @Input() actionButton2: ButtonItem;

  /**
  Sometimes the action button needs to know about the data it will act on
  so this object gets attached to the event submitted with the action.
  */
  @Input() data: any;

  ///**
  //FormStatusModel which, if provided, can be used to determine button status
  //for optional save button.
  //*/
  //@Input() formStatus: FormStatusModel;

  /**
  Boolean which when set to true results in dirty but valid form having a save
  button shown in the header next to the action button.
  */
  @Input() showSaveButton: boolean = false;

  /**
  Boolean which when set to true means the form is in working mode which may
  impact the status of the optional save button since save is often disabled
  when already saving.
  */
  @Input() working: boolean = false;

  /**
  The event to fire if user clicks on the optional save button.
  */
  @Output() save: EventEmitter<EventModel> = new EventEmitter();

  /**
  Flag which we flip to true if the document title is set so on destroy we
  can reset to the app default.
  */
  protected documentTitleWasChanged: boolean = false;

  constructor(protected appService: AppService, public formService: FormStatusService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Set the document title if requested
    if (this.documentTitle) {
      this.appService.title = this.documentTitle;
      this.documentTitleWasChanged = true;
    }
  }

  ngOnDestroy() {
    // If we've been jacking with the doc title then reset it now
    if (this.documentTitleWasChanged) {
      this.appService.titleReset();
    }
  }


  protected fireSave($event) {
    // Do not emit any click events when the button is disabled
    if (this.formService && this.formService.isValid && !this.formService.isPristine) {
      let payload: EventModel = new EventModel("click", event, true, new EventElementModel("button", null, "Save"));
      this.save.emit(payload);
    }
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      //Log.errorMessage(err);
    }
  }


}
