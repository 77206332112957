<ib-peek-with-header [visible]="true">
  <h4 header>Complimentary Purchase</h4>
  <pre body>
{{'{'}}
  "Customer": {{'{'}}
    "FirstName": "Jon",
    "LastName": "Doe",
    "Email": "jon.doe@jondoefamilydomainname.name"
  {{'}'}},
  "Packages": [
    {{'{'}}
      "PackageCode": "WiFi",
      "RetailAmount": 10,
      "BillingAmount": 10,
      "AuthorizationCode": "WiFi-qweiorladsmfwe",
      "ValidStartDateTime": "2016-03-15T04:15:34",
      "ValidEndDateTime": "2016-03-16T04:15:33"
    {{'}'}}
  ],
  "Payment": {{'{'}}
    "Complimentary": true
  {{'}'}}
{{'}'}}
</pre>
</ib-peek-with-header>



<ib-peek-with-header [visible]="true" class="mt-2">
  <h4 header>Credit Card Purchase with Custom Attributes and Cargo Properties</h4>
  <pre body>
{{'{'}}
  "Customer": {{'{'}}
    "FirstName": "Jon",
    "LastName": "Doe",
    "Address1": "123 Main Street",
    "City": "Detroit",
    "State": "MI",
    "PostalCode": "48235",
    "Country": "US",
    "Phone": "313-555-1234",
    "Email": "jon.doe@jondoefamilydomainname.name"
  {{'}'}},
  "Packages": [
    {{'{'}}
      "PackageCode": "Movie",
      "RetailAmount": 5,
      "BillingAmount": 5,
      "AuthorizationCode": "Movie-383711-qweiorladsmfwe",
      "ValidStartDateTime": "2016-03-15T04:15:34",
      "ValidEndDateTime": "2016-03-16T04:15:33",
      "Attributes": {{'{'}}
            "ProductId": 383711,
            "ProductName": "Star Wars: The Force Awakens"
        {{'}'}}
    {{'}'}}
  ],
  "Payment": {{'{'}}
    "CreditCard": {{'{'}}
      "CreditCardNumber": "4111xxxxxxxx1111",
      "ExpirationDate": "2016-07-31T23:59:59",
      "CardId": "123"
    {{'}'}},
    "EmailReceipt": true
  {{'}'}},
  "Attributes": {{'{'}}
    "FlightNumber": "3928",
    "TailNumber": "XX22X",
    "OriginationCity": "DTW",
    "DestinationCity": "HOU"
    {{'}'}},
  "Cargo": {{'{'}}
    "SessionId": "5ce9d4ea-fdcc-45e9-b4a3-324b018e7837",
    "TransactionId": "5cfb369d-5669-4d8f-9ac1-a105ea323ab6"
    {{'}'}},
  "UtcOffset": 0
{{'}'}}
</pre>
</ib-peek-with-header>



<ib-peek-with-header [visible]="true" class="mt-2">
  <h4 header>Secondary Purchase From Same Customer</h4>
  <div body>
    <strong>First Purchase Request</strong>
    <pre>
{{'{'}}
  "Customer": {{'{'}}
    "FirstName": "Jon",
    "LastName": "Doe",
    "Address1": "123 Main Street",
    "City": "Detroit",
    "State": "MI",
    "PostalCode": "48235",
    "Country": "US",
    "Phone": "313-555-1234",
    "Email": "jon.doe@jondoefamilydomainname.name"
  {{'}'}},
  "Packages": [
    {{'{'}}
      "PackageCode": "Movie",
      "RetailAmount": 5,
      "BillingAmount": 5,
      "AuthorizationCode": "Movie-383711-qweiorladsmfwe",
      "ValidStartDateTime": "2016-03-15T04:15:34",
      "ValidEndDateTime": "2016-03-16T04:15:33",
      "Attributes": {{'{'}}
            "ProductId": 383711,
            "ProductName": "Star Wars: The Force Awakens"
        {{'}'}}
    {{'}'}}
  ],
  "Payment": {{'{'}}
    "CreditCard": {{'{'}}
      "CreditCardNumber": "4111xxxxxxxx1111",
      "ExpirationDate": "2016-07-31T23:59:59",
      "CardId": "123"
    {{'}'}},
    "EmailReceipt": true
  {{'}'}},
  "Attributes": {{'{'}}
    "FlightNumber": "3928",
    "TailNumber": "XX22X",
    "OriginationCity": "DTW",
    "DestinationCity": "HOU"
    {{'}'}},
  "Cargo": {{'{'}}
    "SessionId": "5ce9d4ea-fdcc-45e9-b4a3-324b018e7837",
    "TransactionId": "5cfb369d-5669-4d8f-9ac1-a105ea323ab6"
    {{'}'}},
  "UtcOffset": 0
{{'}'}}
</pre>

    <strong>Response to First Purchase Request</strong>
    <p>
      The response to the purchase request includes a CustomerId, BillingAccountId, and PaymentMethodId that can then be used
      in additional purchase requests from the same customer so those purchases can be associated with the same customer record.
    </p>
    <pre>
{{'{'}}
  "Links": [],
  "Errors": [],
  "Trace": [],
  "Success": true,
  "ResultCode": 0,
  "ResultText": "Success",
  "Scope": null,
  "TimeStamp": "2016-03-15T04:15:39",
  "Data": {{'{'}}
    "ResponseCode": 0,
    "ResponseMessage": "Success",
    "CustomerId": 32469424,
    "BillingAccountId": 673521,
    "PaymentMethodId": 4567190,
    "PaymentTransactionId": "201603-nv5n8d",
    "Packages": [
      {{'{'}}
        "ResponseCode": 0,
        "ResponseMessage": "Success",
        "PackageOccurrenceId": 8284766193,
        "PackageId": 4,
        "PackageType": "Entertainment",
        "PackageCode": "Movie",
        "PackageClass": "",
        "PaymentMethodId": 4567190,
        "PaymentTransactionId": "201603-nv5n8d",
        "BillingAmount": 5,
        "AuthorizationCode": "Movie-383711-qweiorladsmfwe",
        "ValidStartDateTime": "2016-03-15T04:15:34",
        "ValidEndDateTime": "2016-03-16T04:15:33",
        "Attributes": {{'{'}}
            "ProductId": 383711,
            "ProductName": "Star Wars: The Force Awakens"
        {{'}'}}
      {{'}'}}
    ],
    "ContactInformation": null,
    "Cargo": {{'{'}}
        "SessionId": "5ce9d4ea-fdcc-45e9-b4a3-324b018e7837",
        "TransactionId": "5cfb369d-5669-4d8f-9ac1-a105ea323ab6"
    {{'}'}},
    "UtcOffset": 0
  {{'}'}}
{{'}'}}
</pre>

    <strong>Second Purchase Request</strong>
    <p>
      When the customer makes a new purchase the CustomerId, BillingAccountId, and PaymentMethodId that were provided in response
      from the previous purchase request are used for this second purchase.  This provides the ability for follow on purchases
      to be associated with the same customer record.
    </p>
    <pre>
{{'{'}}
  "CustomerId": 32469424,
  "BillingAccountId": 673521, 
  "Packages": [
    {{'{'}}
      "PackageCode": "WiFi",
      "RetailAmount": 10,
      "BillingAmount": 10,
      "AuthorizationCode": "WiFi-qweiorladsmfwe",
      "ValidStartDateTime": "2016-03-15T04:22:51",
      "ValidEndDateTime": "2016-03-16T04:22:50"
    {{'}'}}
  ],
  "Payment": {{'{'}}
    "PaymentMethodId": 4567190
  {{'}'}}
{{'}'}}
</pre>
  </div>
</ib-peek-with-header>



<ib-peek-with-header [visible]="true" class="mt-2">
  <h4 header>Trying a Different Credit Card</h4>
  <div body>
    <strong>First Purchase Request</strong>
    <pre>
{{'{'}}
  "Customer": {{'{'}}
    "FirstName": "Jon",
    "LastName": "Doe",
    "Address1": "123 Main Street",
    "City": "Detroit",
    "State": "MI",
    "PostalCode": "48235",
    "Country": "US",
    "Phone": "313-555-1234",
    "Email": "jon.doe@jondoefamilydomainname.name"
  {{'}'}},
  "Packages": [
    {{'{'}}
      "PackageCode": "Movie",
      "RetailAmount": 5,
      "BillingAmount": 5,
      "AuthorizationCode": "Movie-383711-qweiorladsmfwe",
      "ValidStartDateTime": "2016-03-15T04:15:34",
      "ValidEndDateTime": "2016-03-16T04:15:33",
      "Attributes": {{'{'}}
            "ProductId": 383711,
            "ProductName": "Star Wars: The Force Awakens"
      {{'}'}}
    {{'}'}}
  ],
  "Payment": {{'{'}}
    "CreditCard": {{'{'}}
      "CreditCardNumber": "4111xxxxxxxx1111",
      "ExpirationDate": "2016-07-31T23:59:59",
      "CardId": "123"
    {{'}'}},
    "EmailReceipt": true
  {{'}'}},
  "Attributes": {{'{'}}
    "FlightNumber": "3928",
    "TailNumber": "XX22X",
    "OriginationCity": "DTW",
    "DestinationCity": "HOU"
  {{'}'}},
  "Cargo": {{'{'}}
    "SessionId": "5ce9d4ea-fdcc-45e9-b4a3-324b018e7837",
    "TransactionId": "5cfb369d-5669-4d8f-9ac1-a105ea323ab6"
  {{'}'}},
  "UtcOffset": 0
{{'}'}}
</pre>

    <strong>Response to First Purchase Request</strong>
    <p>
      The response to the purchase request indicates that it failed with response code 671 (PaymentTransactionCardDoNotHonor) but it does
      include a CustomerId and BillingAccountId that can then be used if the customer chooses a different payment method.
    </p>
    <pre>
{{'{'}}
  "Links": [],
  "Errors": [],
  "Trace": [],
  "Success": false,
  "ResultCode": 100,
  "ResultText": "PaymentTransactionCardDoNotHonor",
  "Scope": null,
  "TimeStamp": "2016-03-15T04:15:39",
  "Data": {{'{'}}
    "ResponseCode": 671,
    "ResponseMessage": "PaymentTransactionCardDoNotHonor",
    "CustomerId": 32469424,
    "BillingAccountId": 673521,
    "PaymentMethodId": null,
    "Packages": [
      {{'{'}}
        "ResponseCode": 671,
        "ResponseMessage": "PaymentTransactionCardDoNotHonor",
        "PackageOccurrenceId": null,
        "PackageId": 4,
        "PackageType": "Entertainment",
        "PackageCode": "Movie",
        "PackageClass": "",
        "PaymentMethodId": null,
        "PaymentTransactionId": null,
        "BillingAmount": null,
        "AuthorizationCode": null,
        "ValidStartDateTime": null,
        "ValidEndDateTime": null,
        "Attributes": {{'{'}}
            "ProductId": 383711,
            "ProductName": "Star Wars: The Force Awakens"
        {{'}'}}
      {{'}'}}
    ],
    "ContactInformation": null,
    "Cargo": {{'{'}}
        "SessionId": "5ce9d4ea-fdcc-45e9-b4a3-324b018e7837",
        "TransactionId": "5cfb369d-5669-4d8f-9ac1-a105ea323ab6"
    {{'}'}},
    "UtcOffset": 0
  {{'}'}}
{{'}'}}
</pre>

    <strong>Second Purchase Request</strong>
    <p>
      If the customer chooses to attempt the purchase again with a new credit card the CustomerId and BillingAccountId that were provided in response
      from the previous purchase request are used for this second purchase attempt.  This provides the ability for both purchase attempts to be
      to be associated with the same customer record.
    </p>
    <pre>
{{'{'}}
  "CustomerId": 32469424,
  "BillingAccountId": 673521, 
  "Packages": [
    {{'{'}}
      "PackageCode": "Movie",
      "RetailAmount": 5,
      "BillingAmount": 5,
      "AuthorizationCode": "Movie-383711-qweiorladsmfwe",
      "ValidStartDateTime": "2016-03-15T04:15:34",
      "ValidEndDateTime": "2016-03-16T04:15:33",
      "Attributes": {{'{'}}
            "ProductId": 383711,
            "ProductName": "Star Wars: The Force Awakens"
        {{'}'}}
    {{'}'}}
  ],
  "Payment": {{'{'}}
    "CreditCard": {{'{'}}
      "CreditCardNumber": "5222xxxxxxxx2222",
      "ExpirationDate": "2018-10-31T23:59:59",
      "CardId": "234"
    {{'}'}},
    "EmailReceipt": true
  {{'}'}},
  "Attributes": {{'{'}}
    "FlightNumber": "3928",
    "TailNumber": "XX22X",
    "OriginationCity": "DTW",
    "DestinationCity": "HOU"
  {{'}'}},
  "Cargo": {{'{'}}
    "SessionId": "5ce9d4ea-fdcc-45e9-b4a3-324b018e7837",
    "TransactionId": "523e37d1-f047-4733-91bd-98a79d647601"
  {{'}'}},
  "UtcOffset": 0
{{'}'}}
</pre>

  </div>
</ib-peek-with-header>
