import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ApiProperties, ApiEndpoint, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { EventModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { EndpointTestFormBaseClass } from '../endpoint-test-form-base-class';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';

@Component({
  selector: 'app-endpoint-test-form-list-controls',
  templateUrl: './endpoint-test-form-list-controls.component.html',
  styleUrls: ['./endpoint-test-form-list-controls.component.scss']
})
export class EndpointTestFormListControlsComponent extends EndpointTestFormBaseClass implements OnInit, OnChanges {

  constructor(protected docsService: ApiDocsService) {
    super(docsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }


}
