<ib-input-wrapper [label]="label">
  <p-dropdown [options]="licenses" optionLabel="ExternalAssetLicenseId" [(ngModel)]="selectedLicense"
              [style]="{width:'100%'}"
              [readonly]="readonly"
              [disabled]="disabled"
              dropdownIcon="pi pi-caret-down"
              (onChange)="onChange($event)">
    <ng-template let-value pTemplate="selectedItem">
      <ng-container *ngIf="value && value.value && value.value.ExternalAssetLicenseId">{{value.value.ExternalAssetLicenseId}}</ng-container>
      <span *ngIf="!value || !value.value || !value.value.ExternalAssetLicenseId" class="ui-multiselected-empty-token ui-corner-all">&lt;None&gt;</span>
      <!--value: {{value | json}} selected: {{selectedLicense | json}}-->
    </ng-template>
    <ng-template let-item pTemplate="item">
      <ib-input-asset-license-select-display [license]="item.value"></ib-input-asset-license-select-display>
    </ng-template>
  </p-dropdown>
</ib-input-wrapper>
