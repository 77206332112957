import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {

  transform(value: any, type: "SpaceDelimited" | "TitleCase" | "Plural" | "Singular" | "Slug", args?: any): any {

    if (!value) {
      return "";
    }

    if (typeof value !== "string") {
      value = value.toString();
    }

    if (Helper.equals(type, "SpaceDelimited", true)) {
      return Helper.formatIdentifierWithSpaces(value);
    } else if (Helper.equals(type, "TitleCase", true)) {
      return Helper.toTitleCase(value);
    } else if (Helper.equals(type, "Plural", true)) {
      return Helper.plural(value, 2);
    } else if (Helper.equals(type, "Singular", true)) {
      return Helper.plural(value, 1);
    } else if (Helper.equals(type, "Slug", true)) {
      return Helper.encodeURISlug(value);
    }

    return value;

  }

}
