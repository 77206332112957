import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'app-result-codes',
  templateUrl: './result-codes.component.html',
  styleUrls: ['./result-codes.component.scss']
})
export class ResultCodesComponent implements OnInit {

  constructor(protected appService: AppService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "Result Codes";
  }

}
