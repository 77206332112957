<ng-container [ngSwitch]="type">

  <ng-container *ngSwitchCase="'ResultCodePurchase'">
    <app-result-code-purchase [dataModelDocumentationParts]="dataModelDocumentationParts"></app-result-code-purchase>
  </ng-container>
  <ng-container *ngSwitchCase="'ResultCodeVoucher'">
    <app-result-code-voucher [dataModelDocumentationParts]="dataModelDocumentationParts"></app-result-code-voucher>
  </ng-container>

  <ng-container *ngSwitchCase="'PurchaseOverview'">
    <app-purchase-overview [dataModelDocumentationParts]="dataModelDocumentationParts"></app-purchase-overview>
  </ng-container>
  <ng-container *ngSwitchCase="'PurchaseExamples'">
    <app-purchase-examples [dataModelDocumentationParts]="dataModelDocumentationParts"></app-purchase-examples>
  </ng-container>


  <ng-container *ngSwitchDefault>
    <em>Custom Content Type Error: No support for type <span class="badge badge-danger">{{type}}</span>.</em>
  </ng-container>

</ng-container>
