<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <ng-container *ngIf="currentFilter && ( currentFilter.Q || currentFilter.Filter || currentFilter.FilterId )">
    <ib-button icon="filter (solid)"
               label="Export with Current Filter"
               color="primary"
               [block]="true"
               [working]="working"
               (click)="exportUsingCurrentFilter($event)"></ib-button>
    <div class="card mt-2">
      <h5 class="card-header">Filter</h5>
      <div class="card-body">
        <p class="card-text"
           *ngIf="currentFilter.Q"><strong>Keywords:</strong> {{currentFilter.Q}}</p>
        <p class="card-text"
           *ngIf="currentFilter.Filter"><strong>Filter:</strong> {{currentFilter.Filter}}</p>
        <p class="card-text"
           *ngIf="currentFilter.FilterId"><strong>Saved Filter:</strong> {{currentFilter.FilterId | pickListText : Constants.PickList._Filter | async}}
        </p>
      </div>
    </div>
    <br />
  </ng-container>
  <ib-button icon="file-export"
             label="Export with no Filter"
             color="primary"
             [block]="true"
             [working]="working"
             (click)="exportWithoutFilter($event)"></ib-button>
  <br />
  <ib-button icon="cloud-download"
             label="Download Previous Export"
             color="primary"
             [block]="true"
             [working]="working"
             (click)="download($event)"></ib-button>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="currentFilter"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
