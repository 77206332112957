<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label for="{{inputControlId}}" class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}}">
    <div *ngIf="prefixText || prefixIcon" class="input-group-prepend" [ngClass]="{'clickable': isPrefixClickable()}">
      <span class="input-group-text" ngbTooltip="{{prefixTooltip | translate}}" (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
    </div>
    <input type="{{inputType}}" class="form-control {{inputSize}} {{controlClass}}" id="{{inputControlId}}" name="{{name}}"
           placeholder="{{( placeholder | translate ) || placeholder}}"
           ngbTooltip="{{tooltip | translate}}"
           [pKeyFilter]="keyFilterType"
           [min]="min"
           [max]="max"
           [step]="step" 
           [disabled]="disabled ? 'disabled' : null"
           [readonly]="readonly ? 'readonly' : null"
           [required]="required ? 'required' : null"
           (focus)="fireFocus($event, inputControl)"
           (blur)="fireBlur($event, inputControl)"
           (keyup)="fireKeyUp($event, inputControl)"
           (change)="fireChange($event, inputControl)"
           [(ngModel)]="value"
           [ngModelOptions]="{standalone: standalone}"
           #inputControl="ngModel" />
    <div *ngIf="suffixText || suffixIcon" class="input-group-append" [ngClass]="{'clickable': isSuffixClickable()}">
      <span class="input-group-text" ngbTooltip="{{suffixTooltip | translate}}" (click)="fireSuffixClick($event)">
        {{suffixText | translate}}
        <ib-icon icon="{{suffixIcon}}"></ib-icon>
      </span>
    </div>
    <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
      <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
        {{errorMessage | translate:inputInformationValues}}
      </small>
    </div>
  </div>
</div>
