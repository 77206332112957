import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ContactListViewModel } from 'projects/core-lib/src/lib/models/ngModels5';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ib-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() contact: any = null; // full or partial contact object we can parse to get desired values
  @Input() contactId: number = null; // TODO use this to get source image from server ... e.g. some path 3838811.jpg
  @Input() name: string = null;
  @Input() email: string = null;
  @Input() size: number = 50;
  @Input() tooltip: boolean = false;
  @Input() tooltipTemplate: string = "{name}";
  @Input() tooltipPlacement: "top" | "top-left" | "top-right" | "bottom" | "bottom-left" | "bottom-right" | "left" | "left-top" | "left-bottom" | "right" | "right-top" | "right-bottom" = "top";
  @Input() inline: boolean = false;
  @Output() click: EventEmitter<EventModel> = new EventEmitter();

  nameForAvatar: string = "";
  emailForAvatar: string = "";
  tooltipText: string = "";

  customClass: string = "";

  constructor(protected contactService: ContactService) {
    super();
  }


  ngOnInit() {
    super.ngOnInit();
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
  }

  configure() {
    this.setCustomClass();
    this.setContactFromContactId();
    this.setEmailFromContact();
    this.setNameFromContact();
    this.setContactIdFromContact();
    // If we have an assigned name or email that will override whatever we had from a contact object
    if (this.name) {
      this.nameForAvatar = this.name;
    }
    if (this.email) {
      this.emailForAvatar = this.email;
    }
    this.setToolTipText();
  }

  /** Sets the value for this.customClass */
  private setCustomClass(): void {
    if (this.inline) {
      this.customClass = "inline-avatar";
    } else {
      this.customClass = "";
    }
  }

  /** Sets the value for this.contact */
  private setContactFromContactId(): void {
    // Look for a contact record
    if (!this.contact && this.contactId) {
      this.contactService.getContactListObject(this.contactId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(contact => {
          this.contact = contact;
          // Recursive call to configure() will not trigger this again since this.contact will no longer be null.
          if (this.contact) {
            this.configure();
          }
        });
    }
  }

  /** Sets the value for this.email, if it can be found. */
  private setEmailFromContact(): void {
    if (this.contact) {
      let value = Helper.tryGetValue(this.contact, x => x.Email, "", "");
      if (!value) {
        value = Helper.tryGetValue(this.contact, x => x.AlternateEmail, "", "");
      }
      if (value) {
        this.emailForAvatar = value;
      }
    }
  }

  /** Sets the value for this.name, if it can be found. */
  private setNameFromContact(): void {
    if (this.contact) {
      const firstName = Helper.tryGetValue(this.contact, x => x.FirstName, "", "");
      const lastName = Helper.tryGetValue(this.contact, x => x.LastName, "", "");
      let value = "";
      if (firstName || lastName) {
        value = `${firstName} ${lastName}`.trim();
      }
      if (!value) {
        value = Helper.tryGetValue(this.contact, x => x.ContactName, "", "");
        if (!value) {
          value = Helper.tryGetValue(this.contact, x => x.DirectoryName, "", "");
        }
        if (!value) {
          value = Helper.tryGetValue(this.contact, x => x.CustomerName, "", "");
        }
      }
      if (value) {
        this.nameForAvatar = value;
      }
    }
  }

  /** Sets the value for this.contactId, if it can be found. */
  private setContactIdFromContact(): void {
    if (!this.contactId && this.contact) {
      let value: number = Helper.tryGetValue(this.contact, x => x.ContactId, null, null);
      if (!value) {
        value = Helper.tryGetValue(this.contact, x => x.DirectoryId, null, null);
        if (!value) {
          value = Helper.tryGetValue(this.contact, x => x.CustomerId, null, null);
        }
      }
      if (value) {
        this.contactId = value;
      }
    }
  }

  /** Sets the value for this.tooltipText */
  private setToolTipText(): void {
    if (this.tooltipTemplate) {
      this.tooltipText = Helper.replaceAll(Helper.replaceAll(this.tooltipTemplate, "{name}", this.nameForAvatar), "{email}", this.emailForAvatar);
    } else {
      this.tooltipText = this.nameForAvatar;
    }
  }

  fireClick($event) {

    // Fire registered event handler(s)
    const value: any = {
      contact: this.contact,
      contactId: this.contactId,
      name: this.nameForAvatar,
      email: this.emailForAvatar
    }
    const payload: EventModel = new EventModel("click", $event, value, new EventElementModel("avatar", null, this.nameForAvatar, this.emailForAvatar));
    this.click.emit(payload);

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // Since our "ib-input-*" component has this method as does the native html input control.
      // If we used different event names then it wouldn't be an issue but using these event names is more dev friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Some custom components like multiselect do not have the above event method to fire.
      //Log.errorMessage(err);
    }

  }


}
