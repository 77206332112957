<div>

  <h3>Introduction</h3>

  <br />

  <p>
    {{appName}} has a simple <a href="http://en.wikipedia.org/wiki/Representational_state_transfer" target="_blank">RESTful</a>
    web API where each type of resource (e.g. customer, inventory item, package, credit card, etc.) has a URI that you can interact with.
  </p>

  <!--<p>
      For example, if you'd like to use the API to get information about a customer you'd use the following URI:
  </p>

  <div class="well well-sm">
      {{apiEndpoints[0].ApiUrl}}/customers/418371
  </div>-->

  <p>
    Read more about the API including available endpoints using the topics below or the menu on the left.
  </p>

  <br /><br />



  <div class="row">


    <div class="col-lg-1 col-md-1"></div>

    <div class="col-lg-3 col-md-5">
      <a [routerLink]="['/', 'overview', 'endpoints']" style="text-decoration: none;">
        <div class="card text-white bg-secondary mb-3">
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-3">
                  <i class="fal fa-cloud fa-5x fa-fw faa-vertical animated-hover"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-right">Endpoints</h3>
                  <div class="text-right">API Endpoints</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-secondary">
            <span class="float-left">View all API endpoints</span>
            <span class="float-right"><i class="fal fa-arrow-circle-right"></i></span>
            <div class="clearfix"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-5">
      <a [routerLink]="['/', 'overview', 'request-formats']" style="text-decoration: none;">
        <div class="card text-white bg-secondary mb-3">
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-3">
                  <i class="fal fa-exchange fa-5x fa-fw faa-vertical animated-hover"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-right">Formats</h3>
                  <div class="text-right">API Data Formats</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-secondary">
            <span class="float-left">Read about API data formats</span>
            <span class="float-right"><i class="fal fa-arrow-circle-right"></i></span>
            <div class="clearfix"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-lg-3 col-md-5">
      <a [routerLink]="['/', 'overview', 'versions']" style="text-decoration: none;">
        <div class="card text-white bg-secondary mb-3">
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-3">
                  <i class="fal fa-list-ol fa-5x fa-fw faa-vertical animated-hover"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-right">Versions</h3>
                  <div class="text-right">API Versioning</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-secondary">
            <span class="float-left">Read about API versions</span>
            <span class="float-right"><i class="fal fa-arrow-circle-right"></i></span>
            <div class="clearfix"></div>
          </div>
        </div>
      </a>
    </div>


  </div>




  <div class="row mt-3">


    <div class="col-lg-1 col-md-1"></div>

    <div class="col-lg-3 col-md-5">
      <a [routerLink]="['/', 'overview', 'credentials']" style="text-decoration: none;">
        <div class="card text-white bg-secondary mb-3">
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-3">
                  <i class="fal fa-lock fa-5x fa-fw faa-vertical animated-hover"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-right">Security</h3>
                  <div class="text-right">API Security</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-secondary">
            <span class="float-left">Read about API security</span>
            <span class="float-right"><i class="fal fa-arrow-circle-right"></i></span>
            <div class="clearfix"></div>
          </div>
        </div>
      </a>
    </div>


    <div class="col-lg-3 col-md-5">
      <a [routerLink]="['/', 'overview', 'result-codes']" style="text-decoration: none;">
        <div class="card text-white bg-secondary mb-3">
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-3">
                  <i class="fal fa-sort-numeric-up fa-5x fa-fw faa-vertical animated-hover"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-right">Results</h3>
                  <div class="text-right">API Result Codes</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-secondary">
            <span class="float-left">Read about API result codes</span>
            <span class="float-right"><i class="fal fa-arrow-circle-right"></i></span>
            <div class="clearfix"></div>
          </div>
        </div>
      </a>
    </div>



    <div class="col-lg-3 col-md-5">
      <a [routerLink]="['/', 'overview', 'api-use-policy']" style="text-decoration: none;">
        <div class="card text-white bg-secondary mb-3">
          <div class="card-body">
            <div class="card-text">
              <div class="row">
                <div class="col-3">
                  <i class="fal fa-file-alt fa-5x fa-fw faa-vertical animated-hover"></i>
                </div>
                <div class="col-9">
                  <h3 class="text-right">Policies</h3>
                  <div class="text-right">API Use Policy</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-secondary">
            <span class="float-left">Read the API use policy</span>
            <span class="float-right"><i class="fal fa-arrow-circle-right"></i></span>
            <div class="clearfix"></div>
          </div>
        </div>
      </a>
    </div>


  </div>


</div>
