import * as m from "./ngCoreModels";
import * as m5rc from "./ngModelsReportCompiler5";
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export enum ReportCompilerLibraryEntryReasonSuggestedKey {
		Unknown = 0,
		Other = 1,
		Selection = 100,
		SelectionProperty01 = 101,
		SelectionProperty02 = 102,
		SelectionProperty03 = 103,
		SelectionProperty04 = 104,
		SelectionProperty05 = 105,
		SelectionProperty06 = 106,
		SelectionProperty07 = 107,
		SelectionProperty08 = 108,
		SelectionProperty09 = 109,
		SelectionProperty10 = 110,
		Query = 201,
		Category = 301,
		Group = 401,
		Tags = 501
	}
	export enum ReportCompilerLibraryEntryReasonSuggestedWildcardType {
		None = 0,
		CaseSelectionHadWildcards = 10,
		LibrarySelectionHadWildcards = 20,
		LibrarySelectionAllWildcards = 30
	}
	export enum ReportCompilerLibraryEntryScoreCategory {
		Unknown = 1,
		Library = 11,
		Match = 21,
		Preference = 31,
		Total = 999999
	}
	export enum ReportCompilerLibraryEntryScoreType {
		Unknown = 1,
		Item = 11,
		CategorySubtotal = 21,
		Reset = 91,
		Total = 999999
	}
	export enum ReportCompilerLibraryEntryScoreImpactReason {
		Unknown = 0,
		Other = 1,
		SourceLibrary = 20,
		SourceLibraryPersonal = 21,
		SourceLibraryOrganization = 22,
		SourceLibraryIndustry = 23,
		Match = 30,
		MatchExact = 31,
		MatchPartial = 32,
		MatchAlways = 33,
		MatchSearchFoundInTags = 34,
		MatchSearchFoundInLibrary = 35,
		Preference = 40,
		ExplicitPersonalPreference = 41,
		InferredPersonalPreference = 42,
		InferredOrganizationPreference = 43,
		InferredIndustryPreference = 44,
		ObservedPersonalPastUse = 48,
		LibraryContentFeedbackSentiment = 49,
		Love = 1100,
		Like = 1200,
		Dislike = 1300,
		Block = 1400
	}
	export enum ReportCompilerCaseContactLinkType {
		Empty = 0,
		Contact = 1,
		Site = 2,
		OpenedBy = 3,
		OwnedBy = 4,
		AssignedTo = 5,
		Supervisor = 6
	}
	export enum ReportCompilerReportParserParagraphType {
		Pending = 0,
		Accepted = 1,
		Merged = 2,
		DeletedAndSkipped = 3,
		Deleted = 4
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

/// <reference path="ngCoreModels.ts" />

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

    // This is generated code... DO NOT modify this file.
	export class ReportCompilerIndustryLibraryApprovalViewModel {
		VisibilityId: number = null;
		AssetId: number = null;
		Status: string = "";
		DeterminedByContactId: number = null;
		DeterminedDateTime: Date = null;
		RequestedByContactId: number[] = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerIndustryLibraryTypeInformationModel {
		IndustryLibraryTypeInformationId: number = null;
		IndustryLibraryType: string = "";
		IndustryLibraryPartitionId: number = null;
		Comments: string = "";
		Private: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryEntryReasonSuggestedModel {
		KeyCode: m5rc.ReportCompilerLibraryEntryReasonSuggestedKey = null;
		KeyText: string = "";
		Value: string = "";
		Wildcard: m5rc.ReportCompilerLibraryEntryReasonSuggestedWildcardType = null;
		Description: string = "";
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportAttachmentDocumentModel {
		ReportAttachmentDocumentId: number = null;
		ReportAttachmentConfigurationId: number = null;
		CaseId: number = null;
		AssetId: number = null;
		CaseAttachmnet: boolean = false;
		Selected: boolean = false;
		AutoSelect: boolean = false;
		DisplayOrder: number = 0;
		InternalDisplayOrder: number = 0;
		ExternalDocumentId: string = null;
		Title: string = "";
		Description: string = "";
		FriendlyName: string = "";
		FileType: string = "";
		AssetType: string = "";
		AssetClass: string = "";
		AssetClassInferred: string = "";
		SizeBytes: number = 0;
		SizeOther: number = 0;
		SizeInformation: string = "";
		Width: number = 0;
		Height: number = 0;
		ContentCategory: string = "";
		Visibility: string = "";
		AuthorLongName: string = "";
		AuthorShortName: string = "";
		Tags: string[] = [];
		Property01: string = "";
		Value01: string = "";
		Property02: string = "";
		Value02: string = "";
		Property03: string = "";
		Value03: string = "";
		Property04: string = "";
		Value04: string = "";
		Property05: string = "";
		Value05: string = "";
		Property06: string = "";
		Value06: string = "";
		Property07: string = "";
		Value07: string = "";
		Property08: string = "";
		Value08: string = "";
		Property09: string = "";
		Value09: string = "";
		Property10: string = "";
		Value10: string = "";
		FavoriteId: number = null;
		FavoriteRank: number = 0;
		MatchRank: number = 0;
		MatchRankReason: string = "";
		Score: number = 0;
		ScoreCalculation: string = "";
		ScoreCalculationInformation: m5rc.ReportCompilerLibraryEntryScoreCalculationModel[] = [];
		ReasonSuggested: string = "";
		ReasonSuggestedInformation: m5rc.ReportCompilerLibraryEntryReasonSuggestedModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryEntryScoreCalculationModel {
		Score: number = 0;
		CategoryCode: m5rc.ReportCompilerLibraryEntryScoreCategory = null;
		CategoryText: string = "";
		TypeCode: m5rc.ReportCompilerLibraryEntryScoreType = null;
		TypeText: string = "";
		ReasonCode: m5rc.ReportCompilerLibraryEntryScoreImpactReason = null;
		ReasonText: string = "";
		Description: string = "";
		MaxScore: number = 0;
		Weight: number = 0;
		ScoreValueReset: boolean = false;
		Properties: any = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {
		SubjectMatterSubjectElementDamageId: number = null;
		SubjectMatterSubjectElementDamageLocalId: string = null;
		SubjectMatterAreaLocalId: string = null;
		SubjectMatterStructureLocalId: string = null;
		SubjectMatterPropertyLocalId: string = null;
		CaseId: number = null;
		DisplayOrder: number = 0;
		Subject: string = "";
		Element: string = "";
		Damages: string[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterStructuralPropertyModel {
		SubjectMatterPropertyId: number = null;
		SubjectMatterPropertyLocalId: string = null;
		CaseId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		PropertyType: string = "";
		Structures: m5rc.ReportCompilerSubjectMatterStructuralStructureModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterStructuralStructureModel {
		SubjectMatterStructureId: number = null;
		SubjectMatterStructureLocalId: string = null;
		SubjectMatterPropertyLocalId: string = null;
		CaseId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		TrueFrontDirection: string = "";
		ReportFrontDirection: string = "";
		Areas: m5rc.ReportCompilerSubjectMatterStructuralAreaModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterStructuralAreaModel {
		SubjectMatterAreaId: number = null;
		SubjectMatterAreaLocalId: string = null;
		SubjectMatterStructureLocalId: string = null;
		SubjectMatterPropertyLocalId: string = null;
		CaseId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		Stories: string = "";
		OverallFraming: string = "";
		OverallFoundation: string = "";
		RoofFraming: string[] = [];
		WallFraming: string[] = [];
		FloorFraming: string[] = [];
		Foundation: string[] = [];
		Roofing: string[] = [];
		ExteriorCladding: string[] = [];
		FloorCovering: string[] = [];
		InteriorCladding: string[] = [];
		ReportedCauses: string[] = [];
		FoundCauses: string[] = [];
		Tags: string[] = [];
		InteriorDamages: m5rc.ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel[] = [];
		ExteriorDamages: m5rc.ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel extends m5rc.ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel extends m5rc.ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerSubjectMatterOtherModel {
		SubjectMatterId: number = null;
		CaseId: number = null;
		DisplayOrder: number = 0;
		Description: string = "";
		LibrarySelectionRuleValue01: string = "";
		LibrarySelectionRuleValue02: string = "";
		LibrarySelectionRuleValue03: string = "";
		LibrarySelectionRuleValue04: string = "";
		LibrarySelectionRuleValue05: string = "";
		LibrarySelectionRuleValue06: string = "";
		LibrarySelectionRuleValue07: string = "";
		LibrarySelectionRuleValue08: string = "";
		LibrarySelectionRuleValue09: string = "";
		LibrarySelectionRuleValue10: string = "";
		Tags: string[] = [];
		SubjectMatter: any = null;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportSectionTextModel {
		ReportSectionTextId: number = null;
		ReportSectionConfigurationId: number = null;
		CaseId: number = null;
		AssetId: number = null;
		Selected: boolean = false;
		AutoSelect: boolean = false;
		DisplayOrder: number = 0;
		InternalDisplayOrder: number = 0;
		ExternalTextId: string = null;
		Title: string = "";
		FriendlyName: string = "";
		FileType: string = "";
		SizeBytes: number = 0;
		SizeOther: number = 0;
		SizeInformation: string = "";
		ContentCategory: string = "";
		Visibility: string = "";
		AuthorLongName: string = "";
		AuthorShortName: string = "";
		AuthorContactInformation: string = "";
		Copyright: string = "";
		License: string = "";
		LicenseUrl: string = "";
		LicenseAnonymous: boolean = false;
		Tags: string[] = [];
		Property01: string = "";
		Value01: string = "";
		Property02: string = "";
		Value02: string = "";
		Property03: string = "";
		Value03: string = "";
		Property04: string = "";
		Value04: string = "";
		Property05: string = "";
		Value05: string = "";
		Property06: string = "";
		Value06: string = "";
		Property07: string = "";
		Value07: string = "";
		Property08: string = "";
		Value08: string = "";
		Property09: string = "";
		Value09: string = "";
		Property10: string = "";
		Value10: string = "";
		FavoriteId: number = null;
		FavoriteRank: number = 0;
		FavoriteRankMaxNatural: number = 0;
		FavoriteRankOrganization: number = 0;
		FavoriteRankMaxNaturalOrganization: number = 0;
		FavoriteRankIndustry: number = 0;
		FavoriteRankMaxNaturalIndustry: number = 0;
		MatchRank: number = 0;
		MatchRankReason: string = "";
		Score: number = 0;
		ScoreCalculation: string = "";
		ScoreCalculationInformation: m5rc.ReportCompilerLibraryEntryScoreCalculationModel[] = [];
		ReasonSuggested: string = "";
		ReasonSuggestedInformation: m5rc.ReportCompilerLibraryEntryReasonSuggestedModel[] = [];
		Approval: string = "";
		Text: string = "";
		PreviewText: string = "";
		TextBookmarkStart: string = "";
		TextBookmarkEnd: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportAttachmentConfigurationModel {
		ReportAttachmentConfigurationId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		Enabled: boolean = false;
		DisplayOrder: number = 0;
		AttachmentHeading: string = "";
		AttachmentDescription: string = "";
		AttachmentSectionHeadingType: string = "";
		AttachmentRole: string = "";
		ReportAttachmentImageLayoutTemplateAssetId: number = null;
		AllowUploadedAttachments: boolean = false;
		AllowDocumentLibraryAttachments: boolean = false;
		UseLibrarySelectionCriteria: boolean = false;
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportSectionConfigurationModel {
		ReportSectionConfigurationId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		Enabled: boolean = false;
		DisplayOrder: number = 0;
		SectionHeading: string = "";
		SubsectionHeadingType: string = "";
		SectionRole: string = "";
		LibraryCategory: string = "";
		UseLibraryTextSelectionCriteria: boolean = false;
		AutomaticallySuggestLibraryText: boolean = false;
		AutomaticallySelectLibraryText: string = "";
		LibraryTextColumnsUseDefaultConfiguration: boolean = false;
		LibraryTextSort: string = "";
		LibraryTextColumns: m5rc.ReportCompilerReportSectionConfigurationTextColumnModel[] = [];
		ParagraphNumberingStyle: string = "";
		OpeningParagraphs: string[] = [];
		OpeningParagraphsUseParagraphNumbering: boolean = false;
		ClosingParagraphs: string[] = [];
		ClosingParagraphsUseParagraphNumbering: boolean = false;
		SectionEnableTriggers: string[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportSectionConfigurationTextColumnModel {
		Enabled: boolean = false;
		DisplayOrder: number = 0;
		HeadingText: string = "";
		HeadingIcon: string = "";
		PropertyName: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerCaseTemplateContactModel {
		CaseContactRole: string = "";
		ContactAddressTypeLink: string = "";
		ContactAddressTypeCandidates: string[] = [];
		CaseContactLink: string = "";
		Required: boolean = false;
		CanBeCustomer: boolean = false;
		CanBeCustomerContact: boolean = false;
		CanBeDirectory: boolean = false;
		CanBeGroup: boolean = false;
		CanBeLocation: boolean = false;
		DefaultContactType: string = "";
		OnCaseCloseSanitizeIfCustomer: boolean = false;
		OnCaseCloseSanitizeIfCustomerContact: boolean = false;
		PromptForContactCaseNumber: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerCaseCustomizationModel {
		ReportTemplateCanChange: boolean = false;
		ReportAttachmentCoverPageTemplateCanChange: boolean = false;
		AttachmentNumberingStyleCanChange: boolean = false;
		ReportSectionCanChangeOptions: boolean = false;
		ReportSectionCanAdd: boolean = false;
		ReportSectionCanReorder: boolean = false;
		ReportAttachmentCanChangeOptions: boolean = false;
		ReportAttachmentCanAdd: boolean = false;
		ReportAttachmentCanReorder: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerPdfOptionsModel {
		BookmarkLevel: number = 0;
		SecurityEnforcedByTemplate: boolean = false;
		ApplyDigitalSignature: boolean = false;
		UseUserCertificateForDigitalSignature: boolean = false;
		DigitalSignatureAssetId: number = null;
		OpenPassword: string = "";
		PermissionsPassword: string = "";
		EncryptionLevel: number = 0;
		PermitAccessibilityExtractContent: boolean = false;
		PermitAnnotations: boolean = false;
		PermitAssembleDocument: boolean = false;
		PermitExtractContent: boolean = false;
		PermitModifyDocument: boolean = false;
		PermitFormsFill: boolean = false;
		PermitFullQualityPrint: boolean = false;
		PermitPrint: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerConstants {
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerCaseOptionsModel {
		CaseOptionsId: number = null;
		OwnerResourceType: string = "";
		OwnerResourceId: number = null;
		ReportTemplateAssetId: number = null;
		ReportAttachmentCoverPageTemplateAssetId: number = null;
		AttachmentNumberingStyle: string = "";
		PdfOptions: m5rc.ReportCompilerPdfOptionsModel = null;
		TemplateTypeId: number = null;
		CaseCustomization: m5rc.ReportCompilerCaseCustomizationModel = null;
		CaseContacts: m5rc.ReportCompilerCaseTemplateContactModel[] = [];
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerTemplateTypeConfigurationModel {
		TemplateTypeId: number = null;
		TemplateType: string = "";
		Enabled: boolean = false;
		LibraryGroupId: number = null;
		SubjectMatterLabel: string = "";
		SubjectMatterType: string = "";
		SubjectMatterMinimumItemCount: number = 0;
		SubjectMatterMaximumItemCount: number = 0;
		IncludeTags: boolean = false;
		SubjectMatterPickListIds: string[] = [];
		SubjectMatterAttributeSetId: number = null;
		SubjectMatterFormId: number = null;
		SubjectMatterAssetId: number = null;
		LearnImageClassificationLastTrainingDateTime: Date = null;
		LearnImageClassificationLastTrainingResultCode: number = 0;
		LearnImageClassificationLastTrainingResultMessage: string = "";
		LearnImageClassificationLastTrainingExampleCount: m5rc.ReportCompilerImageClassificationExampleModel[] = [];
		Private: boolean = false;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerImageClassificationExampleModel {
		Classification: string = "";
		ExampleCount: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryGroupConfigurationModel {
		LibraryGroupId: number = null;
		LibraryGroup: string = "";
		Enabled: boolean = false;
		LibraryCategoryPickListId: string = null;
		IndustryLibraryContentAllowed: boolean = false;
		IndustryLibraryType: string = "";
		IndustryLibraryContentRequiresApproval: boolean = false;
		IndustryLibraryContentCanSeeAndRequestApproval: boolean = false;
		IndustryLibraryContentAllowedLicenses: string[] = [];
		IndustryLibraryContentSubmissionAllowed: boolean = false;
		IndustryLibraryContentSubmissionRequiresApproval: boolean = false;
		IndustryLibraryContentSubmissionAllowedLicenses: string[] = [];
		IndustryLibraryContentSubmissionDefaultLicense: string = "";
		LearnLibraryContentReviewSentimentLastTrainingDateTime: Date = null;
		LearnLibraryContentReviewSentimentLastTrainingResultCode: number = 0;
		LearnLibraryContentReviewSentimentLastTrainingResultMessage: string = "";
		LearnLibraryContentReviewSentimentLastTrainingPositiveExampleCount: number = 0;
		LearnLibraryContentReviewSentimentLastTrainingNegativeExampleCount: number = 0;
		LearnLibraryContentPersonalPreferences: boolean = false;
		LearnLibraryContentPersonalPreferencesUseThreshold: number = 0;
		LearnLibraryContentOrganizationPreferences: boolean = false;
		LearnLibraryContentOrganizationPreferencesUseThreshold: number = 0;
		LearnLibraryContentIndustryPreferences: boolean = false;
		LearnLibraryContentIndustryPreferencesUseThreshold: number = 0;
		LearnLibraryContentSelectionRulesPersonal: boolean = false;
		LearnLibraryContentSelectionRulesOrganization: boolean = false;
		LearnLibraryContentSelectionRulesIndustry: boolean = false;
		LearnLibraryContentSelectionRulesNarrowSuggestionThreshold: number = 0;
		LearnLibraryContentSelectionRulesWideSuggestionThreshold: number = 0;
		ScoreWeightCategoryLibraryVisibility: number = 0;
		ScoreWeightCategorySelectionRules: number = 0;
		ScoreWeightCategoryPreferences: number = 0;
		ScoreWeightLibraryPersonal: number = 0;
		ScoreWeightLibraryOrganization: number = 0;
		ScoreWeightLibraryIndustry: number = 0;
		ScoreWeightSelectionRulesExactMatch: number = 0;
		ScoreWeightSelectionRulesPartialMatch: number = 0;
		ScoreWeightSelectionRulesAlwaysMatch: number = 0;
		ScoreWeightExplicitPersonalPreference: number = 0;
		ScoreWeightInferredPersonalPreference: number = 0;
		ScoreWeightInferredOrganizationPreference: number = 0;
		ScoreWeightInferredIndustryPreference: number = 0;
		ScoreWeightLibraryContentFeedbackSentiment: number = 0;
		TextIncludeTags: boolean = false;
		TextIncludeSelectionRules: boolean = false;
		TextIncludeVersions: boolean = false;
		TextIncludeFeedback: boolean = false;
		TextIncludeReview: boolean = false;
		TextIncludeApproval: boolean = false;
		TextIncludeAuthor: boolean = false;
		TextIncludeComments: boolean = false;
		TextRequireApprovalForPrivate: boolean = false;
		TextRequireApprovalForPublic: boolean = false;
		DocumentIncludeTags: boolean = false;
		DocumentIncludeSelectionRules: boolean = false;
		DocumentIncludeVersions: boolean = false;
		DocumentIncludeFeedback: boolean = false;
		DocumentIncludeReview: boolean = false;
		DocumentIncludeApproval: boolean = false;
		DocumentIncludeAuthor: boolean = false;
		DocumentIncludeComments: boolean = false;
		DocumentRequireApprovalForPrivate: boolean = false;
		DocumentRequireApprovalForPublic: boolean = false;
		Property01: string = "";
		Property02: string = "";
		Property03: string = "";
		Property04: string = "";
		Property05: string = "";
		Property06: string = "";
		Property07: string = "";
		Property08: string = "";
		Property09: string = "";
		Property10: string = "";
		Property01PickListId: string[] = null;
		Property02PickListId: string[] = null;
		Property03PickListId: string[] = null;
		Property04PickListId: string[] = null;
		Property05PickListId: string[] = null;
		Property06PickListId: string[] = null;
		Property07PickListId: string[] = null;
		Property08PickListId: string[] = null;
		Property09PickListId: string[] = null;
		Property10PickListId: string[] = null;
		Private: boolean = false;
		Comments: string = "";
		TimeZone: string = "";
		MetaData: m.IMetaDataModel = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryReviewResultModel {
		OriginalFileName: string = "";
		MimeFileName: string = "";
		FinalFileName: string = "";
		FinalFileFullPath: string = "";
		Extension: string = "";
		ContentType: string = "";
		ParagraphCountParsedRaw: number = 0;
		ParagraphCountSkipped: number = 0;
		ParagraphCountMetaData: number = 0;
		ParagraphCountParsed: number = 0;
		ParagraphCountAdded: number = 0;
		ParagraphCountUpdated: number = 0;
		ParagraphCountDeleted: number = 0;
		ParagraphCountNotChanged: number = 0;
		ParagraphCountWarning: number = 0;
		ParagraphCountError: number = 0;
		LibraryAssetsAdded: number[] = [];
		LibraryAssetsUpdated: number[] = [];
		LibraryAssetsDeleted: number[] = [];
		LibraryAssetsNotChanged: number[] = [];
		LibraryAssetsWarnings: number[] = [];
		LibraryAssetsErrors: number[] = [];
		Notices: m5rc.ReportCompilerLibraryReviewNoticeModel[] = [];
		Warnings: m5rc.ReportCompilerLibraryReviewNoticeModel[] = [];
		Errors: m5rc.ReportCompilerLibraryReviewNoticeModel[] = [];
		DryRun: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryReviewNoticeModel {
		AssetId: number = null;
		OperationType: string = "";
		ResultCode: m.StandardResultCode = null;
		Message: string = "";
		CurrentLibraryMetaData: string = "";
		CurrentLibraryText: string = "";
		RequestedLibraryMetaData: string = "";
		RequestedLibraryText: string = "";
		Details: string = "";
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryCoverageSummaryModel {
		Item: string = "";
		Count: number = 0;
		Children: m5rc.ReportCompilerLibraryCoverageSummaryModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryCoverageModel {
		LibraryGroupId: number = null;
		LibraryGroup: string = "";
		Property01: string = "";
		Value01: string = "";
		Property02: string = "";
		Value02: string = "";
		Property03: string = "";
		Value03: string = "";
		Property04: string = "";
		Value04: string = "";
		Property05: string = "";
		Value05: string = "";
		Property06: string = "";
		Value06: string = "";
		Property07: string = "";
		Value07: string = "";
		Property08: string = "";
		Value08: string = "";
		Property09: string = "";
		Value09: string = "";
		Property10: string = "";
		Value10: string = "";
		FullMatchCount: number = 0;
		FullOrPartialMatchCount: number = 0;
		AsOf: Date = null;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerLibraryStatsModel {
		LibraryGroupId: string = null;
		LibraryGroup: string = "";
		LibraryCategory: string = "";
		AddedByContactId: number = null;
		AddedByContactName: string = "";
		Visibility: string = "";
		Count: number = 0;
		Validated: boolean = false;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportParserResultModel {
		ReportId: string = null;
		ReportFileName: string = "";
		LibraryGroupId: number = null;
		ShareWithOrganization: boolean = false;
		Tags: string[] = [];
		TemplateTypeId: number = null;
		SubjectMatters: m5rc.ReportCompilerReportParserResultSubjectMatterModel[] = [];
		PendingCount: number = 0;
		AcceptedCount: number = 0;
		MergedCount: number = 0;
		DeletedAndSkippedCount: number = 0;
		DeletedCount: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportParserResultSubjectMatterModel {
		SubjectMatterId: string = null;
		Description: string = "";
		SubjectMatter: any[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportParserResultAssetModel {
		ParagraphNumber: number = 0;
		ParagraphType: m5rc.ReportCompilerReportParserParagraphType = null;
		AssetId: number = null;
		AssetText: string = "";
		AssetCategory: string = "";
		IsCategoryHeading: boolean = false;
		SubjectMatterId: string = null;
		SourceParagraphNumbers: number[] = [];
		ExistingAssetMatches: m5rc.ReportCompilerReportParserResultAssetMatchModel[] = [];
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportParserResultAssetMatchModel {
		MatchAssetId: number = null;
		MatchAssetVisibility: string = "";
		MatchAssetHasWildcards: boolean = false;
		MatchPercent: number = 0;
	}


    // This is generated code... DO NOT modify this file.
	export class ReportCompilerReportParserParagraphMetaDataEditViewModel {
		ReportId: string = null;
		ParagraphNumber: number = 0;
		EndingParagraphNumber: number = 0;
		MetaDataType: string = "";
		MetaDataValue: string = "";
	}

// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.

// This is generated code... DO NOT modify this file.
	export module ApiReportId {
		export var InventoryExpiringMaintenance: string = "expiring-maintenance";
		export var InventoryExpiringWarranty: string = "expiring-warranty";
		export var InventoryExpiringLease: string = "expiring-lease";
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
	export module ReportCompilerConstants {
		export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
		export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
		export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
		export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
		export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
		export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
		export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
		export var LibraryTypeOther: string = "Other";
		export var LibraryTypeStructural: string = "Structural";
		export var ModuleLibraryPersonal: string = "rc-library-personal";
		export var ModuleLibraryIndustry: string = "rc-library-industry";
		export var ModuleLibraryOrganization: string = "rc-library-organization";
		export var ModuleLibraryInsights: string = "rc-library-insights";
		export var ModuleLibraryExport: string = "rc-library-export";
		export var ModuleManagementInsights: string = "rc-management-insights";
		export var ModuleReportParser: string = "rc-report-parser";
		export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
		export var ModuleWorkflow: string = "rc-workflow";
		export var ScoreMatchExact: number = 100;
		export var ScoreMatchPartial: number = 50;
		export var ScoreMatchAlways: number = 25;
		export var ScoreMatchSearchFoundInTag: number = 10;
		export var ScoreMatchSearchFoundInLibrary: number = 5;
		export var ScoreFavLove: number = 100;
		export var ScoreFavLike: number = 50;
		export var ScoreFavDislike: number = 0;
		export var ScoreFavBlock: number = -100;
		export var ScoreFavNaturalDefault: number = 5;
		export var ScoreFavNaturalMax: number = 50;
	}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System.Collections.Generic {
}
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
// This is generated code... DO NOT modify this file.
export module System {
}
// This is generated code... DO NOT modify this file.

