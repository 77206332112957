<div class="float-left">
  <h3 *ngIf="headerText">{{headerText}}</h3>
</div>
<div *ngIf="actionButton || actionButton2 || showSaveButton" class="float-right">
  <ib-button *ngIf="showSaveButton && formService && formService.isValid && !formService.isPristine"
             name="Save" icon="save" label="Save" color="primary" [displayErrorMessages]="false" [working]="working"
             style="padding-right:5px;"
             (click)="fireSave($event)">
  </ib-button>
  <ib-action-button *ngIf="actionButton" [button]="actionButton" [data]="data"></ib-action-button>
  <ib-action-button *ngIf="actionButton2" [button]="actionButton2" [data]="data" style="padding-left: 5px;"></ib-action-button>
</div>
<div class="clearfix"></div>
