<div class="{{inputFormGroupClass}} {{outerClass}}">
  <div class="{{inputLabelClass}}" *ngIf="alignWithOtherControls && !isMobilePortrait">&nbsp;</div>
  <div class="{{inputWrapperClass}}" style="margin-top: 5px;">
    <input class="form-check-input {{inputSize}} {{controlClass}}"
           type="checkbox"
           id="{{inputControlId}}"
           ngbTooltip="{{tooltip | translate}}"
           [disabled]="disabled || readonly ? 'disabled' : null"
           [readonly]="readonly ? 'readonly' : null"
           (focus)="fireFocus($event, inputControl)"
           (blur)="fireBlur($event, inputControl)"
           (keyup)="fireKeyUp($event, inputControl)"
           (change)="fireChange($event, inputControl)"
           [(ngModel)]="value"
           [ngModelOptions]="{standalone: standalone}"
           #inputControl="ngModel">
    <label class="form-check-label {{labelClass}}" for="{{inputControlId}}">
      {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    </label>
  </div>
</div>
