// See https://github.com/stewieoO/angular-tinymce for source

import { Component, NgZone, forwardRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, Inject, AfterViewInit, OnDestroy, InjectionToken, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { Observable, interval } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

//import * as TinyMce from 'tinymce';
import { IApiResponseWrapper, ApiOperationType, ApiProperties, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
declare var tinymce: any; //TinyMce.EditorManager;
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { HtmlEditorEvents } from './html-editor-events';
import { HtmlEditorDefaultSettings } from './html-editor-defaults';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';

//export const TINYMCE_SETTINGS_TOKEN = new InjectionToken('angular-tinymce-settings');

@Component({
  selector: 'ib-html-editor',
  template: `<textarea style="visibility: hidden" #tinymce ></textarea>`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HtmlEditorComponent),
    multi: true
  }]
})
export class HtmlEditorComponent implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges {

  beforeInitValue: string;
  disabled: boolean;
  fromWriteValue: boolean;

  @Input()
  isDisabled: boolean;

  /**
   * Flag if images will be uploaded as assets instead of in-line blobs.
   */
  @Input() uploadImageAssets: boolean = false;

  /**
   * When uploading images as assets the presence of a parentAssetId will
   * result in the asset being a child asset to this parentAssetId.
   * Other asset values are typically N/A when this is set.
   */
  @Input() parentAssetId: number = null;

  /**
   * 
   */
  @Input() systemAssetGroup: string = null;
  /**
   * When uploading images as assets this controls the visibility.  The default
   * of "P" (public) is typically correct as images embedded in html need public
   * visibility to be served up anonymously. 
   */
  @Input() assetVisibility: string = "P";
  /**
   * When uploading images as assets this is assigned to the asset.
   */
  @Input() assetOwnerResourceType: string = null;
  /**
   * When uploading images as assets this is assigned to the asset.
   */
  @Input() assetOwnerResourceId: number = null;
  /**
   * When uploading images as assets this is assigned to the asset.
   */
  @Input() assetOwnerResourceId2: string = null;
  /**
   * When uploading images as assets this is assigned to the asset.
   */
  @Input() assetSecondaryOwnerResourceType: string = null;
  /**
   * When uploading images as assets this is assigned to the asset.
   */
  @Input() assetSecondaryOwnerResourceId: number = null;
  /**
   * When uploading images as assets this is assigned to the asset.
   */
  @Input() assetSecondaryOwnerResourceId2: string = null;


  @Input() height: number = 250;
  @Input() minHeight: number = 250;
  @Input() maxHeight: number = 500;

  // Config Properties
  private _settings: any; //TinyMce.Settings;

  get settings(): any { //TinyMce.Settings {
    return this._settings;
  }

  @Input() set settings(value) {
    if (value) {
      this._settings = value;
    }
  }
  @Input() selector: string;

  // Native events
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public dblclick: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mousedown: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mouseup: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mousemove: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mouseover: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mouseout: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mouseenter: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mouseleave: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public keydown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() public keypress: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() public keyup: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() public contextmenu: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public paste: EventEmitter<ClipboardEvent> = new EventEmitter();

  // Core events
  //@Output() public init: EventEmitter<TinyMce.Events.Event> = new EventEmitter();
  //@Output() public focus: EventEmitter<TinyMce.Events.FocusBlurEvent> = new EventEmitter();
  //@Output() public blur: EventEmitter<TinyMce.Events.FocusBlurEvent> = new EventEmitter();
  //@Output() public beforesetcontent: EventEmitter<TinyMce.Events.ContentEvent> = new EventEmitter();
  //@Output() public setcontent: EventEmitter<TinyMce.Events.ContentEvent> = new EventEmitter();
  //@Output() public getcontent: EventEmitter<TinyMce.Events.ContentEvent> = new EventEmitter();
  //@Output() public preprocess: EventEmitter<TinyMce.Events.ProcessEvent> = new EventEmitter();
  //@Output() public postprocess: EventEmitter<TinyMce.Events.ProcessEvent> = new EventEmitter();
  //@Output() public nodechange: EventEmitter<TinyMce.Events.NodeChangeEvent> = new EventEmitter();
  //@Output() public undo: EventEmitter<TinyMce.Events.UndoRedoEvent> = new EventEmitter();
  //@Output() public redo: EventEmitter<TinyMce.Events.UndoRedoEvent> = new EventEmitter();
  //@Output() public change: EventEmitter<TinyMce.Events.ChangeEvent> = new EventEmitter();
  //@Output() public dirty: EventEmitter<TinyMce.Events.Event> = new EventEmitter();
  //@Output() public remove: EventEmitter<TinyMce.Events.Event> = new EventEmitter();
  //@Output() public execcommand: EventEmitter<TinyMce.Events.CommandEvent> = new EventEmitter();
  //@Output() public pastepreprocess: EventEmitter<TinyMce.Events.ContentEvent> = new EventEmitter();
  //@Output() public pastepostprocess: EventEmitter<TinyMce.Events.ContentEvent> = new EventEmitter();
  @Output() public init: EventEmitter<any> = new EventEmitter();
  @Output() public focus: EventEmitter<any> = new EventEmitter();
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  @Output() public beforesetcontent: EventEmitter<any> = new EventEmitter();
  @Output() public setcontent: EventEmitter<any> = new EventEmitter();
  @Output() public getcontent: EventEmitter<any> = new EventEmitter();
  @Output() public preprocess: EventEmitter<any> = new EventEmitter();
  @Output() public postprocess: EventEmitter<any> = new EventEmitter();
  @Output() public nodechange: EventEmitter<any> = new EventEmitter();
  @Output() public undo: EventEmitter<any> = new EventEmitter();
  @Output() public redo: EventEmitter<any> = new EventEmitter();
  @Output() public change: EventEmitter<any> = new EventEmitter();
  @Output() public dirty: EventEmitter<any> = new EventEmitter();
  @Output() public remove: EventEmitter<any> = new EventEmitter();
  @Output() public execcommand: EventEmitter<any> = new EventEmitter();
  @Output() public pastepreprocess: EventEmitter<any> = new EventEmitter();
  @Output() public pastepostprocess: EventEmitter<any> = new EventEmitter();

  editor: any; //TinyMce.Editor;
  @ViewChild('tinymce', { static: true }) elem: ElementRef;


  writeValue(obj: any): void {
    const val = obj != null ? obj.toString() : '';
    if (this.editor) {
      this.fromWriteValue = true;
      this.editor.setContent(val);
    } else {
      this.beforeInitValue = val;
    }
  }

  onModelChange: Function = () => { };
  onModelTouched: Function = () => { };

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
    this.setEditorMode(disabled);
  }

  setEditorMode(disabled: boolean) {
    if (this.editor) {
      if (disabled) {
        this.editor.setMode('readonly');
      } else {
        this.editor.setMode('design');
      }
    }
  }

  // Note we don't need to inject our settings as we only use the defaults of loading tinymce from the assets folder
  private _input_settings = HtmlEditorDefaultSettings();
  //constructor( @Inject(TINYMCE_SETTINGS_TOKEN) private _input_settings: any, private ngZone: NgZone) {

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected assetService: AssetService,
    private ngZone: NgZone) {

    this._setSettings(this.settings);

    if (!(window as any).tinymce && !document.getElementById('tinymceScript')) {
      const tag = document.createElement('script');
      tag.id = 'tinymceScript';
      tag.setAttribute('src', (this.settings as any).tinymceScriptURL || 'assets/tinymce/tinymce.min.js');
      tag.onload = () => {
        tinymce.baseURL = (this.settings as any).baseURL;
      };
      document.body.appendChild(tag);
    }
  }

  private _setSettings(settings: any) {
    const localSettings = settings || this._input_settings || {};
    this.settings = Object.assign({}, localSettings);
    if ((window as any).tinymce) {
      tinymce.baseURL = (this.settings as any).baseURL;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let needReinit = false;
    if (changes === null) {
      return;
    }

    // If we are flagged that we want to upload image assets rather than keep as blobs then configure that now
    if (changes.uploadImageAssets && changes.uploadImageAssets.currentValue) {
      this.tinymceConfigureUpload(this.settings);
      this._setSettings(this.settings);
      needReinit = true;
    }

    if (changes['settings']) {
      if (changes['settings'].currentValue) {
        this._setSettings(changes['settings'].currentValue);
        needReinit = true;
      }
    }

    if (changes.height && changes.height.currentValue) {
      this.settings.height = Math.max(50, this.height);
      if (this.height < this.minHeight) {
        this.minHeight = Math.max(50, this.height);
        this.settings.autoresize_min_height = Math.max(50, this.minHeight);
      }
    }
    if (changes.minHeight && changes.minHeight.currentValue) {
      this.settings.autoresize_min_height = Math.max(50, this.minHeight);
    }
    if (changes.maxHeight && changes.maxHeight.currentValue) {
      this.settings.autoresize_max_height = Math.max(100, this.maxHeight);
    }

    if (changes['isDisabled']) {
      this.setDisabledState(changes['isDisabled'].currentValue);
    }

    if (needReinit) {
      this.reInitEditor();
    }
  }

  ngAfterViewInit(): void {
    this.initEditor();
  }

  initEditor(): void {
    this.settings.target = this.elem.nativeElement;
    this.initCallbacks(this.settings);
    interval(300)
      .pipe(
        skipWhile(() => !(window as any).tinymce),
        take(1)
      )
      .subscribe(() => {
        tinymce.init(this.settings);
      });
  }


  reInitEditor(): void {
    if (this.editor) {
      this.ngZone.run(() => {
        this.triggerChange(true);
      });
    }

    this.removeEditor();
    this.initEditor();
  }

  initCallbacks(settings: any): void { //TinyMce.Settings): void {

    const origSetup = settings.setup;
    //settings.setup = (editor: TinyMce.Editor) => {
    //  editor.on(HtmlEditorEvents.Init, (e: TinyMce.Events.Event) => this.init.emit(e));
    //  if (origSetup) {
    //    origSetup(editor);
    //  }
    //};
    settings.setup = (editor: any) => {
      editor.on(HtmlEditorEvents.Init, (e: any) => this.init.emit(e));
      if (origSetup) {
        origSetup(editor);
      }
    };

    const origInstanceCallback = settings.init_instance_callback;
    settings.init_instance_callback = (editor: any) => { //(editor: TinyMce.Editor) => {
      this.editor = editor;
      this.setEditorMode(this.disabled);
      if (this.beforeInitValue != null) {
        this.editor.setContent(this.beforeInitValue);
      }
      if (origInstanceCallback) {
        origInstanceCallback(editor);
      }

      editor.on(HtmlEditorEvents.Click, (e: MouseEvent) => this.click.emit(e));
      editor.on(HtmlEditorEvents.DblClick, (e: MouseEvent) => this.dblclick.emit(e));
      editor.on(HtmlEditorEvents.MouseDown, (e: MouseEvent) => this.mousedown.emit(e));
      editor.on(HtmlEditorEvents.MouseUp, (e: MouseEvent) => this.mouseup.emit(e));
      editor.on(HtmlEditorEvents.MouseMove, (e: MouseEvent) => this.mousemove.emit(e));
      editor.on(HtmlEditorEvents.MouseOver, (e: MouseEvent) => this.mouseover.emit(e));
      editor.on(HtmlEditorEvents.MouseOut, (e: MouseEvent) => this.mouseout.emit(e));
      editor.on(HtmlEditorEvents.MouseEnter, (e: MouseEvent) => this.mouseenter.emit(e));
      editor.on(HtmlEditorEvents.MouseLeave, (e: MouseEvent) => this.mouseleave.emit(e));
      editor.on(HtmlEditorEvents.KeyDown, (e: KeyboardEvent) => this.keydown.emit(e));
      editor.on(HtmlEditorEvents.KeyPress, (e: KeyboardEvent) => this.keypress.emit(e));
      editor.on(HtmlEditorEvents.KeyUp, (e: KeyboardEvent) => {
        this.ngZone.run(() => {
          this.triggerChange();
        });
        this.keyup.emit(e);
      });
      editor.on(HtmlEditorEvents.ContextMenu, (e: MouseEvent) => this.contextmenu.emit(e));
      editor.on(HtmlEditorEvents.Paste, (e: ClipboardEvent) => this.paste.emit(e));

      //editor.on(HtmlEditorEvents.Focus, (e: TinyMce.Events.FocusBlurEvent) => this.focus.emit(e));
      //editor.on(HtmlEditorEvents.Blur, (e: TinyMce.Events.FocusBlurEvent) => this.blur.emit(e));
      //editor.on(HtmlEditorEvents.BeforeSetContent, (e: TinyMce.Events.ContentEvent) => this.beforesetcontent.emit(e));
      //editor.on(HtmlEditorEvents.SetContent, (e: TinyMce.Events.ContentEvent) => {
      //  this.ngZone.run(() => {
      //    this.triggerChange();
      //  });
      //  this.setcontent.emit(e);
      //});
      //editor.on(HtmlEditorEvents.GetContent, (e: TinyMce.Events.ContentEvent) => this.getcontent.emit(e));
      //editor.on(HtmlEditorEvents.PreProcess, (e: TinyMce.Events.ProcessEvent) => this.preprocess.emit(e));
      //editor.on(HtmlEditorEvents.PostProcess, (e: TinyMce.Events.ProcessEvent) => this.postprocess.emit(e));
      //editor.on(HtmlEditorEvents.NodeChange, (e: TinyMce.Events.NodeChangeEvent) => this.nodechange.emit(e));
      //editor.on(HtmlEditorEvents.Undo, (e: TinyMce.Events.UndoRedoEvent) => this.undo.emit(e));
      //editor.on(HtmlEditorEvents.Redo, (e: TinyMce.Events.UndoRedoEvent) => this.redo.emit(e));
      //editor.on(HtmlEditorEvents.Change, (e: TinyMce.Events.ChangeEvent) => {
      //  this.ngZone.run(() => {
      //    this.triggerChange();
      //  });
      //  this.change.emit(e);
      //});
      //editor.on(HtmlEditorEvents.Dirty, (e: TinyMce.Events.Event) => this.dirty.emit(e));
      //editor.on(HtmlEditorEvents.Remove, (e: TinyMce.Events.Event) => this.remove.emit(e));
      //editor.on(HtmlEditorEvents.ExecCommand, (e: TinyMce.Events.CommandEvent) => {
      //  this.ngZone.run(() => {
      //    this.triggerChange();
      //  });
      //  this.execcommand.emit(e);
      //});
      //editor.on(HtmlEditorEvents.PastePreProcess, (e: TinyMce.Events.ContentEvent) => this.pastepreprocess.emit(e));
      //editor.on(HtmlEditorEvents.PastePostProcess, (e: TinyMce.Events.ContentEvent) => this.pastepostprocess.emit(e));
      editor.on(HtmlEditorEvents.Focus, (e: any) => this.focus.emit(e));
      editor.on(HtmlEditorEvents.Blur, (e: any) => this.blur.emit(e));
      editor.on(HtmlEditorEvents.BeforeSetContent, (e: any) => this.beforesetcontent.emit(e));
      editor.on(HtmlEditorEvents.SetContent, (e: any) => {
        this.ngZone.run(() => {
          this.triggerChange();
        });
        this.setcontent.emit(e);
      });
      editor.on(HtmlEditorEvents.GetContent, (e: any) => this.getcontent.emit(e));
      editor.on(HtmlEditorEvents.PreProcess, (e: any) => this.preprocess.emit(e));
      editor.on(HtmlEditorEvents.PostProcess, (e: any) => this.postprocess.emit(e));
      editor.on(HtmlEditorEvents.NodeChange, (e: any) => this.nodechange.emit(e));
      editor.on(HtmlEditorEvents.Undo, (e: any) => this.undo.emit(e));
      editor.on(HtmlEditorEvents.Redo, (e: any) => this.redo.emit(e));
      editor.on(HtmlEditorEvents.Change, (e: any) => {
        this.ngZone.run(() => {
          this.triggerChange();
        });
        this.change.emit(e);
      });
      editor.on(HtmlEditorEvents.Dirty, (e: any) => this.dirty.emit(e));
      editor.on(HtmlEditorEvents.Remove, (e: any) => this.remove.emit(e));
      editor.on(HtmlEditorEvents.ExecCommand, (e: any) => {
        this.ngZone.run(() => {
          this.triggerChange();
        });
        this.execcommand.emit(e);
      });
      editor.on(HtmlEditorEvents.PastePreProcess, (e: any) => this.pastepreprocess.emit(e));
      editor.on(HtmlEditorEvents.PastePostProcess, (e: any) => this.pastepostprocess.emit(e));
    };
  }

  triggerChange(forReInit = false) {
    if (this.fromWriteValue) {
      this.fromWriteValue = false;
    } else {
      let content = this.editor.getContent();
      if (!content) {
        content = '';
      }
      if (forReInit) {
        this.beforeInitValue = content;
      }
      this.onModelChange(content);
      this.onModelTouched();
    }
  }

  removeEditor(): void {
    if (this.editor) {
      (tinymce as any).remove(this.editor);
    }
  }

  ngOnDestroy(): void {
    this.removeEditor();
  }



  protected tinymceConfigureUpload(settings: any) { //TinyMce.Settings) {

    settings.imagetools_cors_hosts = [this.appService.config.apiUrl.replace("https://", "").replace("http://", "")];

    settings.images_upload_handler = (blobInfo, success, failure) => {

      let info = blobInfo.blob();
      //console.error(info);
      if (!info || !info.name) {
        // failure callback with our error message
        failure("No file name provided.");
        return;
      }

      let model: any = {};
      let apiProp: ApiProperties = Api.Asset();
      if (this.parentAssetId) {
        apiProp = Api.AssetFileAsNewChildAsset();
        let file: m5.AssetFileEditViewModel = new m5.AssetFileEditViewModel();
        // We store the parent asset id here but since we're calling the api that explicitly says a new child is created this will internally be used as the parent asset id
        file.AssetId = this.parentAssetId;
        file.ContentType = info.type;
        file.SizeBytes = info.size;
        file.FileName = info.name;
        file.FriendlyName = info.name;
        file.IsPublic = Helper.equals(this.assetVisibility, "P", true);
        file.FileContentsBase64 = blobInfo.base64();
        model = file;
      } else {
        let asset: m5.AssetEditViewModel = new m5.AssetEditViewModel();
        asset.SystemAssetGroup = this.systemAssetGroup;
        asset.Visibility = this.assetVisibility;
        asset.OwnerResourceType = this.assetOwnerResourceType;
        asset.OwnerResourceId = this.assetOwnerResourceId;
        asset.OwnerResourceId2 = this.assetOwnerResourceId2;
        asset.SecondaryOwnerResourceType = this.assetSecondaryOwnerResourceType;
        asset.SecondaryOwnerResourceId = this.assetSecondaryOwnerResourceId;
        asset.SecondaryOwnerResourceId2 = this.assetSecondaryOwnerResourceId2;
        asset.ContentType = info.type;
        asset.SizeBytes = info.size;
        asset.FriendlyName = info.name;
        asset.AssetText = "Base64:" + blobInfo.base64();
        model = asset;
      }
      let apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);

      this.apiService.add(apiCall, model).subscribe((result: IApiResponseWrapper) => {
        if (result.Data.Success) {
          // Build the URL to the newly uploaded image
          let url: string = <string>this.assetService.buildFileViewUrl(result.Data.Data.AssetId, result.Data.Data.FriendlyName, result.Data.Data.FileType, false, false, false);
          // success callback with our image url
          success(url);
          return;
        } else {
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, result.Data.Message, 0);
          // failure callback with our error message
          failure(result.Data.Message);
          return;
        }
      });
    };

  }


}
