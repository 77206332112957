import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { FormGroupFluentModel } from 'projects/common-lib/src/lib/form/form-fluent-model';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, Query, IApiResponseScope } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { EventModel, ButtonItem, Action, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormBaseClass } from 'projects/common-lib/src/lib/form/form-base-class';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpContext } from 'projects/core-lib/src/lib/models/help-context'
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';

@Component({
  selector: 'ib-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.css']
  // inherit from host providers: [FormStatusService]
})
export class FilterBuilderComponent extends FormBaseClass<m5.FilterConditionGroupViewModel> {

  @Input() objectName: string = "";
  @Input() filter: m5.FilterEditViewModel = null;
  @Input() initialize: boolean = true;

  // this.dataModel.Columns .Name & .DataTypeCode
  //selectedFilterColumn: any = null;
  columnPickList: m5core.PickListSelectionViewModel[] = [];

  actionButton: ButtonItem = null;

  //previewData: any[] = [];
  //previewScope: IApiResponseScope = null;

  //currentColumn: string = "";
  //currentComparison: string = "";
  //currentValue: string = "";

  booleanOperatorPickList: m5core.PickListSelectionViewModel[] = [];
  compareStringPickList: m5core.PickListSelectionViewModel[] = [];
  compareNonStringPickList: m5core.PickListSelectionViewModel[] = [];
  dateRangeOptionsPickList: m5core.PickListSelectionViewModel[] = [];
  contactIdOptionsPickList: m5core.PickListSelectionViewModel[] = [];
  columnOptionsPickList: m5core.PickListSelectionViewModel[] = [];

  dataModel: any = null; // DataDictionaryTable or DataDictionaryView

  /**
  Make TypeCode available in html view.
  */
  public TypeCode = m.System.TypeCode;


  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected formService: FormStatusService,
    protected queryService: QueryService,
    protected ngbModalService: NgbModal) {

    super(appService, uxService, formService, true, Constants.ContactType.Directory);

    // These three properties get passed in any events the form emits.
    this.formId = "FilterBuilder";
    this.formDescription = "Filter Builder";
    this.formCargo = {};

  }

  // If any of these are implemented here they need to call super.x() to get the base class implementation executed.
  ngOnInit() {
    super.ngOnInit();
    this.loadDataModel();
    this.booleanOperatorPickList = StaticPickList.FilterBooleanOperator();
    this.compareStringPickList = StaticPickList.FilterBuilderComparisonOperatorString();
    this.compareNonStringPickList = StaticPickList.FilterBuilderComparisonOperatorNonString();
    this.contactIdOptionsPickList = StaticPickList.FilterContactIdOptions();
    this.dateRangeOptionsPickList = StaticPickList.DateRangeOptions();
    this.actionButton = this.buildActionButton();
    this.appService.helpLinkConfigure(HelpContext.FilterSettings);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.objectName) {
      this.loadDataModel();
    }
    if (changes.data) {
      if (this.data && this.dataModel) {
        this.queryService.filterConditionsWireUpMeta(this.data, this.dataModel);
        if (this.initialize) {
          this.addDefaultCondition(this.data, false);
        }
      }
    }
    if (changes.initialize && this.initialize) {
      this.addDefaultCondition(this.data, false);
    }
  }

  //ngOnDestroy() {
  //  super.ngOnDestroy();
  //}
  //ngAfterViewInit() {
  //  super.ngAfterViewInit();
  //}



  protected buildActionButton(): ButtonItem {
    const button = new ButtonItem("", "plus", "secondary");
    button.size = "sm";
    button.menuItemSize = "sm";
    button.options.push(new Action("condition", "Add Condition", "", "", ($event: EventModelTyped<m5.FilterConditionGroupViewModel>) => {
      //console.error("add condition", $event);
      this.addDefaultCondition($event?.data, true);
      this.formDirty();
      //if ($event?.data) {
      //  if (!$event.data.Conditions) {
      //    $event.data.Conditions = [];
      //  }
      //  const condition = new m5.FilterConditionViewModel();
      //  if (this.dataModel && this.dataModel.Columns && this.dataModel.Columns.length > 0) {
      //    condition.PropertyName = this.dataModel.Columns[0].Name;
      //    condition.DataType = this.dataModel.Columns[0].DataTypeCode;
      //  }
      //  condition.ComparisonOperator = "Equals";
      //  $event.data.Conditions.push(condition);
      //  this.queryService.filterConditionsWireUpMetaOne($event.data, this.dataModel, condition.PropertyName);
      //  this.formDirty();
      //}
    }));
    button.options.slice(-1)[0].visible = (data: m5.FilterConditionGroupViewModel) => {
      if (data && data.Groups && data.Groups.length > 0) {
        // If we have one or more groups then we don't allow adding conditions we only allow adding more groups
        // The child groups can host conditions.  Otherwise, it gets very confusing for end-users to have groups
        // with conditions and child groups.
        return false;
      }
      return true;
    };
    button.options.push(new Action("group", "Add Group", "", "", ($event: EventModelTyped<m5.FilterConditionGroupViewModel>) => {
      //console.error("add group", $event);
      if ($event?.data) {
        if (!$event.data.Groups) {
          $event.data.Groups = [];
        }
        // If we have current conditions we push those into a peer group with our new group otherwise the UI gets pretty
        // complicated with a group that has both conditions and groups and users may not be able to visualize how the
        // logic is going to work but by pushing the conditions into a peer group it seems easier for user to visualize.
        if ($event.data.Conditions && $event.data.Conditions.length > 0) {
          // Peer group with current conditions
          const peer = new m5.FilterConditionGroupViewModel();
          peer.ConditionBooleanOperator = $event.data.ConditionBooleanOperator;
          peer.Conditions = $event.data.Conditions;
          peer.GroupBooleanOperator = "And";
          $event.data.Groups.push(peer);
          $event.data.Conditions = [];
        }
        // New group
        const group = new m5.FilterConditionGroupViewModel();
        group.ConditionBooleanOperator = "And";
        group.GroupBooleanOperator = "And";
        this.addDefaultCondition(group, true);
        //const condition = new m5.FilterConditionViewModel();
        //if (this.dataModel && this.dataModel.Columns && this.dataModel.Columns.length > 0) {
        //  condition.PropertyName = this.dataModel.Columns[0].Name;
        //  condition.DataType = this.dataModel.Columns[0].DataTypeCode;
        //}
        //condition.ComparisonOperator = "Equals";
        //group.Conditions.push(condition);
        //this.queryService.filterConditionsWireUpMetaOne(group, this.dataModel, condition.PropertyName);
        $event.data.Groups.push(group);
        this.formDirty();
      }
    }));
    return button;
  }


  // Sets up the callback to get the data model, column names, etc.
  loadDataModel() {
    if (!this.objectName) {
      return;
    }
    const apiProp = Api.DocumentationRawDataModel();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.cacheUseStorage = true;
    this.apiService.execute(apiCall, this.objectName).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        this.dataModel = result.Data.Data;
        this.parseDataModel();
        this.queryService.filterConditionsWireUpMeta(this.data, this.dataModel);
        if (this.initialize) {
          this.addDefaultCondition(this.data, false);
        }
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });
  }

  protected parseDataModel() {

    if (!this.dataModel) {
      console.error("unable to parse null data model");
      return;
    }
    if (!this.dataModel.Columns || this.dataModel.Columns.length === 0) {
      console.error("unable to parse data model without columns");
      return;
    }

    this.columnPickList = StaticPickList.DataModelColumns(this.dataModel.Columns);

    //this.dataModel.Columns.forEach((col: any, index: number, cols: any[]) => {

    //  //this.filterColumns.push(new FilterColumn(col.Name, col.DataTypeCode));

    //  if (Helper.equals(col.Name, "MarkedForDeletionDateTime", true) ||
    //    Helper.equals(col.Name, "MarkedForDeletionByContactId", true) ||
    //    Helper.equals(col.Name, "CurrentRowVersion", true) ||
    //    Helper.equals(col.Name, "PartitionId", true)) {
    //    // These columns are excluded from the list of columns that can be selected
    //  } else {
    //    const picker: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
    //    picker.PickListId = ``;
    //    picker.DisplayOrder = index;
    //    picker.DisplayText = col.Description;
    //    picker.Value = col.Name;
    //    this.columnPickList.push(picker);
    //  }

    //});

  }


  addDefaultCondition(group: m5.FilterConditionGroupViewModel, force: boolean) {

    if (!group) {
      return;
    }
    if (!group.Conditions) {
      group.Conditions = [];
    }
    // If we have a condition then we only add when forced (i.e. requested)
    if (!force && group.Conditions.length > 0) {
      return;
    }
    // If we don't have a data model object with columns then we can't add a default condition
    if (!this.dataModel || !this.dataModel.Columns || this.dataModel.Columns.length === 0) {
      Log.warningMessage("No data model with columns available so unable to add default filter condition.");
      return;
    }

    // Pick a default columns
    let col = this.dataModel.Columns[0];
    let matches = this.dataModel.Columns.filter(x => Helper.equals(x.Name, "Description", true));
    if (matches.length > 0) {
      col = matches[0];
    } else {
      matches = this.dataModel.Columns.filter(x => Helper.startsWith(x.Name, "External", true) && Helper.endsWith(x.Name, "Id", true));
      if (matches.length > 0) {
        col = matches[0];
      }
    }

    const condition = new m5.FilterConditionViewModel();
    condition.PropertyName = col.Name;
    condition.DataType = col.DataTypeCode;
    condition.ComparisonOperator = "Equals";
    this.data.Conditions.push(condition);
    this.queryService.filterConditionsWireUpMetaOne(this.data, this.dataModel, condition.PropertyName);

    return;

  }


  onColumnSelect($event: EventModel, group: m5.FilterConditionGroupViewModel, index: number) {
    //console.error(index, $event, group);
    this.queryService.filterConditionsWireUpMetaOne(group, this.dataModel, $event.data);
    //const col = Helper.firstOrDefault<any>(this.dataModel.Columns, x => Helper.equals(x.Name, $event.data, true));
    //if (col) {
    //  if (group && group.Conditions) {
    //    group.Conditions.forEach((condition) => {
    //      if (Helper.equals(condition.PropertyName, $event.data, true)) {
    //        condition.DataType = col.DataTypeCode;
    //        // Stick some data in a meta object which we won't save to help with ux
    //        if (!(condition as any).Meta) {
    //          (condition as any).Meta = {};
    //        }
    //        (condition as any).Meta.Column = col;
    //        (condition as any).Meta.PickListOptions = StaticPickList.DataModelColumnOptions(col.Options);
    //        (condition as any).Meta.PickListName = ""; // TODO when we have this in the data model column object
    //        if (condition.DataType === m.System.TypeCode.DateTime && !condition.ValueMetaData) {
    //          condition.ValueMetaData = "CUSTOM";
    //        } else if (this.queryService.filterConditionIsContactId(condition) && !condition.ValueMetaData) {
    //          condition.ValueMetaData = "Me";
    //        }
    //        this.formDirty();
    //      }
    //    });
    //  } else {
    //    Log.errorMessage(`No group defined or no conditions for the group defined in onColumnSelect call for column named '${$event.data}' in object '${this.objectName}'.`);
    //  }
    //} else {
    //  Log.errorMessage(`Unable to find column named '${$event.data}' in object '${this.objectName}'.`);
    //}
  }

  isContactId(condition: m5.FilterConditionViewModel) {
    return this.queryService.filterConditionIsContactId(condition);
  }

  isSelectSingleInput(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return false;
    }
    if (this.queryService.isLikeOperator(condition.ComparisonOperator) ||
      this.queryService.isInOperator(condition.ComparisonOperator) ||
      this.queryService.isBetweenOperator(condition.ComparisonOperator)) {
      return false;
    }
    if (!(condition as any).Meta) {
      return false;
    }
    if ((condition as any).Meta.PickListId || ((condition as any).Meta.PickListOptions && (condition as any).Meta.PickListOptions.length > 0)) {
      return true;
    }
    return false;
  }

  isMultiSelectSingleInput(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return false;
    }
    if (!this.queryService.isInOperator(condition.ComparisonOperator)) {
      return false;
    }
    if (!(condition as any).Meta) {
      return false;
    }
    if ((condition as any).Meta.PickListId || ((condition as any).Meta.PickListOptions && (condition as any).Meta.PickListOptions.length > 0)) {
      return true;
    }
    return false;
  }

  isTextSingleInput(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return true;
    }

    if (!this.queryService.isBetweenOperator(condition.ComparisonOperator)) {
      if (!this.isSelectSingleInput(condition) && !this.isMultiSelectSingleInput(condition)) {
        if (condition.DataType !== m.System.TypeCode.DateTime || Helper.equals(condition.ValueMetaData, 'CUSTOM', true)) {
          //User selects anything besides ContactId.
          if (!this.queryService.filterConditionIsContactId(condition)) {
            return true;
          }

          //Always return true for 'Other' execpt when the user also selected ContactId.
          if (Helper.equals(condition.ValueMetaData, 'Other', true) && !this.queryService.filterConditionIsContactId(condition)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  canDisplayInputContact(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return true;
    }

    if (!this.queryService.isBetweenOperator(condition.ComparisonOperator)) {
      if (!this.isSelectSingleInput(condition) && !this.isMultiSelectSingleInput(condition)) {
        if (condition.DataType !== m.System.TypeCode.DateTime || Helper.equals(condition.ValueMetaData, 'CUSTOM', true)) {
          //User selects ContactId and Other
          if (this.queryService.filterConditionIsContactId(condition) && Helper.equals(condition.ValueMetaData, 'Other', true)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isTextRangeInput(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return false;
    }
    if (this.queryService.isBetweenOperator(condition.ComparisonOperator) &&
      (condition.DataType !== m.System.TypeCode.DateTime || Helper.equals(condition.ValueMetaData, 'CUSTOM', true)) &&
      (!this.queryService.filterConditionIsContactId(condition) || Helper.equals(condition.ValueMetaData, 'Other', true))) {
      return true;
    }
    return false;
  }

  onComparisonOperatorSelect($event: EventModel, condition: m5.FilterConditionViewModel) {
    //console.error(index, $event, group);
    if (!condition) {
      return;
    }
    this.formDirty();
    if (this.queryService.isBetweenOperator(condition.ComparisonOperator)) {
      // Convert single value to array of 2 items
      if (!Helper.isArray(condition.Value)) {
        condition.Value = [condition.Value, condition.Value];
      }
    } else {
      // Convert array of 2 items to single value
      if (Helper.isArray(condition.Value)) {
        condition.Value = condition.Value[0];
      }
    }
  }

  onDeleteCondition($event: EventModel, condition: m5.FilterConditionViewModel, group: m5.FilterConditionGroupViewModel, index: number) {
    if (this.filter && this.filter.FilterLocked) {
      // Silent no action on locked filter
      return;
    }
    if (!group) {
      Log.errorMessage(`No group specified as parent of the condition to delete.`);
      return;
    }
    if (!condition) {
      Log.errorMessage(`No condition object to delete specified.`);
      return;
    }
    if (index === undefined || index === -1) {
      Log.errorMessage(`No index specified for condition object to delete.`);
      return;
    }
    group.Conditions.splice(index, 1);
    this.formDirty();
    //console.error(index, $event, group, condition);
  }

  onDeleteGroup($event: EventModel, group: m5.FilterConditionGroupViewModel, parentGroup: m5.FilterConditionGroupViewModel, index: number) {
    if (this.filter && this.filter.FilterLocked) {
      // Silent no action on locked filter
      return;
    }
    if (!group) {
      Log.errorMessage(`No group object to delete specified.`);
      return;
    }
    if (!index && !parentGroup) {
      // Deleting top layer group
      group.Conditions = [];
      group.Groups = [];
    } else {
      parentGroup.Groups.splice(index, 1);
      this.formDirty();
    }
    //console.error(index, $event, group, parentGroup);
  }

  setContact($event, condition) {
    condition.Value = $event.data.ContactId;
    condition.ValueDescription = $event.data.ContactName;

    this.formDirty();
  }

}
