import { Component, OnInit, OnChanges, SimpleChanges, Input, ViewChild, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { NgForm, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-modal-dynamic-form',
  templateUrl: './modal-dynamic-form.component.html',
  styleUrls: ['./modal-dynamic-form.component.css']
})
export class ModalDynamicFormComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() public options: ModalCommonOptions = new ModalCommonOptions();
  @Input() public disableWhenPristine: boolean = false;
  @Input() public disableWhenInvalid: boolean = true;
  @Input() public form: m5web.FormEditViewModel;
  @Input() public data: any;
  //@Output() public onOk: EventEmitter<EventModel> = new EventEmitter();
  //@Output() public onCancel: EventEmitter<EventModel> = new EventEmitter();

  public formStatus: FormStatusModel = new FormStatusModel();

  /**
   * The constructor
   * @param modal The NgbModal service will inject the active modal here so we can close or dismiss the modal from inside this component.
   */
  constructor(public modal: NgbActiveModal, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  ngAfterViewChecked() {
    // Address ModalDynamicFormComponent.html:11 ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'.
    this.cdr.detectChanges();
  }

  protected configure() {

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

    //console.error(this.form);
    //console.error(this.data);

  }

  onFormStatusChange($event) {
    this.formStatus = $event.data;
  }

}
