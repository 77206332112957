<div>

  <div class="row" style="padding-bottom:8px;" ng-show="url">
    <div class="{{Constants.Layout.fullWidth}}">
      <ib-peek-with-header [visible]="true">
        <h4 header>Request Url</h4>
        <ib-code-block body>
          {{httpMethod | uppercase}} {{url}}
        </ib-code-block>
      </ib-peek-with-header>
    </div>
  </div>

  <div class="row" [hidden]="!requestHeaders">
    <div class="{{Constants.Layout.fullWidth}}">
      <ib-peek-with-header [visible]="true">
        <h4 header>Request Headers Sample</h4>
        <ib-code-block body>
          {{requestHeaders | json}}
        </ib-code-block>
      </ib-peek-with-header>
    </div>
  </div>

  <div class="row" style="padding:8px 0px 8px 0px;">
    <div class="{{Constants.Layout.fullWidth}}">
      <ib-peek-with-header [visible]="true">
        <h4 header>Request Body Sample</h4>
        <ib-code-block body>
          {{requestBody | json}}
        </ib-code-block>
      </ib-peek-with-header>
    </div>
  </div>

  <div *ngIf="requestModelDocumentation" style="padding:0px 0px 8px 0px;">
    <button type="submit" class="btn btn-xs btn-outline-secondary" (click)="requestModelDocumentationVisible = !requestModelDocumentationVisible;">
      <div [hidden]="requestModelDocumentationVisible">
        <i class="far fa-angle-double-down"></i>&nbsp;Show Request Body Details
      </div>
      <div [hidden]="!requestModelDocumentationVisible">
        <i class="far fa-angle-double-up"></i>&nbsp;Hide Request Body Details
      </div>
    </button>
    <div [hidden]="!requestModelDocumentationVisible">
      <app-data-model-property-table [properties]="requestModelDocumentation.Columns"
                                     [showPartitionId]="false">
      </app-data-model-property-table>
    </div>
  </div>

  <div class="row" [hidden]="!responseHeaders">
    <div class="{{Constants.Layout.fullWidth}}">
      <ib-peek-with-header [visible]="true">
        <h4 header>Response Headers Sample</h4>
        <ib-code-block body>
          {{responseHeaders | json}}
        </ib-code-block>
      </ib-peek-with-header>
    </div>
  </div>

  <div class="row" style="padding:8px 0px 8px 0px;">
    <div class="{{Constants.Layout.fullWidth}}">
      <ib-peek-with-header [visible]="true">
        <h4 header>Response Body Sample</h4>
        <ib-code-block body>
          {{responseBody | json}}
        </ib-code-block>
      </ib-peek-with-header>
    </div>
  </div>

  <div *ngIf="responseModelDocumentation" style="padding:0px 0px 8px 0px;">
    <button type="submit" class="btn btn-xs btn-outline-secondary" (click)="responseModelDocumentationVisible = !responseModelDocumentationVisible;">
      <div [hidden]="responseModelDocumentationVisible">
        <i class="far fa-angle-double-down"></i>&nbsp;Show Response Body Details
      </div>
      <div [hidden]="!responseModelDocumentationVisible">
        <i class="far fa-angle-double-up"></i>&nbsp;Hide Response Body Details
      </div>
    </button>
    <p [hidden]="!responseModelDocumentationVisible" style="padding-top: 5px;">
      Every response from the API uses a common response format
      <ib-peek><app-response-body></app-response-body></ib-peek>.
      Details regarding the response object's data property is listed below.
    </p>
    <div class="clearfix"></div>
    <div [hidden]="!responseModelDocumentationVisible">
      <app-data-model-property-table [properties]="responseModelDocumentation.Columns"
                                     [showPartitionId]="false">
      </app-data-model-property-table>
    </div>
  </div>

</div>
