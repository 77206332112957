import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCheckboxComponent),
  multi: true
};

@Component({
  selector: 'ib-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputCheckboxComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  /**
  When true (the default) space where labels appear for other controls is used for spacing
  with this input so the controls align since this control's label appears after the input.
  */
  @Input() alignWithOtherControls: boolean = true;

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (this.vertical) {
      this.inputWrapperClass += " pl-1";
    } else {
      this.inputWrapperClass += " form-check";
    }

  }

}
