import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5pay from "projects/core-lib/src/lib/models/ngModelsPayment5";
import * as wm5 from "projects/core-lib/src/lib/models/ngWalletModels";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";

export class ApiModulePayment {


  //#region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModulePayment).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModulePayment[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModulePayment[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModulePayment.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModulePayment.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  //#endregion Helper Functions


  //#region Payment APIs


  public static PaymentMethodCardType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Method Card Type";
    api.documentation.objectPrimaryKey = "PaymentMethodCardTypeSurrogateId";
    api.documentation.objectDescriptionPropertyNames = ["PaymentMethodCardTypeId", "Description"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentMethodCardType;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentMethodCardTypeEditViewModel();
    api.documentation.documentationUrlBase = "/payment-method-card-type/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentMethodCardType;
    api.pathVariables = "{paymentMethodCardTypeSurrogateId}";
    api.pathModelProperties = "PaymentMethodCardTypeSurrogateId";
    api.cacheName = "staticObjectCache";
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentMethodCardTypes}`);
    return api;
  };

  public static CustomerPaymentMethodCreditCard(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Customer Credit Card";
    api.documentation.objectPrimaryKey = "PaymentMethodId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentMethodCreditCard;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentMethodCreditCardEditViewModel();
    api.documentation.documentationUrlBase = "/customer/payment-method/credit-card/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentMethod;
    api.pathVariables = ["{contactId}", "{paymentMethodId}"];
    api.pathModelProperties = ["ContactId", "PaymentMethodId"];
    api.cacheName = "contactCache";
    api.parentApi = "Customer";
    api.useStandardEndpoints("/customers/{contactId}/payment-methods/credit-cards");
    ApiHelper.updateApiEndpointDocumentation(api, ApiOperationType.List, "", "This API retrieves a list of credit cards for the specified contact.  Note that retired; hidden; and blacklisted credit cards are not included in results by default.  To include credit cards with retired; hidden; or blacklisted status include expand parameter of \"all\".");
    ApiHelper.updateApiEndpointDocumentation(api, ApiOperationType.Edit, "", "This API updates a credit card.  Note that the credit card type and credit card number cannot be updated.  If those values need to be changed a new credit card needs to be added.");
    ApiHelper.updateApiEndpointDocumentation(api, ApiOperationType.Delete, "", "This API deletes a credit card.  Note that credit cards that have been used one or more times are <em>not</em> actually deleted but are instead wiped of any sensitive information and flagged as retired.");
    return api;
  };

  public static PaymentMethod(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Method";
    api.documentation.objectPrimaryKey = "PaymentMethodId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentMethod;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentMethodEditViewModel();
    api.documentation.documentationUrlBase = "/contact/payment-method/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentMethod;
    api.pathVariables = ["{contactId}", "{paymentMethodId}"];
    api.pathModelProperties = ["ContactId", "PaymentMethodId"];
    api.cacheName = "contactCache";
    api.parentApi = "Customer";
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/payment-methods?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a list of payment methods for the specified contact.  Note that retired, hidden, and blacklisted payment methods are not included in results by default.  To include payment methods with retired, hidden, or blacklisted status include expand parameter of \"all\".";
    api.endpoints.slice(-1)[0].documentation.responseDataModelObject = new m5pay.PaymentMethodListViewModel();
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = Constants.DataModelName.PaymentMethodList;
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/payment-methods/{paymentMethodId}", ApiOperationType.Get));
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/payment-methods", ApiOperationType.Add));
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/payment-methods/{paymentMethodId}", ApiOperationType.Edit));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API updates a payment method.  Note that the encryption key tag, payment method card type id, and account number cannot be updated.  If those values need to be changed a new payment method needs to be added.";
    api.endpoints.push(new ApiEndpoint("/contacts/{contactId}/payment-methods/{paymentMethodId}", ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API deletes a payment method.  Note that payment methods that have been used one or more times are <em>not</em> actually deleted but are instead wiped of any sensitive information and flagged as retired.";
    return api;
  };

  public static PaymentProvider(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Provider";
    api.documentation.objectPrimaryKey = "PaymentProviderId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalPaymentProviderId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentProvider;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentProviderEditViewModel();
    api.documentation.documentationUrlBase = "/payment-provider/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentProvider;
    api.pathVariables = "{paymentProviderId}";
    api.pathModelProperties = "PaymentProviderId";
    api.cacheName = "paymentCache";
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentProviders}`, null, null, true);
    return api;
  };

  public static PaymentProviderSupportedCardType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Provider Supported Card Type";
    api.documentation.objectPrimaryKey = ["PaymentProviderId", "PaymentMethodCardTypeId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentProviderSupportedCardType;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentProviderSupportedCardTypeEditViewModel();
    api.documentation.documentationUrlBase = "/payment-provider/supported-card-type/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentProviderSupportedCardType;
    api.pathVariables = ["{paymentProviderId}", "{paymentMethodCardTypeId}"];
    api.pathModelProperties = ["PaymentProviderId", "PaymentMethodCardTypeId"];
    api.cacheName = "paymentCache";
    api.parentApi = "PaymentProvider";
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentProviders}/{paymentProviderId}/${m.RouteSegment.PaymentMethodCardTypes}`, null, null, true);
    return api;
  };

  public static PaymentProviderSelectionRule(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Provider Selection Rule";
    api.documentation.objectPrimaryKey = ["PaymentProviderSelectionRuleId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentProviderSelectionRule;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentProviderSelectionRuleEditViewModel();
    api.documentation.documentationUrlBase = "/payment-provider/selection-rule/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentProviderSelectionRule;
    api.pathVariables = ["{paymentProviderId}", "{paymentProviderSelectionRuleId}"];
    api.pathModelProperties = ["PaymentProviderId", "PaymentProviderSelectionRuleId"];
    api.cacheName = "paymentCache";
    api.parentApi = "PaymentProvider";
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentProviders}/{paymentProviderId}/${m.RouteSegment.SelectionRules}`, null, null, true);
    return api;
  };

  public static PaymentProviderTrigger(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Provider Trigger";
    api.documentation.objectPrimaryKey = ["PaymentProviderTriggerId"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentProviderTrigger;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentProviderTriggerEditViewModel();
    api.documentation.documentationUrlBase = "/payment-provider/trigger/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentProviderTrigger;
    api.pathVariables = ["{paymentProviderId}", "{paymentProviderTriggerId}"];
    api.pathModelProperties = ["PaymentProviderId", "PaymentProviderTriggerId"];
    api.cacheName = "paymentCache";
    api.parentApi = "PaymentProvider";
    api.useStandardEndpoints(`/${m.RouteSegment.PaymentProviders}/{paymentProviderId}/${m.RouteSegment.Triggers}`, null, null, true);
    return api;
  };

  public static PaymentTransaction(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Transaction";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentTransaction;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentTransactionEditViewModel();
    api.documentation.documentationUrlBase = "/payment-transaction/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentTransaction;
    api.pathVariables = "{paymentTransactionId}";
    api.pathModelProperties = "PaymentTransactionId";
    api.cacheName = "paymentCache";
    api.endpoints.push(new ApiEndpoint("/payment-transactions?page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a list of payment transactions.  Note that only transactions in the current calendar month are included unless the filter includes a date range " +
      "or the expand parameter of \"all\" is used.  For performance reasons a date range filter is preferred over the expand parameter of \"all\".";
    api.endpoints.push(new ApiEndpoint("/payment-transactions/{paymentTransactionId}", ApiOperationType.Get));
    return api;
  };

  public static PaymentTransactionGetByIdType(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Transaction";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentTransaction;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentTransactionEditViewModel();
    api.documentation.documentationUrlBase = "/payment-transaction/get-by-id-type/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentTransaction;
    api.pathVariables = ["{paymentTransactionId}", "{idType}", "{requestType}", "{expand}"];
    api.pathModelProperties = ["PaymentTransactionId", "IdType", "RequestType", "Expand"];
    api.cacheName = "paymentCache";
    api.endpoints.push(new ApiEndpoint("/payment-transactions/{paymentTransactionId}?idType={idType}&requestType={requestType}&expand={expand}", ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Get Transaction by Id Type";
    api.endpoints.slice(-1)[0].documentation.menuText = "Get Transaction by Id Type";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API retrieves a payment transaction by id and id type.  Possible id type values include " +
      "'PaymentTransactionId' , 'TransactionOrderReference', and 'TransactionPaymentId' (or its alias 'PaymentProviderTransactionPaymentId').  " +
      "If the expand query string parameter is set to 'Contact' the response will include information about the contact the payment translation belongs " +
      "to in the meta property.";
    return api;
  };

  public static PaymentTransactionAdd(version: number = AppConfig.apiVersion): ApiProperties {
    const api = ApiModulePayment.PaymentTransaction(version);
    api.modules = ["billing"];
    // Different documentation and model
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentTransactionAdd;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentTransactionEditViewModel();
    // Clear methods set in our starting object and add the one we want
    api.endpoints = Enumerable.from(api.endpoints).where(x => x.type !== ApiOperationType.List && x.type !== ApiOperationType.Get).toArray();
    api.endpoints.push(new ApiEndpoint("/payment-transactions", ApiOperationType.Add));
    return api;
  };

  public static PaymentTransactionSale(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Sale";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentTransactionSale;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentTransactionSaleAddViewModel();
    api.documentation.documentationUrlBase = "/payment-transaction/sale/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentTransaction;
    api.pathVariables = "{process}";
    api.pathModelProperties = "Process";
    api.cacheName = "paymentCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentTransactions}/${m.RouteSegment.Sales}?process={process}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add Sale";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add Sale";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API adds a sale payment transaction.  If the process query string value " +
      "is true (the default) then the transaction will be processed within the API request and the response will include the results of the " +
      "payment transaction processing.  If false, the transaction will be processed by the back-end payment transaction processor and the " +
      "payment transaction get method will need to be called to discover if the payment processing was successful or not.";
    return api;
  };

  public static PaymentTransactionRefund(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Refund";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentTransactionRefund;
    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentTransactionRefundAddViewModel();
    api.documentation.documentationUrlBase = "/payment-transaction/refund/";
    api.documentation.securityAccessArea = Constants.AccessArea.PaymentTransaction;
    api.pathVariables = "{process}";
    api.pathModelProperties = "Process";
    api.cacheName = "paymentCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentTransactions}/${m.RouteSegment.Refunds}?process={process}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Add Refund";
    api.endpoints.slice(-1)[0].documentation.menuText = "Add Refund";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API adds a refund payment transaction.  If the process query string value " +
      "is true (the default) then the transaction will be processed within the API request and the response will include the results of the " +
      "payment transaction processing.  If false, the transaction will be processed by the back-end payment transaction processor and the " +
      "payment transaction get method will need to be called to discover if the payment processing was successful or not.";
    return api;
  };

  //public static PaymentTransactionEdit(version: number = AppConfig.ApiVersion): ApiProperties {
  //    let api = m.Api.PaymentTransaction(version);
  //    // Different documentation and model
  //    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.PaymentTransactionEdit;
  //    api.documentation.requestAndResponseDataModelObject = new m5pay.PaymentTransactionEditViewModel();
  //    // Clear methods set in our starting object and add the one we want
  //    api.endpoints = Enumerable.From(api.endpoints).Where(x => x.type !== m.ApiOperationType.List && x.type !== m.ApiOperationType.Get).ToArray();
  //    api.endpoints.push(new ApiEndpoint("/payment-transactions/{paymentTransactionId}", ApiOperationType.Edit));
  //    return api;
  //};

  public static PaymentTransactionDelete(version: number = AppConfig.apiVersion): ApiProperties {
    const api = ApiModulePayment.PaymentTransaction(version);
    api.modules = ["billing"];
    // Clear methods set in our starting object and add the one we want
    api.endpoints = Enumerable.from(api.endpoints).where(x => x.type !== ApiOperationType.List && x.type !== ApiOperationType.Get).toArray();
    api.endpoints.push(new ApiEndpoint("/payment-transactions/{paymentTransactionId}", ApiOperationType.Delete));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Delete Payment Transaction";
    api.endpoints.slice(-1)[0].documentation.menuText = "Delete Payment Transaction";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API endpoint is used to delete a payment transaction.  " +
      "Note that successful payment transactions cannot be deleted.";
    return api;
  };

  public static PaymentTransactionReceipt(version: number = AppConfig.apiVersion): ApiProperties {
    const api = ApiModulePayment.PaymentTransaction(version);
    api.modules = ["billing"];
    // Different documentation and model
    api.documentation.requestAndResponseDataModelDocumentationName = ""; //Constants.DataModelName.PaymentTransactionEdit;
    api.documentation.requestAndResponseDataModelObject = null; // new m5pay.PaymentTransactionEditViewModel();
    // Clear methods set in our starting object and add the one we want
    api.endpoints = Enumerable.from(api.endpoints).where(x => x.type !== ApiOperationType.List && x.type !== ApiOperationType.Get && x.type !== ApiOperationType.Edit).toArray();
    api.pathVariables = ["{paymentTransactionId}", "{toList}", "{ccList}", "{bccList}"];
    api.pathModelProperties = ["PaymentTransactionId", "ToList", "CcList", "BccList"];
    api.endpoints.push(new ApiEndpoint("/payment-transactions/{paymentTransactionId}/actions/send-receipt?toList={toList}&ccList={ccList}&bccList={bccList}", ApiOperationType.Call));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Request Transaction Receipt";
    api.endpoints.slice(-1)[0].documentation.menuText = "Request Transaction Receipt";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API provides the ability to request a receipt.  The receipt is sent to the email address of record for the contact who owns " +
      "the payment transaction.";
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "file-text-o";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Receipt";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static PaymentNotification(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectDescription = "Payment Notification";
    api.documentation.objectPrimaryKey = "PaymentTransactionId";
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.PaymentNotification;
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.PaymentTransaction;
    api.documentation.requestDataModelObject = new m5pay.PaymentNotificationAddViewModel();
    api.documentation.responseDataModelObject = new m5pay.PaymentTransactionEditViewModel();
    api.documentation.documentationUrlBase = "/payment-notification/";
    api.pathVariables = "{paymentTransactionId}";
    api.pathModelProperties = "PaymentTransactionId";
    api.cacheName = "paymentCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentNotifications}`, ApiOperationType.Add));
    return api;
  };

  public static PaymentNotificationPayPalIPN(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectName = "PayPalIPN";
    api.documentation.objectDescription = "PayPal IPN";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/payment-notification/pay-pal/ipn/";
    api.pathVariables = ["{apiKey}", "{contactId}"];
    api.pathModelProperties = ["ApiKey", "ContactId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentNotifications}/${m.RouteSegment.PayPal}/${m.RouteSegment.IPN}/{apiKey}/{contactId}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "PayPal IPN";
    api.endpoints.slice(-1)[0].documentation.menuText = "PayPal IPN";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is utilized to facilitate logging of PayPal IPN responses desired from " +
      "PayPal.  This API will log posts to this url but performs no other operations unless explicitly configured to do so.  " +
      "<br/><br/>" +
      "Note that PayPal does not typically post IPN messages for direct payment API calls.  These are typically provided for PayPal button events when configured." +
      "<br/><br/>" +
      "The api key used in the url for this api endpoint <strong>must be properly secured</strong> to only provide access to resources required to accomplish the " +
      "purposes of the webhook post.  The api key flags must to contain 'webhook' as well as the allowed webhook types.  For example, if using this for " +
      "PayPal IPN the api key flags would contain: 'webhook,pay-pal-ipn'.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = { dynamic: "No specific format is required.  Any data posted is logged with no assumptions about format or contents." };
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.responseDataModelIsNull = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    const testForm: ApiDocTestFormProperty[] = [];
    testForm.push(new ApiDocTestFormProperty("ApiKey"));
    testForm.push(new ApiDocTestFormProperty("ContactId"));
    testForm.push(new ApiDocTestFormProperty("Body", ApiDocTestFormPropertyType.TextArea));
    testForm.slice(-1)[0].height = 10;
    testForm.slice(-1)[0].isHttpRequestBody = true;
    api.endpoints.slice(-1)[0].documentation.testFormProperties = testForm;
    api.endpoints.slice(-1)[0].documentation.testFormNoJson = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressAuthenticationHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressEncryptionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressContentTypeHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressApiVersionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressLocalDeviceHeaders = true;
    return api;
  };

  public static PaymentNotificationAdyenNotificationWebhookTarget(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectName = "AdyenNotificationWebhook";
    api.documentation.objectDescription = "Adyen Notification Webhook";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/payment-notification/adyen/notification/";
    api.pathVariables = ["{apiKey}"];
    api.pathModelProperties = ["ApiKey"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.PaymentNotifications}/${m.RouteSegment.Adyen}/${m.RouteSegment.Webhooks}/${m.RouteSegment.Notifications}/{apiKey}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Adyen Notification";
    api.endpoints.slice(-1)[0].documentation.menuText = "Adyen Notification";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is utilized to facilitate recording payment notifications from Adyen.  " +
      "This API will log notifications submitted to this url but performs no other operations unless explicitly configured to do so.  " +
      "<br/><br/>" +
      "There are other Adyen notification webhooks for things like refund result notifications that should be used instead of this generic notification api endpoint." +
      "<br/><br/>" +
      "The api key used in the url for this api endpoint <strong>must be properly secured</strong> to only provide access to resources required to accomplish the " +
      "purposes of the webhook post.  The api key flags must to contain 'webhook' and 'adyen' to secure the endpoint.";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = {
      "live": "false",
      "notificationItems": [
        {
          "NotificationRequestItem": {
            "amount": {
              "currency": "EUR",
              "value": 2500
            },
            "eventCode": "REFUND",
            "eventDate": "2021-11-01T00:19:34+01:00",
            "merchantAccountCode": "YOUR_MERCHANT_ACCOUNT",
            "merchantReference": "Refund123",
            "originalReference": "8836183819713023",
            "paymentMethod": "visa",
            "pspReference": "8412534564722331",
            "reason": "Transaction hasn't been captured, refund not possible",
            "success": "false"
          }
        }
      ]
    };
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.responseDataModelIsNull = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    const testForm: ApiDocTestFormProperty[] = [];
    testForm.push(new ApiDocTestFormProperty("ApiKey"));
    testForm.push(new ApiDocTestFormProperty("Body", ApiDocTestFormPropertyType.TextArea));
    testForm.slice(-1)[0].height = 10;
    testForm.slice(-1)[0].isHttpRequestBody = true;
    api.endpoints.slice(-1)[0].documentation.testFormProperties = testForm;
    api.endpoints.slice(-1)[0].documentation.testFormNoJson = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressAuthenticationHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressEncryptionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressContentTypeHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressApiVersionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressLocalDeviceHeaders = true;
    return api;
  };


  public static BraintreeClientToken(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectName = "BraintreeClientToken";
    api.documentation.objectDescription = "Braintree Client Token";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = null;
    api.documentation.responseDataModelDocumentationName = null;
    api.documentation.requestDataModelObject = null;
    api.documentation.responseDataModelObject = null;
    api.documentation.documentationUrlBase = `/payments/external/braintree/token/`;
    api.pathVariables = "{merchantId}";
    api.pathModelProperties = "MerchantId"
    api.cacheName = null; //"purchaseObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Payments}/${m.RouteSegment.External}/${m.RouteSegment.Braintree}/${m.RouteSegment.Token}/{merchantId}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "Braintree Client Token";
    api.endpoints.slice(-1)[0].documentation.title = "Braintree Client Token";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = false;
    api.endpoints.slice(-1)[0].documentation.overviewText = `<h4>Braintree Client Token Notes</h4>
<p>
A Braintree purchase is a multi-step process that includes transfer of control the purchase process to
another site using a client token which is retrieved using this API call.  If there is only one Braintree
merchant account the merchantId property can be passed as 'null' or 'undefined'.  If there is more than one
Braintree merchant account then not providing a merchantId value may result in a client token being
retrieved for an unintended merchant account.
</p>
<p>
When successful the client token will be found in the data property of the response object.  For example:
</p>
<div class="card bg-light mb-3">
<div class="card-body p-2">
<pre><code>{
  "Success": true,
  "ResultCode": 0,
  "ResultText": "Success",
  "Message": "",
  "Scope": null,
  "Data": "1zYW5kLnNhbmRib3guYnJhaW50cmVlLWFwaS5jb20vd20zM2gyNW5rYmdjMmd0NSJ9LCJwYXlwYWxFbmFibGVkIjp0cnVlLCJwYXlwYWwiOnsiYmlsbGluZ0FncmVlbWVudHNFbmFibGVkIjp0cnVlLCJlbnZpcm9ubWVudE5vTmV0d29yayI6dHJ1ZSwidW52ZXR0ZWRNZXJjaGFudCI6ZmFsc2UsImFsbG93SHR0cCI6dHJ1ZSwiZGlzcGxheU5hbWUiOiJOdWJpbGwgQ29ycG9yYXRpb24iLCJjbGllbnRJZCI6bnVsbCwicHJpdmFjeVVybCI6Imh0dHA6Ly9leGFtcGxlLmNvbS9wcCIsInVzZXJBZ3JlZW1lbnRVcmwiOiJodHRwOi8vZXhhbXBsZS5jb20vdG9zIiwiYmFzZVVybCI6Imh0dHBzOi8vYXNzZXRzLmJyYWludHJlZWdhdGV3YXkuY29tIiwiYXNzZXRzVXJsIjoiaHR0cHM6Ly9jaGVja291dC5wYXlwYWwuY29tIiwiZGlyZWN0QmFzZVVybCI6bnVsbCwiZW52aXJvbm1lbnQiOiJvZmZsaW5lIiwiYnJhaW50cmVlQ2xpZW50SWQiOiJtYXN0ZXJjbGllbnQzIiwibWVyY2hhbnRBY2NvdQ==",
  "Meta": {},
  "Links": [],
  "Errors": [],
  "TimeStamp": "2021-03-13T15:13:21.1191Z",
  "Trace": []
}</code></pre>
</div>
</div>
`;
    return api;
  };


  public static PayPalAuthorization(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectName = "PayPalAuthorization";
    api.documentation.objectDescription = "PayPal Authorization";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.PayPalAuthorizationRequest;
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.PayPalAuthorizationResponse;

    const model = new m5.PayPalAuthorizationRequestModel();
    model.ReturnUrl = `${location.protocol}//${location.hostname}/test/landing?success=true&title=PayPal%20Response&message=The%20PayPal%20transaction%20was%20successful.`;
    model.CancelUrl = `${location.protocol}//${location.hostname}/test/landing?success=false&title=PayPal%20Response&message=The%20PayPal%20transaction%20was%20cancelled.`;
    model.Packages.push(new m5.PayPalAuthorizationRequestPackageModel());
    model.Attributes = {};
    model.Cargo = {};
    //model.Options
    api.documentation.requestDataModelObject = model;

    api.documentation.responseDataModelObject = new m5.PayPalAuthorizationResponseModel;

    api.documentation.documentationUrlBase = `/payments/external/pay-pal/authorization/`;
    api.pathVariables = ""; //"{paymentTransactionId}";
    api.pathModelProperties = ""; //"PaymentTransactionId"
    api.cacheName = null; //"purchaseObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Payments}/${m.RouteSegment.External}/${m.RouteSegment.PayPal}/${m.RouteSegment.Authorization}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "PayPal Authorization";
    api.endpoints.slice(-1)[0].documentation.title = "PayPal Authorization";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.overviewText = `<h4>PayPal Authorization Notes</h4>
<p>
A PayPal purchase is a multi-step process that includes transfer of control the purchase process to paypal.com. The steps involved are:
</p>
<ol>
<li>Submit a PayPal authorization request including URL to go to when the user click cancel and a URL to go to when the user completes the PayPal purchase process.</li>
<li>If authorized to conduct the PayPal transaction the response will include:</li>
    <ul>
    <li>A PayPalPaymentId property that needs to be maintained in session, cookie, or cache so it can be accessed when the user completes the PayPal purchase process.</li>
    <li>A PayPalApprovalUrl property which is the URL you should redirect the user to for them to approve the purchase.</li>
    </ul>
<li>If the user cancels the transaction they will be directed to the cancel URL provided with the PayPal authorization request in step 1.</li>
<li>If the user completes the transaction they will be directed to the return URL provided with the PayPal authorization request in step 1 and
will include query string parameters 'token' and 'PayerID'.  Those values along with the PayPalPaymentId property previously cached in session,
cookie, or cache are submitted to the purchase API using the Payment.ExternalPayment property.</li>
</ol>
`;
    return api;
  };



  public static PayPalAuthorizationLegacyRoute(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectName = "PayPalAuthorization";
    api.documentation.objectDescription = "PayPal Authorization";
    api.documentation.objectPrimaryKey = null;
    api.documentation.requestDataModelDocumentationName = Constants.DataModelName.PayPalAuthorizationRequest;
    api.documentation.responseDataModelDocumentationName = Constants.DataModelName.PayPalAuthorizationResponse;

    const model = new m5.PayPalAuthorizationRequestModel();
    model.ReturnUrl = `${location.protocol}//${location.hostname}/api-docs/#/test/landing?success=true&title=PayPal%20Response&message=The%20PayPal%20transaction%20was%20successful.`;
    model.CancelUrl = `${location.protocol}//${location.hostname}/api-docs/#/test/landing?success=false&title=PayPal%20Response&message=The%20PayPal%20transaction%20was%20cancelled.`;
    model.Packages.push(new m5.PayPalAuthorizationRequestPackageModel());
    model.Attributes = {};
    model.Cargo = {};
    //model.Options
    api.documentation.requestDataModelObject = model;

    api.documentation.responseDataModelObject = new m5.PayPalAuthorizationResponseModel;

    api.documentation.documentationUrlBase = `/billing/pay-pal/authorization/`;
    api.pathVariables = ""; //"{paymentTransactionId}";
    api.pathModelProperties = ""; //"PaymentTransactionId"
    api.cacheName = null; //"purchaseObjectCache";
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Billing}/${m.RouteSegment.PayPal}/${m.RouteSegment.Authorization}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.menuText = "PayPal Authorization";
    api.endpoints.slice(-1)[0].documentation.title = "PayPal Authorization";
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = true;
    api.endpoints.slice(-1)[0].documentation.showOverviewResponseDataModel = true;
    api.endpoints.slice(-1)[0].documentation.overviewText = `<h4>PayPal Authorization Notes</h4>
<p>
A PayPal purchase is a multi-step process that includes transfer of control the purchase process to paypal.com. The steps involved are:
</p>
<ol>
<li>Submit a PayPal authorization request including URL to go to when the user click cancel and a URL to go to when the user completes the PayPal purchase process.</li>
<li>If authorized to conduct the PayPal transaction the response will include:</li>
    <ul>
    <li>A PayPalPaymentId property that needs to be maintained in session, cookie, or cache so it can be accessed when the user completes the PayPal purchase process.</li>
    <li>A PayPalApprovalUrl property which is the URL you should redirect the user to for them to approve the purchase.</li>
    </ul>
<li>If the user cancels the transaction they will be directed to the cancel URL provided with the PayPal authorization request in step 1.</li>
<li>If the user completes the transaction they will be directed to the return URL provided with the PayPal authorization request in step 1 and
will include query string parameters 'token' and 'PayerID'.  Those values along with the PayPalPaymentId property previously cached in session,
cookie, or cache are submitted to the purchase API using the Payment.ExternalPayment property.</li>
</ol>
` +
      "<br/><br/>" +
      `<strong> NOTE: This endpoint is maintained for backwards compatibility.  The preferred endpoint for this API is "/${m.RouteSegment.Payments}/${m.RouteSegment.External}/${m.RouteSegment.PayPal}/${m.RouteSegment.Authorization}".</strong>`;
    return api;
  };


  public static PayPalIPNLegacyRoute(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties();
    api.modules = ["billing"];
    api.version = version;
    api.documentation.objectName = "PayPalIPN";
    api.documentation.objectDescription = "PayPal IPN";
    api.documentation.objectPrimaryKey = "";
    api.documentation.documentationUrlBase = "/billing/pay-pal/ipn/";
    api.pathVariables = ["{apiKey}", "{contactId}"];
    api.pathModelProperties = ["ApiKey", "ContactId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/${m.RouteSegment.Billing}/${m.RouteSegment.PayPal}/${m.RouteSegment.IPN}/{apiKey}/{contactId}`, ApiOperationType.Add));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "PayPal IPN";
    api.endpoints.slice(-1)[0].documentation.menuText = "PayPal IPN";
    api.endpoints.slice(-1)[0].documentation.overviewText = "This API is utilized to facilitate logging of PayPal IPN responses desired from " +
      "PayPal.  This API will log posts to this url but performs no other operations unless explicitly configured to do so.  " +
      "<br/><br/>" +
      "Note that PayPal does not typically post IPN messages for direct payment API calls.  These are typically provided for PayPal button events when configured." +
      "<br/><br/>" +
      "The api key used in the url for this api endpoint <strong>must be properly secured</strong> to only provide access to resources required to accomplish the " +
      "purposes of the webhook post.  The api key flags must to contain 'webhook' as well as the allowed webhook types.  For example, if using this for " +
      "PayPal IPN the api key flags would contain: 'webhook,pay-pal-ipn'.";
    "<br/><br/>" +
      `<strong> NOTE: This endpoint is maintained for backwards compatibility.  The preferred endpoint for this API is "/${m.RouteSegment.PaymentNotifications}/${m.RouteSegment.PayPal}/${m.RouteSegment.IPN}/{apiKey}/{contactId}".</strong>`;
    api.endpoints.slice(-1)[0].documentation.showOverviewRequestDataModel = false;
    api.endpoints.slice(-1)[0].documentation.requestDataModelObject = { dynamic: "No specific format is required.  Any data posted is logged with no assumptions about format or contents." };
    api.endpoints.slice(-1)[0].documentation.requestDataModelDocumentationName = "";
    api.endpoints.slice(-1)[0].documentation.responseDataModelIsNull = true;
    api.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = "";
    const testForm: ApiDocTestFormProperty[] = [];
    testForm.push(new ApiDocTestFormProperty("ApiKey"));
    testForm.push(new ApiDocTestFormProperty("ContactId"));
    testForm.push(new ApiDocTestFormProperty("Body", ApiDocTestFormPropertyType.TextArea));
    testForm.slice(-1)[0].height = 10;
    testForm.slice(-1)[0].isHttpRequestBody = true;
    api.endpoints.slice(-1)[0].documentation.testFormProperties = testForm;
    api.endpoints.slice(-1)[0].documentation.testFormNoJson = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressAuthenticationHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressEncryptionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressContentTypeHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressApiVersionHeaders = true;
    api.endpoints.slice(-1)[0].documentation.testFormSuppressLocalDeviceHeaders = true;
    return api;
  };


  //#endregion


}
