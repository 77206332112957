import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiEndpointViewModel } from 'projects/core-lib/src/lib/api/ApiModels';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { takeUntil } from 'rxjs/operators';
import { FormBaseClass } from 'projects/common-lib/src/lib/form/form-base-class';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiDocsSettings } from 'projects/core-lib/src/lib/config/api-docs-settings';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';

@Component({
  selector: 'app-endpoint',
  templateUrl: './endpoint.component.html',
  styleUrls: ['./endpoint.component.scss'],
  providers: [FormStatusService]
})
export class EndpointComponent extends FormBaseClass<any> implements OnInit, OnChanges {

  @Input() dataModelName: string = "";
  @Input() sort: "natural" | "asc" | "desc" = "natural";

  settings: ApiDocsSettings = null;

  path: string = "";
  method: string = "";
  id: string = "";

  apiProp: m.ApiProperties = null;
  apiCall: m.ApiCall = null;
  apiEndpoint: m.ApiEndpoint = null;

  apiVersion: number;

  // For edit test form we provide the ability to get the current contents of an object as starting point for edit
  apiGetEndpoint: m.ApiEndpoint;
  apiGetCall: m.ApiCall;

  requestDataModelDocumentationName: string = "";
  requestDataModelDocumentation: m.ApiDocDataModel = null;
  responseDataModelDocumentationName: string = "";
  responseDataModelDocumentation: m.ApiDocDataModel = null;
  ///**
  //Our default overview page for api docs doesn't make any distinction between request/response data model so feed it via this getter
  //*/
  //public get dataModelDocumentation(): any {
  //    if (this.requestDataModelDocumentation) {
  //        return this.requestDataModelDocumentation;
  //    } else {
  //        return this.responseDataModelDocumentation;
  //    }
  //}
  // A dynamic object with each data model part that has properties attached to it being a property of the
  // object.  This allows custom api doc views to embed references to these models in-line.
  dataModelDocumentationParts: any = {};

  sampleRequestBody: any = {};
  sampleResponseBody: any = {};
  testResults: m.ApiDocTestResult = new m.ApiDocTestResult();
  requestData: any = {};
  responseData: any = {};
  loadJsonEditor: boolean = false;

  showOverview: boolean = true;
  overviewText: string = "";
  overviewTemplateUrl: string = "";
  showOverviewRequestDataModel: boolean = true;
  showOverviewResponseDataModel: boolean = false;

  showApiEndpointConfig: boolean = false;
  apiEndpointConfig: any = null;

  showFormat: boolean = true;
  formatTemplateUrl: string = "";

  showResultCodes: boolean = true;
  resultCodesText: string = "";
  resultCodesTemplateUrl: string = "";

  showTest: boolean = true;

  pages: m.ApiDocPage[] = [];

  working: boolean = false;

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected formService: FormStatusService,
    protected docsService: ApiDocsService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(appService, uxService, formService, true, Constants.ContactType.Directory);
    this.settings = this.appService.config.settings;
  }

  ngOnInit() {

    super.ngOnInit();

    this.route.url.pipe(takeUntil(this.ngUnsubscribe)).subscribe((segments: UrlSegment[]) => {
      this.findApiEndpointToUse(segments);
      this.loadDataModelInformation();
      this.configurePages();
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }


  onNavChange($event) {
    //console.error($event);
    // Some things get lazy loaded when we click on the tab
    if (Helper.equals($event.nextId, "Test", true)) {
      // Code editor won't render until clicked on unless we delay until viewed
      this.loadJsonEditor = true;
    }
  }

  onSort(sort) {
    this.sort = sort;
  }


  protected findApiEndpointToUse(segments: UrlSegment[]) {

    if (!segments || segments.length === 0) {
      return;
    }

    this.path = "";
    this.method = "";
    this.id = "";
    segments.forEach((segment: UrlSegment, index: number, segments: UrlSegment[]) => {
      // Endpoints may start with common "/endpoints" path that we can skip here
      if (Helper.equals(segment.path, "endpoints", true)) {
        return;
      }
      if (index === (segments.length - 1)) {
        // The last segment is the method with optional id suffix
        this.method = segment.path;
        // In some situations we have a method and id separated by hyphen
        if (this.method.includes("-")) {
          this.id = this.method.substring(this.method.indexOf("-")).replace("-", "");
          this.method = this.method.substring(0, this.method.indexOf("-")).replace("-", "");
        }
      } else {
        if (this.path) {
          this.path += "/";
        }
        this.path += segment.path;
      }
    });
    // Now find the api based on the docs url
    const match = this.docsService.findApiFromDocumentationUrl(this.path, this.method, this.id);
    this.apiProp = match.apiProp;
    this.apiCall = match.apiCall;
    this.apiEndpoint = match.apiEndpoint;
    if (this.apiProp) {
      this.apiGetEndpoint = ApiHelper.getApiEndpoint(this.apiProp, m.ApiOperationType.Get);
      this.apiGetCall = ApiHelper.createApiCall(this.apiProp, m.ApiOperationType.Get);
    }
    // We don't want to hit cache in api docs
    if (this.apiCall) {
      this.apiCall.cacheIgnoreOnRead = true;
    }
    // Reset due to route change
    this.testResults = new m.ApiDocTestResult();

  }


  // For edit test form we provide the ability to get the current contents of an object as starting point for edit
  protected load(data: any, pathProperties: any) {

    // Use pathProperties if any provided.
    if (!Helper.isEmpty(pathProperties)) {
      // In this case since the call url isn't resolved only using data in our service the api call object
      // has to be recreated because we need the url fresh so we can rebuild it fresh based on pathProperties that
      // may have changed since it was last executed.
      this.apiGetCall = ApiHelper.createApiCall(this.apiProp, m.ApiOperationType.Get);
      this.apiGetCall.url = ApiHelper.buildApiAbsoluteUrl(this.apiGetCall, pathProperties);
    }

    this.testResults = new m.ApiDocTestResult();
    this.apiService.execute(this.apiGetCall, data).subscribe((response: m.IApiResponseWrapper) => {
      if (response.Data.Success) {
        // For a successful get request we update the model we're working with
        this.requestData = response.Data.Data;
      }
      // Fill in test results
      this.testResults.requestMethod = response.Request.Method;
      this.testResults.requestUrl = response.Request.Url;
      this.testResults.requestHeaders = response.Request.Headers;
      this.testResults.requestData = response.Request.Data;
      this.testResults.responseStatus = response.Status;
      this.testResults.responseStatusText = response.StatusText;
      this.testResults.responseHeaders = response.Headers;
      this.testResults.responseData = response.Data;
    });

  }


  public submit(data: any, pathProperties: any) {

    // Use the updated data object from our component
    if (data) {
      this.requestData = data;
    }

    //console.error(pathProperties);
    //console.error(data);

    // If we have a pathModelPropertyTriggeringDownload defined then see if that property is truthy
    // and build the url to open in a window which will trigger the download
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.pathModelPropertyTriggeringDownload) {
      if (this.requestData[this.apiEndpoint.documentation.pathModelPropertyTriggeringDownload]) {
        // Build URL including token since we're redirecting to the URL for download and there will be no auth headers
        let url = ApiHelper.buildApiAbsoluteUrl(this.apiCall, this.requestData);
        url = ApiHelper.addQueryStringToUrl(url, `token=${this.apiCall.token}`);
        // Update test results
        this.testResults = new m.ApiDocTestResult();
        this.testResults.requestMethod = "Get";
        this.testResults.requestUrl = url;
        // Assign location and file will download.
        window.location.href = url;
        return;
      }
    }

    // Use pathProperties if any provided.
    if (!Helper.isEmpty(pathProperties)) {
      // In this case since the call url isn't resolved only using requestData in our service the api call object
      // has to be recreated because we need the url fresh so we can rebuild it fresh based on pathProperties that
      // may have changed since it was last executed.
      this.apiCall = ApiHelper.createApiCall(this.apiProp, this.apiCall.type, this.apiCall.endpointId, this.apiCall);
      this.apiCall.url = ApiHelper.buildApiAbsoluteUrl(this.apiCall, pathProperties);
    }

    // In some special cases we have a test form property that is the body of our call.  In that scenario
    // we need to rebuild our call object, build the url from our request data object now and then pass
    // just that one form property value to our service.
    let bodyPropertyName: string = null;
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.testFormProperties.length > 0) {
      const property = Helper.firstOrDefault(this.apiEndpoint.documentation.testFormProperties, x => x.isHttpRequestBody);
      if (property) {
        // Note what property has our body
        bodyPropertyName = property.name;
        // Recreated call because we need the url fresh so we can rebuild it fresh
        this.apiCall = ApiHelper.createApiCall(this.apiProp, this.apiCall.type, this.apiCall.endpointId, this.apiCall);
        // Use pathProperties if any provided.
        if (!Helper.isEmpty(pathProperties)) {
          this.apiCall.url = ApiHelper.buildApiAbsoluteUrl(this.apiCall, pathProperties);
        }
        // Use the request data to plug in any url variables now since we won't be submitting the request data object to the service.
        // Pass in url string in case it was partially resolved via the pathProperties object.
        this.apiCall.url = ApiHelper.buildApiAbsoluteUrl(this.apiCall, this.requestData, this.apiCall.url);
      }
    }

    let headerOptions: any = null;
    if (this.apiEndpoint.documentation) {
      headerOptions = {
        NoAuthHeaders: this.apiEndpoint.documentation.testFormSuppressAuthenticationHeaders,
        NoEncryptionHeaders: this.apiEndpoint.documentation.testFormSuppressEncryptionHeaders,
        NoContentTypeHeaders: this.apiEndpoint.documentation.testFormSuppressContentTypeHeaders,
        NoApiVersionHeaders: this.apiEndpoint.documentation.testFormSuppressApiVersionHeaders,
        NoLocalDeviceHeaders: this.apiEndpoint.documentation.testFormSuppressLocalDeviceHeaders
      };
    }

    // Execute and get results
    this.testResults = new m.ApiDocTestResult();
    this.working = true;
    if (bodyPropertyName) {
      this.apiService.execute(this.apiCall, this.requestData[bodyPropertyName], headerOptions).subscribe((response: m.IApiResponseWrapper) => {
        this.testResults.requestMethod = response.Request.Method;
        this.testResults.requestUrl = response.Request.Url;
        this.testResults.requestHeaders = response.Request.Headers;
        this.testResults.requestData = response.Request.Data;
        this.testResults.responseStatus = response.Status;
        this.testResults.responseStatusText = response.StatusText;
        this.testResults.responseHeaders = response.Headers;
        this.testResults.responseData = response.Data;
        this.working = false;
      });
    } else {
      this.apiService.execute(this.apiCall, this.requestData, headerOptions).subscribe((response: m.IApiResponseWrapper) => {
        this.testResults.requestMethod = response.Request.Method;
        this.testResults.requestUrl = response.Request.Url;
        this.testResults.requestHeaders = response.Request.Headers;
        this.testResults.requestData = response.Request.Data;
        this.testResults.responseStatus = response.Status;
        this.testResults.responseStatusText = response.StatusText;
        this.testResults.responseHeaders = response.Headers;
        this.testResults.responseData = response.Data;
        this.working = false;
      });
    }

  }

  public submit2(data: any, pathProperties: any, action: string) {

    // Use the updated data object from our component
    if (data) {
      this.requestData = data;
    }

    // Use pathProperties if any provided.
    if (!Helper.isEmpty(pathProperties)) {
      // In this case since the call url isn't resolved only using requestData in our service the api call object
      // has to be recreated because we need the url fresh so we can rebuild it fresh based on pathProperties that
      // may have changed since it was last executed.
      this.apiCall = ApiHelper.createApiCall(this.apiProp, this.apiCall.type, this.apiCall.endpointId);
      this.apiCall.url = ApiHelper.buildApiAbsoluteUrl(this.apiCall, pathProperties);
    }

    if (Helper.equals(action, "download", true)) {
      // Build URL including token since we're redirecting to the URL for download and there will be no auth headers.
      // Pass in url string in case it was partially resolved via the pathProperties object.
      let url = ApiHelper.buildApiAbsoluteUrl(this.apiCall, this.requestData, this.apiCall.url);
      url = ApiHelper.addQueryStringToUrl(url, `token=${this.apiCall.token}`);
      // Update test results
      this.testResults = new m.ApiDocTestResult();
      this.testResults.requestMethod = "Get";
      this.testResults.requestUrl = url;
      // Assign location and file will download.
      window.location.href = url;
    } else {
      Log.errorMessage(`unknown api controller submit2 action ${action}`);
    }

  }


  protected configurePages() {

    let description: string = this.apiProp.documentation.objectDescription.toLowerCase();
    let descriptionPlural: string = this.apiProp.documentation.objectDescriptionPlural.toLowerCase();
    let readOnly: boolean = this.apiProp.documentation.readOnly;

    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.objectDescription) {
      description = this.apiEndpoint.documentation.objectDescription.toLowerCase();
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.objectDescriptionPlural) {
      descriptionPlural = this.apiEndpoint.documentation.objectDescriptionPlural.toLowerCase();
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.readOnly) {
      readOnly = this.apiEndpoint.documentation.readOnly;
    }

    // Overview page
    this.showOverview = true;
    this.overviewTemplateUrl = "";
    this.overviewText = "";
    this.showOverviewRequestDataModel = false;
    this.showOverviewResponseDataModel = true;
    if (this.apiEndpoint.documentation && !this.apiEndpoint.documentation.showOverview) {
      this.showOverview = this.apiEndpoint.documentation.showOverview;
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.overviewTemplateUrl) {
      this.overviewTemplateUrl = this.apiEndpoint.documentation.overviewTemplateUrl;
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.overviewText) {
      this.overviewText = this.apiEndpoint.documentation.overviewText;
    }
    if (this.apiEndpoint.documentation) {
      this.showOverviewRequestDataModel = this.apiEndpoint.documentation.showOverviewRequestDataModel;
      this.showOverviewResponseDataModel = this.apiEndpoint.documentation.showOverviewResponseDataModel;
    }
    if (!this.overviewText) {
      if (this.apiEndpoint.type === m.ApiOperationType.List) {
        this.overviewText = `This API endpoint is used to retrieve a list of ${descriptionPlural}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Report) {
        this.overviewText = `This API endpoint is used to retrieve a report of ${descriptionPlural}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Get) {
        this.overviewText = `This API endpoint is retrieve a single ${description}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Add) {
        this.overviewText = `This API endpoint is used to add a ${description}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Edit) {
        this.overviewText = `This API endpoint is used to edit a ${description}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Merge) {
        this.overviewText = `This API endpoint is used to merge a partial ${description} object with the file existing at the specified url.  ` +
          "Note that it is not possible to merge deep object properties individually.  This means, for example, that it is not possible to set " +
          "InvoiceLineItem.Invoice.DueDate as the entire Invoice object would be replaced.  If deep value changes are needed then either GET " +
          "the current object, update the needed properties, and do a PUT of the whole object or use the PATCH method.  " +
          "<br/><br/>" +
          "This API endpoint uses a custom http verb MERGE.  If custom http verbs are not supported by your http client use a POST with the " +
          "X-HTTP-Method-Override http header set to MERGE in order to instruct the API that you are doing a merge.";
      } else if (this.apiEndpoint.type === m.ApiOperationType.Patch) {
        this.overviewText = `This API endpoint is used to patch a ${description} object using ` +
          "<a href='https://tools.ietf.org/html/rfc6902' target= '_blank'> JSON Patch</a> format.  " +
          "<br/><br/>" +
          "This API endpoint uses the http verb PATCH.  If the PATCH verb is not supported by your http client use a POST with the X-HTTP-Method-Override " +
          "http header set to PATCH in order to instruct the API that you are doing a patch." +
          "<br/><br/>" +
          "The following are differences between the JSON Patch standard and this API implementation: " +
          "<br/><br/>" +
          "<ul>" +
          "<li>Test operation is not supported.</li>" +
          "</ul>";
      } else if (this.apiEndpoint.type === m.ApiOperationType.Delete) {
        this.overviewText = `This API endpoint is used to delete a ${description}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Copy) {
        this.overviewText = `This API endpoint is used to copy a ${description}.`;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Call) {
        this.overviewText = `This API endpoint calls ${description}.`;
      }
      if (readOnly &&
        this.apiEndpoint.type !== m.ApiOperationType.List &&
        this.apiEndpoint.type !== m.ApiOperationType.Report &&
        this.apiEndpoint.type !== m.ApiOperationType.Get) {
        this.overviewText += "  This API endpoint is only valid for system administrators.";
      }
    }

    // Api config page
    this.showApiEndpointConfig = false;
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.showApiEndpointConfig && this.apiEndpoint.id) {
      // If we were told to show the api endpoint config and we have an id to facilitate that then show it
      this.showApiEndpointConfig = true;
      this.loadApiEndpointConfig(this.apiEndpoint.id);
    }

    // Format page
    this.showFormat = true;
    this.formatTemplateUrl = "";
    if (this.apiEndpoint.documentation && !this.apiEndpoint.documentation.showFormat) {
      this.showFormat = this.apiEndpoint.documentation.showFormat;
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.formatTemplateUrl) {
      this.formatTemplateUrl = this.apiEndpoint.documentation.formatTemplateUrl;
    }

    // Result codes page
    this.showResultCodes = true;
    this.resultCodesTemplateUrl = "";
    this.resultCodesText = "";
    if (this.apiEndpoint.documentation && !this.apiEndpoint.documentation.showResultCodes) {
      this.showResultCodes = this.apiEndpoint.documentation.showResultCodes;
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.resultCodesTemplateUrl) {
      this.resultCodesTemplateUrl = this.apiEndpoint.documentation.resultCodesTemplateUrl;
    }
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.resultCodesText) {
      this.resultCodesText = this.apiEndpoint.documentation.resultCodesText;
    }

    // Test page defaults to true but could be set false if api endpoint doesn't call for a test page
    this.showTest = true;
    if (this.apiEndpoint.documentation && !this.apiEndpoint.documentation.showTest) {
      this.showTest = this.apiEndpoint.documentation.showTest;
    }
    // Test page could also be disabled by config
    if (Helper.equals(this.settings.apiTestForm, "Never", true)) {
      this.showTest = false;
    } else if (Helper.equals(this.settings.apiTestForm, "UserFlag", true) && this.user && this.user.AuthenticationData &&
      !Helper.contains(this.user.AuthenticationData.Flags, "api-docs-test-form", true)) {
      this.showTest = false;
    }


    // Custom pages
    this.pages = [];
    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.pages && this.apiEndpoint.documentation.pages.length > 0) {
      this.pages = this.apiEndpoint.documentation.pages;
    }

  }


  protected loadDataModelInformation(): void {

    this.loadDataModelDocumentation(this.getRequestDocumentationModelName(), true);
    this.loadDataModelDocumentation(this.getResponseDocumentationModelName(), false);

    this.requestData = ApiHelper.getApiRequestDataModelObject(this.apiProp, this.apiEndpoint, this.requestDataModelDocumentation);
    this.responseData = ApiHelper.getApiResponseDataModelObject(this.apiProp, this.apiEndpoint, this.responseDataModelDocumentation);
    this.prepareSampleDataModelObjects();

  }


  protected getRequestDocumentationModelName(): string {

    // We still want the docs just not in our format page
    //// For get, delete, and copy there no request body to document in a model
    //if (this.apiCall.method === m.ApiMethodType.Get || this.apiCall.method === m.ApiMethodType.Delete || this.apiCall.type === m.ApiOperationType.Copy) {
    //    return "";
    //}

    // See if we have endpoint specific model name defined
    if (this.apiEndpoint && this.apiEndpoint.documentation && this.apiEndpoint.documentation.requestDataModelDocumentationName) {
      return this.apiEndpoint.documentation.requestDataModelDocumentationName;
    }

    if (this.apiProp) {
      return this.apiProp.documentation.requestDataModelDocumentationName;
    } else {
      return "";
    }

  }

  protected getResponseDocumentationModelName(): string {

    // We still want the docs just not in our format page
    //// For delete there is no response body to document in a model
    //if (this.apiCall.type === m.ApiOperationType.Delete) {
    //    return "";
    //}

    // See if we have endpoint specific model name defined
    if (this.apiEndpoint && this.apiEndpoint.documentation && this.apiEndpoint.documentation.responseDataModelDocumentationName) {
      return this.apiEndpoint.documentation.responseDataModelDocumentationName;
    }

    if (this.apiProp) {
      return this.apiProp.documentation.responseDataModelDocumentationName;
    } else {
      return "";
    }

  }

  protected loadDataModelDocumentation(modelName: string, requestModel: boolean): void {
    if (!modelName) {
      // not every endpoint has model documentation
      return;
    }
    if (requestModel) {
      this.requestDataModelDocumentationName = modelName;
    } else {
      this.responseDataModelDocumentationName = modelName;
    }
    const apiProp = Api.DocumentationDataModel(this.apiVersion);
    const apiCall = ApiHelper.createApiCall(apiProp, m.ApiOperationType.Get);
    this.apiService.execute(apiCall, { ModelName: modelName }).subscribe((response: m.IApiResponseWrapper) => {
      if (!response.Data.Success) {
        Log.errorMessage(response.Data.Message);
        return;
      }
      if (requestModel) {
        this.requestDataModelDocumentation = response.Data.Data;
      } else {
        this.responseDataModelDocumentation = response.Data.Data;
      }
      // Now that we have a data model loaded it may change what our data model objects look like so rebuild those
      this.requestData = ApiHelper.getApiRequestDataModelObject(this.apiProp, this.apiEndpoint, this.requestDataModelDocumentation);
      this.responseData = ApiHelper.getApiResponseDataModelObject(this.apiProp, this.apiEndpoint, this.responseDataModelDocumentation);
      //console.error(this.requestData);
      //console.error(this.responseData);
      this.prepareSampleDataModelObjects();
      // Finally for use in custom views we have a peek component that we support be putting each doc part that has columns/properties in a dynamic object
      this.dataModelDocumentationParts[response.Data.Data.RawName] = response.Data.Data.Columns;
      // Now dig in since we can have embedded objects a few levels deep
      this.dataModelDocPartSetup(response.Data.Data.Columns);
    });
  }

  protected loadApiEndpointConfig(apiEndpointId: string): void {
    if (!apiEndpointId) {
      // not every endpoint has id defined
      return;
    }
    const apiProperties = Api.DocumentationApiConfig(this.apiVersion);
    const api = ApiHelper.createApiCall(apiProperties, m.ApiOperationType.Get);
    this.apiService.execute(api, { ApiEndpointId: apiEndpointId }).subscribe((response: m.IApiResponseWrapper) => {
      if (!response.Data.Success) {
        Log.errorMessage(response.Data.Message);
        return;
      }
      this.apiEndpointConfig = response.Data.Data;
      if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.apiEndpointConfigSampleRequestObjectPropertyName) {
        this.sampleRequestBody = Helper.deepCopy(this.apiEndpointConfig.Settings[this.apiEndpoint.documentation.apiEndpointConfigSampleRequestObjectPropertyName]);
      }
      if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.apiEndpointConfigSampleResponseObjectPropertyName) {
        this.sampleResponseBody = Helper.deepCopy(this.apiEndpointConfig.Settings[this.apiEndpoint.documentation.apiEndpointConfigSampleResponseObjectPropertyName]);
      }
    });
  }

  protected dataModelDocPartSetup(properties: m.ApiDocDataModelColumn[]) {
    properties.forEach((property: m.ApiDocDataModelColumn, index: number, array: m.ApiDocDataModelColumn[]) => {
      if (property.Properties && property.Properties.length > 0) {
        let name = property.RawName;
        if (!name) {
          name = Helper.replaceAll(property.Name, " ", "");
        }
        this.dataModelDocumentationParts[name] = property.Properties;
        // Now do recursive call since we can have embedded objects a few levels deep
        this.dataModelDocPartSetup(property.Properties);
      }
    });
  }

  protected prepareSampleDataModelObjects(): void {

    const requestModel: any = Helper.deepCopy(this.requestData);
    const responseModel: any = Helper.deepCopy(this.responseData);

    if (!this.apiCall) {
      Log.debug("orange", "Warning", "Unable to build out the format documentation due to null api call object.", true);
      return;
    }

    // For get, delete, and copy request the request format is the url; otherwise, it's the model we passed in
    if (this.apiCall.method === m.ApiMethodType.Get || this.apiCall.method === m.ApiMethodType.Delete || this.apiCall.type === m.ApiOperationType.Copy) {
      this.sampleRequestBody = null;
    } else {
      this.sampleRequestBody = requestModel;
    }

    if (this.apiCall.type === m.ApiOperationType.List || this.apiCall.type === m.ApiOperationType.Report) {
      this.sampleResponseBody = ApiHelper.getApiResponseDataModelSample([responseModel, responseModel]);
    } else if (this.apiCall.type === m.ApiOperationType.Call) {
      if (requestModel === responseModel) {
        this.sampleResponseBody = ApiHelper.getApiResponseDataModelSample(null);
      } else {
        this.sampleResponseBody = ApiHelper.getApiResponseDataModelSample(responseModel);
      }
    } else if (this.apiCall.type === m.ApiOperationType.Delete) {
      this.sampleResponseBody = ApiHelper.getApiResponseDataModelSample(null);
    } else {
      this.sampleResponseBody = ApiHelper.getApiResponseDataModelSample(responseModel);
    }

  }

  onTestLoad($event) {
    //console.error("test submit", $event);
    this.load($event.data, $event.cargo.pathProperties);
  }

  onTestSubmit($event) {
    //console.error("test submit", $event);
    this.submit($event.data, $event.cargo.pathProperties);
  }

  onTestSubmit2($event) {
    //console.error("test submit 2", $event);
    this.submit2($event.data, $event.cargo.pathProperties, $event.cargo.action);
  }

}
