<div class="{{inputFormGroupClass}}">
  <div class="{{inputLabelClass}}">

    <span class="{{inputLabelClass}}"
          ngbTooltip="{{tooltip | translate}}"
          style="padding-right:0;"
          [ngClass]="{ 'float-left': vertical }">
      <span *ngIf="required && includeRequiredIcon">
        <ib-icon icon="exclamation-circle (solid)"
                 ngbTooltip="Required"></ib-icon>&nbsp;
      </span>
      {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    </span>
    <div [ngClass]="{ 'clearfix': !vertical }"></div>
    <div class="float-right"
         *ngIf="!readonly">
      <ib-button icon="plus"
                 label="Add"
                 color="primary"
                 size="x-small"
                 (click)="objectAdd()"></ib-button>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="{{inputWrapperClass}}">
    <div class="table-responsive">
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <ng-container *ngIf="( objectPropertiesList && objectPropertiesList.length > 0 ); else headerNoMetaData">
              <th *ngFor="let property of objectPropertiesList; trackBy: trackByIndex">{{property.InputLabel}}</th>
            </ng-container>
            <ng-template #headerNoMetaData>
              <th *ngFor="let label of objectLabels; trackBy: trackByIndex">{{label}}</th>
            </ng-template>
            <th *ngIf="!readonly"><i class="far fa-bars"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data; let rowIndex = index; trackBy: trackByIndex">
            <ng-container *ngIf="( objectPropertiesList && objectPropertiesList.length > 0 ); else rowNoMetaData">
              <td *ngFor="let property of objectPropertiesList; trackBy: trackByIndex">
                <ng-container *ngIf="property.DataType === TypeCode.Boolean">
                  <i class="far"
                     [ngClass]="{'fa-check-square': item[property.PropertyName], 'fa-square': !item[property.PropertyName]}"></i>
                </ng-container>
                <ng-container *ngIf="property.DataType !== TypeCode.Boolean">
                  {{item[property.PropertyName] | pickListText: property.PickListId | async}}
                </ng-container>
              </td>
            </ng-container>
            <ng-template #rowNoMetaData>
              <td *ngFor="let property of objectPropertyNames; trackBy: trackByIndex">
                {{item[property]}}
              </td>
            </ng-template>
            <td *ngIf="!readonly">
              <ib-icon name="edit"
                       icon="pencil"
                       light="true"
                       tooltip="Edit"
                       (click)="objectEdit(item, rowIndex)">
              </ib-icon>
              &nbsp;
              <ib-icon name="delete"
                       icon="times"
                       light="true"
                       tooltip="Delete"
                       (click)="objectDelete(item, rowIndex)">
              </ib-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>