import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';

@Component({
  selector: 'app-request-formats-lists',
  templateUrl: './request-formats-lists.component.html',
  styleUrls: ['./request-formats-lists.component.scss']
})
export class RequestFormatsListsComponent implements OnInit {

  constructor(
    protected appService: AppService,
    public apiService: ApiService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "List Request Format";
  }

}
