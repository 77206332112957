import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiProperties, ApiEndpoint, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';

@Component({
  selector: 'app-endpoint-header',
  templateUrl: './endpoint-header.component.html',
  styleUrls: ['./endpoint-header.component.scss']
})
export class EndpointHeaderComponent implements OnInit, OnChanges {

  @Input() apiProperties: ApiProperties = null;
  @Input() apiEndpoint: ApiEndpoint = null;
  @Input() apiCall: ApiCall = null;

  public title: string = "";
  public docUrl: string = "";
  public apiUrl: string = "";
  public httpMethod: string = "";

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  constructor(
    protected appService: AppService,
    public apiService: ApiService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  protected init(): void {

    // See if we have the objects that will tell us we're ready
    if (!this.apiProperties || !this.apiEndpoint) {
      return;
    }

    if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.title) {
      this.title = this.apiEndpoint.documentation.title;
    } else if (this.apiEndpoint.documentation && this.apiEndpoint.documentation.objectDescription) {
      if (this.apiEndpoint.type === m.ApiOperationType.List) {
        this.title = "List " + this.apiEndpoint.documentation.objectDescriptionPlural;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Report) {
        this.title = "Report " + this.apiEndpoint.documentation.objectDescriptionPlural;
      } else {
        this.title = m.ApiOperationType[this.apiEndpoint.type] + " " + this.apiEndpoint.documentation.objectDescription;
      }
    } else {
      if (this.apiEndpoint.type === m.ApiOperationType.List) {
        this.title = "List " + this.apiProperties.documentation.objectDescriptionPlural;
      } else if (this.apiEndpoint.type === m.ApiOperationType.Report) {
        this.title = "Report " + this.apiProperties.documentation.objectDescriptionPlural;
      } else {
        this.title = m.ApiOperationType[this.apiEndpoint.type] + " " + this.apiProperties.documentation.objectDescription;
      }
    }
    this.appService.title = this.title;

    this.docUrl = ApiHelper.getApiDocumentationUrl(this.apiProperties, this.apiEndpoint);
    if (!this.apiProperties.supportedVersions || this.apiProperties.supportedVersions.length === 0) {
      this.apiProperties.supportedVersions = [];
      this.apiProperties.supportedVersions.push(this.apiProperties.version);
    }

    this.httpMethod = m.ApiMethodType[this.apiEndpoint.method]; // Method name

  }

  onVersion(version) {
    console.error('version', version);
  }

}
