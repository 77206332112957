<ng-progress></ng-progress>
<ib-nav-header *ngIf="nav.showStandardSiteElements"></ib-nav-header>
<div class="ib-site-wrapper">
  <div *ngIf="nav.showStandardSiteElements" class="{{nav.themeClasses}} ib-sidebar-container" [ngClass]="{'ib-menu-width-narrow': nav.isSideCollapsed, 'ib-menu-width-standard': !nav.isSideCollapsed}">
    <ib-nav-side></ib-nav-side>
  </div>
  <div class="ib-site-container" [ngClass]="{'ib-menu-width-narrow': nav.isSideCollapsed, 'ib-menu-width-standard': !nav.isSideCollapsed, 'ib-menu-width-hidden': !nav.showStandardSiteElements}">
    <div class="container-fluid">
      <ib-alert></ib-alert>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<ib-nav-footer *ngIf="nav.showStandardSiteElements"></ib-nav-footer>
