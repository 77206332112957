import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiProperties, ApiCall, ApiOperationType, IApiResponse, ApiResponse } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'ib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnChanges {

  /*
   * By default we are uploading files to the server.  If the file is intended
   * to be intercepted by the web application and handled locally then this should
   * be set to false assets using properties assigned here.  This scenario should
   * handle the file event which will be fired when a new file is submitted.
   */
  @Input() upload: boolean = true;

  /*
   * By default we are uploading assets using properties assigned here.
   * If we need to upload to a target other than assets set this boolean
   * to false and provide the upload url and optional upload data.
   */
  @Input() assetUpload: boolean = true;

  /*
   * If not uploading to assets set the upload url here.
   */
  @Input() uploadUrl: string = "";

  /*
   * If not uploading to assets and upload data is desired or if complete
   * control desired over the asset data set the upload data here.
   */
  @Input() uploadData: any = null;

  // If uploading to an existing asset this is the asset id
  @Input() assetId: number = null;

  // If uploading child assets this is the parent asset id
  @Input() parentAssetId: number = null;

  // The attachment owner type as expected by the asset model
  @Input() ownerType: string = "";
  // The attachment owner id as expected by the asset model
  @Input() ownerId: number = null;
  // The attachment secondary owner type (if any)
  @Input() secondaryOwnerType: string = "";
  // The attachment secondary owner id (if any)
  @Input() secondaryOwnerId: number = null;

  // Upload icon defaults to "cloud-upload (solid)"
  @Input() uploadIcon: string = "cloud-upload (solid)";
  // Upload text defaults to "<strong>Drop files or click to upload</strong>"
  @Input() uploadText: string = "<strong>Drop files or click to upload</strong>";

  // This is a comma separated list of mime types or file extensions. e.g.: image/*,application/pdf,.docx.  Defaults to any file type.
  @Input() acceptedFileTypes: string = null;

  // The maximum file size in MB.  Defaults to 100.
  @Input() maxFileSize: number = 100;

  // The maximum number of files to upload in parallel.  Defaults to 5.
  @Input() parallelUploads: number = 5;

  // The dropzone component defaults to 30 seconds which may be too quick for some of the server side processing that might happen.
  @Input() timeout: number = 180000; // 180,000 ms = 180 seconds = 3 minutes

  // Optional asset group, category, etc.
  @Input() assetGroup: string = "";
  @Input() assetCategory: string = "";
  @Input() assetClass: string = "";
  @Input() assetScope: string = "";
  @Input() assetTags: string[] = [];
  @Input() assetVisibility: string = "I";
  @Input() assetSystemGroup: string = "";

  // Counter to increment when we should clear the upload history.  Each change results in the history being cleared.
  @Input() clearHistoryCount: number = 0;

  // Flag if we should show stats about the upload process.  Defaults to true.
  @Input() showStats: boolean = true;

  // Callback on successful upload with response properties file: any, response: m5.IApiResponse
  @Output() success: EventEmitter<EventModel> = new EventEmitter();
  // Callback on failed upload with response properties file: any, response: m5.IApiResponse
  @Output() error: EventEmitter<EventModel> = new EventEmitter();

  // Callback on file posted to the client.  This is typically used when upload is set to false.
  @Output() file: EventEmitter<EventModel> = new EventEmitter();

  // Counters to use for displaying stats
  countPending: number = 0;
  countStarted: number = 0;
  countUploading: number = 0;
  countDone: number = 0;
  countSuccess: number = 0;
  countError: number = 0;

  config: any = {};


  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected assetService: AssetService,
    protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.configureDropZone()
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.clearHistoryCount) {
      this.countPending = 0;
      this.countStarted = 0;
      this.countUploading = 0;
      this.countDone = 0;
      this.countSuccess = 0;
      this.countError = 0;
    }

    this.configureDropZone()

  }

  protected configureDropZone() {

    const self = this;

    let message: string = ""; //"<i class='fa fa-cloud-upload fa-2x fa-fw faa-vertical animated-hover'></i>&nbsp;&nbsp;<strong>Drop files or click to upload</strong>";
    if (this.uploadIcon) {
      message += `<i class='${IconHelper.parseIcon(this.uploadIcon).calculatedClasses} fa-2x fa-fw faa-vertical animated-hover'></i>`;
    }
    if (this.uploadIcon && this.uploadText) {
      message += "&nbsp;&nbsp;";
    }
    if (this.uploadText) {
      message += this.uploadText;
    }

    let targetUrl: string | SafeUrl = this.uploadUrl;
    if (!targetUrl) {
      targetUrl = this.addAssetWithFileUploadUrl();
    }

    const token: string = ApiHelper.getAuthToken();
    const headers: any = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    } else {
      let apiKey: string = ApiHelper.getAuthKey();
      if (!apiKey) {
        apiKey = this.appService.config.anonymousApiKey;
      }
      headers["X-Auth-Key"] = apiKey;
    }
    headers["X-Trace"] = true;
    headers["X-Api-Version"] = this.appService.config.apiVersion;

    this.config = {

      url: targetUrl,
      maxFilesize: this.maxFileSize,
      parallelUploads: this.parallelUploads,
      headers: headers,
      timeout: this.timeout,
      dictDefaultMessage: message,

      autoProcessQueue: this.upload,

      success: (file: any, response: IApiResponse) => {
        // Update counts
        this.countUploading--;
        this.countDone++;
        this.countSuccess++;
        if (this.assetUpload) {
          // We just added an asset to clear any asset list cache
          this.apiService.cache.cacheRemoveValue(Api.Asset().cacheName, "http", true);
        }
        // Sometimes onSuccess() isn't enough as it may be abstracted away by a few layers so broadcast our count for those who care to know (e.g. forms that require uploads, etc.) 
        //this.$rootScope.$broadcast("AttachmentCount", this.countSuccess);
        // already called via component event binding ... this.fireSuccess({ file: file: response: response });
        try {
          // Scroll to bottom
          const dzDiv = document.getElementsByClassName("dz-wrapper");
          dzDiv[0].scrollTop = dzDiv[0].scrollHeight;
        } catch (err) {
          console.error(err);
        }
      },

      error: (file: any, response: IApiResponse) => {
        // Update counts
        // Client side errors have response of type string instead of IApiResponse
        // and means the file was never pending upload so we decrement pending
        // instead of uploading counters.
        if (typeof response === "string") {
          this.countPending--;
          if (this.countPending < 0) {
            this.countPending = 0;
          }
        } else {
          this.countUploading--;
          if (this.countUploading < 0) {
            this.countUploading = 0;
          }
        }
        this.countDone++;
        this.countError++;
        //console.error("error event");
        //console.error(file);
        //console.error(response);
        let message: string = "";
        if (typeof response === "string") {
          // dropzone sends it's own error messages in string
          message = <string><any>response;
          // Build a response object that can be used for our callback which won't be happy with a dropzone string
          response = new ApiResponse();
          response.Success = false;
          response.ResultCode = 100;
          response.Message = message;
        } else {
          // our api returns a json object with property Message
          message = response.Message;
        }
        // already called via component event binding ... this.fireError({ file: file, response: response });
        file.previewElement.classList.add("dz-error");
        const _ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
        const _results = [];
        let _i = 0;
        let _len = 0;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          const node = _ref[_i];
          _results.push(node.textContent = message);
        }
        try {
          // Scroll to bottom
          const dzDiv = document.getElementsByClassName("dz-wrapper");
          dzDiv[0].scrollTop = dzDiv[0].scrollHeight;
        } catch (err) {
          console.error(err);
        }
        return _results;
      },

      sending: (file, xhr, formData) => {
        // Update counts
        this.countPending--;
        this.countStarted++;
        this.countUploading++;
        // Will send the specified data with the file upload
        let data: any = this.uploadData;
        if (!data && this.assetUpload) {
          const asset: m5.AssetEditViewModel = new m5.AssetEditViewModel();
          if (this.parentAssetId) {
            asset.ParentAssetId = this.parentAssetId;
          }
          if (this.ownerType || this.ownerId) {
            // If we have an owner then this asset is an attachment 
            asset.SystemAssetGroup = "Attachment";
            asset.OwnerResourceType = this.ownerType;
            asset.OwnerResourceId = this.ownerId;
          }
          if (this.secondaryOwnerType || this.secondaryOwnerId) {
            asset.SecondaryOwnerResourceType = this.secondaryOwnerType;
            asset.SecondaryOwnerResourceId = this.secondaryOwnerId;
          }
          asset.AssetGroup = this.assetGroup || "";
          asset.AssetCategory = this.assetCategory || "";
          asset.AssetClass = this.assetClass || "";
          asset.AssetScope = this.assetScope || "";
          asset.Tags = this.assetTags || [];
          asset.Visibility = this.assetVisibility || "I";
          asset.SystemAssetGroup = this.assetSystemGroup || "";
          // Push asset to our generic data that is added to the form data
          data = asset;
        }
        if (data) {
          // Attach our JSON object to the form data being submitted via POST
          formData.append("Data", JSON.stringify(data));
        }
      },

      init: function () {
        // Don't overwrite built in addedfile functionality as it will jack up the component
        this.on("addedfile", function (file) {
          self.countPending++;
          // If we're not uploading then incur the overhead of getting file contents to fire off file event
          if (!self.upload) {
            //console.error("ready to read file", file);
            const reader = new FileReader();
            //reader.addEventListener("loadend", (event) => {
            //  //console.error(event.target.result);
            //  self.fireFile(file, event.target.result);
            //});
            reader.onload = (event) => {
              //var contents = event.target.result;
              //console.log("File contents: " + contents);
              //console.error('ready to raise file upload event');
              self.fireFile(file, event.target.result);
              //console.error('done with raise file upload event');
            };
            reader.onerror = (event) => {
              Log.errorMessage(`File could not be read! Code ${event?.target?.error?.code}.`);
            };
            reader.readAsText(file);
            //console.error('file read');
            //console.error("reader",reader);
            self.uxService.detectChanges();
          }
        });
      }

    };

    // If attaching files to an existing asset id then we have a few differences
    // like sending this to a different url and not attaching JSON data that is
    // used when creating a new asset entry with the file.
    if (this.assetId) {
      this.config.url = this.assetService.buildFileUploadUrl(this.assetId, false);
      this.config.sending = (file, xhr, formData) => {
        // Update counts
        this.countPending--;
        this.countStarted++;
        this.countUploading++;
      };
    }

    // Don't create this config key unless we have a value we're assigning
    if (this.acceptedFileTypes) {
      this.config.acceptedFiles = this.acceptedFileTypes;
    }

    //console.error(this.config);

  }

  protected addAssetWithFileUploadUrl(): SafeUrl | string {
    // TODO allowAnonymous => now what????
    const apiProp: ApiProperties = Api.AssetAddFromUpload();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    let url = ApiHelper.buildApiAbsoluteUrl(apiCall, {});
    url = ApiHelper.addQueryStringToUrl(url, `token=${apiCall.token}`);
    //return this.sanitizer.bypassSecurityTrustUrl(url);
    return url;
  }


  public fireSuccess(event: any) {
    const cargo: any = {
      countPending: this.countPending,
      countStarted: this.countStarted,
      countUploading: this.countUploading,
      countDone: this.countDone,
      countSuccess: this.countSuccess,
      countError: this.countError
    };
    let data: any = event;
    if (Helper.isArray(event)) {
      if (event.length >= 2) {
        data = event[1];
      }
    }
    const payload: EventModel = new EventModel("success", event, data, new EventElementModel("upload"), cargo);
    this.success.emit(payload);
    //console.error(payload);
  }

  public fireError(event: any) {
    const cargo: any = {
      countPending: this.countPending,
      countStarted: this.countStarted,
      countUploading: this.countUploading,
      countDone: this.countDone,
      countSuccess: this.countSuccess,
      countError: this.countError
    };
    let data: any = event;
    if (Helper.isArray(event)) {
      if (event.length >= 2) {
        data = event[1];
      }
    }
    const payload: EventModel = new EventModel("error", event, data, new EventElementModel("upload"), cargo);
    this.error.emit(payload);
    //console.error(payload);
  }

  public fireFile(file: any, contents: any) {
    const cargo: any = {
      countPending: this.countPending,
      countStarted: this.countStarted,
      countUploading: this.countUploading,
      countDone: this.countDone,
      countSuccess: this.countSuccess,
      countError: this.countError
    };
    let data: any = { file: file, contents: contents };
    //if (Helper.isArray(event)) {
    //  if (event.length >= 2) {
    //    data = event[1];
    //  }
    //}
    const payload: EventModel = new EventModel("file", event, data, new EventElementModel("upload"), cargo);
    this.file.emit(payload);
    //console.error(payload);
  }

  @HostListener('window:dragenter', ['$event'])
  blockDragEnter($event) {
    // Prevent dropping files outside of drop zone since that will cause browser to navigate to the dropped file and maybe break our host page
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = "none";
    $event.dataTransfer.dropEffect = "none";
  }
  @HostListener('window:dragover', ['$event'])
  blockDragOver($event) {
    // Prevent dropping files outside of drop zone since that will cause browser to navigate to the dropped file and maybe break our host page
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = "none";
    $event.dataTransfer.dropEffect = "none";
  }
  @HostListener('window:drop', ['$event'])
  blockDrop($event) {
    // Prevent dropping files outside of drop zone since that will cause browser to navigate to the dropped file and maybe break our host page
    $event.preventDefault();
    $event.dataTransfer.effectAllowed = "none";
    $event.dataTransfer.dropEffect = "none";
  }


}
