import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'app-request-formats-methods',
  templateUrl: './request-formats-methods.component.html',
  styleUrls: ['./request-formats-methods.component.scss']
})
export class RequestFormatsMethodsComponent implements OnInit {

  constructor(protected appService: AppService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "Request Methods";
  }

}
