import { Component, OnInit } from '@angular/core';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';

@Component({
  selector: 'ib-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.css']
})
export class NavFooterComponent implements OnInit {

  constructor(public nav: NavService) { }

  ngOnInit() {
  }

}
