import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiEndpointViewModel } from 'projects/core-lib/src/lib/api/ApiModels';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { takeUntil } from 'rxjs/operators';
import { FormBaseClass } from 'projects/common-lib/src/lib/form/form-base-class';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { ApiDocsService } from 'projects/core-lib/src/lib/services/api-docs.service';
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';

@Component({
  selector: 'app-data-model-object',
  templateUrl: './data-model-object.component.html',
  styleUrls: ['./data-model-object.component.scss'],
  providers: [FormStatusService]
})
export class DataModelObjectComponent extends FormBaseClass<m.DataModel> implements OnInit, OnChanges {

  @Input() dataModelName: string = "";
  @Input() sort: "natural" | "asc" | "desc" = "natural";

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected formService: FormStatusService,
    protected docsService: ApiDocsService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super(appService, uxService, formService, true, Constants.ContactType.Directory);
  }

  ngOnInit() {

    super.ngOnInit();

    // In some scenarios our id is provided in the route instead of via input attribute
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
      let id = params["id"];
      if (id) {
        this.dataModelName = id;
        this.loadData();
      }
    });

    this.loadData();

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.loadData();
  }

  loadData() {
    if (!this.dataModelName) {
      return;
    }
    this.loading = true;
    this.docsService.getDataModel(this.dataModelName, true).subscribe(model => {
      this.data = model;
      this.loading = false;
      if (model) {
        this.appService.title = model.Name;
      } else {
        this.appService.title = this.dataModelName;
      }
    });
  }

  onSort(sort) {
    this.sort = sort;
  }

}
