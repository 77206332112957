import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'app-policy-terms-of-use',
  templateUrl: './policy-terms-of-use.component.html',
  styleUrls: ['./policy-terms-of-use.component.scss']
})
export class PolicyTermsOfUseComponent implements OnInit {

  get appName(): string {
    return this.appService.appInfoOrDefault.Branding.ApplicationNameShort;
  }

  constructor(protected appService: AppService) {
    // Authenticate user (this will redirect to login if needed)
    this.appService.tryGetUser(true);
  }

  ngOnInit() {
    this.appService.title = "Terms of Use";
  }


}
